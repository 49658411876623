import React from 'react'

const NotFoundView = () => (
    <div className="animated fadeInRight">
        <div className="row no-gutters justify-content-center hp-mt-3">
            <div className="col-12 text-center">
                <img alt="" src="/img/infiniteLogo.png" />
            </div>
            <div className="col-12 text-center hp-mt-1">
                <div className="hh1">404 Not Found</div>
            </div>
            <div className="col-12 text-center hp-mt-1">
                <div className="c3">This page does not exist!</div>
            </div>
        </div>
    </div>
)

export default NotFoundView