import _ from 'lodash'
import React, {Component} from 'react'
import withStyles from 'react-jss'
import classNames from 'classnames'
import {RadioGroup, Radio} from 'react-radio-group'
import styles from './styles'
import {
  QUESTION_TYPE_TEXT,
  QUESTION_TYPE_TEL,
  QUESTION_TYPE_EMAIL
} from '../../../common/const'

class TextQuestion extends Component {
  
  constructor(props) {
    super(props)

    this.state = {
      value: {
        id: null,
        title: 'New Quetion',
        order: 1,
        questionType: QUESTION_TYPE_TEXT,
        ...props.value 
      }
    }

    this.onChange = this
      .onChange
      .bind(this)
    this.onRemoveClick = this
      .onRemoveClick
      .bind(this)
    
  }

  componentWillReceiveProps(nextProps) {
    this.setState(state => ({
      value: nextProps.value || state.value
    }))
  }

  onRemoveClick = e => {
    if (typeof(this.props.onRemoveClick) === 'function') {
      this
        .props
        .onRemoveClick(this.state)
    }
  }

  onChange = nextValue => {
    this.setState(state => {
      const nextState = {
        ...state.value,
        ...nextValue
      }
      if (typeof(this.props.onChange) === 'function') {
        this
          .props
          .onChange(nextState)
      }
      return {
        value: nextState
      }
    })
  }

  render() {
    const {classes} = this.props
    const {value} = this.state
    
    return (
      <div className={classes.question}>
        <h4>
          <button
            className={classNames({
            'btn': true,
            [classes.btnDelete]: true
          })}
            onClick={this.onRemoveClick()}>
            <i className="fa fa-minus-circle"></i>
          </button>
          <input
            type="text"
            value={value.title}
            placeholder="คำถาม"
            onChange={e => this.onChange({ title: e.target.value})}/>

        </h4>
        <div className={classes.questionChoice}>
          <RadioGroup
            selectedValue={value.questionType}
            onChange={value => this.onChange({ questionType: value })}>
            <label className={classes.label}><Radio value={QUESTION_TYPE_TEXT}/>&nbsp;ข้อความ</label>
            <label className={classes.label}><Radio value={QUESTION_TYPE_TEL}/>&nbsp;หมายเลขโทรศัพท์</label>
            <label className={classes.label}><Radio value={QUESTION_TYPE_EMAIL}/>&nbsp;อีเมล์</label>
          </RadioGroup>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(TextQuestion)