import React, { Component } from 'react'
import PropTypes from 'prop-types'

import AsyncSelectInput from '../common/AsyncSelectInput'
import datastore from '../../datastore'
import ConfirmModal from '../ConfirmModal'

class SelectStaffModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                staffId: null,
            }
        }
    }

    getStaffOptions = query => {
        return datastore.other.lookupStaff(query)
            .then(res => {
                const staffOptions = res.results.map(s => (
                    { value: s[0], label: s[1] }
                )) 

                return {
                    options: staffOptions
                }
            })
    }

    assignStaff = () => {
        this.props.assignStaff(this.state.data.staffId)
    }

    render() {
        return (
            <ConfirmModal
                ref="modal"
                title="เลือกเจ้าหน้าที่ผู้รับผิดชอบ"
                onConfirm={ this.assignStaff }
            >
                <AsyncSelectInput 
                    name="staffId" 
                    target={ this } 
                    placeholder="เลือกเจ้าหน้าที่"
                    stateKey="data"
                    loadOptions={ this.getStaffOptions }
                />
            </ConfirmModal>
        )
    }
}

SelectStaffModal.propTypes = {
    assignStaff: PropTypes.func,
}

export default SelectStaffModal
