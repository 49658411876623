import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'

import config from '../common/config'

class NoAccessView extends Component {
    render() {
        const { role } = this.props
        const defaultUrl = config.permissions[role][0]
        return <div className="animated fadeInRight">
            <div className="row no-gutters justify-content-center hp-mt-3">
                <div className="col-12 text-center">
                    <img alt="" src="/img/infiniteLogo.png" />
                </div>
                <div className="col-12 text-center hp-mt-1">
                    <div className="hh1">คุณไม่สามารถเข้าถึงหน้านี้ได้</div>
                </div>
                <div className="col-12 text-center hp-mt-1">
                    <Link to={defaultUrl}>กลับหน้าแรก</Link>
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        role: state.auth.role
    }
}

export default connect(mapStateToProps, null)(NoAccessView)
