import React,{ PureComponent } from 'react';
import _ from 'lodash'
import { Link } from 'react-router'

import Wrapper from '../../layouts/Wrapper';
import Card from '../../components/common/Card';
import datastore from '../../datastore';
import config from '../../common/config'
import Table from '../../components/common/tables/Table';
import AddDocumentModal from './AddDocumentModal';
import DeleteDocumentModal from './DeleteDocumentModal';

class BookInfoView extends PureComponent {
    constructor(props){
        super(props);
            this.state = {
            showDocument: true,
            selectedDocumentId: 0,
            selectedFilename: '',
            documents: []
        }

        this.addDocumentHandler = this.addDocumentHandler.bind(this);
        this.deleteButtonClickHandler = this.deleteButtonClickHandler.bind(this)
        this.cancelButtonClickHandler = this.cancelButtonClickHandler.bind(this)
        this.uploadButtonClickHandler = this.uploadButtonClickHandler.bind(this)
        this.downloadButtonClickHandler = this.downloadButtonClickHandler.bind(this)
        this.deleteDocumentButtonClickHandler = this.deleteDocumentButtonClickHandler.bind(this)


        this.columns = [
            {
                header: '',
                accessor: 'status',
                render: value => <StatusIcon status={ value } />,
                width: 0.25
            },
            {
                header: '',
                accessor: 'name',
                subaccessor: 'url',
                render: (value, subvalue) => {
                    return ( 
                        <div className="h-100 d-flex flex-column justify-content-center">
                            <h6>{ value }</h6> 
                            <p className="f13 grey mb-0">{ subvalue || 'ไม่มีไฟล์' }</p>
                        </div>
                    )
                }
            },
            {
                header: '',
                accessor: 'status',
                subaccessor: ['name', 'id'],
                filterable: true,
                width: 3,
                render: (value, subvalue, id) => <UploadButton 
                    status={ value }
                    filename={ subvalue[0] }
                    documentId={ subvalue[1] }
                    deleteButtonClickHandler={ this.deleteButtonClickHandler }
                    cancelButtonClickHandler={ this.cancelButtonClickHandler }
                    uploadButtonClickHandler={ this.uploadButtonClickHandler }
                    deleteDocumentButtonClickHandler={ this.deleteDocumentButtonClickHandler }
                    downloadButtonClickHandler={ () => this.downloadButtonClickHandler(id) }
                />
            }
        ]
  }

    componentWillMount() {
        datastore.document.getDocumentInfo(this.props.record.id)
        .then(info => {
            this.setState({documents: info.documents})
        })
    }

    addDocumentHandler(filename, file) {
        datastore.document.addGeneralDocument(this.props.record.id, filename, file)
        .then(res => {
            return datastore.document.getDocumentInfo(this.props.record.id)
        })
        .then(info => {
            this.setState({
            documents: info.documents,
            selectedDocumentId: info.documents[0].id
            })
        })
    }

    deleteDocumentHandler(documentId) {
        const recordId = this.props.record.id
        datastore.document.deleteGeneralDocument(recordId, documentId)
        .then(res => {
            return datastore.document.getDocumentInfo(this.props.record.id)
        })
        .then(info => {
            this.setState({documents: info.documents})
        })
    }

    deleteButtonClickHandler(filename, documentId) {
        this.setState({ 
        selectedDocumentId: documentId,
        selectedFilename: filename
        })

        this.refs.deleteDocumentModal.refs.modal.openModal()
    }

    uploadButtonClickHandler(documentId) {
        this.setState({ 
            selectedDocumentId: documentId
        })

        this.refs.selectFileModal.refs.modal.openModal()
    }

    downloadHandler(recordId, documentId) {
      datastore.document.downloadGeneralDocument(recordId, documentId)
          .then(res => {
              console.log(`downloaded ${documentId}`)
              console.log(res)
          })
    }

    cancelButtonClickHandler() {
        console.log("cancel uploading ...")
    }

    downloadButtonClickHandler(documentId) {
        const recordId = this.props.record.id
        console.log(`downloading ${this.state.selectedDocumentId} ..`)
        this.downloadHandler(recordId, documentId)
    }

    deleteDocumentButtonClickHandler(documentId) {
        console.log(`deleting ${documentId}`)
        this.deleteDocumentHandler(documentId)
    }

    render(){
        const { footer } = this.props
        return (
        <Wrapper>
            <Card>
            <div className="d-flex flex-row justify-content-between align-items-center">
                    <h4 style={{color: "#404040" ,fontSize: "20px"}}>เอกสารที่เกี่ยวข้อง</h4>
                    <div>
                        <button 
                            className="btn btn-outline" 
                            style={{ width: "11rem" }}
                            onClick={ () => this.setState({ 
                                showDocument: !this.state.showDocument 
                            })} 
                        >
                            { this.state.showDocument
                                ? ('ซ่อนรายการเอกสาร')
                                : ('ดูรายการเอกสาร') 
                            }
                        </button>
                    </div>
            </div>
            { this.state.showDocument
                ? <div className="mt-15">
                    { _.isEmpty(this.state.documents)
                        ? <p className="f14 text-center grey">
                            <span>ยังไม่มีรายการเอกสาร</span>
                        </p>
                        : <Table
                            noColumnHeader 
                            noHeader
                            columns={ this.columns } 
                            values={ this.state.documents } 
                            itemsPerPage={ config.itemsPerPage } 
                            rowClickCallback={ this.rowClickCallback } 
                        />
                    }
                    <AddNewDocumentButton 
                        handler={ () => this.refs.addDocumentModal.refs.modal.openModal() }
                    />
                </div>
                : null 
            }
            { footer }
            <AddDocumentModal
                ref="addDocumentModal"
                addDocumentHandler={ (filename,file) => this.addDocumentHandler(filename, file) }
            />
            <DeleteDocumentModal
                ref="deleteDocumentModal"
                text={ `คุณต้องการลบเอกสาร "${ this.state.selectedFilename }" หรือไม่?` } 
                deleteHandler={ () => this.deleteDocumentHandler(this.state.selectedDocumentId) }
            />
            </Card>
        </Wrapper>
        )
    }
}

export default BookInfoView;

export const FILE_STATUS = {
  uploaded: 'UPLOADED',
  uploading: 'UPLOADING',
  notUploaded: 'NOTUPLOADED'
}

const UploadButton = ({ 
  status, 
  filename,
  documentId,
  deleteButtonClickHandler,
  downloadButtonClickHandler,
  deleteDocumentButtonClickHandler,
}) => {
  let button
  switch (status) {
      case FILE_STATUS.uploaded:
          button = <div className="d-flex flex-row align-items-center">
              <Link 
                  className='link mr-15' 
                  onClick={ () => downloadButtonClickHandler() }
              >
                  <i className="fa fa-eye" style={{ fontSize:"1.5rem" }} />
              </Link>
              <Link 
                  className='link-warning' 
                  onClick={ () => deleteButtonClickHandler(filename, documentId) }
              >
                  <i className="fa fa-trash" style={{ fontSize:"1.5rem" }} />
              </Link>
          </div>
          break
      default:
          button = null
  }

  return (
      <div className="h-100 d-flex flex-column justify-content-center align-items-end">
          { button }
      </div>
  )
}

const StatusIcon = ({ status }) => {
  let src
  switch (status) {
      case FILE_STATUS.uploaded:
          src = "/img/i_uploaded.svg"
          break
      case FILE_STATUS.uploading:
          src = "/img/i_uploading.gif" 
          break
      case FILE_STATUS.notUploaded:
          src = "/img/i_not_uploaded.svg"
          break
      default:
          src = "/img/add.png"
  }

  return (
      <div className="h-100 d-flex flex-column justify-content-center"> 
          <img alt="" src={src} height="24px" width="24px" />
      </div>
  )
}

const AddNewDocumentButton = ({ handler }) => {
  return (
      <div 
          className="clickable"
          onClick={ handler }
          style={{ maxWidth:"160px"}}
      >
          <p className="f14 mb-0 ml-1">
              <i className="fa fa-plus grey" style={{ width: "24px" }} />
              <span>เพิ่มรายการเอกสาร</span>
          </p>
      </div>
  )
}