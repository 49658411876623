import React, { Component } from 'react'

export default class FormNewLine extends Component {

    render() {
        return (
            <div className="w-100 mb-35" />
        )
    }
}
