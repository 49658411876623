import apiClient from '../apis'
import { convertInspectionsInfo } from '../common/util'

export function getProducts(offset, pageSize, sortKey, sortOrder, filters, query, occupied, isTransfered) {
    return apiClient.product.getProducts(offset, pageSize, sortKey, sortOrder, filters, query, occupied, isTransfered)
        .then(res => {
            const products = res.values.map(p => convertProductInfo(p))
            return {
                length: res.length,
                values: products
            }
        })
}

export function getDefectProducts(offset, pageSize, sortKey, sortOrder, query) {
    return apiClient.product.getDefectProducts(offset, pageSize, sortKey, sortOrder, query)
        .then(res => {
            const products = res.values.map(p => convertProductInfo(p))
            return {
                length: res.length,
                values: products
            }
        })
}

export function exportProduct() {
    return apiClient.product.exportProduct()
}

export function getProduct(productId) {
    return (
        apiClient.product.getProduct(productId)
        .then(product => {
            return convertProductInfo(product)
        })
    )
}

export function assignQCInspection(productId, inspectionDate, staffId, engineerName) {
    // if (IS_MOCK) {
    //     console.log(`Assigning: ${productId} - ${staffId} - ${engineerName}`)
    //     return createPromise()
    // }

    return apiClient.product.assignQCInspection(
        productId, 
        inspectionDate, 
        staffId, 
        engineerName
    )
}

export function createProduct(productInfo) {
    return apiClient.product.createProduct(productInfo)
}

function convertProductInfo(product) {
    if (!product) {
        return null
    }

    const inspections = convertInspectionsInfo(product.inspections, "qc")
    return {
        id: product.product_id.toString(),
        projectId: product.project_id.toString(),
        orderingSkuCode: product.sku_code,
        buildingId: product.building_id,
        roomTypeId: product.room_type_id,
        roomVeiwId: product.room_view_id,
        standardPrice: product.standard_price,
        standardDown: product.standard_down,
        standardBook: product.standard_book,
        status: product.status,
        floorPlanImg: product.floor_plans,
        inspections: inspections,
        productTypeId: product.product_type_id,
        addressNo: product.address_no,
        isReserved: product.is_reserved,
        isTransferred: product.is_transfer,
        passQc: product.pass_qc
    }
}
