import React, { Component } from 'react'
import PropTypes from 'prop-types'

import TextWithLabel from '../../../components/common/TextWithLabel'
import Table from '../../../components/common/tables/Table'
import { translate, openNewWindowSameHost } from '../../../common/util'

class DefectsTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showDefectTable: false,
        }

        this.columns = [
            {
                header: 'จุดที่',
                accessor: 'id',
            },
            {
                header: 'รายระเอียดขุดบกพร่อง',
                accessor: 'title',
            },
            {
                header: 'ชนิดงาน',
                accessor: 'defectType',
                filterable: true
            },
            {
                header: 'ตำแหน่ง',
                accessor: 'location',
            },
            {
                header: 'สถานะการแก้ไข',
                accessor: 'status',
                filterable: true,
                render: value => {
                    const indicatorColor = value === 'แก้ไขแล้ว' ? 'green' : 'red'
                    const text = value === 'แก้ไขแล้ว' ? 'แก้ไขแล้ว' : 'ยังไม่ได้แก้ไข'
                    return <span><i className='fa fa-circle mr-1' style={{ color: indicatorColor }} />{ text }</span>
                }
            },
        ]
    }

    render() {
        const { defects, status, rowClickCallback, inspectionId, recordId } = this.props
        if (!defects) {
            return null
        }

        const hasDefect = defects.length > 0
        return (
            <div>
                <div className="d-flex flex-row py-1 justify-content-between align-items-center">
                    <div className="d-flex flex-column flex-md-row justify-content-start" style={{ width: "40%" }}>
                        <Status status={ status } />
                        <TextWithLabel label="จุดแก้ไขเพื่มเติม">
                            { defects.length }
                        </TextWithLabel>
                    </div>
                    <div className="d-flex flex-row flex-end">
                        <button 
                            onClick={() => openNewWindowSameHost(`/pdefect-summary/${recordId}/${inspectionId}`, null)} 
                            className="btn btn-outline mr-05" 
                            style={{ width: "fit-content", fontSize: "13px" }}
                            disabled={!hasDefect}
                        >
                            <i className="fa fa-download" /> สรุปรายการแก้ไข
                        </button>
                        <button 
                            onClick={() => openNewWindowSameHost(`/pdefect-list/${recordId}/${inspectionId}`, null)} 
                            className="btn btn-outline mr-05" 
                            style={{ width: "fit-content", fontSize: "13px" }}
                        >
                            <i className="fa fa-download" /> แจ้งรายการแก้ไข
                            {/* ดาวน์โหลดเอกสาร */}
                        </button>
                        <button 
                            disabled={!hasDefect}
                            className="btn btn-main" style={{ width: "8rem", fontSize: "13px" }}
                            onClick={ () => this.setState({ 
                                showDefectTable: !this.state.showDefectTable 
                            })} 
                        >
                            { this.state.showDefectTable
                                ? "ซ่อนรายการแก้ไข"
                                : "ดูรายการแก้ไข"
                            }
                        </button>
                    </div>
                </div>
                { this.state.showDefectTable
                    ? <Table 
                        columns={ this.columns } 
                        values={ defects }
                        rowClickCallback={ rowClickCallback } 
                    />
                    : null 
                }
            </div>
        )
    }
}

DefectsTable.propTypes = {
    defects: PropTypes.array.isRequired,
    rowClickCallback: PropTypes.func,
    status: PropTypes.string,
    inspectionId: PropTypes.string,
    recordId: PropTypes.string,
}

export default DefectsTable

const Status = ({ status }) => {
    const text = translate('inspection_statuses', status)
    const color = status === '3' ? 'green'
        : status === '4' ? 'red'
        : 'black'

    return <div className="mr-3">
        <TextWithLabel label="สถานะ" color={ color }>{ text }</TextWithLabel>
    </div>
}