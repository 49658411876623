import React, { PureComponent } from 'react';
import Wrapper from '../../layouts/Wrapper';
import BasicCampaignDetailView from '../basicInfo/BasicCampaignDetail';
import datastore from '../../datastore';
import Card from '../../components/common/Card';
import LazyTable from '../../components/common/tables/LazyTable';
import CreateAdsModal from '../../components/forms/CreateAdsForm';
import PromotionModal from './Modal/PromotionModal';

export default class CampaignDetailView extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      record: null,
      selectedRecord: null,
      selectedPromotion: null
    }

    this.goToEditPage = this.goToEditPage.bind(this);
    this.getPromotionList = this.getPromotionList.bind(this);
    this.getAdsList = this.getAdsList.bind(this);
    this.onCreateAds = this.onCreateAds.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onClosePromotionModal= this.onClosePromotionModal.bind(this)
    this.onUpdateAds = this.onUpdateAds.bind(this);
    this.openCreateAdsModal = this.openCreateAdsModal.bind(this);
    this.openPromotionModal = this.openPromotionModal.bind(this);
    this.adsColumns = [
      {
        header: 'สื่อโฆษณา',
        unSortable: true,
        accessor: 'ads_name'
      },
      {
        header: 'ชนิดโฆษณา',
        unSortable: true,
        accessor: 'ads_type'
      },
      {
        header: 'งบประมาณ',
        unSortable: true,
        accessor: 'cost',
        render: value => {
          return <span>{value} บาท</span>
        }
      },
      {
        header: 'ผู้เยี่ยมชม',
        unSortable: true,
        accessor: 'visitor'
      },
      {
        header: 'ผู้จอง',
        unSortable: true,
        accessor: 'booked'
      },
      {
        header: 'ระยะเวลา',
        unSortable: true,
        accessor: 'duration',
        render: (value) => {
          return <span>{value} วัน</span>
        }
      }
    ]
    this.promotionColumns = [
      {
        header: 'โปรโมชั่น',
        unSortable: true,
        accessor: 'promotion_name'
      },
      {
        header: 'งบประมาณ',
        unSortable: true,
        accessor: 'cost',
        render: value => {
          return <span>{value} บาท</span>
        }
      },
      {
        header: 'ระยะเวลา',
        unSortable: true,
        accessor: 'duration',
        render: (value) => {
          return <span>{value} วัน</span>
        }
      }
    ]
  }

  componentWillMount() {
    const campaignId = this.props.params.campaignId
    datastore.campaign.getCampaignDetail(campaignId)
      .then(record => {
        this.props.setHeader(
          record.campaign_name, 
          [this.goToEditPage ,this.openCreateAdsModal, this.openPromotionModal],
          ['แก้ไขแคมเปญ', 'สร้างสื่อโฆษณา', 'สร้างโปรโมชั่น']
        )
        this.setState({
          record
        })
      })
  }

  goToEditPage() {
    const campaignId = this.props.params.campaignId
    this.props.router.push(`/campaign/${campaignId}/edit-campaign`)
  }

  openCreateAdsModal(selectedRecord) {
    this.setState({selectedRecord})
    this.refs.createAds.openModal()
  }

  openPromotionModal(selectedPromotion) {
    this.setState({selectedPromotion})
    this.refs.promotionModal.openModal()
  }

  onCreateAds() {
    const campaignId = this.props.params.campaignId
    datastore.campaign.getCampaignDetail(campaignId)
      .then(record => {
        this.setState({
          record
        })
      })
  }

  onUpdateAds() {
    this.setState({selectedRecord: null})

    const campaignId = this.props.params.campaignId
    datastore.campaign.getCampaignDetail(campaignId)
      .then(record => {
        this.setState({
          record
        })
      })
  }

  onCloseModal() {
    this.setState({selectedRecord: null})
    this.refs.createAds.closeModal()
  }

  onClosePromotionModal() {
    this.setState({selectedPromotion: null})
    this.refs.promotionModal.closeModal()
  }

  getAdsList() {
    const campaignId = this.props.params.campaignId
    return datastore.campaign.getAdsList(campaignId)
  }

  getPromotionList(offset, pageSize) {
    const campaignId = this.props.params.campaignId
    return datastore.campaign.getPromotionList(campaignId, offset, pageSize)
  }

  render() {
    const campaignId = this.props.params.campaignId
    const { options } = this.props;
    return (
      <Wrapper>
        <BasicCampaignDetailView record={ this.state.record } />
        <h2>สื่อโฆษณา</h2>
        <div className="mb-2 px-15 py-1">
          <Card>
            <LazyTable
              columns={ this.adsColumns }
              getValues={ this.getAdsList }
              filterOptions={ options }
              rowClickCallback={ (row) => this.openCreateAdsModal(row) }
              ref="adsListTable"
            />
          </Card>
        </div>
        <CreateAdsModal
          ref="createAds"
          campaignId={campaignId}
          selectedRecord={this.state.selectedRecord}
          onCreateAds={this.onCreateAds}
          onCloseModal={this.onCloseModal}
          onUpdateAds= {this.onUpdateAds}
        />
        <h2>โปรโมชั่น</h2>
        <div className="mb-2 px-15 py-1">
          <Card>
            <LazyTable
              columns={ this.promotionColumns }
              getValues={ this.getPromotionList }
              filterOptions={ options }
              rowClickCallback={ (row) => this.openPromotionModal(row) }
              ref="adsListTable"
            />
          </Card>
        </div>
        <PromotionModal 
          ref="promotionModal"
          campaignId={campaignId}
          selectedRecord={this.state.selectedPromotion}
          onCreatePromotion={this.onCreateAds}
          onCloseModal={this.onClosePromotionModal}
        />
      </Wrapper>
    )
  }
}