import React, { Component } from 'react'
import { connect } from 'react-redux'
import JSONBig from 'json-bigint'
import moment from 'moment'
import _ from 'lodash'

import datastore from '../../datastore'
import Card from '../../components/common/Card'
import Wrapper from '../../layouts/Wrapper'
import { formatDateTime } from '../../common/util'
import AssignModal from './AssignModal'
import ConfirmationModal from './ConfirmationModal'
import Calendar from '../../components/common/Calendar'

class AssignView extends Component {
    appointments = {
        '2018-08-24':  [
            {
                time: '11:00',
                skuCode: '151/4',
                technicianName: 'ช่างทัก'
            },
            {
                time: '12:00',
                skuCode: '151/4',
                technicianName: 'ช่างทัก'
            }
        ],
        '2018-08-11': [{
            time: '20:00',
            skuCode: '151/2',
            technicianName: 'ช่างทัก'
        }]
    }

    state = {
        selectedDate: null,
        appointments: null,
        data: {
            type: null,
            time: null,
            technician: null,
            selectedDefects: {},
            ready: false,
        }
    }

    updateAssignedDefects = () => {
        datastore.warranty.getAssignedDefects()
            .then(res => {
                const assignedDefects = {}
                const defectsByDate = res.items.reduce((acc, item) => {
                    const skuCode = item.sku_code
                    const datetime = moment(item.tentative_date)
                    const datestr = datetime.format('YYYY-MM-DD')
                    const timestr = datetime.format('HH:mm')

                    if (!(datestr in acc)) {
                        acc[datestr] = {}
                    }

                    if (!(timestr in acc[datestr])) {
                        acc[datestr][timestr] = {}
                    }

                    if (!(skuCode in acc[datestr][timestr])) {
                        acc[datestr][timestr][skuCode] = []
                    }

                    acc[datestr][timestr][skuCode].push(item)

                    assignedDefects[item.id] = true

                    return acc
                }, {})

                const appointments = Object.entries(defectsByDate).reduce((acc, [datestr, bytime]) => {
                    acc[datestr] = _.flatten(Object.entries(bytime).map(([timestr, bysku]) => {
                        return Object.entries(bysku).map(([skuCode, defects]) => {
                            return {
                                id: defects[0].id,
                                time: timestr,
                                skuCode,
                                technicianName: defects[0].technician_name,
                                before: defects[0].before_link
                            }
                        })
                    }))

                    return acc
                }, {})

                this.setState({
                    defectsByDate,
                    assignedDefects,
                    appointments
                })
            })
    }

    componentWillMount() {
        this.props.setHeader('ตารางงาน')
        this.updateAssignedDefects()
    }

    openAssignModal = date => {
        this.setState({ selectedDate: date })
        if(this.refs.assignModal){
            this.refs.assignModal.openModal()
        }
    }

    resetDataState = () => {
        this.setState({
            data: {
                type: null,
                time: null,
                technician: null,
                selectedDefects: {},
                ready: false,
            }
        })
    }

    confirm = () => {
        const { data, selectedDate } = this.state
        const datetime = formatDateTime(`${selectedDate.format('YYYY-MM-DD')} ${data.time}`)

        const ps = data.selectedDefects.map(d => {
            return datastore.warranty.assign(
                d.id.toString(), 
                datetime, 
                data.technician.account_id.toString())
        })

        Promise.all(ps)
            .then(res => {
                this.resetDataState()
                this.updateAssignedDefects()
            })

    }

    assign = () => {
        const technician = JSONBig.parse(this.state.data.technician)
        const selectedDefects = (Object.entries(this.state.data.selectedDefects)
            .map(([d, v]) => {
                return v ? JSONBig.parse(d) : null
            }))
            .filter(x => x !== null)

        const data = {
            technician,
            time: this.state.data.time,
            type: this.state.data.type,
            selectedDefects,
            ready: true,
        }

        this.setState({ data }, () => this.refs.confirmationModal.refs.modal.openModal())
    }

    render() {
        const { profile } = this.props
        const url = window.location.href

        if (!this.state.appointments) {
            return null
        }

        return (
            <Wrapper>
                <Card>
                    <Calendar appointments={this.state.appointments} onClickDate={url.indexOf(`?`) > 0 ? this.openAssignModal : ''} />
                    <AssignModal
                        ref="assignModal"
                        date={this.state.selectedDate}
                        assign={this.assign}
                        parent={this}
                        profile={profile}
                        assignedDefects={this.state.assignedDefects}
                        onClose={this.resetDataState}
                    />
                    <ConfirmationModal
                        ref="confirmationModal"
                        confirm={this.confirm}
                        data={this.state.data}
                        onClose={this.resetDataState}
                    />
                </Card>
            </Wrapper>
        )
    }
}

function mapStateToProps(state) {
    return {
        options: state.other.options,
        profile: state.auth.profile
    }
}

export default connect(mapStateToProps, null)(AssignView)
