import React, { Component } from 'react'

export default class ModalFooter extends Component {
    render() {
        const { children } = this.props
        return (
            <div className="d-flex flex-row justify-content-end mt-25">
                { children }
            </div>
        )
    }
}