import React, { Component } from 'react'
import _ from 'lodash'

import Wrapper from '../../layouts/Wrapper'
import TextInput from '../../components/common/TextInput'
import datastore from '../../datastore'
import { translate } from '../../common/util'

export default class SelectProductView extends Component {
    state = {
        data: {
            query: '',
        },
        selectedProduct: null,
        searchResults: [],
        customerName: null,
    }

    searchProduct = () => {
        if (!this.state.data.query) {
            this.setState({
                searchResults: []
            })
        }  else {
            datastore.product.getProducts(0, 20, 'sku_code', false, null, this.state.data.query, false)
                .then(res => {
                    this.setState({
                        searchResults: res.values
                    })
                })
        }
    }

    selectProduct = product => {
        this.setState({
            selectedProduct: product
        })
    }

    reserve = (firstName, lastName, productId) => {
        console.log(`reserve ${productId} for ${firstName}`)
        datastore.sale.quickSell(firstName, lastName, productId)
            .then(res => {
                this.goToSuccessStep()
            })
    }

    componentWillMount() {
        this.props.setHeader('เลือกห้อง')
        const customerId = this.props.params.customerId
        datastore.customer.getCustomer(customerId)
            .then(customer => {
                const customerName = `${customer.firstName} ${customer.lastName}`
                this.setState({
                    customerName,
                    firstName: customer.firstName,
                    lastName: customer.lastName
                })
            })
    }

    goToSuccessStep = () => {
        this.props.router.push('/reserve/success')
    }

    goToSearchStep = () => {
        this.props.router.push('/reserve/search')
    }

    render() {
        return <Wrapper>
            <div className="row justify-content-center pt-3">
                <div className="col-12 col-md-6">
                    <div className="input-group align-items-center">
                        <TextInput
                            name="query"
                            placeholder="รหัสสินค้า"
                            target={this}
                            stateKey="data"
                            onChange={() => this.searchProduct()}
                        />
                        <i className="fa fa-search" style={{ color: "#00ACE1" }}></i>
                    </div>
                    { !_.isEmpty(this.state.searchResults) ? 
                        <div>
                            <div className="mt-2 mb-05">ผลการค้นหา</div>
                            <div className="p-05" style={{ backgroundColor: "rgba(0,0,0,0.1)", borderRadius: "10px" }}>
                                { this.state.searchResults.map(product => {
                                    return <SearhResultItem 
                                        key={product.skuCode} 
                                        product={product} 
                                        selectProduct={product => this.selectProduct(product)} 
                                    />
                                })}
                            </div>
                        </div>
                    : null }
                    <div className="my-2">
                        <h2>{`ลูกค้า: ${this.state.customerName}`}</h2>
                        <h2>{`ห้อง: ${this.state.selectedProduct ? this.state.selectedProduct.skuCode : 'กรุณาเลือกห้อง'}`}</h2>
                    </div>
                    <button 
                        onClick={() => this.reserve(this.state.firstName, this.state.lastName, this.state.selectedProduct.id.toString())} 
                        className="mb-05 py-1 btn btn-main w-100" 
                        style={{ flexGrow: 1 }}
                        disabled={this.state.selectedProduct === null}
                    >
                        จองห้อง
                    </button>
                    <button
                        onClick={this.goToSearchStep}
                        className="py-1 btn btn-red-outline w-100" 
                        style={{ flexGrow: 1 }}
                    >
                        ยกเลิก
                    </button>
                </div>
            </div>
        </Wrapper>
    }
}

const SearhResultItem = ({ product, selectProduct }) => {
    return <div className="clickable" onClick={() => selectProduct(product)}>
        <p className="m-0 p-02">
            {product.skuCode} &nbsp; {translate('buildings', product.buildingId.toString())} &nbsp; {translate('room_types', product.roomTypeId.toString())}
        </p>
    </div>
    
}
