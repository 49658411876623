import _ from 'lodash'

import { NA_TEXT } from '../common/const'
import { postRequest, getRequest, putRequest, deleteRequest, getReportWithBranchId, putRequestForUninterested } from '../common/customUtil'
import config from '../common/config'

export function getLeads(offset, pageSize, query) {
    let data = {
        offset: offset,
        limit: pageSize,
        qualified: false
    }

    if(query !== ''){
        data = {
            ...data,
            first_name: query
        }

        return getRequest(config.shoyuApiUrl,`/web/leads/search`, data)
        .then(res => {
            return { values: res.table_data_rows, length: res.total }
        })
        .catch(err => {
            console.error(`getLead failed with ${err}`)
        })
    }
    else{
        return getRequest(config.shoyuApiUrl,`/web/leads`, data)
        .then(res => {
            return { values: res.table_data_rows, length: res.total }
        })
        .catch(err => {
            console.error(`getLead failed with ${err}`)
        })
    }
}

export function searchLeads(firstName) {
    const data = {
        first_name: firstName
    }

    return getRequest(config.shoyuApiUrl, `/web/leads/search`, data)
        .then(res => {
            return { values: res.table_data_rows, length: res.total }
        })
        .catch(err => {
            console.error(`getLead failed with ${err}`)
        })
}

export function exportLead() {
    return getReportWithBranchId(config.shoyuApiUrl, '/web/leads/export')
}

export function getLeadOptions(){
    return getRequest(config.shoyuApiUrl,'/web/constant/lead')
}

export function getLead(leadId){
    return getRequest(config.shoyuApiUrl,`/web/leads/${leadId}`)
}

export function createLead(leadInfo){
    return  postRequest(config.shoyuApiUrl ,'/web/leads', convertLeadInfo(leadInfo))
}
export function createAnswer(leadId,answers){
    return postRequest(config.shoyuApiUrl, `/web/leads/${leadId}/answers`, convertAnswer(answers))
}

export function createTestLead(leadInfo){
    return  Promise.resolve({message: ""})
}

export function updateLeadInfo(leadId,leadInfo){
    return putRequest(config.shoyuApiUrl, `/web/leads/${leadId}`, leadInfo)
}

export function updateAnswers(leadId,answers){
    return putRequest(config.shoyuApiUrl, `/web/leads/${leadId}/answers`, convertAnswer(answers))
}

export function getQuestions(){
    return getRequest(config.shoyuApiUrl,'/web/leads/questions')
}

export function getQuestionsForEdit(leadId){
    return getRequest(config.shoyuApiUrl,'/web/leads/questions')
        .then(question => {
            const answers = getAnswers(leadId)
            return Promise.all([question, answers])
        })
}

export function getAnswers(leadId){
    return getRequest(config.shoyuApiUrl,`/web/leads/${leadId}/answers`)
}

export function getLeadSummary(projectId, period, targetMonth){
    const data = { 
        project_id: projectId,
        period: period,
        target_month: targetMonth
    }
    return postRequest(config.shoyuApiUrl,'/web/leads/summary', data)
}

export function getLeadActivities(projectId, period, targetMonth){
    const data = { 
        project_id: projectId,
        period: period,
        target_month: targetMonth
    }
    return postRequest(config.shoyuApiUrl,'/web/leads/activities', data)
}

export function getLead_(projectId, leadId) {
    const data = { 
        project_id: projectId,
    }
    return getRequest(config.shoyuApiUrl,`/web/leads/activities/${leadId}`, data)
}

export function searchLeads_(query) {
    const data = {
        value: query
    }

    return postRequest(config.shoyuApiUrl, `/leads/customer/search`, data)
        .then(res => {
            return res.leads
        })
        .catch(err => {
            console.error(`getLead failed with ${err}`)
        })
}

export function getBuildingSummary(projectId) {
    const data = {
        project_id: projectId
    }

    return postRequest(config.shoyuApiUrl, `/building/summary`, data)
        .then(res => {
            return res.list
        })
}

export function getBuildingInfo(buildingId, floor) {
    const data = {
        project_building_id: buildingId,
        floor: floor
    }

    return postRequest(config.shoyuApiUrl, '/building', data)
        .then(res => {
            return res.products
        })
}

export function changeInterestStatus(id) {
    return putRequestForUninterested(config.shoyuApiUrl, `/web/leads/${id}/uninterested`, null, true, true)
        .then(res => {
            return res
        })
}

export function filterSummaryByDate(projectId, startDate, endDate) {
    const data = {
        project_id: projectId,
        start: startDate,
        end: endDate
    }
    return postRequest(config.shoyuApiUrl, `/web/leads/summaryDate`, data)
}

export function filterActivityByDate(projectId, startDate, endDate) {
    const data = {
        project_id: projectId,
        start: startDate,
        end: endDate
    }
    return postRequest(config.shoyuApiUrl, `/web/leads/activitiesDate`, data)
}

export function deleteLead(leadId){
    return deleteRequest(config.shoyuApiUrl, `/web/leads/${leadId}`)
}

function convertLeadInfo(info) {
    return {
        first_name: _.isEmpty(info.first_name) ? NA_TEXT : info.first_name.trim(),
        last_name: _.isEmpty(info.last_name) ? NA_TEXT : info.last_name.trim(),
        email: info.email,
        phone_number: _.isEmpty(info.phone_number) ? NA_TEXT : info.phone_number,
        qualified: info.qualified,
        prospect: info.prospect,
        gender: info.gender,
        age: info.age,
        occupation: info.occupation ?  info.occupation : 0,
        income: info.income ? info.income : 0,
        address: info.address,
        sub_district: info.sub_district,
        district: info.district,
        province: info.province,
        postal_code: info.postal_code,
        line_id: info.line_id,
        sources: info.sources,
        product: info.product,
        occupation_detail: info.occupation_detail
    }
}

function convertAnswer(info){
    return {
        answer_list: info
    }
}
