export default {
  wrapper: {
    width: '100%',
    maxWidth: '900px',
    margin: '0 auto',
    marginBottom: '50px',
    boxShadow: '#ccc 2px 2px 5px 0px'
  },
  label : {
    margin: '1em'
  },
  question : {
    margin: '1em',
    '& $h4': {
      fontSize: '1em'
    }
  },
  small : {
    fontSize: '50%',
    color: '#565656'
  },
  btnDelete : {
    color: 'red',
    padding: 0,
    fontSize: '1.25em'
  },
  overlay : {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  cover: {
    border: 'dashed 2px #c0d2e8',
    display: 'flex',
    minHeight: '300px',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    marginBottom: '1em'
  },
  command: {
    position: 'fixed',
    right: 0,
    bottom: 0,
  },
  commandInner: {
    width: '100%',
    maxWidth: '900px',
    textAlign: 'right',
  },
  questionSectionTitle: {
    display: 'flex'
  },
}