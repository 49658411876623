import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Card from './common/Card'
import TextWithLabel from './common/TextWithLabel'
import CardTitle from './common/CardTitle'

class BasicInfoCard extends Component {
    render() {
        const { title, content, onEditButton } = this.props
        return (
            <Card>
                <CardTitle title={title} onEditButton={onEditButton}/>
                <BasicInfoContent content={content} />
            </Card>
        )
    }
}

BasicInfoCard.propTypes = {
    title: PropTypes.string,
    content: PropTypes.array,
}

export default BasicInfoCard

export const BasicInfoContent = ({ content }) => {
    return <div>
        {content.map((c, index) => <BasicInfoRow key={index} rowContent={c} />)}
    </div>
}

const BasicInfoRow = ({ rowContent }) => {
    const count = rowContent.length
    if (count > 4) {
        console.error('rowContent should not have more than 4 elements')
        console.error(rowContent)
        return null
    }

    const colSize = 12 / count

    return (
        <div className="row no-gutters pt-1">
            {rowContent.map((c, index) => {
                return <div key={index} className={`col-md-${colSize}`}>
                    <TextWithLabel label={c.label}>
                        {c.render ? c.render(c.value) : c.value}
                    </TextWithLabel>
                </div>
            })}
            <div className="w-100 mb-2"/>
        </div>
    )
}
