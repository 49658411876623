import React, { Component } from 'react'

import Wrapper from '../../layouts/Wrapper'
import BasicInfoContainer from '../../components/BasicInfoCard'
import Loading from '../../components/common/Loading'
import { translate } from '../../common/util'
import { NA_TEXT, APPROVED_STATUS, INSPECTION_PASSED_STATUS } from '../../common/const'
import moment  from 'moment';
import { Formatter } from '../../utilities/Formatter';
import apis from '../../apis'

export default class BasicInfoView extends Component {

    delete = () => {
        const isConfirm = window.confirm('คุณต้องการที่จะลบบันทึกรายการขายนี้ใช่หรือไม่ ?')
        if (isConfirm) {
            const p = apis.sale.deleteSaleRecord(this.props.record.id).then(() => {
                this.props.router.push('/sale-record')
            })

            return p
        }
    }

    render() {
        const { record } = this.props
        if (!record) {
            return <Loading />
        }

        return (
            <Wrapper>
                <GeneralInfoCard record={ record } />
                <CustomerInfoCard record={ record } />
                <ProductInfoCard record={ record } />
                {/*
                    <ContractlInfoCard record={ record } />
                */}
                <div style={{textAlign: 'center'}}>
                    <button
                        onClick={() => {
                            this.delete()
                        }}
                        className="m-05 btn btn-save-cancel btn-red-no-border"
                        style={{padding: 0}}
                    >
                        ลบบันทึกการขาย
                    </button>
                </div>
            </Wrapper>
        )
    }
}

const GeneralInfoCard = ({ record }) => {
    const data = [
        [
            {
                label: 'ชื่อผู้ทำสัญญา',
                value: record.customerName,
            },
            {
                label: 'สินค้า',
                value: record.skuCode
            },
        ],
        [
            {
                label: 'วันที่จอง',
                value: record.created,
                render: value => {
                    if(value) {
                        const date = moment(value)
                        return <span>{Formatter.formatDateTh(Formatter.createDate(date))}</span>
                    }
                    return null
                }
            },
            {
                label: 'สถานะการขอกู้',
                value: record.loanStatus,
                render: value => {
                    const color = value === APPROVED_STATUS || value === 9 ? 'green' : 'orange'
                    const text = translate('loan_statuses', value)
                    return <span style={{ color: color }}>{ text }</span>
                }
            },
        ],
        [
            {
                label: 'วันที่ทำสัญญา',
                value: record.contractDate,
                render: value => {
                    if(value !== 'ไม่ระบุ') {
                        const date = moment(value)
                        return <span>{Formatter.formatDateTh(Formatter.createDate(date))}</span>
                    }
                    return <span style={{color: "#ffb758"}}>ยังไม่ทำสัญญา</span>
                }
            },
            {
                label: 'วันที่โอน',
                value: record.transferDate,
                render: value => {
                    if(value) {
                        const date = moment(value)
                        return <span>{Formatter.formatDateTh(Formatter.createDate(date))}</span>
                    }
                    return <span style={{color: "#ffb758"}}>ยังไม่โอน</span>
                }
            }
        ],
        [
            {
                label: 'สถานะ',
                value: record.saleRecordStatus,
                render: value => {
                    let color;
                    let text
                    if(value === -1){
                        color = 'red'
                        text = 'ยกเลิก'
                    } else if (value === 0) {
                        color = 'orange'
                        text = 'จอง'
                    } else if (value === 1) {
                        color = '#00ace1'
                        text = 'ทำสัญญา'
                    } else if (value === 2) {
                        color = 'green'
                        text = 'โอนแล้ว'
                    } else {
                        color = 'black'
                        text = 'ไม่ระบุ'
                    }
                    // const color = value ? 'green' : 'orange'
                    // const text = value ? 'โอนแล้ว' : 'ยังไม่โอน'
                    return <span style={{ color: color }}>{ text }</span>
                }
            },
            {
                label: 'เจ้าหน้าที่ผู้รับผิดชอบ',
                value: record.staffName,
            },
        ]
    ]

    return <BasicInfoContainer title="ข้อมูลทั่วไป" content={ data } />
}

const CustomerInfoCard = ({ record }) => {
    const data = [
        [
            {
                label: 'ชื่อ - นามสกุล',
                value: record.customerName
            },
            {
                label: 'เพศ',
                value: translate('genders', record.gender),
            },
        ],
        [
            {
                label: 'อายุ',
                value: record.age,
            },
            {
                label: 'อาชีพ',
                value: translate('occupations', record.occupation),
            },
        ],
        [
            {
                label: 'โทรศัพท์',
                value: record.telephone,
            },
            {
                label: 'อีเมล',
                value: record.email,
            },
        ],
        [
            {
                label: 'ที่อยู่ปัจจุบัน',
                value: record.address,
            },
        ]
    ]

    return <BasicInfoContainer title="ข้อมูลผู้ทำสัญญา" content={ data } />
}

const ProductInfoCard = ({ record }) => {
    const data = [
        [
            {
                label: 'รหัสสินค้า',
                value: record.skuCode,
            },
            {
                label: 'ตึก',
                value: translate('buildings', record.buildingId),
            },
        ],
        [
            {
                label: 'ขนาดห้อง',
                value: translate('room_types', record.roomType),
            },
            /*{
                label: 'ตำแหน่งห้อง',
                value: translate('room_views', record.roomView) || NA_TEXT,
            },*/
        ],
        /*[
            {
                label: 'โครงการ',
                value: record.projectName
            },
        ],*/
    ]

    return <BasicInfoContainer title="ข้อมูลสินค้า" content={ data } />
}

const ContractlInfoCard = ({ record }) => {
    const data = [
        [
            {
                label: 'หมายเลขสัญญา',
                value: record.contractNo,
            },
            {
                label: 'หมายเลขจอง',
                value: record.reserveNo
            },
        ],
        [
            {
                label: 'วันที่ทำสัญญา',
                value: record.contractDate
            },
            {
                label: 'ประเภท',
                value: record.contractType,
            },
        ],
        [
            {
                label: 'สถานะ',
                value: record.contractStatus,
            },
            {
                label: 'จำนวน',
                value: record.contractAmount,
            },
        ],
    ]

    return <BasicInfoContainer title="ข้อมูลสัญญา" content={ data } />
}