import React, { Component } from 'react'
import { Link } from 'react-router'

import Wrapper from '../layouts/Wrapper'
import datastore from '../datastore'
import BasicInfoCard from '../components/BasicInfoCard'
import { translate, getProject } from '../common/util'
import QualityControlInfoView from './qualityControlInfo'
import AddProductModal from '../components/Modal/AddProductModal'
import apis from '../apis'

export default class ProductInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            product: null,
            record: null,
            activeViewIndex: 0,
            productDetail: null
        }

        this.openEditProductModal = this.openEditProductModal.bind(this)

        apis.product.getProductShoyu(this.props.params.productId)
        .then(result => {
            this.setState({
                productDetail: result
            })
        })
    }

    componentWillMount() {
        this.updateProduct()
    }

    updateProduct = () => {
        const productId = this.props.params.productId
        datastore.product.getProduct(productId)
            .then(product => {
                this.props.setHeader(`${ product.orderingSkuCode }`)
                this.setState({
                    product: product
                })
            })
    }

    changeView = index => {
        this.setState({
            activeViewIndex: index
        })
    }

    openEditProductModal = () => {
        this.refs.editProduct.openModal()
    }

    render() {
        return (
            <div>
                <TabBar activeViewIndex={this.state.activeViewIndex} changeView={this.changeView} />
                <Wrapper>
                    <ActiveView 
                        index={this.state.activeViewIndex} 
                        updateProduct={this.updateProduct}
                        product={this.state.product}
                        editProduct={this.openEditProductModal}
                    />
                    <AddProductModal 
                        ref="editProduct"
                        router={this.props.router}
                        product={this.state.product}
                        detail={this.state.productDetail}
                        onUpdateProduct={this.updateProduct}
                    />
                </Wrapper>
            </div>
        )
    }
}

const TabBar = ({ activeViewIndex, changeView }) => {
    const columnClassName = "text-center text-none py-1 mr-02 w-15"
    return (
        <div className="d-flex flex-row w-100 pl-1" style={{ height: '', boxShadow: "2px 2px 4px 0 rgba(0,0,0,0.25)" }}>
            <Link className={`${columnClassName} ${isActive(0, activeViewIndex)}`} onClick={ () => changeView(0) }>ข้อมูลทั่วไป</Link>
            <Link className={`${columnClassName} ${isActive(1, activeViewIndex)}`} onClick={ () => changeView(1) }>Quality Control</Link>
        </div>
    )
}

const ActiveView = ({ index, updateProduct, product, editProduct }) => {
    return index === 0 ? <GeneralInfoCard product={ product } editProduct={editProduct} />
        // TODO: Should refactor qc and inspection to one component.
        : index === 1 ? <QualityControlInfoView product={ product } updateProduct={ updateProduct } />
        : null 
}

const isActive = (index, activeIndex) => {
    return index === activeIndex
        ? 'tab-active'
        : 'tab-inactive'
}

const GeneralInfoCard = ({ product, editProduct }) => {
    if (!product) {
        return null
    }

    const data = [
            [
                {
                    label: 'รหัสสินค้า',
                    value: product.orderingSkuCode,
                },
                /*{
                    label: 'โครงการ',
                    value: product.projectId,
                    render: value => {
                        const p = getProject(value)
                        return p && p.name ? p.name : 'ไม่ระบุ'
                    }
                },*/
            ],
            [
                {
                    label: 'ประเภท',
                    value: translate('room_types', product.roomTypeId),
                },
                {
                    label: 'รหัสตึก',
                    value: translate('buildings', product.buildingId),
                },

            ],
            [
                {
                    label: 'สถานะการขาย',
                    value: (() => {
                        if (product.isTransferred) {
                            return "โอนห้องแล้ว"
                        }

                        if (product.isReserved) {
                            return "ห้องถูกจองแล้ว"
                        }

                        return "ห้องยังไม่ถูกจอง"
                    })(),
                },
                 {
                     label: 'สถานะ QC',
                     value: (() => {
                         if (product.passQc) {
                             return "ผ่าน"
                         }
                         return "ยังไม่ตรวจสอบ"
                        })()
                     
                 },
            ],
        ] 
        
    return <BasicInfoCard title="ข้อมูลสินค้า" content={ data } onEditButton={editProduct}/>
}
