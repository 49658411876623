import React, { Component } from 'react'
import _ from 'lodash'

import datastore from '../datastore'
import Loading from '../components/common/Loading'
import { parseAddressAndLogo } from '../common/util'
import { PrintableSummary, InspectionHeader } from '../components/printable'

export default class PrintableDefectSummary extends Component {
    state = {
        record: null,
        inspection: null,
        project: null,
    }

    componentWillMount() {
        const recordId = this.props.params.recordId
        const inspectionId = this.props.params.inspectionId
        datastore.sale.getSaleRecord(recordId)
            .then(record => {
                const inspection = _.find(record.inspections, ['id', inspectionId])
                this.setState({
                    record: record,
                    inspection: inspection
                })
            })

        datastore.user.getProfile()
            .then(res => {
                this.setState({
                    project: res.current_project
                })
            })
    }

    render() {
        if (!this.state.record || !this.state.project) {
            return <Loading />
        }
        
        const { address, logoUrl } = parseAddressAndLogo(this.state.project.meta_json)
        const header = <InspectionHeader
            inspection={this.state.inspection}
            skuCode={this.state.record.skuCode}
            projectName={this.state.record.projectName}
            logoUrl={logoUrl}
        />

        return <PrintableSummary 
            header={header}
            skuCode={this.state.record.skuCode}
            projectName={this.state.record.projectName}
            address={address}
            logoUrl={logoUrl}
            defects={this.state.inspection.defects}
            firstPageNumber={1}
        />
    }
}
