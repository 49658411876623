import React, { Component } from 'react'
import PropTypes from 'prop-types'

import TextInput from '../../components/common/TextInput'
import FormNewLine from '../../components/common/FormNewLine'
import CheckboxInput from '../../components/common/CheckboxInput'
import ConfirmModal from '../../components/ConfirmModal'
import SelectInput, { SEPERATOR } from '../../components/common/SelectInput'
import { formatNumberWithComma } from '../../common/util'

class AddBankModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                template: null,
                amount: '',
                selfSubmission: false
            }
        }
    }

    addBank() {
        const template = JSON.parse(this.state.data.template)
        const amount = this.state.data.amount

        if (!template) {
            console.error('Please select bank!')
            return
        }

        this.props.addBankHandler(template.bankId, template.templateId, amount, this.state.data.selfSubmission)

        this.setState({
            data: {
                template: null,
                amount: ''
            }
        })
    }

    render() {
        const { bankOptions } = this.props

        if (!bankOptions) {
            return null
        }

        const templateOptions = [...bankOptions.primary, SEPERATOR, ...bankOptions.secondary]

        return (
            <ConfirmModal 
                ref="modal"
                title="เพิ่มธนาคาร"
                disabled={!this.state.data.template}
                onConfirm={() => this.addBank()}
            >
                <div className="row">
                    <div className="col-6">
                        <selectWrap>
                            <SelectInput
                                name="template"
                                placeholder="รายการเอกสาร"
                                target={this}
                                stateKey="data"
                                options={templateOptions}
                            />
                        </selectWrap>
                    </div>
                    <div className="col-6">
                        <TextInput 
                            name="amount" 
                            placeholder="จำนวนที่ขอกู้" 
                            target={this} 
                            stateKey="data"
                            renderFormatter={value => formatNumberWithComma(value)}
                            dataFormatter={value => value.replace(/,/g, '')} 
                        />
                    </div>
                    <FormNewLine/>
                    <div className="col-12">
                        <CheckboxInput 
                            name="selfSubmission" 
                            label="ลูกค้ายื่นเอกสารโดยตรงกับธนาคาร"
                            target={this} 
                            stateKey="data"
                        />
                    </div>
                </div>
            </ConfirmModal>
        )
    }
}

AddBankModal.propTypes = {
    addBankHandler: PropTypes.func,
    bankOptions: PropTypes.object,
}

export default AddBankModal