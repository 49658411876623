import * as sale from './sale'
import * as product from './product'
import * as customer from './customer'
import * as other from './other'
import * as loan from './loan'
import * as user from './user'
import * as survey from './survey'
import * as warranty from './warranty'
import * as lead from './lead'
import * as activity from './activity'
import * as document from './document'
import * as campaign from './campaign'
import * as dashboard from './dashboard'
import * as userManagement from './userManagement'

export default {
    sale,
    product,
    customer,
    other,
    loan,
    user,
    survey,
    warranty,
    lead,
    activity,
    document,
    campaign,
    dashboard,
    userManagement
}
