import moment from 'moment'
import numeral from 'numeral'

export class Formatter {

  static createDate(date) {
    if (date) {
      return moment(date, 'YYYY-MM-DD HH:mm:ss+Z');
    } else {
      return new Date();
    }
  }

  static formatDateTh(date) {
    const splitDate = date.toString().split(' ').slice(1, 4);

    const day = Formatter.formatDayTh(splitDate[1]);
    const month = Formatter.formatMonthTh(splitDate[0]);
    const year = Formatter.formatYearTh(splitDate[2]);
    const dateFormatted = [day, month, year].join(' ');

    return dateFormatted;
  }

  static formatDateWithFullMonthTh(date) {
    const splitDate = date.toString().split(' ').slice(1, 4);

    const day = Formatter.formatDayTh(splitDate[1]);
    const month = Formatter.formatFullMonthTh(splitDate[0]);
    const year = Formatter.formatYearTh(splitDate[2]);
    const dateFormatted = [day, month, year].join(' ');

    return dateFormatted;
  }

  static formatFullMonthTh(month) {
    const monthsEng = ['Jan', 'Feb', 'Mar', 'Apr',
      'May', 'Jun', 'Jul', 'Aug',
      'Sep', 'Oct', 'Nov', 'Dec' ];

    const monthTh = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน',
      'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม',
      'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];

    return monthTh[monthsEng.indexOf(month)];
  }

  static formatDayTh(day) {
    return day.replace(/^0+(?!\.|$)/, '');
  }

  static formatMonthTh(month) {
    const monthsEng = ['Jan', 'Feb', 'Mar', 'Apr',
      'May', 'Jun', 'Jul', 'Aug',
      'Sep', 'Oct', 'Nov', 'Dec' ];

    const monthTh = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.',
      'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.',
      'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'];

    return monthTh[monthsEng.indexOf(month)];
  }

   static formatYearTh(year) {
    return parseInt(year, 10) + 543;
  }

  static amountFormatter(amount) {
    return numeral(amount).format('0,0.00');
  }

  static formatMonthNumberToTh(month) {
    switch(month) {
      case 1:
        return 'มกราคม'
      case 2:
        return 'กุมภาพันธ์'
      case 3:
        return 'มีนาคม'
      case 4:
        return 'เมษายน'
      case 5:
        return 'พฤษภาคม'
      case 6:
        return 'มิถุนายน'
      case 7:
        return 'กรกฎาคม'
      case 8:
        return 'สิงหาคม'
      case 9:
        return 'กันยายน'
      case 10:
        return 'ตุลาคม'
      case 11:
        return 'พฤศจิกายน'
      case 12:
        return 'ธันวาคม'
    }
  }
}