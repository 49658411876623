import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { inModal } from '../hoc/inModal'
import ModalTitle from './common/ModalTitle'
import ModalFooter from './common/ModalFooter'

class ConfirmForm extends Component {
    constructor(props) {
        super(props)

        this.onConfirm = this.onConfirm.bind(this)
        this.onCancel = this.onCancel.bind(this)
    }

    onConfirm() {
        const { onConfirm, closeModal } = this.props
        if (onConfirm) {
            onConfirm()
        }

        closeModal()
    }

    onCancel() {
        const { onCancel, closeModal } = this.props
        if (onCancel) {
            onCancel()
        }

        closeModal()
    }

    render() {
        const { closeModal, title, children, disabled } = this.props
        return (
            <div className="d-flex flex-column justify-content-between">
                <ModalTitle title={ title } onClick={ () => closeModal() } />
                { children }
                <ModalFooter>
                    <button onClick={ () => this.onCancel() } className="btn btn-outline ml-auto mr-1">ยกเลิก</button>
                    <button
                        onClick={ () => this.onConfirm() }
                        className="btn btn-main"
                        disabled={ disabled }
                    >
                        ยืนยัน
                    </button>
                </ModalFooter>
            </div>
        )
    }
}

const ConfirmModal = inModal(ConfirmForm)
ConfirmModal.propTypes = {
    title: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    disabled: PropTypes.bool
}

export default ConfirmModal
