import React, { Component } from 'react'

import Wrapper from '../layouts/Wrapper'
import { CustomerInfoForm } from '../components/forms/CustomerInfoForm';


export default class CustomerInfoEdit extends Component {
  componentWillMount() {
    this.props.setHeader(
      `แก้ไขข้อมูลผู้ทำสัญญา`,
      [this.confirm, this.cancel],
      ['ยืนยันการแก้ไข', 'ยกเลิก']
    )
  }

  cancel = () => {
    this.props.router.goBack()
  }

  confirm = () => {
    this
      .refs
      .form
      .getWrappedInstance()
      .save()
      .then(() => this.props.router.goBack())
  }

  render() {
    return (
      <Wrapper>
        <CustomerInfoForm
          ref="form"
          recordId={this.props.params.recordId}
          customerId={this.props.params.customerId}
        />
      </Wrapper>
    );
  }
}
