export const DEFECTS_LIST = {
    defects: [
        {
            id: 1,
            description: 'n/a',
            defect_type: 1,
            warranty_type: 1,
            location_id: 2,
            sla_days: 30,
            created_date: '2018-07-08 14:18:51',
            technician_name: 'สมฤทธิ์ ทรัพย์ถนอม',
            technician_phone: '081-234-5678',
            technician_picture: 'http://www.pieglobal.com/wp-content/uploads/2015/10/placeholder-user.png'
        },
        {
            id: 2,
            description: 'n/a',
            defect_type: 2,
            warranty_type: 2,
            location_id: 3,
            sla_days: 30,
            created_date: '2018-06-02 14:18:51',
            technician_name: 'สมฤทธิ์ ทรัพย์ถนอม',
            technician_phone: '081-234-5678',
            technician_picture: 'http://www.pieglobal.com/wp-content/uploads/2015/10/placeholder-user.png'
        }
    ],
    offset: 0,
    page_size: 10,
    total: 2
}

export const PRODUCT = {
    product: {
        id: 1,
        customer_name: 'ยุพาพร บัวระพา',
        sku_code: '151/110',
        defect_report_status: 1,
        severity: 2,
        warranty_status: 2,
        staff_name: 'อนุสรณ์ หัตถกรรม'
    }
}

export const PRODUCTS_LIST = {
    products: [
        {
            id: 1,
            customer_name: 'ยุพาพร บัวระพา',
            sku_code: '151/110',
            defect_report_status: 1,
            severity: 2,
            warranty_status: 2,
            staff_name: 'อนุสรณ์ หัตถกรรม'
        },
        {
            id: 2,
            customer_name: 'พูลสุข ชูจตุโร',
            sku_code: '151/73',
            defect_report_status: 2,
            severity: 1,
            warranty_status: 1,
            staff_name: 'อนุสรณ์ หัตถกรรม'
        },
    ],
    offset: 0,
    page_size: 10,
    total: 2
}
