import apiClient from '../apis'
import { createPromise } from '../common/util'
import mocks from '../mocks'

const IS_MOCK = false

export function getCustomers(offset, pageSize, sortKey, sortOrder, filters, query) {
    return apiClient.customer.getCustomers(offset, pageSize, sortKey, sortOrder, filters, query)
        .then(res => {
            const customers = res.values.map(c => convertCustomerInfo(c))
            return {
                length: res.length,
                values: customers
            }
        })
}

export function getCustomer(customerId) {
    if (IS_MOCK) {
        return createPromise(mocks.customer.CUSTOMER_INFO)
    }

    return apiClient.customer.getCustomer(customerId)
        .then(res => {
            return convertCustomerInfo(res)
        })
}

export function getCustomerForEdit(recordId) {
    if (IS_MOCK) {
        return createPromise(mocks.customer.CUSTOMER_INFO)
    }

    return apiClient.sale.getSaleRecord(recordId)
        .then(res => {
            return convertCustomerInfoForEdit(res)
        })
}

export function createCustomer(customerInfo) {
    return apiClient.customer.createCustomer(customerInfo)
}

export function updateCustomer(customerInfo, customerId, recordId) {
    const updateMetaJson = apiClient.customer.updateSaleMetaDetail(customerInfo, recordId)
    const updateCustomerInfo = apiClient.customer.updateCustomerInfo(customerInfo, customerId)

    return Promise.all([updateMetaJson, updateCustomerInfo])
}

function convertCustomerInfo(customer) {
    return {
        id: customer.id,
        firstName: customer.first_name,
        lastName: customer.last_name,
        gender: customer.gender,
        tel: customer.tel,
        occupation: 3,
        income: 4,
        saleStage: customer.sale_stage,
    }
}


function convertCustomerInfoForEdit(record) {
    const customer = record.customer
    let addressesJson = ''
    if(customer.addresses_json){
        if(JSON.parse(customer.addresses_json) === null){
            addressesJson = ''
        } else {
            addressesJson = JSON.parse(customer.addresses_json)
        }
    }

    return {
        id: customer.id,
        customerCode: customer.customer_code ? customer.customer_code : null,
        firstName: customer.first_name && customer.first_name !== '-' ? customer.first_name : '',
        lastName: customer.last_name && customer.last_name !== '-' ? customer.last_name : '',
        gender: customer.gender,
        age: customer.age && customer.age !== '-' ? customer.age : '',
        citizenId: customer.ref_id && customer.ref_id !== '-' ? customer.ref_id : '',
        tel: customer.tel && customer.tel !== '-' ? customer.tel : '',
        email: customer.email && customer.email !== '-' ? customer.email : '',
        lineId: customer.line_id && customer.line_id !== '-' ? customer.line_id : '',
        addressesJson: addressesJson,
        occupation: customer.occupation,
        income: customer.income,
        purpose: customer.purpose && customer.purpose !== '-' ? customer.purpose : '',
        channelId: customer.channel_id,
        note: customer.note && customer.note !== '-' ? customer.note : '',
        clientId: customer.client_id,
        created: customer.created,
        creatorId: customer.creator_id,
        refId: customer.ref_id && customer.ref_id !== '-' ? customer.ref_id : '',
        metaDetail: record.sale_record_meta_detail
    }
}