import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getBuildingSummary, getBuildingProducts } from '../../../apis/building';
import Wrapper from '../../../layouts/Wrapper';

import BuildingBox from './BuildingBox';
import FilterBar from './FilterBar';
import apiClient from '../../../apis';

class Products extends Component {
  state = {
    buildings: [],
    selectedBuilding: null,
    selectedFloor: null,
    buildingBoxData: null,
    currentProject: null,
  };

  componentDidMount() {
    this.props.setHeader('รายการสินค้า')
    const { profile } = this.props;
    const { current_project } = profile || {};


    this.setState(
      {
        currentProject: current_project,
      },
      this.getBuildings
    );
  }

  getBuildings = () => {
    const { currentProject } = this.state;
    const projectId = currentProject.id.toString();
    apiClient.lead.getBuildingSummary(currentProject.id.toString())
      .then(buildings => {
          const formatted = buildings.map(b => {
              return {
                  buildingId: b.building_id,
                  buildingName: b.building_name,
                  floors: b.floors.map(f => {
                      return {
                        floorId: f.floor,
                        floorName: f.floor_name,
                        floorPlanUrl: f.floor_plan_url
                      }
                  })
              }
          })
          this.setState({
            buildings: formatted
          });
      })
  }

  getBuildingBoxData = () => {
    const { selectedBuilding, selectedFloor } = this.state;
    const { buildingId } = selectedBuilding || {};
    const { floorId } = selectedFloor || {};
      console.log(selectedFloor)
    apiClient.lead.getBuildingInfo(buildingId, floorId)
        .then(res => {
            console.log(res)
            this.setState({
                buildingBoxData: {
                    floorUrl: selectedFloor.floorPlanUrl,
                    products: res.map(r => {
                        return {
                            id: r.id.toString(),
                            name: r.name,
                            status: r.status_id,
                            price: r.price,
                            crossOutPrice: r.crossout_price,
                            x: r.x,
                            y: r.y,
                            type: r.type,
                            floorPlanUrl: r.product_plan_url
                        }
                    }),
                }
            });
        })
    // getBuildingProducts(buildingId, floorId).then(res => {
    //   this.setState({
    //     buildingBoxData: res,
    //   });
    // });
    //this.setState({ buildingBoxData: BUILDING_BOX_DATA });
  };

  handleBuildingClick = building => {
    this.setState({
      selectedBuilding: building,
      selectedFloor: null,
      buildingBoxData: null,
    });
  };

  handleFloorClick = floor => {
    this.setState(
      {
        selectedFloor: floor,
      },
      this.getBuildingBoxData
    );
  };

  render() {
    const { buildings, selectedBuilding, selectedFloor, buildingBoxData } = this.state;
    const { floorUrl, products } = buildingBoxData || {};
    const { openProjectModal, profile } = this.props;

      console.log(buildingBoxData)

    return (
      <Wrapper>
        <div className="d-flex flex-row align-items-center justify-content-between mb-15">
          <div
            className="btn btn-main d-flex flex-row align-items-center justify-content-between"
            onClick={openProjectModal}
            style={{ maxWidth: '70%' }}
          >
            <div style={{ flexGrow: 1, overflow: 'hidden' }}>{profile.current_project.name}</div>
            <i className={`fa fa-chevron-down white ml-05`} style={{ fontSize: '12px' }} />
          </div>
        </div>
        <div className="d-flex flex-column flex-xl-row">
          <FilterBar
            buildings={buildings}
            selectedBuilding={selectedBuilding}
            selectedFloor={selectedFloor}
            onBuildingClick={this.handleBuildingClick}
            onFloorClick={this.handleFloorClick}
          />
          <BuildingBox
            building={selectedBuilding}
            floor={selectedFloor}
            imgUrl={floorUrl}
            products={products}
          />
        </div>
      </Wrapper>
    );
  }
}

export default connect(
  function(state) {
    return { profile: state.auth.profile };
  },
  null
)(Products);

const BUILDING_BOX_DATA = {
  floorUrl: '/img/mock-condo-plan.jpg',
  buildingUrl: '',
  products: [
    {
      id: 'p-1',
      type: '1ห้องนอน',
      status: 1,
      name: '151/5',
      x: 255,
      y: 150,
      floorPlanUrl: '/img/mock-room-floorplan.png',
      price: '3,000,000',
      crossOutPrice: '5,000,000',
    },
    {
      id: 'p-2',
      type: '2ห้องนอน',
      status: 2,
      name: '151/6',
      x: 345,
      y: 150,
      floorPlanUrl: '/img/mock-room-floorplan.png',
      price: '3,000,000',
      crossOutPrice: '5,000,000',
    },
    {
      id: 'p-3',
      type: '1ห้องนอน',
      status: 3,
      name: '151/7',
      x: 390,
      y: 215,
      floorPlanUrl: '/img/mock-room-floorplan.png',
      price: '3,000,000',
      crossOutPrice: '5,000,000',
    },
    {
      id: 'p-4',
      type: '1ห้องนอน',
      status: 2,
      name: '151/8',
      x: 480,
      y: 150,
      floorPlanUrl: '/img/mock-room-floorplan.png',
      price: '3,000,000',
      crossOutPrice: '5,000,000',
    },
    {
      id: 'p-5',
      type: '1ห้องนอน',
      status: 4,
      name: '151/8',
      x: 680,
      y: 310,
      floorPlanUrl: '/img/mock-room-floorplan.png',
      price: '3,000,000',
      crossOutPrice: '5,000,000',
    },
    {
      id: 'p-6',
      type: '1ห้องนอน',
      status: 1,
      name: '151/8',
      x: 680,
      y: 355,
      floorPlanUrl: '/img/mock-room-floorplan.png',
      price: '3,000,000',
      crossOutPrice: '5,000,000',
    },
    {
      id: 'p-7',
      type: '1ห้องนอน',
      status: 3,
      name: '151/8',
      x: 735,
      y: 400,
      floorPlanUrl: '/img/mock-room-floorplan.png',
      price: '3,000,000',
      crossOutPrice: '5,000,000',
    },
  ],
};
const MOCK_DATA = [
  {
    buildingId: 'b-a',
    buildingName: 'builing-A',
    floors: [
      { floorId: 'b-a-f-1', floorName: 'floor-1' },
      { floorId: 'b-a-f-2', floorName: 'floor-2' },
    ],
  },
  {
    buildingId: 'b-b',
    buildingName: 'builing-B',
    floors: [
      { floorId: 'b-b-f-1', floorName: 'floor-1' },
      { floorId: 'b-b-f-2', floorName: 'floor-2' },
      { floorId: 'b-b-f-3', floorName: 'floor-3' },
      { floorId: 'b-b-f-4', floorName: 'floor-4' },
      { floorId: 'b-b-f-5', floorName: 'floor-3' },
      { floorId: 'b-b-f-6', floorName: 'floor-4' },
    ],
  },
  {
    buildingId: 'b-c',
    buildingName: 'builing-C',
    floors: [
      { floorId: 'b-c-f-1', floorName: 'floor-1' },
      { floorId: 'b-c-f-2', floorName: 'floor-2' },
      { floorId: 'b-c-f-3', floorName: 'floor-3' },
    ],
  },
];
