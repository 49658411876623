import React, { Component } from 'react'

import datastore from '../../datastore'
import ConfirmModal from '../../components/ConfirmModal'

class DeleteDefectModal extends Component {
    state = {
        selectedReason: '',
    }

    handleReasonChange = value => {
        this.setState({ selectedReason: value })
    }

    deleteDefect = () => {
        const { defectId, refresh } = this.props
        datastore.warranty.cancelDefect(defectId, this.state.selectedReason)
            .then(d => {
                refresh()
            })
    }

    render() {
        return (
            <ConfirmModal
                ref="modal"
                title="ลบจุดบกพร่อง"
                onConfirm={this.deleteDefect}
                disabled={!this.state.selectedReason}
            >
                <div className="row no-gutters justify-content-between mb-2">
                    <select 
                        className="form-control input w-100" 
                        name="selectedReason"
                        onChange={e => this.handleReasonChange(e.target.value)}
                        value={this.state.selectedReason}
                    >
                        <option value="" disabled>เลือกเหตุผลที่ยกเลิก</option>
                        {REASON_OPTIONS.map((o, index) => {
                            return <option value={o.value} key={index}>{o.label}</option>
                        })}
                    </select>
                </div>
            </ConfirmModal>
        )
    }
}

export default DeleteDefectModal

const REASON_OPTIONS = [
    {
        value: '0',
        label: 'ข้อมูลไม่ชัดเจน'
    },
    {
        value: '1',
        label: 'ไม่ใช่จดบกพร่อง'
    },
]
