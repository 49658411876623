import _ from 'lodash'

import { GET_TRANSLATIONS } from '../actions/types'

const INITIAL_STATE = {
    translations: {},
    options: {}
}

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_TRANSLATIONS:
            if (!action.translations.version) {
                return { ...state }
            }

            const translations = Object.keys(action.translations)
                .reduce((t, key) => {
                    if (key === 'version') {
                        return t
                    }

                    t[key] = action.translations[key].reduce((acc, e) => {
                        acc[e[0]] = e[1]
                        return acc
                    }, {})

                    return t
                }, {})

            const options = Object.keys(action.translations)
                .reduce((acc, key) => {
                    if (key === 'version') {
                        return acc
                    }

                    acc[key] = action.translations[key].map(e => {
                        return {
                            value: e[0],
                            label: e[1]
                        }
                    })

                    return acc

                }, {})

            const primaryBankIds = action.translations.default_banks
            const bankOptions = action.translations.banks.reduce((acc, bank) => {
                const bankId = bank.id
                const bankTemplateOptions = [{
                        value: {
                            templateId: bank.templates[0].id,
                            bankId: bankId
                        },
                        label: `${bank.templates[0].name}`
                    }]

                if (_.includes(primaryBankIds, bankId)) {
                    acc.primary.push(...bankTemplateOptions)
                } else {
                    acc.secondary.push(...bankTemplateOptions)
                }

                return acc
            }, { 
                primary: [],
                secondary: []
            })

            return { 
                ...state, 
                translations: translations,
                options: options,
                bankOptions: bankOptions
            }
        default:
            return state
    }
}
