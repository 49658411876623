import React, { PureComponent } from 'react';
import { inModal } from '../../hoc/inModal';
import ModalTitle from '../common/ModalTitle';
import { Formatter } from '../../utilities/Formatter';
import TextWithLabel from '../common/TextWithLabel';
import AssignDetailModal from '../AssignDetailModal';
import datastore from '../../datastore';

export class AssignedWorkList extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      defect: null
    }
  }

  getDefectDetail = defectId => {
    const defect = datastore.warranty.getDefectDetail(defectId)
        .then(result => {
            this.setState({
                defect: result
            })
        })
    return defect
  }

  render() {
    return (
      <div>
        <ModalTitle 
          title={`รายการงานวันที่ ${Formatter.formatDateTh(Formatter.createDate(this.props.date))}`} 
          onClick={() => this.props.closeModal()}
        />
        <div className="assigned-work-container">
          {
            this.props.appointments.map((app, index) => {
              return (
                <div 
                  key={index}  
                  className="assigned-work-card" 
                  style={{ width: '100%' }}
                  onClick={() => {
                    this.getDefectDetail(app.id)
                    this.refs.assignDetail.openModal()
                  }}
                >
                  <div className="d-flex flex-row align-items-center justify-content-around" style={{width: '100%'}}>
                    <img src={app.before} alt="before-image" width="58" height="58" />
                    <TextWithLabel label="เวลา">{app.time}</TextWithLabel>
                    <TextWithLabel label="ห้อง">{app.skuCode}</TextWithLabel>
                    <TextWithLabel label="ช่าง">{app.technicianName}</TextWithLabel>
                  </div>
                </div>
              )
            })
          }
        </div>
        <AssignDetailModal 
          ref="assignDetail"
          defect={this.state.defect}
        />
      </div>
    )
  }
}

const AssignedWorkModal = inModal(AssignedWorkList)
export default AssignedWorkModal;