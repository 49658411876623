import React from 'react'
import styled from 'styled-components'
import {Colors} from '../../../../theme/colors'

export const MONTHS = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 }
]

export const Select = styled.select`
    padding: 12px;
    color: ${props => props.disabled ? Colors.Grey : Colors.BillmeBlue};
    font-size: 1rem;
    flex: 1;
    border: none;
    outline: none;
    cursor: pointer;
    background: ${Colors.White};
`

export const Container = styled.div`
    border: 1px solid;
    border-color: ${props => props.disabled ? Colors.Grey : Colors.BillmeBlue};
    display: flex;
    border-radius: 0.5rem;
    overflow: hidden;
    height: 50px;
    background: ${Colors.White};
`

export const MonthSelection = ({disabled, onSelected, selected, ...props}) => (
    <Container disabled={disabled}>
        <Select
            disabled={disabled}
            onChange={e => onSelected(e.target.value)}
        >
            { MONTHS.map(m => {
                return <option key={m.value} value={m.value} selected={selected == m.value}>
                    {m.label}
                </option>
            })}
        </Select>
    </Container>
)

export default MonthSelection