import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

import SelectInput from '../../components/common/SelectInput'
import CheckboxInput from '../../components/common/CheckboxInput'
import TextAreaInput from '../../components/common/TextAreaInput'
import TextInput from '../../components/common/TextInput'
import datastore from '../../datastore'
import { inModal } from '../../hoc/inModal'
import ModalTitle from '../../components/common/ModalTitle'
import ModalFooter from '../../components/common/ModalFooter'
import FormNewLine from '../../components/common/FormNewLine'
import { getOptions } from '../../common/util'

export class CreateDefectForm extends Component {
    state = {
        selectedProduct: null,
        products: null ,
        data: {
            description: "",
            defectType: null,
            location: null,
            warrantyType: null,
            urgent: null,
            imageFile: null,
            imageUrl: null,
        },
        typeSelector: []
    }

    componentWillMount() {
        datastore.product.getDefectProducts(0, 10000, 'sku_code', true, null, null)
            .then(res => {
                const productOptions = res.values.map(p => {
                    return {
                        value: p,
                        label: p.orderingSkuCode,
                    }
                })

                this.setState({ productOptions })
            })
        datastore.warranty.getDefectLocation(this.props.projectId)
            .then(result => {
                const defectLocations = result.key_values.map(value => {
                    return {
                        value: value.key,
                        label: value.value
                    }
                })
                this.setState({typeSelector: defectLocations})
            })
    }

    save = () => {
        const { imageFile } = this.state.data
        if (imageFile) {
            // TODO: Refactor this to have one call to createDefectHandler.
            datastore.other.uploadImage(imageFile)
                .then(res => {
                    const newData = this.state.data
                    newData.imageUrl = res.file_url
                    this.setState({ data: newData })
                    this.props.createDefectHandler(this.state.selectedProduct.id, newData)
                })
        } else {
            this.props.createDefectHandler(this.state.selectedProduct.id, this.state.data)
        }

        this.props.closeModal()
    }

    onImageClick = () => {
        if (this.refs.fileInput) {
            this.refs.fileInput.click()
        }
    }

    selectProduct = p => {
        this.setState({
            selectedProduct: p.value
        })
    }

    selectFile = files => {
        if (files.length > 0) {
            const file = files[0]
            const imageUrl = URL.createObjectURL(file)
            const data = {
                ...this.state.data,
                imageFile: file,
                imageUrl: imageUrl
            }

            this.setState({ 
                data
            })
        }
    }

    render() {
        const { options, noHeaderAndFooter, product } = this.props
        const valid = this.state.data.defectType 
            && this.state.data.location 
            && this.state.data.warrantyType
            && this.state.selectedProduct
            && this.state.data.imageFile

        const customStyles = {
            control: () => ({
                border: 'none',
                display: 'inline',
                cursor: 'text',
                width: '100%'
            }),
            indicatorSeparator: () => ({
                display: 'none',
            }),
            dropdownIndicator: () => ({
                display: 'none',
            }),
        }

        const form = (
            <div className="d-flex flex-column" style={{ flexGrow: 1 }}>
                <div className="row">
                <div className="col-4">
                    <Select
                        className="form-control input w-100"
                        value={this.state.data.selectedProduct}
                        onChange={this.selectProduct}
                        options={this.state.productOptions}
                        placeholder="รหัสสินค้า"
                        styles={customStyles}
                    />
                </div>
                </div>
                <FormNewLine />
                <div className="row">
                    <div className="col-12">
                        <TextAreaInput
                            name="description"
                            placeholder="รายละเอียด"
                            rows={4}
                            target={this}
                            stateKey="data"
                        />
                    </div>
                    <FormNewLine />
                    <div className="col-4">
                        <selectWrap>
                            <SelectInput
                                name="defectType"
                                placeholder="ชนิดงาน"
                                target={this}
                                stateKey="data"
                                options={getOptions('defect_types')}
                            />
                        </selectWrap>
                    </div>
                    <div className="col-4">
                        <selectWrap>
                            <SelectInput
                                name="location"
                                placeholder="ตำแหน่ง"
                                target={this}
                                stateKey="data"
                                options={this.state.typeSelector}
                            />
                        </selectWrap>
                    </div>
                    <div className="col-4">
                        <selectWrap>
                            <SelectInput
                                name="warrantyType"
                                placeholder="ประเภท"
                                target={this}
                                stateKey="data"
                                options={getOptions('warranty_types')}
                            />
                        </selectWrap>
                    </div>
                    <FormNewLine/>
                    <div className="col-12">
                        <CheckboxInput 
                            name="urgent" 
                            label="เร่งด่วน"
                            target={this} 
                            stateKey="data"
                        />
                    </div>
                    <FormNewLine />
                    <div className="col-6">
                        <p className="f13 grey">ภาพก่อนแก้ไข</p>
                        <a href="#" onClick={this.onImageClick} className="">
                            {this.state.data.imageFile
                                ? <img
                                    src={this.state.data.imageUrl}
                                    className="img-rounded"
                                    style={{
                                        width: '240px',
                                        maxWidth: '240px',
                                        border: '1px solid grey'
                                    }}
                                /> : <span>เลือกภาพ</span>
                            }
                        </a>
                        <input
                            type="file"
                            name="name"
                            id="file"
                            onChange={e => this.selectFile(e.currentTarget.files)}
                            ref="fileInput"
                            style={{ display: 'none' }}
                        />
                        <p style={{color: "#f9777f"}}>* กรุณาเลือกรูปภาพก่อนกดบันทึก</p>
                    </div>
                    <div className="col-6">
                        <p className="f13 grey">ผังบ้าน</p>
                        <img
                            src={this.state.selectedProduct 
                                ? this.state.selectedProduct.floorPlanImg[0] 
                                : null
                            }
                            className="img-rounded"
                            style={{
                                width: '240px',
                                maxWidth: '240px',
                                border: '1px solid grey'
                            }}
                        />
                    </div>
                </div>
            </div>
        )

        if (noHeaderAndFooter) {
            return form
        }

        return (
            <div className="d-flex flex-column">
                <ModalTitle title="เพิ่มรายการข้อบกพร่อง" onClick={() => this.props.closeModal()} />
                {form}
                <ModalFooter>
                    <button 
                        disabled={!valid}
                        onClick={() => this.save()} 
                        className="btn btn-main"
                    >บันทึก</button>
                </ModalFooter>
            </div>

        )
    }
}

const CreateDefectModal = inModal(CreateDefectForm)
export default CreateDefectModal
