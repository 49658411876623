import mocks from '../mocks'
import { createPromise } from '../common/util'
import { FILE_STATUS } from '../views/loanInfo/DocumentList'
import apiClient from '../apis'

const IS_MOCK = false

export function getLoanInfo(saleRecordId) {
    if (IS_MOCK) {
        return createPromise(convertLoanInfo(mocks.loan.LOAN_INFO))
    }

    return apiClient.loan.getLoanInfo(saleRecordId)
        .then(loanInfo => {
            return convertLoanInfo(loanInfo)
        })
}

export function addNewDocument(loanId, bankId, filename) {
    if (IS_MOCK) {
        console.log('Adding new document')
        mocks.loan.addNewDocumentToMock(bankId, filename)
        return createPromise()
    }

    return apiClient.loan.addNewDocument(loanId, bankId, filename)
}

export function deleteDocument(documentId) {
    if (IS_MOCK) {
        console.log(`Deleting document ${documentId}`)
        // TODO: Fix this mock.
        // mocks.loan.addNewDocumentToMock(bankId, filename)
        return createPromise()
    }

    return apiClient.loan.deleteDocument(documentId)
}

export function uploadDocument(documentId, file) {
    if (IS_MOCK) {
        console.log(`Uploading file: ${documentId}`)
        // TODO: Fix this mock.
        // mocks.loan.uploadDocumentToMock(bankId, filename)
        return createPromise()
    }

    return apiClient.loan.uploadDocument(documentId, file)
}

export function deleteUploadedDocument(documentId) {
    if (IS_MOCK) {
        console.log(`Deleting ${documentId}`)
        // TODO: Fix this mock.
        // mocks.loan.deleteDocumentFromMock(bankId, documentId)
        return createPromise()
    }

    return apiClient.loan.deleteUploadedDocument(documentId)
}

export function downloadDocument(documentId) {
    if (IS_MOCK) {
        console.log(`Downloading ${documentId}`)
        return createPromise()
    }

    return apiClient.loan.downloadDocument(documentId)
}

export function updateCustomerLoanType(loanId, incomeStatus, maritalStatus) {
    if (IS_MOCK) {
        console.log(`Update customer loan type: ${incomeStatus}, ${maritalStatus} for ${loanId}`)
        return createPromise()
    }

    return apiClient.loan.updateCustomerLoanType(loanId, incomeStatus, maritalStatus) 
}

export function updateBankLoanInfo(loanId, bankId, estimatedAmount, requestedAmount, approvedAmount, status, selfSubmission) {
    if (IS_MOCK) {
        console.log(`Update bank loan info ${loanId} to bank ${bankId} with estimated amount = ${estimatedAmount} and requested amount = ${requestedAmount}`)
        return createPromise()
    }

    console.log(`Update bank loan info ${loanId} to bank ${bankId} with estimated amount = ${estimatedAmount} and requested amount = ${requestedAmount} and status = ${status}`)

    return apiClient.loan.updateBankLoanInfo(loanId, bankId, estimatedAmount, requestedAmount, approvedAmount, status, selfSubmission)
}

export function addBankTemplate(loanId, bankId, templateId, amount, selfSubmission) {
    if (IS_MOCK) {
        console.log(`Add template ${templateId} to bank ${bankId} with amount ${amount}`)
        return createPromise()
    }

    return apiClient.loan.addBankTemplate(loanId, bankId, templateId, amount, selfSubmission)
} 

export function selectPaymentOption(recordId, isCash) {
    return apiClient.loan.selectPaymentOption(recordId, isCash)
}

function convertLoanInfo(loanInfo) {
    const info = { ...loanInfo }

    info.incomeStatus = loanInfo.income_status
    info.maritalStatus = loanInfo.marital_status

    info.docNotUploadedCount = 0

    info.documents = info.documents
        ? info.documents.map(d => {
            d.status = d.uploaded ? FILE_STATUS.uploaded : FILE_STATUS.notUploaded
            info.docNotUploadedCount += d.uploaded ? 0 : 1

            return d
        })
        : []

    info.banks = info.banks.map(b => {
        if (b.id) {
            b.notUploadedCount = 0
            b.documents = b.documents
                ? b.documents.map(d => {
                    d.status = d.uploaded ? FILE_STATUS.uploaded : FILE_STATUS.notUploaded
                    b.notUploadedCount += d.uploaded ? 0 : 1

                    return d
                })
                : []

            b.selfSubmission = b.self_submission
            b.estimatedAmount = b.estimated_amount.toString()
            b.requestedAmount = b.requested_amount.toString()
            b.approvedAmount = b.approved_amount.toString()
        }

        return b
    })

    return info
}