
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { PieChart, Pie, Cell } from 'recharts'

import Card from '../../components/common/Card'

export default class PieCharts extends Component {
    getPercent = (numerator, denominator) => {
        if (denominator === 0) {
            return 0
        }

        return Math.round(numerator / denominator * 100)
    }

    convertDocumentStatus(data) {
        return Object.keys(data).map((key) => {
            const keysTranslation = {
                notStarted: "ยังไม่เริ่มยื่นสินเชื่อ",
                waitingForDocument: "รอเอกสาร",
                waitingForBankSubmission: "รอส่งธนาคาร",
                waitingForResult: "รอผลอนุมัติ",
                receivedUnofficialResult: "ได้รับผลอนุมัติแบบไม่เป็นทางการ",
                receivedResult: "ได้รับผลอนุมัติ",
                cash: "จ่ายเงินสด",
            }
            const colorsTranslation = {
                notStarted: "rgba(0,0,0,0.1)",
                waitingForDocument: "#FC0000",
                waitingForBankSubmission: "#E77471",
                waitingForResult: "#FBDE6C",
                receivedUnofficialResult: "#A3E2D5",
                receivedResult: "#00AA7D",
                cash: "#006349",
            }
            return { name: keysTranslation[key], value: data[key], color: colorsTranslation[key] }
        })
    }

    convertInspectStatus(data) {
        return Object.keys(data).map((key) => {
            const keysTranslation = {
                waiting: "รอตรวจรับ",
                first: "รอตรวจรับครั้งที่ 2",
                second: "รอตรวจรับครั้งที่ 3",
                third: "รอตรวจรับ มากกว่าครั้งที่ 3",
                passed: "ตรวจรับผ่าน"
            }
            const colorsTranslation = {
                waiting: "#FFD078",
                first: "#A3E2D5",
                second: "#75D3C0",
                third: "#47C5AB",
                passed: "#17A185"
            }
            return { name: keysTranslation[key], value: data[key], color: colorsTranslation[key] }
        })
    }

    convertResultStatus(data) {
        let output = {}
        Object.keys(data).map((key) => {
            const keysTranslation = {
                rejected: "ไม่อนุมัติ",
                requestDocument: "ขอเอกสาร",
                incompleteLimit: "ไม่เต็มวงเงิน",
                completeLimit: "เต็มวงเงิน"
            }
            const colorsTranslation = {
                rejected: "#F92434",
                requestDocument: "#FFD078",
                incompleteLimit: "#61CDC2",
                completeLimit: "#17A185"
            }
            output[keysTranslation[key]] = { value: data[key], color: colorsTranslation[key] }
            return null
        })
        return [output,]
    }

    renderTransferChart(transfer) {
        // { total: 1400, actual: 600 }
        return (
            <div style={{ position: "relative", width: "160px", height: "160px" }}>
                <PieChart width={ 144 } height={ 144 }>
                    <Pie
                        data={ [{ value: transfer.total - transfer.actual }, { value: transfer.actual }] }
                        dataKey="value"
                        startAngle={ 90 }
                        endAngle={ 450 }
                        innerRadius={ 64 }
                        outerRadius={ 72 }
                    >
                        <Cell fill={ "#D1FCF6" } />
                        <Cell fill={ "#00ACE1" } />
                    </Pie>
                </PieChart>
                <div style={{
                    position: "absolute",
                    top: "46%",
                    left: "45%",
                    transform: "translate(-50%,-50%)",
                    textAlign: "center"
                }}>
                    <div style={{ fontSize: "13px", color: "#00ACE1" }}>โอนแล้ว</div>
                    <div style={{ fontSize: "36px", color: "#00ACE1" }}>{this.getPercent(transfer.actual, transfer.total)}%</div>
                    <div>{ transfer.actual }/<span style={{ color: "#CCC" }}>{transfer.total}</span></div>
                </div>

            </div>

        )
    }

    renderChart(data) {
        // [
        //     { name: "รอเอกสาร", value: 160, color: "#FFFFFF" },
        //     { ... }
        // ]
        return (
            <PieChart width={ 144 } height={ 144 }>
                <Pie
                    data={ data }
                    dataKey="value"
                    startAngle={ 90 }
                    endAngle={ 450 }
                    outerRadius={ 72 }
                >
                    {
                        data.map(data => <Cell key={ data.name } fill={ data.color } strokeWidth={ 0 } />)
                    }
                </Pie>
            </PieChart>
        )
    }

    renderBulletList(data, convertedResultStatus) {
        const total = data.reduce((d, v) => d + v.value, 0)

        return (
            <div className="d-flex flex-column justify-content-start">
                { data.map(d =>
                    <div className="d-flex flex-row mb-15" key={ d.name }>
                        <i className="fa fa-circle mr-1 pt-02" style={{ color: d.color }} />
                        <div>
                            <div>{ d.name }: { d.value } คน
                                <span className="ml-05" style={{ color: "#8A8A8F", fontSize: "14px" }}>({this.getPercent(d.value, total)}%)</span>
                            </div>
                            { d.name === "ได้รับผลอนุมัติ" ? this.renderResultStatusBulletList(convertedResultStatus) : null }
                        </div>
                    </div>
                )}
            </div>
        )
    }

    renderResultStatusBulletList(data) {
        return (
            <div className="row no-gutters">
                { Object.keys(data[0]).map((key) => {
                    const value = data[0][key]
                    return (
                        <div className="col-12 mt-05" key={ key } style={{ fontSize: "12px", color: "#8A8A8F" }}>
                            <i className="fa fa-circle mr-05" style={{ color: value.color }} />{ key }: { value.value } 
                        </div>
                    )
                })}
            </div>
        )
    }

    render() {
        const { data } = this.props
        //const convertedDocumentStatus = this.convertDocumentStatus(documentStatus)
        //const convertedInspectStatus = this.convertInspectStatus(inspectStatus)
        //const convertedResultStatus = this.convertResultStatus(resultStatus)

        return (
            <Card>
                <div className="row">
                    <div className="col-12 mt-1">
                        <h4>{data.title}</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-2 col-12 d-flex flex-column align-items-center  my-2">
                        <h5>สถานะการโอน</h5>
                    </div>
                    <div className="col-xl-2 col-4 d-flex flex-column align-items-center my-2">
                        <h5 className="text-overflow-1">สถานะเอกสาร</h5>
                        <PC data={data.data[0]} />
                    </div>
                    <div className="col-xl-3 col-6 d-flex flex-column my-2 pt-15">
                        { /* this.renderBulletList(convertedDocumentStatus, convertedResultStatus) */ }
                    </div>
                    <div className="col-xl-2 col-4 d-flex flex-column align-items-center my-2">
                        <h5 className="text-overflow-1">สถานะการตรวจรับ</h5>
                    </div>
                    <div className="col-xl-3 col-6 d-flex my-2 pt-15">
                    </div>
                </div>
            </Card>
        )
    }
}

const PC = ({ data }) => {
    const total = data.data.reduce((acc, d) => d.value + acc, 0)
    return <div style={{ position: "relative", width: "160px", height: "160px" }}>
        <PieChart width={ 144 } height={ 144 }>
            <Pie
                data={data.data}
                dataKey="value"
                startAngle={ 90 }
                endAngle={ 450 }
                innerRadius={ 64 }
                outerRadius={ 72 }
            >
                <Cell fill={ "#D1FCF6" } />
                <Cell fill={ "#00ACE1" } />
            </Pie>
        </PieChart>
        <div style={{
            position: "absolute",
            top: "46%",
            left: "45%",
            transform: "translate(-50%,-50%)",
            textAlign: "center"
        }}>
            <div style={{ fontSize: "36px", color: "#00ACE1" }}>{total}</div>
            <div style={{ fontSize: "13px", color: "#00ACE1" }}>{data.unit}</div>
        </div>

    </div>
}
