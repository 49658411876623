import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import SelectInput from '../common/SelectInput'
import TextInput from '../common/TextInput'
import apiClient from '../../apis'
import _ from 'lodash'
import { inModal } from '../../hoc/inModal'
import ModalTitle from '../common/ModalTitle'
import ModalFooter from '../common/ModalFooter'
import FormNewLine from '../common/FormNewLine'
import CheckboxInput from '../common/CheckboxInput';
import { phoneNumberFormatter } from '../../common/util'

export class LeadInfoForm extends Component {
    constructor(props){
        super(props)

        this.state = {
            data: {
                first_name: "",
                last_name: "",
                email: "",
                phone_number: "",
                qualified: true,
                prospect: false,
                gender: null,
                age: "",
                income: null,
                occupation: null,
                address: "",
                sub_district: "",
                district: "",
                province: "",
                postal_code: "",
                line_id: "",
                sources: [],
                occupation_detail: ""
            },
            show: {
                interest: ''
            },
            occupations: [],
            salary_ranges: [],
            answer_list: [
                {
                    question_id: "",
                    answers: [],
                },
            ],
            questions: [],
            online_sources_list: [],
            offline_sources_list: [],
            products: [],
            isClick: false
        }

        apiClient.lead.getLeadOptions().then(result => {
            const occupations = result.occupations.map(option => {
                return {
                    value: option.key,
                    label: option.value
                }
            })
            const salary = result.salary_ranges.map(option => {
                return {
                    value: option.key,
                    label: option.value
                }
            })
            this.setState({occupations: occupations})
            this.setState({salary_ranges: salary})
        })
        apiClient.lead.getQuestions().then(res => {
            const questions = res.questions.map(question => {
                return {
                    question_id: question.id,
                    title: question.title,
                    answers: question.answers
                }
            })
            this.setState({
                questions: questions,
                answer_list: questions.map(question => {
                    return {
                        question_id: question.question_id,
                        answers: []
                    }
                })
            })
        })

        apiClient.campaign.getAds().then(res => {
            const onlineAds = res.advertisements.filter(list => list.type === 'online').map(list => {
                return {
                    id: list.id,
                    values: list.name,
                    type: list.type
                }
            })

            const offlineAds = res.advertisements.filter(list => list.type === 'offline').map(list => {
                return {
                    id: list.id,
                    values: list.name,
                    type: list.type
                }
            })

            this.setState({
                online_sources_list: onlineAds,
                offline_sources_list: offlineAds
            })
        })
    }

    save = (skipRefreshFunc=false) => {
        if(this.state.data.full_name === ""){
            alert("กรุณากรอกชื่อ-นามสกุล")
            return Promise.reject()
        }
        else if(this.state.data.phone_number === ""){
            alert("กรุณากรอกเบอร์โทรศัพท์")
            return Promise.reject()
        }
        else{
            const p = apiClient.lead.createLead(this.state.data).then(result => {
                    if (result.key) {
                        alert("สร้างผู้สนใจสำเร็จแล้ว")
                        if (this.state.answer_list.filter(answer => answer.answers.length !== 0).length !== 0) {
                            apiClient.lead.createAnswer(result.key, this.state.answer_list)
                        }
                        return result;
                    } else {
                        alert("สร้างผู้สนใจไม่สำเร็จ")
                        return Promise.reject()
                    }
                })
                return p
        }
    }

    checkboxRender = (question, id) =>{
        return question.map((value,index) => {
            return  (
                <div key={index}>
                    <input className="mr-05" type="checkbox" value={value} id={id} onChange={this.onChoiceSelect} />
                    <label htmlFor={id}>{value}</label>
                </div>
            )
        })
    }

    renderSourceCheckbox = (sources) => {
        return sources.map((source, index) => {
            return (
                <div key={`ad-index-${index}`}>
                    <input className="mr-05" type="checkbox" value={source.values} id={source.id} onChange={this.onSelectSource}/>
                    <label htmlFor={source.id}>{source.values}</label>
                </div>
            )
        })
    }

    onSelectSource = (event) => {
        let data = this.state.data
        if(event.target.checked === true) {
            data.sources.push(event.target.id)
        }
        else {
            const position = data.sources.indexOf(event.target.id)
            data.sources.splice(position, 1)
        }
        this.setState({data})
    }

    onChoiceSelect = (event) => {
        let answer_list = this.state.answer_list

        answer_list.map((answer, index) => {
            if(event.target.checked === true){
                if(answer.question_id == event.target.id){
                    answer.answers.push(event.target.value)
                }
            }
            else{
                if(answer.question_id == event.target.id){
                    const order = answer.answers.indexOf(event.target.value)
                    answer.answers.splice(order, 1)
                }
            }
        })
        this.setState({answer_list})
    }

    searchProduct = () => {
        if (!this.state.data.product) {
            this.setState({
                products: [],
                isClick: false
            })
        }  else {
            apiClient.activity.getProductSearch(this.state.data.product)
                .then(res => {
                    const product = res.products.map(product => {
                        return {
                            product: product.value,
                            value: product.value
                        }
                    })
                    const data = this.state.data
                    data.product = data.product
                    this.setState({
                        products: product,
                        isClick: false,
                        product: data
                    })
                })
        }
    }

    selectProduct = product => {
        const data = this.state.data
        const show = this.state.show
        show.interest = product.value
        data.product= product.product
        this.setState({
            interest: show,
            product: data,
            isClick : true
        })
    }

    render() {
        const { options, noHeaderAndFooter } = this.props

        const form = (
            <div className="d-flex flex-column" style={{ flexGrow: 1 }}>
                <div className="row">
                    <div className="col-sm-3">
                        <TextInput
                            name="first_name"
                            placeholder="ชื่อ"
                            type="text"
                            required={ true }
                            target={ this }
                            stateKey="data"
                        />
                    </div>
                    <div className="col-sm-3">
                        <TextInput
                            name="last_name"
                            placeholder="นามสกุล"
                            type="text"
                            target={ this }
                            stateKey="data"
                        />
                    </div>
                    <div className="col-sm-2" style={{lineHeight: 3.5}}>
                        <CheckboxInput
                            name="qualified"
                            target={ this }
                            stateKey="data"
                            label="Qualified"
                        />
                    </div>
                    <div className="col-sm-2" style={{lineHeight: 3.5}}>
                        <CheckboxInput 
                            name="prospect"
                            target={ this }
                            stateKey="data"
                            label="Prospect"
                        />
                    </div>

                    <FormNewLine />
                    <div className="col-sm-4">
                        <TextInput
                            name="phone_number"
                            placeholder="เบอร์โทรศัพท์ (xxx-xxx-xxxx)"
                            required={ true }
                            target={ this }
                            stateKey="data"
                            renderFormatter={value => phoneNumberFormatter(value)}
                            dataFormatter={value => value.replace(/-/g, '')}
                        />
                    </div>
                    <div className="col-sm-4">
                        <TextInput
                            name="email"
                            placeholder="E-mail"
                            type="email"
                            target={ this }
                            stateKey="data"
                        />
                    </div>

                    <FormNewLine />
                    <div className="col-sm-3">
                        <selectWrap>
                            <SelectInput
                                name="gender"
                                placeholder="เพศ"
                                target={ this }
                                stateKey="data"
                                options={ options.genders }
                            />
                        </selectWrap>
                    </div>
                    <div className="col-sm-3">
                        <TextInput
                            name="age"
                            placeholder="อายุ"
                            type="number"
                            target={ this }
                            stateKey="data"
                        />
                    </div>
                    <div className="col-sm-3">
                        <TextInput
                            name="line_id"
                            placeholder="Line ID"
                            type="text"
                            target={ this }
                            stateKey="data"
                        />
                    </div>

                    <FormNewLine />
                    <div className="col-sm-3">
                        <TextInput
                            name="district"
                            placeholder="เขต/อำเภอ"
                            target={ this }
                            stateKey="data"
                        />
                    </div>
                    <div className="col-sm-3">
                        <TextInput
                            name="province"
                            placeholder="จังหวัด"
                            target={ this }
                            stateKey="data"
                        />
                    </div>
                    <FormNewLine />
                    <div className="col-sm-3">
                        <selectWrap>
                            <SelectInput
                                name="occupation"
                                placeholder="อาชีพ"
                                target={ this }
                                stateKey="data"
                                options={ this.state.occupations }
                            />
                        </selectWrap>
                    </div>
                    <div className="col-sm-6">
                        <TextInput 
                            name="occupation_detail"
                            placeholder="รายละเอียดของอาชีพ"
                            target={ this }
                            stateKey="data"
                        />
                    </div>
                    <FormNewLine />
                    <div className="col-sm-6">
                        <selectWrap>
                            <SelectInput
                                name="income"
                                placeholder="รายได้ / เดือน(บาท)"
                                target={ this }
                                stateKey="data"
                                options={ this.state.salary_ranges }
                            />
                        </selectWrap>
                    </div>
                    <FormNewLine />
                    {/* <div className="col-sm-6">
                        <TextInput
                            name="product"
                            placeholder="ห้องที่สนใจ"
                            stateKey="data"
                            target={ this }
                            required={true}
                            onChange={() => this.searchProduct() }
                        />
                        {
                            !_.isEmpty(this.state.products) ?
                            !this.state.isClick ?
                            (
                                <div>
                                    <div className="mt-2 mb-05">ผลการค้นหา</div>
                                    <div className="p-05" style={{ backgroundColor: "rgba(0,0,0,0.1)", borderRadius: "10px" }}>
                                        { this.state.products.map(product => {
                                            return (
                                            <SearchResultItem
                                                key={product.value}
                                                product={product}
                                                selectProduct={product => this.selectProduct(product)}
                                            />
                                            )
                                        })}
                                    </div>
                                </div>
                            ) :
                            null :
                            null
                        }
                    </div>
                    <FormNewLine /> */}
                    <div className="col-sm-6">
                        <h3>แบบสอบถาม</h3>
                    </div>
                    <FormNewLine />
                    {
                        this.state.questions.map((question, index) => {
                            return (
                                <div className={`col-sm-${index + 3}`} key={`question-${index}`}>
                                    <h5>{`${index + 1}.`} {this.state.questions.length === 0 ? "" : this.state.questions[index].title}</h5>
                                    <br />
                                    {this.checkboxRender(question.answers, this.state.questions.length > 0 ? this.state.questions[index].question_id : '')}
                                </div>
                            )
                        })
                    }
                    <div className="col-sm-6" style={{marginTop: "40px"}}>
                        <h3>สื่อโครงการ</h3>
                    </div>
                    <FormNewLine />
                    {
                        this.state.online_sources_list.length > 0 ?
                        <div className="col-sm-3">
                            <h5>สื่อOnline</h5>
                            {this.renderSourceCheckbox(this.state.online_sources_list)}
                        </div>
                        : null
                    }
                    {
                        this.state.offline_sources_list.length > 0 ?
                        <div>
                            <h5>สื่อOffline</h5>
                            {this.renderSourceCheckbox(this.state.offline_sources_list)}
                        </div>
                        : null
                    }
                </div>
            </div>
        )

        if (noHeaderAndFooter) {
            return form
        }

        return (
            <div className="d-flex flex-column">
                <ModalTitle title="เพิ่มผู้สนใจ" onClick={ () => this.props.closeModal() } />
                {form}
                <ModalFooter>
                    <button onClick={ () => this.save() } className="btn btn-main">บันทึก</button>
                </ModalFooter>
            </div>

        )
    }
}
LeadInfoForm.propTypes = {
    options: PropTypes.object,
    refreshFunc: PropTypes.func,
    customerId: PropTypes.string,
    noHeaderAndFooter: PropTypes.bool,
}

function mapStateToProps(state) {
    return {
        options: state.other.options,
    }
}

const SearchResultItem = ({product, selectProduct}) => {
    return <div className="clickable" onClick={() => selectProduct(product)}>
        <p className="m-0 p-02">
            {product.value}
        </p>
    </div>
}

LeadInfoForm = connect(mapStateToProps, null, null, { withRef: true })(LeadInfoForm)

const LeadInfoModal = inModal(LeadInfoForm)
export default LeadInfoModal