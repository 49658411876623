import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router, browserHistory } from 'react-router'
import { createStore, applyMiddleware, compose } from 'redux'
import fetchIntercept from 'fetch-intercept'

import getRoutes from './routes'
import reducers from './reducers'
import registerServiceWorker from './registerServiceWorker'
import thunk from 'redux-thunk'

import './toolbox/theme.css'
import theme from './toolbox/theme'
import ThemeProvider from 'react-toolbox/lib/ThemeProvider'

const finalCreateStore = compose(
    applyMiddleware(thunk),
)(createStore)

export const store = finalCreateStore(reducers)

fetchIntercept.register({
    response: function (response) {
        switch(response.status) {
            case 401:
            {
                browserHistory.push('/login')
                return Promise.reject(response.statusText)
            }
            case 200:
            case 201:
            case 203:
            case 204:
            case 205:
            case 301:
            case 302:
            case 304:
                return response
            default:
                return Promise.reject(response.statusText)
        }
    },
})

ReactDOM.render(
    <ThemeProvider theme={ theme }>
        <Provider store={store}>
            <Router history={browserHistory} routes={getRoutes(store)} />
        </Provider>
    </ThemeProvider>,
    document.getElementById('root')
)

// registerServiceWorker()
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister();
  });
}
