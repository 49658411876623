import React, { Component } from 'react'
import { connect } from 'react-redux'

import { removeToken } from '../../actions/auth'
import datastore from '../../datastore'
import Wrapper from '../../layouts/Wrapper'
import Card from '../../components/common/Card'
import CardTitle from '../../components/common/CardTitle'
import ProjectCard from './ProjectCard'

class SelectProject extends Component {
    state = {
        projects: null
    }

    componentWillMount() {
        datastore.user.getProfile()
            .then(res => {
                this.setState({
                    projects: res.projects
                })
            })
    }

    logout = () => {
        this.props.removeToken()
        this.props.router.push('/login')
    }

    render() {
        if (!this.state.projects) {
            return null
        }

        return (
            <div className="d-flex flex-column align-items-center w-100" style={{ backgroundColor: "#17A185", position: "absolute", top: "0", left: "0" }}>
                <Wrapper className="w-100 d-flex flex-column align-items-center">
                    <img src="/img/infiniteLogoWhite.svg" height="80px" alt="logo" className="mt-15 mb-3" style={{ color: "white" }} />
                    <Card className="w-100 py-2 px-2">
                        {/* <CardTitle large hasLine title="คอนโดมิเนียม" /> */}
                        <CardTitle large hasLine title="โครงการ" />
                            <div className="d-flex flex-row justify-content-between">
                                {this.state.projects.length > 0
                                    ? this.state.projects.map((p, index) => {
                                        return <ProjectCard name={p.name} projectId={p.id.toString()} key={index} />
                                    })
                                    : <h5>ไม่มีโครงการที่สามารถเข้าถึงได้</h5>
                                }
                            </div>
                        {/* <CardTitle large hasLine title="ทาวน์โฮม" /> */}
                    </Card>
                    <button className="btn btn-red" onClick={this.logout}>ออกจากระบบ</button>
                </Wrapper>
            </div>
        )
    }
}

const actions = {
    removeToken
}

export default connect(null, actions)(SelectProject)
