import React, { Component } from 'react'
import { Link } from 'react-router'

export default class SuccessView extends Component {
    componentWillMount() {
        this.props.setHeader('')
    }

    render() {
        return (
            <div className="row h-100">
                <div className="col-6 offset-3 d-flex flex-column">
                    <div style={{ marginTop: "10rem" }} className="d-flex flex-row justify-content-between align-items-center mb-5">
                        <h3>เพิ่มลูกค้า/จองห้องสำเร็จ</h3>
                    </div>
                    <Link to="/reserve/search">กลับหน้าค้นหาลูกค้า</Link>
                </div>
            </div>
        )
    }
}