import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Wrapper extends Component {
    render() {
        const { children, className } = this.props
        
        return (
            // <div className="animated fadeInRight p-15">
            <div className={`p-15 ${className}`}>
                { children }
            </div>
        )
    }
}

Wrapper.propTypes = {
    enabled: PropTypes.bool
}