import React, { Component } from 'react'
import { connect } from 'react-redux'

import datastore from '../../datastore'
import LazyTable from '../../components/common/tables/LazyTable'
import Card from '../../components/common/Card'
import Wrapper from '../../layouts/Wrapper'
import { translate } from '../../common/util'
import CreateDefectModal from './CreateDefectModal'
import { RESTRICTED_KEYWORD, PARTIAL_KEYWORD } from '../../common/const'
import { store } from '../../index';

class WarrantiesView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            query: ''
        }
        this.rowClickCallback = this.rowClickCallback.bind(this)
        this.getValues = this.getValues.bind(this)
        this.handleExport = this.handleExport.bind(this)
        this.columns = [
            {
                header: 'รหัสสินค้า',
                accessor: 'skuCode',
                unSortable: false,
                width: 1,
            },
            {
                header: 'ชื่อเจ้าของกรรมสิทธิ์',
                accessor: 'customerName',
                unSortable: false,
                width: 2,
                render: value => <span className="text-overflow-2">{value}</span>
            },
            {
                header: 'ความเร่งด่วนของปัญหา',
                accessor: 'severity',
                unSortable: false,
                width: 2,
                render: value => <span className="text-overflow-2">{translate('warranty_defect_severity', value)}</span>
            },
            {
                header: 'สถานะการประกัน',
                accessor: 'warrantyStatus',
                unSortable: false,
                width: 2,
                render: value => {
                    const style = { color: value === 1 ? 'green' : 'red' }
                    return <span className="text-overflow-2" style={style}>
                        {translate('warranty_expiry_statuses', value)}
                    </span>
                }
            },
            {
                header: 'สถานะการแจ้งซ่อม',
                accessor: 'status',
                unSortable: true,
                width: 2,
                render: value => <span className="text-overflow-2">{translate('warranty_defect_report_statuses', value)}</span>
            },
            {
                header: 'วันที่ซ่อม',
                accessor: 'dueDate',
                unSortable: true,
                width: 2,
            }
        ]
    }

    componentWillMount() {
        this.props.setHeader(
            'รับประกันสินค้า',
            [this.handleExport, this.isPartial() ? null : this.goToAssignView, this.openAddDefectModal],
            ['ดาวน์โหลด CSV', this.isPartial() ? null : 'ดูตารางงาน', 'เพิ่มรายการข้อบกพร่อง'],
            this.searchCallback,
            'ค้นหารหัสสินค้า'
        )
    }

    handleExport() {
        datastore.warranty.exportWarranty()
    }

    isPartial = () => {
        return this.props.router.location.pathname.indexOf(PARTIAL_KEYWORD) !== -1
    }

    isRestricted = () => {
        return this.props.router.location.pathname.indexOf(RESTRICTED_KEYWORD) !== -1
    }

    openAddDefectModal = () => {
        this.refs.createModal.openModal()
    }

    goToAssignView = () => {
        this.props.router.push('/warranty-assign/')
    }

    refresh = () => {
        this.setState({ query: '' })
    }

    createDefectHandler = (productId, defectInfo) => {
        datastore.warranty.createDefect(productId, defectInfo)
            .then(d => {
                this.refresh()
            })
    }

    searchCallback = value => {
        this.setState({
            query: value
        })
    }

    getValues(offset, pageSize, sortKey, sortOrder, filters) {
        return datastore.warranty.getProducts(offset, pageSize, sortKey? sortKey : 'first_created', sortOrder, filters, this.state.query)
    }

    rowClickCallback(row) {
        const url = this.isRestricted() ? `/warranty-list/${row.id}/restricted` : `/warranty-list/${row.id}`
        this.props.router.push(url)
    }

    render() {
        const { options } = this.props
        const projectId = store.getState().auth.profile.current_project.id
        return (
            <Wrapper>
                <Card>
                    <LazyTable
                        columns={ this.columns }
                        getValues={ this.getValues }
                        filterOptions={ options }
                        rowClickCallback={ this.rowClickCallback }
                        ref="productTable"
                    />
                    <CreateDefectModal
                        ref="createModal"
                        createDefectHandler={this.createDefectHandler}
                        productId={this.productId}
                        product={this.state.product}
                        projectId={projectId}
                    />
                </Card>
            </Wrapper>
        )
    }
}

function mapStateToProps(state) {
    return {
        options: state.other.options,
    }
}

export default connect(mapStateToProps, null)(WarrantiesView)
