import React, { Component } from 'react'
import PropTypes from 'prop-types'

import TextWithLabel from '../../../components/common/TextWithLabel'
import { inModal } from '../../../hoc/inModal'
import ModalTitle from '../../../components/common/ModalTitle'

class DefectInfo extends Component {
    render() {
        const { closeModal, defect, uploadHandler } = this.props
        return (
            <div>
                <ModalTitle title="" onClick={ () => closeModal() } />
                <div className="row justify-content-center">
                    <div className="col-4 text-center px-2">
                        <Map map={ defect.map } />
                    </div>
                    <div className="col-8">
                        <Detail 
                            index={ defect.id }
                            title={ defect.title }
                            defectType={ defect.defectType }
                            location={ defect.location }
                            status={ defect.status }
                            before={ defect.before }
                            after={ defect.after }
                            uploadHandler={ uploadHandler }
                        />
                    </div>
                </div>
            </div>
        )
    }
}

DefectInfo.propTypes = {
    defect: PropTypes.object,
    uploadHandler: PropTypes.func
}

const DefectInfoModal = inModal(DefectInfo)
export default DefectInfoModal

const Map = ({ map }) => <img src={ map } width="240px" alt="" />

const Detail = ({ 
    index, 
    title, 
    defectType, 
    location, 
    status, 
    before, 
    after, 
    uploadHandler }) => (
    <div className="h-100 pr-2" style={{ maxWidth: "680px" }}>
        <h5 className="w-100">{ index }. { title }</h5>
        <div className="row no-gutters justify-content-between mb-2">
            <TextWithLabel label="ชนิดงาน">{ defectType }</TextWithLabel>
            <TextWithLabel label="ตำแหน่ง">{ location }</TextWithLabel>
            <Status status={ status } />
        </div>
        <DefectImages 
            before={ before }
            after={ after }
            uploadHandler={ uploadHandler }
        />
    </div>
)

const DefectImages = ({ before, after, uploadHandler }) => (
    <div className="row no-gutters justify-content-between align-items-start">
        <div>
            <p className="f13 grey">ก่อนแก้ไข</p>
            <img src={ before } width="240px" height="240px" alt="" className="img-rounded" />
        </div>
        <div className="d-flex flex-column justify-content-around">
            <p className="f13 grey">หลังแก้ไข</p>
            <img src={ after } width="240px" height="240px" alt="" className="img-rounded" />
            {/* <button 
                className="btn btn-main align-self-center mt-1"
                onClick={ uploadHandler }
            >
                อัปโหลด
            </button> */}
        </div>
    </div>
)

const Status = status => {
    let color, text
    switch (status.status) {
        case 'แก้ไขแล้ว':
            color = 'green'
            text = 'แก้ไขแล้ว'
            break
        default:
            color = 'red'
            text = 'ยังไม่ได้แก้ไข'
            break
    }
    return <TextWithLabel label="สถานะ" color={ color }>{ text }</TextWithLabel>
}
