import React, { Component } from 'react'
import _ from 'lodash'

import Wrapper from '../../../layouts/Wrapper'
import apiClient from '../../../apis'
import TextInput from '../../../components/common/TextInput'
import Card from '../../../components/common/Card'
import { LeadInfo } from './leadInfoModal'
import { phoneNumberFormatter } from '../../../common/util'

export default class SearchView extends Component {
    state = {
        searchResults: [],
        selectedLead: null,
        data: {
            query: '',
        },
    }

    componentWillMount() {
        this.props.setHeader('ค้นหาผู้สนใจซื้อ')
    }

    selectLead = leadId => {
        this.setState({ selectedLead: null })
        apiClient.lead.getLead_(0, leadId)
            .then(lead => {
                this.setState({ selectedLead: { ...lead, id: leadId }})
            })
    }

    searchCustomer = () => {
        if (!this.state.data.query) {
            this.setState({
                searchResults: []
            })
        } else {
            apiClient.lead.searchLeads_(this.state.data.query)
                .then(leads => {
                    const searchResults = leads.map(c => {
                        return { 
                            leadId: c.id.toString(), 
                            tel: c.phone_number,
                            name: c.full_name
                        }
                    })

                    this.setState({
                        searchResults
                    })
                })
        }
    }

    formatPhoneNumber = value => { 
        const tels = value.split(',')
        return tels.map((t, index) => <div className="text-overflow-2" key={index}>{t}</div>)
    }

    goToAddCustomerStep = customerId => {
        const next = this.props.location.query.next
        this.props.router.push(`/sale-mode/add-lead?next=${next}`)
    }

    goToNextStep = () => {
        const next = this.props.location.query.next
        const activityId = this.props.location.query.activityId
        const leadId = this.state.selectedLead.id
        const url = next === 'activity' ? `/sale-mode/leads/${leadId}/add-activity?activityId=${activityId}`
            : next === 'reserve' ?  `/sale-mode/leads/${leadId}/book`
            : '/not-found'

        this.props.router.push(url)
    }

    render() {
        const { selectedLead } = this.state
        const next = this.props.location.query.next

        return (
            <Wrapper>
                <div className="row h-100">
                    <div className="col-4 d-flex flex-column">
                        <Card>
                            <div className="d-flex flex-row justify-content-between align-items-center mb-5">
                                <h3>ค้นหาผู้สนใจ</h3>
                                <button 
                                    className="btn btn-main" 
                                    onClick={this.goToAddCustomerStep}
                                >
                                    เพิ่มผู้สนใจซื้อใหม่
                                </button>
                            </div>
                            <div className="input-group align-items-center">
                                <TextInput name="query" placeholder="ชื่อ" type="text" target={ this } stateKey="data" onChange={ this.searchCustomer } />
                                <i className="fa fa-search" style={{ color: "#00ACE1" }}></i>
                            </div>
                            { !_.isEmpty(this.state.searchResults) 
                                ? (
                                    <div>
                                        <div className="mt-2 mb-05">ผลการค้นหา</div>
                                            <div style={{ 
                                                //backgroundColor: "rgba(0,0,0,0.1)", 
                                            }}
                                            >
                                            {this.state.searchResults.map((result, index) =>
                                                <div 
                                                    className="clickable" 
                                                    key={index} 
                                                    onClick={() => this.selectLead(result.leadId)}
                                                    style={{ 
                                                        fontSize: '18px',
                                                        marginTop: '8px',
                                                        borderBottom: '1px solid #ccc',
                                                        padding: '4px'
                                                    }}
                                                >
                                                    <div>{result.name}</div>
                                                    <div className="d-flex" style={{ fontSize: '12px', marginTop: '4px' }}>
                                                        เบอร์โทร:&nbsp;&nbsp;{phoneNumberFormatter(result.tel)}
                                                    </div>

                                                    {/* <span className="ml-auto">{this.formatPhoneNumber(result.tel)}</span> */}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div style={{ marginTop: '36px' }}>
                                        ... ไม่มีผลการค้นหา ...
                                    </div>
                                )
                            }
                        </Card>
                    </div>
                    <div className="col-8 d-flex flex-column">
                        <Card>
                            {selectedLead 
                                ? (
                                    <div>
                                        <LeadInfo customer={selectedLead} />
                                        <button 
                                            onClick={this.goToNextStep} 
                                            className="btn btn-outline"
                                            style={{ float: 'right' , marginTop: '16px'}}
                                        >
                                            {next === 'activity' ? 'สร้างกิจกรรม' 
                                                : next === 'reserve' ? 'จองห้อง'
                                                : '???'
                                            }
                                        </button>
                                    </div>
                                ) : 'กรุณาเลือกผู้สนใจ'
                            }
                        </Card>
                    </div>
                </div>
            </Wrapper>
        )
    }
}
