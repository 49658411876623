import React, { Component } from 'react'
import { connect } from 'react-redux'

import Wrapper from '../../layouts/Wrapper'
import PieCharts from './PieCharts'
import datastore from '../../datastore'
import { removeToken } from '../../actions/auth'

class DashboardView extends Component {
    state = {
        pieChartData: {
            title: "มูลค่าโครงการ",
            legends: [
                { label: "โอนแล้ว", color: "green" },
                { label: "รอโอน", color: "yellow" },
                { label: "ว่าง", color: "grey" },
            ],
            data: [
                {
                    unit: "ล้านบาท",
                    data: [
                        { name: "โอนแล้ว", value: 654 },
                        { name: "ขายรอโอน", value: 1598 },
                        { name: "ว่าง", value: 1952 },
                    ]
                },
                {
                    unit: "ยูนิต",
                    data: [
                        { name: "โอนแล้ว", value: 576 },
                        { name: "ขายรอโอน", value: 727 },
                        { name: "ว่าง", value: 2397 },
                    ]
                }
            ]
        },
        movementData: [
            { value: 540, label: "ผู้เยี่ยมชมใหม่ (คน)" },
            { value: 431, label: "ผู้เยี่ยมชมซ้ำ (คน)" },
            { value: 431, label: "การจอง (คน)" },
            { value: 132, label: "ทำสัญญา (คน)" },
            { value: 3, label: "การโอน (คน)" },
            { value: 11, label: "ยกเลิก (คน)" },
        ]
    }

    logout = () => {
        this.props.removeToken()
        this.props.router.push('/')
    }

    componentWillMount() {
        this.props.setHeader('ภาพรวมโครงการ')
        //datastore.other.getDashboardData()
            //.then(res => {
                //const dashboardData = res.find(d => d.title === this.props.profile.current_project.name)
                //this.setState({
                    //dashboardData
                //})
            //})
            //.catch(error => {
                //this.logout()
            //})
    }

    render() {
        //if (!this.state.dashboardData) {
            //return null
        //}


        return (
            <Wrapper>
                <PieCharts data={this.state.pieChartData} />
            </Wrapper>
        )
    }
}

function mapStateToProps(state) {
    return {
        profile: state.auth.profile
    }
}

export default connect(mapStateToProps, { removeToken })(DashboardView)
