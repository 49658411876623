import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import SelectInput from '../common/SelectInput'
import TextAreaInput from '../common/TextAreaInput'
import TextInput from '../common/TextInput'
import apiClient from '../../apis'
import { inModal } from '../../hoc/inModal'
import ModalTitle from '../common/ModalTitle'
import ModalFooter from '../common/ModalFooter'
import FormNewLine from '../common/FormNewLine'

export class CustomerInfoForm extends Component {
    state = {
        data: {
            // companyId: 0,
            refId: '',

            // Fully support
            firstName: "",
            lastName: "",

            gender: "",
            age: "",
            citizenId: "",

            tel: "",
            email: "",

            lineId: "",

            addressesJson: null,

            occupation: "",
            income: "",

            // created: "0001-01-01 00:00:00",

            purpose: "",
            channelId: "",

            note: "",
        },
    }

    componentWillMount() {
        this.setInitialData(this.props.customerId)
    }

    componentWillReceiveProps(nextProps) {
        this.setInitialData(nextProps.customerId)
    }

    setInitialData = customerId => {
        // if (customerId) {
        //     datastore.customer.getCustomer(customerId)
        //         .then(info => {
        //             console.log(info)
        //             this.setState({
        //                 data: info
        //             })
        //         })
        // }
    }

    save = (skipRefreshFunc=false) => {
        const p = this.props.customerId
            ? apiClient.customer.updateCustomer(this.state.data, this.props.customerId)
            : apiClient.customer.createCustomer(this.state.data)

        p.then(res => {
            if (this.props.refreshFunc && !skipRefreshFunc) {
                this.props.refreshFunc(res)
            }
            if (this.props.closeModal) {
                this.props.closeModal()
            }
        })
        return p
    }

    render() {
        const { options, noHeaderAndFooter } = this.props
        const form = (
            <div className="d-flex flex-column" style={{ flexGrow: 1 }}>
                <div className="row">
                    <div className="col-sm-6">
                        <TextInput
                            name="firstName"
                            placeholder="ชื่อ"
                            type="text"
                            target={ this }
                            stateKey="data"
                        />
                    </div>
                    <div className="col-sm-6">
                        <TextInput
                            name="lastName"
                            placeholder="นามสกุล"
                            type="text"
                            target={ this }
                            stateKey="data"
                        />
                    </div>

                    <FormNewLine />
                    <div className="col-sm-3">
                        <selectWrap>
                            <SelectInput
                                name="gender"
                                placeholder="เพศ"
                                target={ this }
                                stateKey="data"
                                options={ options.genders }
                            />
                        </selectWrap>
                    </div>
                    <div className="col-sm-3">
                        <TextInput
                            name="age"
                            placeholder="อายุ"
                            type="number"
                            target={ this }
                            stateKey="data"
                        />
                    </div>
                    <div className="col-sm-6">
                        <TextInput
                            name="citizenId"
                            placeholder="เลขประจําตัวประชาชน"
                            type="number"
                            target={ this }
                            stateKey="data"
                        />
                    </div>

                    <FormNewLine />
                    <div className="col-sm-6">
                        <TextInput
                            name="tel"
                            placeholder="เบอร์โทรศัพท์ (xxxxxxxxxx,xxxxxxxxxx)"
                            type="text"
                            target={ this }
                            stateKey="data"
                            dataFormatter={ value => value.replace(/-/g, '') }
                        />
                    </div>
                    <div className="col-sm-6">
                        <TextInput
                            name="email"
                            placeholder="E-mail"
                            type="email"
                            target={ this }
                            stateKey="data"
                        />
                    </div>

                    <FormNewLine />
                    <div className="col-sm-6">
                        <TextInput
                            name="lineId"
                            placeholder="LINE ID"
                            type="text"
                            target={ this }
                            stateKey="data"
                        />
                    </div>

                    <FormNewLine />
                    <div className="col-sm-12">
                        <TextAreaInput
                            name="home"
                            placeholder="ที่อยู่ปัจจุบัน"
                            rows={ 4 }
                            target={ this }
                            stateKey="data"
                            nestedKey="addressesJson"
                        />
                    </div>

                    <FormNewLine />
                    <div className="col-sm-12">
                        <TextAreaInput
                            name="work"
                            placeholder="ที่อยู่ที่ทำงาน"
                            rows={ 4 }
                            target={ this }
                            stateKey="data"
                            nestedKey="addressesJson"
                        />
                    </div>

                    <FormNewLine />
                    <div className="col-sm-6">
                        <selectWrap>
                            <SelectInput
                                name="occupation"
                                placeholder="เลือกอาชีพ"
                                target={ this }
                                stateKey="data"
                                options={ options.occupations }
                            />
                        </selectWrap>
                    </div>
                    <div className="col-sm-6">
                        <selectWrap>
                            <SelectInput
                                name="income"
                                placeholder="รายได้ / เดือน(บาท)"
                                target={ this }
                                stateKey="data"
                                options={ options.salary_ranges }
                            />
                        </selectWrap>
                    </div>

                    <FormNewLine />
                    <div className="col-sm-6">
                        <TextInput
                            name="purpose"
                            placeholder="วัตถุประสงค์"
                            type="text"
                            target={ this }
                            stateKey="data"
                        />
                    </div>
                    <div className="col-sm-6">
                        <selectWrap>
                            <SelectInput
                                name="channelId"
                                placeholder="ช่องทางการตลาด"
                                target={ this }
                                stateKey="data"
                                options={ options.channels }
                            />
                        </selectWrap>
                    </div>

                    <FormNewLine />
                    <div className="col-sm-12">
                        <TextAreaInput
                            name="note"
                            placeholder="หมายเหตุ"
                            rows={ 4 }
                            target={ this }
                            stateKey="data"
                        />
                    </div>
                </div>
            </div>
        )

        if (noHeaderAndFooter) {
            return form
        }

        return (
            <div className="d-flex flex-column">
                <ModalTitle title="เพิ่มลูกค้า" onClick={ () => this.props.closeModal() } />
                {form}
                <ModalFooter>
                    <button onClick={ () => this.save() } className="btn btn-main">บันทึก</button>
                </ModalFooter>
            </div>

        )
    }
}

CustomerInfoForm.propTypes = {
    options: PropTypes.object,
    refreshFunc: PropTypes.func,
    customerId: PropTypes.string,
    noHeaderAndFooter: PropTypes.bool,
}

function mapStateToProps(state) {
    return {
        options: state.other.options,
    }
}

CustomerInfoForm = connect(mapStateToProps, null, null, { withRef: true })(CustomerInfoForm)

const CustomerInfoModal = inModal(CustomerInfoForm)
export default CustomerInfoModal