import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'

import TextWithLabel from '../../components/common/TextWithLabel'
import SelectInput from '../../components/common/SelectInput'
import { inModal } from '../../hoc/inModal'
import ModalTitle from '../../components/common/ModalTitle'
import DeleteDefectModal from './DeleteDefectModal'
import ConditionalContainer from '../../components/common/ConditionalContainer'

class DefectInfo extends Component {
    refresh = () => {
        const { refresh, closeModal } = this.props
        closeModal()
        refresh()
    }

    render() {
        const { closeModal, defect, uploadHandler, product, refresh, canDelete } = this.props
        return (
            <div>
                <ModalTitle title="" onClick={() => closeModal()} />
                <div className="row justify-content-center" >
                    <div 
                        className="col-12 pr-2 h-100" 
                        style={{ 
                            maxWidth: "760px", 
                            display: 'flex', 
                            justifyContent: 'flex-end'  
                        }}
                    >
                    {
                        !defect.technicianName ?
                            <button
                                className="mb-05 py-1 btn btn-main"
                                style={{marginRight: "16px"}}
                                onClick={() => {
                                    browserHistory.push(`/warranty-assign?id=${this.props.defect.id}`)
                                }}
                            >
                                มอบหมายงาน
                            </button>
                        : null
                    }
                        <ConditionalContainer show={canDelete}>
                            <button 
                                onClick={() => this.refs.deleteModal.refs.modal.openModal()} 
                                className="mb-05 py-1 btn btn-main" 
                            >
                                ลบจุดบกพร่อง
                            </button>
                        </ConditionalContainer>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <Detail 
                        index={defect.id}
                        skuCode={product.orderingSkuCode}
                        description={defect.description}
                        location={defect.location}
                        elapsedDays={defect.elapsedDays}
                        technicianName={defect.technicianName}
                        technicianPhone={defect.technicianPhone}
                        technicianPicture={defect.technicianPicture}
                        status={defect.fixedDate}
                        defectType={defect.defectType}
                        warrantyType={defect.warrantyType}
                        before={defect.before}
                        after={defect.after}
                        uploadHandler={uploadHandler}
                    />
                </div>
                <DeleteDefectModal ref="deleteModal" defectId={defect.id.toString()} refresh={this.refresh} />
            </div>
        )
    }
}

DefectInfo.propTypes = {
    defect: PropTypes.object,
    uploadHandler: PropTypes.func
}

const DefectInfoModal = inModal(DefectInfo)
export default DefectInfoModal

const Detail = ({ 
    index, 
    skuCode,
    description, 
    location,
    elapsedDays,
    technicianName,
    technicianPhone,
    technicianPicture,
    status, 
    defectType, 
    warrantyType, 
    before, 
    after,
    uploadHandler }) => (
        <div className="col-12 pr-2 h-100" style={{ maxWidth: "760px" }}>
            <div 
                className="row no-gutters justify-content-between mb-1" 
            >
                <h4>รายละเอียด</h4>
            </div>
            <div className="row no-gutters justify-content-between mb-2">
                <TextWithLabel label="บ้านเลขที่">{skuCode}</TextWithLabel>
                <Status status={status} />
                <TextWithLabel label="วันนับจากแจ้ง">{elapsedDays}</TextWithLabel>
            </div>
            <hr />
            <div className="row no-gutters justify-content-between mb-2">
                <TextWithLabel label="รายละเอียดข้อบกพร่อง">{description}</TextWithLabel>
            </div>
            <div className="row no-gutters justify-content-between mb-2">
                <TextWithLabel label="ชนิดงาน">{defectType}</TextWithLabel>
                <TextWithLabel label="ตำแหน่ง">{location}</TextWithLabel>
                <TextWithLabel label="ประเภท">{warrantyType}</TextWithLabel>
            </div>
            <DefectImages 
                before={before}
                after={after}
                uploadHandler={uploadHandler}
            />
            <hr />
            <div className="row no-gutters justify-content-between mb-2">
                <TextWithLabel label="ช่าง">{technicianName}</TextWithLabel>
                {/* <TextWithLabel label="เบอร์โทรศัพท์">{technicianPhone}</TextWithLabel> */}
                {/* <div className="d-flex flex-column justify-content-around">
                    <p className="f13 grey m-0 mb-02">รูปช่าง</p>
                    <img 
                        src={technicianPicture} 
                        width="240px" 
                        height="240px" 
                        alt="" 
                        className="img-rounded" 
                    />
                </div> */}
            </div>
        </div>

    )

const DefectImages = ({ before, after, uploadHandler }) => (
    <div className="row no-gutters justify-content-between align-items-start">
        <div>
            <p className="f13 grey">ก่อนแก้ไข</p>
            <img src={ before } width="240px" height="240px" alt="" className="img-rounded" />
        </div>
        <div className="d-flex flex-column justify-content-around">
            {after ? <p className="f13 grey">หลังแก้ไข</p> : null}
            {after ? <img src={ after } width="240px" height="240px" alt="" className="img-rounded" /> : null}
            {/* <button 
                className="btn btn-main align-self-center mt-1"
                onClick={ uploadHandler }
            >
                อัปโหลด
            </button> */}
        </div>
    </div>
)

const Status = status => {
    let color, text
    switch (status.status) {
        case null:
            color = 'red'
            text = 'ยังไม่ได้แก้ไข'
            break
        default:
            color = 'green'
            text = 'แก้ไขแล้ว'
            break
    }
    return <TextWithLabel label="สถานะ" color={ color }>{ text }</TextWithLabel>
}
