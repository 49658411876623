import _ from 'lodash'

import { NA_TEXT } from '../common/const'
import { getRequest, postRequest, putRequest, camelToSnake } from '../common/util'

export function getCustomers(offset, pageSize, sortKey, sortOrder, filters, query) {
    const data = {
        offset: offset,
        page_size: pageSize,
    }

    if (sortKey) {
        data.order = {
            name: camelToSnake(sortKey),
            reverse: sortOrder ? false : true
        }
    }

    if (filters) {
        data.filters = filters.map(f => {
            return {
                name: camelToSnake(f.key),
                operator: '=',
                value: f.value
            }
        })
    }

    if (query) {
        const f = data.filters || []
        f.push({
            name: camelToSnake(query[0]),
            operator: 'like',
            value: `%${query[1]}%`
        })

        data.filters = f
    }

    return postRequest('/customer/list', data)
        .then(res => {
            return { values: res.customers, length: res.total }
        })
        .catch(err => {
            console.error(`getCustomer failed with ${err}`)
        })
}

export function getCustomer(customerId) {
    return getRequest(`/customer/${customerId}`)
}

export function createCustomer(customerInfo) {
    // TODO: Should be validation on server? Proper default values?
    return postRequest('/customer/create', convertCustomerInfo(customerInfo))
}

export function updateCustomer(customerInfo, customerId) {
    return putRequest(`/customer/${customerId}`, convertCustomerInfo(customerInfo))
}

export function updateCustomerInfo(customerInfo, customerId) {
    return putRequest(`/customer/${customerId}`, convertCustomerInfoEdit(customerInfo))
}

export function updateSaleMetaDetail(metaDetail, recordId) {
    return putRequest(`/saleRecord/${recordId}/metaJson`, convertJsonMetaDetail(metaDetail))
}

function convertCustomerInfo(info) {
    return {
        client_id: info.clientId,
        company_id: info.companyId,
        ref_id: info.refId,

        first_name: _.isEmpty(info.firstName) ? NA_TEXT : info.firstName,
        last_name: _.isEmpty(info.lastName) ? NA_TEXT : info.lastName,
        gender: info.gender,
        tel: _.isEmpty(info.tel) ? NA_TEXT : info.tel,
        occupation: info.occupation,
        income: info.income,
        addresses_json: JSON.stringify(info.addressesJson),
        channel_id: info.channelId,
        creator_id: info.creatorId,
        created: info.created,
        age: info.age,
        email: info.email,
        line_id: info.lineId,
        purpose: info.purpose,
        note: info.note,
    }
}

// TODO: will move to correct package later
function convertJsonMetaDetail(info) {
    const metaDetail = info.metaDetail
    metaDetail.customer = info.customerCode ?
        `${info.customerCode} - ${info.firstName} ${info.lastName}` :
        `${info.firstName} ${info.lastName}`

    return metaDetail
}

// TODO: will refact later
function convertCustomerInfoEdit(info) {
    return {
        id: info.id,
        ref_id: info.citizenId ? info.citizenId : '-',
        customer_code: info.customerCode,
        client_id: info.clientId,
        first_name: _.isEmpty(info.firstName) ? NA_TEXT : info.firstName,
        last_name: _.isEmpty(info.lastName) ? NA_TEXT : info.lastName,
        gender: info.gender,
        tel: _.isEmpty(info.tel) ? NA_TEXT : info.tel,
        occupation: info.occupation,
        income: info.income,
        channel_id: info.channelId,
        addresses_json: JSON.stringify(info.addressesJson),
        age: info.age ? info.age : '-',
        email: info.email ? info.email : '-',
        created: info.created,
        creator_id: info.creatorId,
        line_id: info.lineId ? info.lineId : '-',
        purpose: info.purpose ? info.purpose : '-',
        note: info.note ? info.note : '-'
    }
}