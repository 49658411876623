import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { inModal } from '../../../../hoc/inModal';
import ModalTitle from '../../../../components/common/ModalTitle';
import ModalFooter from '../../../../components/common/ModalFooter';
import TextWithLabel from '../../../../components/common/TextWithLabel';
import { formatNumberWithComma } from '../../../../common/util';

class ProductModalContent extends Component {
  constructor(props) {
    super(props);

    this.onConfirm = this.onConfirm.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onConfirm() {
    const { onConfirm, closeModal } = this.props;
    if (onConfirm) {
      onConfirm();
    }
    closeModal();
  }

  onCancel() {
    const { onCancel, closeModal } = this.props;
    if (onCancel) {
      onCancel();
    }
    closeModal();
  }

  render() {
    const {
      closeModal,
      name,
      type,
      status,
      building,
      floor,
      imgUrl,
      price,
      crossOutPrice,
      disabled,
    } = this.props;
    const { bgColor: statusBg, text: statusText } = status || {};
    return (
      <div className="d-flex flex-column justify-content-between">
        <ModalTitle title={`ห้อง ${name}`} onClick={() => closeModal()} />
        <div className="d-flex justify-content-between">
          <div style={{ flexGrow: 1 }}>
            <TextWithLabel className="mb-1" label="รหัสห้อง">
              {name}
            </TextWithLabel>
            <TextWithLabel className="mb-1" label="ประเภท">
              {type}
            </TextWithLabel>
            <TextWithLabel className="mb-1" label="ตึก">
              {building}
            </TextWithLabel>
            <TextWithLabel className="mb-1" label="ชั้น">
              {floor}
            </TextWithLabel>
            <TextWithLabel className="mb-1" label="ราคา">
              <span className="red mr-02" style={{ position: 'relative' }}>
                <div
                  className="border-red w-100"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    borderBottom: '2px solid',
                    transform: 'translateY(-50%)',
                  }}
                />
                {formatNumberWithComma(crossOutPrice.toString())}
              </span>
              {formatNumberWithComma(price.toString())}
            </TextWithLabel>
            <TextWithLabel className="mb-1" label="สถานะ">
              <div className="d-flex align-items-center">
                <div
                  className={`${statusBg}-bg mr-05`}
                  style={{ borderRadius: '50%', width: '20px', height: '20px' }}
                />
                {statusText}
              </div>
            </TextWithLabel>
          </div>
          <div className="d-flex justify-content-center align-items-center" style={{ flexGrow: 1 }}>
            <img src={imgUrl} width={'300px'} />
          </div>
        </div>

        <ModalFooter>
          <button onClick={() => this.onCancel()} className="btn btn-outline ml-auto mr-1">
            ยกเลิก
          </button>
          {/*
          <button onClick={() => this.onConfirm()} className="btn btn-main" disabled={disabled}>
            จอง
          </button>
          */}
        </ModalFooter>
      </div>
    );
  }
}

const ProductModal = inModal(ProductModalContent);

export default ProductModal;
