import React, {PureComponent} from 'react';
import { Line, HorizontalBar } from 'react-chartjs-2'
import Card from '../../components/common/Card';
import { SummaryCard } from './components/SummaryCard';
import datastore from '../../datastore';
import { formatNumberWithComma } from '../../common/util';
import { DashboardState } from './DashboardState';
import moment from 'moment';
import SelectInput from '../../components/common/SelectInput';
import './styles/ExecutiveDashboard.css';
import { SubCard } from './components/SubCard';

export default class ExecutiveDashboardView extends PureComponent {

  constructor(props) {
    super(props)
    this.state = DashboardState.initialState
    this.yearSelector = [
      {
        label: 'ปีปัจจุบัน',
        value: moment().year(),
      },
      {
        label: 'ปีที่แล้ว',
        value: moment().year() - 1,
      }
    ]

    datastore.dashboard.getDashboardData().then(result => {
      this.setState({
        summaryCard: result
      })
    })
    datastore.dashboard.getAdsData(this.state.data.year).then(result => {
      this.setState({
        onlineAdsData: result[0],
        offlineAdsData: result[1]
      })
    })
    datastore.dashboard.getLeadSourceData(this.state.data.year).then(result => {
      this.setState({
        leadSourceData: result
      })
    })
    datastore.dashboard.getInterestProductData(this.state.data.year).then(result => {
      this.setState({
        unitRanking: result
      })
    })
    datastore.dashboard.getConcernData(this.state.data.year).then(result => {
      this.setState({
        concernRanking: result
      })
    })
    datastore.dashboard.getWalkAndBookingData(this.state.data.year).then(result => {
      this.setState({
        walkAndBookingData: result[0],
        walkAndBookingUnit: result[1]
      })
    })
    datastore.dashboard.getTransferData(this.state.data.year).then(result => {
      this.setState({
        transferData: result[0],
        transferUnit: result[1]
      })
    })
    datastore.dashboard.getSummaryConversion().then(result => {
      this.setState({
        conversionData: result
      })
    })
    datastore.campaign.getCampaignList(0, 50).then(result => {
      let campaignSelector = []
      result.values.map(campaign => {
        campaignSelector.push({
          label: campaign.campaign_name,
          value: campaign.id.toString()
        })
      })
      campaignSelector.unshift({
        label: 'ทั้งหมด',
        value: '0'
      })

      this.setState({campaignSelector})
    })

    datastore.dashboard.getCostPerWalkData(this.state.campaignId).then(result => {
      this.setState({
        costPerWalk: result
      })
    })
  }

  componentWillMount() {
    this.props.setHeader('ภาพรวมโครงการ')
  }

  onChangeCampaign(campaignId) {

    datastore.dashboard.getCostPerWalkData(campaignId).then(result => {
      this.setState({
        costPerWalk: result
      })
    })
  }

  onYearChange(year) {
    this.setState({year})
    datastore.dashboard.getAdsData(this.state.data.year).then(result => {
      this.setState({
        onlineAdsData: result[0],
        offlineAdsData: result[1]
      })
    })
    datastore.dashboard.getLeadSourceData(this.state.data.year).then(result => {
      this.setState({
        leadSourceData: result
      })
    })
    datastore.dashboard.getInterestProductData(this.state.data.year).then(result => {
      this.setState({
        unitRanking: result
      })
    })
    datastore.dashboard.getConcernData(this.state.data.year).then(result => {
      this.setState({
        concernRanking: result
      })
    })
    datastore.dashboard.getWalkAndBookingData(this.state.data.year).then(result => {
      this.setState({
        walkAndBookingData: result[0],
        walkAndBookingUnit: result[1]
      })
    })
    datastore.dashboard.getTransferData(this.state.data.year).then(result => {
      this.setState({
        transferData: result[0],
        transferUnit: result[1]
      })
    })
  }

  render() {
    console.log(this.state.campaignSelector)
    return (
      <div className="executive-dashboard">
        { this.renderSummaryCard() }
        { this.renderStatusCard() }
        <div className="header-title-section">
          <img src="./img/walk.svg" className="summary-icon" width="30" height="30" alt="" />
          <h2 style={{marginLeft: '16px', fontSize: '24px', width: '87%'}}>แนวโน้มการ Walk และการขาย</h2>
          <div className="select-outline graph-filter">
            <selectWrap>
              <SelectInput 
                name="year"
                placeholder="เลือกปี"
                target={ this }
                stateKey="data"
                options={ this.yearSelector }
                onChange={(event) => this.onYearChange(event.target.value)}
              />
            </selectWrap>
          </div>
        </div>
        <div className="line-graph-section">
          <Line
            data={this.state.walkAndBookingData}
            title="Walkและการขาย"
            height={300}
            getElementAtEvent={(elems) => {
              if (elems.length > 0 ) {
                this.setState({
                  monthForWalkGraph: elems[0]._index
                })
              }
            }}
            options={{
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true
                  }
                }]
              },
              maintainAspectRatio: false,
              tooltips: {
                titleFontSize: 16,
                bodyFontSize: 14,
                xPadding: 10,
                yPadding: 10,
                displayColors: true,
                enabled: true,
                // callbacks: {
                //     label: (tooltipItem, data) => {
                //         // console.log(tooltipItem)
                //         return `${data.datasets[tooltipItem.datasetIndex].label || ''}: ${tooltipItem.yLabel}`
                //     }
                // }
              }
            }}
          />
        </div>
        <div className="walk-and-sold-detail">
          <div style={{width: '24%'}}>
            <SubCard 
              title="จำนวนการ Walk"
              data={
                this.state.walkAndBookingUnit.walk[this.state.monthForWalkGraph] ?
                `${this.state.walkAndBookingUnit.walk[this.state.monthForWalkGraph]} คน`
                : "0"
              }
              color="#00ace1"
              note={this.monthSelector(this.state.monthForWalkGraph)}
            />
          </div>
          <div style={{width: '24%'}}>
            <SubCard 
              title="จำนวนยูนิตที่ขาย"
              data={
                this.state.walkAndBookingUnit.booking[this.state.monthForWalkGraph] ?
                `${this.state.walkAndBookingUnit.booking[this.state.monthForWalkGraph]} ยูนิต`
                : "0"
              }
              color="#00b777"
              note={this.monthSelector(this.state.monthForWalkGraph)}
            />
          </div>
          <div style={{width: '24%'}}>
            <SubCard 
              title="มูลค่าการขาย"
              data={
                this.state.walkAndBookingUnit.valuation[this.state.monthForWalkGraph] ?
                `${formatNumberWithComma(this.state.walkAndBookingUnit.valuation[this.state.monthForWalkGraph])} ฿`
                : "0"
              }
              note={this.monthSelector(this.state.monthForWalkGraph)}
            />
          </div>
          <div style={{width: '24%'}}>
            <SubCard 
              title="อัตราส่วน walk:ขาย"
              data={
                this.state.walkAndBookingUnit.walk[this.state.monthForWalkGraph] < '1' && this.state.walkAndBookingUnit.booking[this.state.monthForWalkGraph] > '0' ?
                `0:${this.state.walkAndBookingUnit.booking[this.state.monthForWalkGraph]}` :
                this.state.walkAndBookingUnit.walk[this.state.monthForWalkGraph] > '0' && this.state.walkAndBookingUnit.booking[this.state.monthForWalkGraph] < '1' ?
                `${this.state.walkAndBookingUnit.walk[this.state.monthForWalkGraph]}:0` :
                this.simplify(`${this.state.walkAndBookingUnit.walk[this.state.monthForWalkGraph]}:${this.state.walkAndBookingUnit.booking[this.state.monthForWalkGraph]}`)
              }
              note={this.monthSelector(this.state.monthForWalkGraph)}
            />
          </div>
        </div>
        <div className="ranking-section-for-walk">
          <div style={{width: '49%'}}>
            <Card>
              <HorizontalBar 
                data={this.state.unitRanking}
                title="ยูนิตที่มีคนสนใจมากที่สุด 5 อันดับแรก"
                height={300}
                options={{
                  scales: {
                    xAxes: [{
                      ticks: {
                        beginAtZero: true
                      }
                    }]
                  },
                  maintainAspectRatio: false,
                  title: {
                    display: true,
                    text: 'ยูนิตที่มีคนสนใจมากที่สุด 5 อันดับแรก',
                    position: 'top',
                    fontSize: 16
                  }
                }}
              />
            </Card>
          </div>
          <div style={{width: '49%'}}>
            <Card>
              <HorizontalBar 
                data={this.state.concernRanking}
                title="เหตุผลที่ยังไม่ตัดสินใจซื้อมากที่สุด 5 อันดับแรก"
                height={300}
                options={{
                  scales: {
                    xAxes: [{
                      ticks: {
                        beginAtZero: true
                      }
                    }]
                  },
                  maintainAspectRatio: false,
                  title: {
                    display: true,
                    text: 'เหตุผลที่ยังไม่ตัดสินใจซื้อมากที่สุด 5 อันดับแรก',
                    position: 'top',
                    fontSize: 16
                  }
                }}
              />
            </Card>
          </div>
        </div>
        <div className="header-title-section">
          <img src="./img/payment.svg" className="summary-icon" width="30" height="30" />
          <h2 style={{marginLeft: '16px', fontSize: '24px'}}>แนวโน้มการโอนห้อง</h2>
        </div>
        <div className="line-graph-section">
          <Line
            data={this.state.transferData}
            title="โอนห้อง"
            height={300}
            getElementAtEvent={(elems) => {
              if (elems.length > 0 ) {
                this.setState({
                  monthForTransferGraph: elems[0]._index
                })
              }
            }}
            options={{
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true
                  }
                }]
              },
              maintainAspectRatio: false
            }}
          />
        </div>
        <div className="transfer-detail">
            <div style={{width: '49%'}}>
              <SubCard 
                title="จำนวนยูนิตที่โอน"
                data={`${this.state.transferUnit.total[this.state.monthForTransferGraph]} ยูนิต`}
                color="#00b777"
                note={this.monthSelector(this.state.monthForTransferGraph)}
              />
            </div>
            <div style={{width: '49%'}}>
              <SubCard 
                title="มูลค่าการโอนห้อง"
                data={
                  this.state.transferUnit.valuation[this.state.monthForTransferGraph] ?
                  `${formatNumberWithComma(this.state.transferUnit.valuation[this.state.monthForTransferGraph])} ฿` :
                  "0"
                }
                note={this.monthSelector(this.state.monthForTransferGraph)}
              />
            </div>
        </div>
        <div className="header-title-section">
          <img src="./img/payment.svg" className="summary-icon" width="30" height="30" />
          <h2  style={{marginLeft: '16px', fontSize: '24px', width: '87%'}}>ความคุ้มค่าของสื่อออนไลน์ และออฟไลน์ (Cost per Walk)</h2>
          <div className="select-outline graph-filter">
            <selectWrap>
              <SelectInput
                style={{padding: '0 24px 0 0'}} 
                name="year"
                placeholder="เลือกแคมเปญ"
                target={ this }
                stateKey="data"
                options={ this.state.campaignSelector }
                onChange={(event) => this.onChangeCampaign(event.target.value)}
              />
            </selectWrap>
          </div>
        </div>
        <div className="line-graph-section">
          <Line
            data={this.state.leadSourceData}
            title="ความคุ้มค่าของสื่อออนไลน์ และออฟไลน์ (Cost per Walk)"
            height={300}
            getElementAtEvent={(elems) => {
              if (elems.length > 0 ) {
                this.setState({
                  monthForCostGraph: elems[0]._index
                })
              }
            }}
            options={{
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true
                  }
                }]
              },
              maintainAspectRatio: false
            }}
          />
        </div>
        <div className="cost-per-walk-detail">
          <div className="offline-cost-detail">
            <div style={{width: '32%'}}>
              <SubCard 
                title="Cost per Walk สื่อOffline"
                data={`${this.state.costPerWalk.offline.costPerWalk} ฿`}
                color="#00ace1"
              />
            </div>
            <div style={{width: '32%'}}>
              <SubCard 
                title="ต้นทุนสื่อ Offline"
                data={`${this.state.costPerWalk.offline.cost} ฿`}
              />
            </div>
            <div style={{width: '32%'}}>
              <SubCard 
                title="จำนวน Walk จากสื่อOffline"
                data={`${formatNumberWithComma(this.state.costPerWalk.offline.totalWalk)} คน`}
              />
            </div>
          </div>
          <div className="online-cost-detail">
            <div style={{width: '32%'}}>
              <SubCard 
                title="Cost per Walk สื่อOnline"
                data={`${this.state.costPerWalk.online.costPerWalk} ฿`}
                color="#ffb400"
              />
            </div>
            <div style={{width: '32%'}}>
              <SubCard 
                title="ต้นทุนสื่อ Online"
                data={`${this.state.costPerWalk.online.cost} ฿`}
              />
            </div>
            <div style={{width: '32%'}}>
              <SubCard 
                title="จำนวน Walk จากสื่อOnline"
                data={`${formatNumberWithComma(this.state.costPerWalk.online.totalWalk)} คน`}
              />
            </div>
          </div>
        </div>
        <div className="ranking-section">
          <div style={{width: '49%'}}>
            <Card>
              <HorizontalBar 
                data={this.state.offlineAdsData}
                title="สื่อออฟไลน์ที่ใช้งานอยู่ และสร้าง Awareness ได้มากสุด 5 อันดับแรก"
                height={300}
                options={{
                  scales: {
                    xAxes: [{
                      ticks: {
                        beginAtZero: true
                      }
                    }]
                  },
                  maintainAspectRatio: false,
                  title: {
                    display: true,
                    text: 'สื่อออฟไลน์ที่ใช้งานอยู่ และสร้าง Awareness ได้มากสุด 5 อันดับแรก',
                    position: 'top',
                    fontSize: 16
                  }
                }}
              />
            </Card>
          </div>
          <div style={{width: '49%'}}>
            <Card>
              <HorizontalBar 
                data={this.state.onlineAdsData}
                title="สื่อออนไลน์ที่ใช้งานอยู่ และสร้าง Awareness ได้มากสุด 5 อันดับแรก"
                height={300}
                options={{
                  scales: {
                    xAxes: [{
                      ticks: {
                        beginAtZero: true
                      }
                    }]
                  },
                  maintainAspectRatio: false,
                  title: {
                    display: true,
                    text: 'สื่อออนไลน์ที่ใช้งานอยู่ และสร้าง Awareness ได้มากสุด 5 อันดับแรก',
                    position: 'top',
                    fontSize: 16
                  }
                }}
              />
            </Card>
          </div>
        </div>
      </div>
    )
  }

  renderSummaryCard() {
    return (
      <div className="dashboard-summary-container" style={{borderBottom: '1px solid #eaeae5'}}>
        <SummaryCard 
          type="overall"
          title="จำนวนยูนิตทั้งหมด"
          icon="./img/buildings.svg"
          summaryText={`${this.state.summaryCard.total.count} ยูนิต`}
          summaryAmountText={`มูลค่า ${formatNumberWithComma(this.state.summaryCard.total.valuation)} ฿`}
        />
        <SummaryCard 
          type="sold"
          title="ขายแล้ว (จอง, ทำสัญญา)"
          icon="./img/sold.svg"
          summaryText={`${this.state.summaryCard.sold.count} ยูนิต`}
          summaryAmountText={`มูลค่า ${formatNumberWithComma(this.state.summaryCard.sold.valuation)} ฿`}
        />
        <SummaryCard 
          type="available"
          title="ยังไม่ได้ขาย"
          icon="./img/price-tag.svg"
          summaryText={`${this.state.summaryCard.free.count} ยูนิต`}
          summaryAmountText={`มูลค่า ${formatNumberWithComma(this.state.summaryCard.free.valuation)} ฿`}
        />
      </div>
    )
  }

  renderStatusCard() {
    return (
      <div className="dashboard-status-container">
        <div style={{width: '19%'}}>
          <div className="Dashboard__status-card">
            <img src="./img/walk.svg" className="summary-icon" width="24" height="24" />
            <p>จำนวนคน Walk</p>
            <h2 style={{color: '#00ace1'}}>{this.state.conversionData.walk.total} คน</h2>
          </div>
        </div>
        <div style={{width: '19%'}}>
          <div className="Dashboard__status-card">
            <img src="./img/contract.svg" className="summary-icon" width="24" height="24" />
            <p>จอง, ทำสัญญา</p>
            <h2 style={{color: '#00ace1'}}>{this.state.conversionData.book.total} ยูนิต</h2>
          </div>
          <SubCard
            title="รอสินเชื่อ"
            data={`${this.state.conversionData.book.pending} ยูนิต`}
            color="#ffb400"
          />
          <SubCard
            title="ยกเลิก"
            data={`${this.state.conversionData.book.cancel} ยูนิต`}
            color="#f9777f"
          />
        </div>
        <div style={{width: '19%'}}>
          <div className="Dashboard__status-card">
            <img src="./img/loan-approved.svg" className="summary-icon" width="24" height="24" />
            <p>อนุมัติสินเชื่อ</p>
            <h2 style={{color: '#00ace1'}}>{this.state.conversionData.loanApprove.total} ยูนิต</h2>
          </div>
          <SubCard
            title="รอตรวจห้อง"
            data={`${this.state.conversionData.loanApprove.pending} ยูนิต`}
            color="#ffb400"
          />
          <SubCard
            title="ยกเลิก/ไม่ผ่าน"
            data={`${this.state.conversionData.loanApprove.cancel} ยูนิต`}
            color="#d1d1d6"
          />
        </div>
        <div style={{width: '19%'}}>
          <div className="Dashboard__status-card">
            <img src="./img/inspection.svg" className="summary-icon" width="24" height="24" />
            <p>ตรวจรับห้อง</p>
            <h2 style={{color: '#00ace1'}}>{this.state.conversionData.fixedInspection.total} ยูนิต</h2>
          </div>
          <SubCard
            title="รอนัดโอน"
            data={`${this.state.conversionData.fixedInspection.pending} ยูนิต`}
            color="#ffb400"
          />
          <SubCard
            title="ยกเลิก"
            data={`${this.state.conversionData.fixedInspection.cancel} ยูนิต`}
            color="#d1d1d6"
          />
        </div>
        <div style={{width: '19%'}}>
          <div className="Dashboard__status-card">
            <img src="./img/payment.svg" className="summary-icon" width="24" height="24" />
            <p>โอนห้อง</p>
            <h2 style={{color: '#00ace1'}}>{this.state.conversionData.transfer.total} ยูนิต</h2>
          </div>
          <SubCard
            title="มูลค่า"
            data={`${formatNumberWithComma(this.state.conversionData.transfer.valuation)} ฿`}
            color="#00b777"
          />
        </div>
      </div>
    )
  }

  simplify(str) {
    var result = '', data = str.split(':'),
        numOne = Number(data[0]),
        numTwo = Number(data[1]);
    for (var i = Math.max(numOne, numTwo); i > 1; i--) {
      if ((numOne % i == 0) && (numTwo % i == 0)) {
          numOne /= i;
          numTwo /= i;
      }
    }
    if (numTwo === 1) {
      result = numOne.toString()
    }
    result = numOne.toString() + ':' + numTwo.toString()
    return result
  }

  monthSelector(month) {
    switch (month) {
      case 0:
        return 'มกราคม'
      case 1:
        return 'กุมภาพันธ์'
      case 2:
        return 'มีนาคม'
      case 3:
        return 'เมษายน'
      case 4:
        return 'พฤษภาคม'
      case 5:
        return 'มิถุนายน'
      case 6:
        return 'กรกฎาคม'
      case 7:
        return 'สิงหาคม'
      case 8:
        return 'กันยายน'
      case 9:
        return 'ตุลาคม'
      case 10:
        return 'พฤศจิกายน'
      case 11:
        return 'ธันวาคม'
    }
  }
}