import React, { Component } from 'react'
import Wrapper from '../../../layouts/Wrapper'
import { LeadInfoForm } from '../../../components/forms/LeadInfoModal'
import Card from '../../../components/common/Card'

export default class AddLeadView extends Component {
    componentWillMount() {
        this.props.setHeader('เพิ่มผู้สนใจ')
    }

    goToShowListStep() {
        this.props.router.push('/sale-mode/leads')
    }


    goToNextStep = leadId => {
        const next = this.props.location.query.next
        const url = next === 'activity' ? `/sale-mode/leads/${leadId}/add-activity`
            : next === 'reserve' ?  `/sale-mode/leads/${leadId}/book`
            : '/not-found'

        this.props.router.push(url)
    }

    goToAddActivityStep = leadId => {
        this.props.router.push(`/sale-mode/leads/${leadId}/add-activity`)
    }

    goToBookingStep = leadId => {
        this.props.router.push(`/sale-mode/leads/${leadId}/book`)
    }

    render() {
        const next = this.props.location.query.next
        return (
            <Wrapper>
                <div className="row">
                    <div className="col-sm-12">
                        <Card>
                            <div style={{ paddingTop: '24px' }}>
                                <LeadInfoForm
                                    ref="form"
                                    noHeaderAndFooter
                                />
                            </div>
                            <div 
                                className="d-flex flex-row my-3 justify-content-center"
                            >
                                <button
                                    onClick={() => this.goToShowListStep()}
                                    className="m-05 btn btn-save-cancel btn-outline"
                                    style={{padding: 0}}
                                >
                                    ยกเลิก
                                </button>
                                <button
                                    onClick={() => {
                                        this
                                            .refs
                                            .form.getWrappedInstance()
                                            .save()
                                            .then(result => {
                                                this.goToBookingStep(result.key)
                                            })
                                    }}
                                    className="m-05 btn btn-main"
                                    style={{padding: 0, width: "160px"}}
                                >
                                    บันทึกและจองห้อง
                                </button>
                                <button
                                    onClick={() => {
                                        this
                                            .refs
                                            .form.getWrappedInstance()
                                            .save()
                                            .then(result => {
                                                this.goToAddActivityStep(result.key)
                                            })
                                    }}
                                    className="m-05 btn btn-main"
                                    style={{padding: 0, width: "185px"}}
                                >
                                    บันทึกและสร้างกิจกรรม
                                </button>
                            </div>
                        </Card>
                    </div>
                </div>
            </Wrapper>
        )
   }
}

