import { getRequest, postRequest, camelToSnake, getReport, putRequest, deleteRequest } from '../common/util'
import * as Request from '../common/customUtil';
import config from '../common/config';

export function getProducts(offset, pageSize, sortKey, sortOrder, filters, query, occupied, isTransfered) {
    const data = {
        offset: offset,
        page_size: pageSize
    }

    if (sortKey) {
        data.order = {
            name: camelToSnake(sortKey),
            reverse: sortOrder ? false : true
        }
    }

    if (filters) {
        data.filters = filters.map(f => {
            return {
                name: camelToSnake(f.key),
                operator: '=',
                value: f.value
            }
        })
    }
    
    if (query) {
        const f = data.filters || []
        f.push({
            name: camelToSnake('skuCode'),
            operator: 'like',
            value: `%${query}%`
        })

        data.filters = f
    }

    data.occupied = occupied

    if (isTransfered != null) {
        data.is_transfer = isTransfered
    }

    return postRequest('/product/list', data)
        .then(res => {
            return { values: res.products, length: res.total }
        })
        .catch(err => {
            console.error(`getProducts failed with ${err}`)
        })
}

export function getDefectProducts(offset, pageSize, sortKey, sortOrder, query) {
    const data = {
        offset: offset,
        page_size: pageSize
    }

    if (sortKey) {
        data.order = {
            name: camelToSnake(sortKey),
            reverse: sortOrder ? false : true
        }
    }
    
    if (query) {
        const f = data.filters || []
        f.push({
            name: camelToSnake('skuCode'),
            operator: 'like',
            value: `%${query}%`
        })

        data.filters = f
    }

    return postRequest('/product/list/transferred', data)
        .then(res => {
            return { values: res.products, length: res.total }
        })
        .catch(err => {
            console.error(`getProducts failed with ${err}`)
        })
}

export function exportProduct() {
    return getReport(config.baseApiUrl, '/product/list/export')
}

export function getProduct(productId) {
    return getRequest(`/product/${productId}`)
}

export function assignQCInspection(productId, inspectionDate, staffId, engineerName) {
    const data = {
        product_id: productId,
        inspection_date: inspectionDate,
        staff_id: staffId,
        mech_name: engineerName
    }

    return postRequest('/qc/assign', data)
}

export function createProduct(productInfo) {
    return postRequest('/product/create', convertProductInfo(productInfo))
}

export function createProductShoyu(productInfo) {
    return Request.postRequest(config.shoyuApiUrl, '/web/products', convertProductInfoShoyu(productInfo))
}

export function updateProduct(productId, productInfo) {
    return putRequest(`/product/${productId}`, convertProductInfo(productInfo))
}

export function updateProductShoyu(productInfo) {
    return Request.postRequest(config.shoyuApiUrl, '/web/products/save', convertUpdateProductInfoShoyu(productInfo))
}

export function getProductShoyu(productId) {
    return Request.getRequest(config.shoyuApiUrl, `/web/products/${productId}`)
}

export function deleteProduct(productId) {
    return deleteRequest(`/product/${productId}`)
}

export function deleteProductFromShoyu(productId) {
    return Request.deleteRequest(config.shoyuApiUrl, `/web/products/${productId}`)
}

export function randomId() {
    return getRequest('/application/random/id')
    .then(result => {
        return result
    });
}

function convertProductInfo(info) {
    return {
        id: info.id,
        project_id: info.projectId,
        sku_code: info.skuCode,
        standard_price: info.standardPrice,
        room_type_id: info.roomTypeId,
        room_view_id: info.roomViewId ? info.roomViewId : 0,
        message: info.message ? info.message : '',
        address_no: info.addressNo ? info.addressNo : '',
        current_status: info.currentStatus ? info.currentStatus : 0,
        creator_id: info.creatorId ? info.creatorId : 0,
        building_id: info.buildingId,
        standard_down: info.standardDown,
        standard_book: info.standardBook ? info.standardBook : 0,
        product_type_id: info.productTypeId ? info.productTypeId : 0
    }
}

function convertProductInfoShoyu(info) {
    return {
        id: info.id,
        room_type_id: info.roomTypeId,
        project_building_id: info.buildingId,
        name: info.skuCode,
        floor: info.floor,
        price: info.standardPrice,
        crossout_price: info.standardDown
    }
}

function convertUpdateProductInfoShoyu(info) {
    return {
        product_id: info.productId,
        price: info.standardPrice,
        crossout_price: info.standardDown,
        status_id: info.statusId,
        floor: info.floor
    }
}
