import React, { Component } from 'react'
import PropTypes from 'prop-types'
import datastore from '../datastore'
import { reloadPage } from '../common/util'
import Card from './common/Card'

export default class ProjectCard extends Component {
    switchProject = projectId => {
        datastore.user.switchProject(projectId)
            .then(res => {
                window.location.reload()
            })
    }

    render() {
        const { projectId, name } = this.props
        return (
            <Card className="project-card-container">
            <div className="project-card" onClick={() => this.switchProject(projectId)}>
                <div className="project-card-img d-flex justify-content-center align-items-center mb-1">
                    <div className="btn">เลือกโครงการ</div>
                </div>
                <h5 className="project-name">{name}</h5>
            </div>
            </Card>
        )
    }
}

ProjectCard.propTypes = {
    projectId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
}
