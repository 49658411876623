export const CUSTOMER_INFO = {
    clientId: 0,
    companyId: 0,
    refId: '',
    firstName: "สุบุษบง",
    lastName: "จันโท",
    gender: "1",
    age: 42,
    citizenId: "1001200230034",
    tel: "0839281111",
    email: "sb2004j@gmail.com",
    lineId: "sb2004j",
    addressesJson: null,
    occupation: "3",
    income: "4",
    purpose: "",
    channelId: "2",
    note: "",
    productQuery: "",
    selectedProduct: "",
}

export const CUSTOMERS_LIST = [
    {
        firstName: 'ดนัยศักดิ์',
        lastName: 'เปศะนันทน์',
        gender: 2,
        tel: '087-355-7848',
        occupation: 3,
        income: 4,
        saleStage: 'จอง',
    },
    {
        firstName: 'กทดสอบสอง',
        lastName: 'นามสกุลสาม',
        gender: 2,
        tel: '089-999-1234',
        occupation: 1,
        income: 4,
        saleStage: 'ไม่จอง',
    },
    {
        firstName: 'ทวีภัทร',
        lastName: 'ไชยพูล',
        gender: 2,
        tel: '086-235-9272',
        occupation: 1,
        income: 2,
        saleStage: 'จอง',
    },
    {
        firstName: 'ชาญ',
        lastName: 'โกมลวานิช',
        gender: 2,
        tel: '087-355-7848',
        occupation: 1,
        income: 2,
        saleStage: 'จอง',
    },
    {
        firstName: 'สุธัญรัตน์',
        lastName: 'พันธุ์มณี',
        gender: 1,
        tel: '087-720-8062',
        occupation: 2,
        income: 1,
        saleStage: 'ไม่จอง',
    },
    {
        firstName: 'ราตรี',
        lastName: 'เสริมทรัพย์',
        gender: 1,
        tel: '087-355-7848',
        occupation: 1,
        income: 3,
        saleStage: 'ไม่จอง',
    },
    {
        firstName: 'คทายุทธ',
        lastName: 'กันจินะ',
        gender: 2,
        tel: '089-999-1234',
        occupation: 2,
        income: 4,
        saleStage: 'จอง',
    },
    {
        firstName: 'คณิตศร',
        lastName: 'เงสันเทียะ',
        gender: 2,
        tel: '088-552-2696',
        occupation: 3,
        income: 1,
        saleStage: 'ไม่จอง',
    },
    {
        firstName: 'สุประนอม',
        lastName: 'ทราบพรมราช',
        gender: 1,
        tel: '089-999-1234',
        occupation: 3,
        income: 2,
        saleStage: 'ไม่จอง',
    },
    {
        firstName: 'จุลจักร',
        lastName: 'ทองฉิม',
        gender: 2,
        tel: '085-568-3697',
        occupation: 2,
        income: 2,
        saleStage: 'จอง',
    },
    {
        firstName: 'ชัยศักร์',
        lastName: 'ลาภเจริญวงศา',
        gender: 2,
        tel: '087-201-3390',
        occupation: 2,
        income: 3,
        saleStage: 'จอง',
    },
    {
        firstName: 'จตุรวิทย์',
        lastName: 'จิราธรธำรงรัตน์',
        gender: 2,
        tel: '085-393-1514',
        occupation: 1,
        income: 2,
        saleStage: 'ไม่จอง',
    },
]