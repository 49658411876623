import { postRequest, getRequest } from '../common/customUtil'
import JSONBig from 'json-bigint'
import  config  from '../common/config'
export function createBooking (data) {
    return postRequest(config.shoyuApiUrl,`/web/booking`, convertBookingData(data))
}

export function getSources () {
    return getRequest(config.shoyuApiUrl, `/web/sources`);
}

function convertBookingData(data) {
    return {
        citizen_id: data.citizen_id,
        first_name: data.first_name,
        last_name: data.last_name,
        lead_id: data.lead_id,
        mobile: data.mobile,
        product_id: data.product_id,
        sale_amount: data.sale_amount,
        advertisement_id: JSONBig.parse(data.source)
    }
}