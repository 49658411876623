import React, { Component } from 'react'

import Wrapper from '../../layouts/Wrapper'
import BasicInfoContainer from '../../components/BasicInfoCard'
import Loading from '../../components/common/Loading'


export default class BasicLeadInfoView extends Component {
    render() {
        const { record } = this.props
        if (!record) {
            return <Loading />
        }

        return (
            <Wrapper>
                <GeneralInfoCard record={ record } />
            </Wrapper>
        )
    }
}

class GeneralInfoCard extends Component{
    renderText(value,label){
        let color = ''
        let text = ''
        if(value === true && label === "Qualified"){
            color = 'green'
            text = 'Qualified'
        }
        else if(value === true && label === "Prospect"){
            color = 'green'
            text = 'Prospect'
        }
        else if (value === false && label === "Qualified"){
            color = 'red'
            text = 'Not qualified'
        }
        else{
            color = 'red'
            text = 'Not prospect'
        }
        return {color: color, text: text}
    }

    render(){
        const { record } = this.props
        const data = [
            [
                {
                    label: 'ชื่อผู้สนใจ',
                    value: record.first_name,
                },
                {
                    label: 'นามสกุล',
                    value: record.last_name,
                },
                {
                    label: 'Qualified',
                    value: record.qualified,
                    render: value => {
                        return <span style={{ color: this.renderText(value,"Qualified").color }}>{ this.renderText(value,"Qualified").text }</span>
                    }
                },
            ],
            [
                {
                    label: 'Prospect',
                    value: record.prospect,
                    render: value => {
                        return <span style={{ color: this.renderText(value,"Prospect").color }}>{ this.renderText(value,"Prospect").text }</span>
                    }
                },
                {
                    label: 'เบอร์โทรศัพท์',
                    value: record.phone_number
                },
                {
                    label: 'E-mail',
                    value: record.email
                },
            ],
            [
                {
                    label: 'เพศ',
                    value: record.gender
                },
                {
                    label: 'อายุ',
                    value: record.age
                },
                {
                    label: 'อาชีพ',
                    value: record.occupation
                },
            ],
            [
                {
                    label: 'รายละเอียดอาชีพ',
                    value: record.occupation_detail ? record.occupation_detail : '-'
                },
                {
                    label: 'รายได้',
                    value: record.income
                },
                {
                    label: 'Line ID',
                    value: record.line_id ? record.line_id : '-'
                }
            ]
        ]
        return <BasicInfoContainer title="ข้อมูลผู้สนใจ" content={ data } />
    }
}