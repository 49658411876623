import React, { Component } from 'react'
import { connect } from 'react-redux'

import datastore from '../datastore'
import { translate } from '../common/util'
import Card from '../components/common/Card'
import Wrapper from '../layouts/Wrapper'
import CustomerInfoModal from '../components/forms/CustomerInfoModal'
import LazyTable from '../components/common/tables/LazyTable'

class CustomersView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            query: ''
        }
        this.rowClickCallback = this.rowClickCallback.bind(this)
        this.ctaButtonCallback = this.ctaButtonCallback.bind(this)
        this.searchCallback = this.searchCallback.bind(this)
        this.getValues = this.getValues.bind(this)
        this.columns = [
            {
                header: 'ชื่อ',
                accessor: 'firstName',
            },
            {
                header: 'นามสกุล',
                accessor: 'lastName',
            },
            {
                header: 'เพศ',
                accessor: 'gender',
                filterAccessor: 'genders',
                render: value => translate('genders', value),
                width: 0.5,
            },
            {
                header: 'เบอร์โทรศัพท์',
                accessor: 'tel',
                render: value => { 
                    const tels = value.split(',')
                    return tels.map((t, index) => <div className="text-overflow-2" key={ index }>{ t }</div>)
                }
            },
            {
                header: 'อาชีพ',
                accessor: 'occupation',
                width: 0.75,
                render: value => { 
                    const text = translate('occupations', value)
                    return <span className="text-overflow-2">{ text }</span>
                }
            },
            {
                header: 'รายได้ / เดือน (บาท)',
                accessor: 'income',
                filterAccessor: 'salaryRanges',
                render: value => { 
                    const text = translate('salary_ranges', value)
                    return <span className="text-overflow-2">{ text }</span>
                }
            },
            {
                header: 'สถานะการจอง',
                accessor: 'saleStage',
                render: value => {
                    const indicatorColor = value === 2 ? 'green' : 'red'
                    const text = translate('customer_statuses', value)
                    return <span className="text-overflow-1"><i className='fa fa-circle mr-1' style={{ color: indicatorColor }} />{ text }</span>
                }
            },
        ]        
    }

    componentWillMount() {
        this.props.setHeader('รายการลูกค้า', this.ctaButtonCallback, 'เพิ่มลูกค้า', this.searchCallback, 'ค้นหาลูกค้า')
    }

    ctaButtonCallback = () => {
        this.refs.infoModal.openModal()
    }

    searchCallback(value) {
        this.setState({
            query: value
        })
    }

    getValues(offset, pageSize, sortKey, sortOrder, filters) { 
        return datastore.customer.getCustomers(offset, pageSize, sortKey, sortOrder, filters, ['firstName', this.state.query])
    }

    rowClickCallback(row) {
        this.props.router.push(`/customers-list/${row.id}/basic-info`)
    }

    render() {
        const { options } = this.props
        return (
            <Wrapper>
                <Card>
                    <LazyTable 
                        columns={ this.columns } 
                        getValues={ this.getValues } 
                        filterOptions={ options }
                        rowClickCallback={ this.rowClickCallback }
                        ref="customerTable"
                    />
                </Card>
                <CustomerInfoModal ref="infoModal" refreshFunc={ () => this.refs.customerTable.refreshTable() }/>
            </Wrapper>
        )
    }
}

function mapStateToProps(state) {
    return {
        options: state.other.options,
    }
}

export default connect(mapStateToProps, null)(CustomersView)