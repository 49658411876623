import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import _ from 'lodash'
import {RadioGroup, Radio} from 'react-radio-group'
import {CheckboxGroup, Checkbox} from 'react-checkbox-group'
import withStyles from 'react-jss'
import {animateScroll} from 'react-scroll'
import classNames from 'classnames'
import {BounceLoader} from 'react-spinners'

import {QUESTION_TYPE_SINGLE, QUESTION_TYPE_MULTI, QUESTION_TYPE_TEXT, QUESTION_TYPE_TEL, QUESTION_TYPE_EMAIL} from '../../../common/const'
import Wrapper from '../../../layouts/Wrapper'
import Loading from '../../../components/common/Loading'
import datastore from '../../../datastore'
import ConfirmModal from '../../../components/ConfirmModal'
import Card from '../../../components/common/Card'
import TextInput from '../../../components/common/TextInput'
import styles from './styles'
import ChoiceQuestion from './questionTypeChoice'
import TextQuestion from './questionTypeText'

class SurveyPublicView extends Component {
  state = {
    survey: null,
    questions: [],
    answers: [],
    contact: {},
    currentQuestionIndex: 0
  }

  constructor(props) {
    super(props)

    this.renderQuestionRecord = this
      .renderQuestionRecord
      .bind(this)
    this.onChoiceSelectChange = this
      .onChoiceSelectChange
      .bind(this)
    this.onSubmitClick = this
      .onSubmitClick
      .bind(this)
    this.nextStep = this
      .nextStep
      .bind(this)
  }

  componentDidMount() {
    const alias = this.props.params.alias

    datastore
      .survey
      .getSurveyInfoByAlias(alias)
      .then(survey => this.setState(state => ({
        survey,
        questions: survey.questions,
        answers: _.map(survey.questions, question => ({
          questionId: question.id,
          questionType: question.questionType,
          answers: [QUESTION_TYPE_SINGLE, QUESTION_TYPE_MULTI].indexOf(question.questionType) !== -1
            ? []
            : ''
        }))
      })))
  }

  onChoiceSelectChange = (question, next) => value => {

    this.setState(state => ({
      answers: _.map(state.answers, answer => {
        if (answer.questionId === question.id) {
          answer.answers = value
        }
        return answer
      })
    }), next)
  }

  onSubmitClick = event => {
    this.setState({processing: true})

    const payload = {
      id: this.state.survey.id,
      sessionId: String(this.state.survey.sessionId),
      answers: this.state.answers,
      contact: this.state.contact
    }

    datastore
      .survey
      .submitSurvey(payload)
      .then(res => {
        this.setState({processing: false, success: true})
      })
  }

  onReloadClick = event => {
    window
      .location
      .reload()
  }

  nextStep = incrementStep => {
    this.setState(state => ({
      currentQuestionIndex: state.currentQuestionIndex + (incrementStep || 1)
    }))
  }

  renderQuestionRecord = index => {
    const {classes} = this.props
    const {questions, answers} = this.state

    switch (questions[index].questionType) {
      case QUESTION_TYPE_SINGLE:
        return <ChoiceQuestion
          question={questions[index]}
          answers={answers[index].answers}
          onChange={this.onChoiceSelectChange(questions[index], () => setTimeout(this.nextStep, 500))}/>
      case QUESTION_TYPE_MULTI:
        return <ChoiceQuestion
          question={questions[index]}
          answers={answers[index].answers}
          onChange={this.onChoiceSelectChange(questions[index])}/>
      case QUESTION_TYPE_EMAIL:
      case QUESTION_TYPE_TEXT:
      case QUESTION_TYPE_TEL:
        return <TextQuestion
          question={questions[index]}
          answers={answers[index].answers}
          onChange={this.onChoiceSelectChange(questions[index])}/>

    }
  }

  renderCover = survey => (
    <div className={this.props.classes.cover}>
      <img src={survey.coverUrl}/>
      <div className={this.props.classes.headline}>
        <h1>{survey.title}</h1>
        <p className={this.props.classes.description}>
          {_.split(survey.description, /\n/img).map(line => <p className={this.props.classes.descriptionP}>{line}</p>)}
        </p>
      </div>
    </div>
  )

  renderContactForm = () => (
    <div
      className={classNames({
      [this.props.classes.contactForm]: true,
      [this.props.classes.question]: true
    })}>
      <div className={this.props.classes.questionInner}>
        <h3>ข้อมูลการติดต่อ</h3>
        <p>
          <TextInput
            name="name"
            placeholder="ชื่อ - นามสกุล"
            type="text"
            target={this}
            stateKey="contact"/>
        </p>
        <p>
          <TextInput
            name="email"
            placeholder="อีเมล์"
            type="email"
            target={this}
            stateKey="contact"/>
        </p>
        <p>
          <TextInput
            name="tel"
            placeholder="หมายเลขโทรศัพท์"
            type="text"
            target={this}
            stateKey="contact"/>
        </p>
        <p>
          <TextInput
            name="line"
            placeholder="Line ID"
            type="text"
            target={this}
            stateKey="contact"/>
        </p>
      </div>
    </div>
  )

  renderStepper = (prev, next, submit) => {
    const renderedPrev = (
      <button className="btn" onClick={e => this.nextStep(-1)}>
        ย้อนกลับ
      </button>
    )

    const renderedNext = (
      <button className="btn btn-main" onClick={e => this.nextStep(1)}>
        ถัดไป
      </button>
    )

    const renderedSubmit = (
      <button className="btn btn-main" onClick={this.onSubmitClick}>
        ส่งแบบสอบถาม
      </button>
    )

    return (
      <div className={this.props.classes.stepperWrapper}>
        <div className={this.props.classes.stepperRight}>
          {prev
            ? renderedPrev
            : null}
          {next
            ? renderedNext
            : null}
          {submit
            ? renderedSubmit
            : null}
        </div>
      </div>

    )
  }

  renderProcessing = () => (
    <div className={this.props.classes.overlay}>
      <div>
        <BounceLoader color="#00ACE1"/>
        <p>working...</p>
      </div>
    </div>
  )

  renderSuccess = () => (
    <div className={this.props.classes.overlay}>
      <div>
        <svg
          id="successAnimation"
          className="animated"
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="100"
          viewBox="0 0 70 70">
          <path
            id="successAnimationResult"
            fill="#00ACE1"
            d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"/>
          <circle
            id="successAnimationCircle"
            cx="35"
            cy="35"
            r="24"
            stroke="#00ACE1"
            strokeWidth="2"
            strokeLinecap="round"
            fill="transparent"/>
          <polyline
            id="successAnimationCheck"
            stroke="#00ACE1"
            strokeWidth="2"
            points="23 34 34 43 47 27"
            fill="transparent"/>
        </svg>
        <p>ส่งแบบสอบถามแล้ว</p>
        <p>
          <button className="btn btn-outline" onClick={this.onReloadClick}>
            เริ่มต้นใหม่
          </button>
        </p>
      </div>

    </div>
  )

  render() {
    const {classes} = this.props
    const {survey, questions, currentQuestionIndex, processing, success} = this.state
    console.log(survey)

    if (!survey) {
      return <Loading/>
    }

    const progressPercent = ((currentQuestionIndex) / questions.length) * 100
    const renderedCover = this.renderCover(survey)

    const renderedQuestions = currentQuestionIndex < questions.length
      ? this.renderQuestionRecord(currentQuestionIndex)
      : this.renderContactForm()
    const renderedStepper = this.renderStepper(currentQuestionIndex >= 1, currentQuestionIndex < questions.length, currentQuestionIndex === questions.length)
    const renderedProcessIndicator = this.renderProcessing()
    const renderedSuccess = this.renderSuccess()

    return (
      <Wrapper>
        <div className={classes.root} ref={el => this.container = el}>
          {renderedCover}
          <div className={classes.wrapper}>
            <progress style={{
              width: `${progressPercent}%`
            }}/> {renderedQuestions}
            {renderedStepper}
          </div>
        </div>
        {processing
          ? renderedProcessIndicator
          : null}
        {success
          ? renderedSuccess
          : null}
      </Wrapper>
    )
  }
}

export default withStyles(styles)(SurveyPublicView)