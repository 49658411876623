import apiClient from '../apis';
import mocks from '../mocks';
import { createPromise } from '../common/util';
import moment from 'moment'

const isMock = false;

export function getCampaignList(offset, pageSize) {
  if(isMock){
    return createPromise(mocks.campaign.CAMPAIGN_LIST);
  }
  else {
    return apiClient.campaign.getCampaignList(offset, pageSize)
      .then(result => {
        const campaign = result.campaigns.map(list=> convertCampaignList(list))
        return {
          length: result.total,
          values: campaign
        }
    })
  }
}

export function getPromotionList(campaignId, offset, pageSize){
  return apiClient.campaign.getPromotionList(campaignId, offset, pageSize)
    .then(result => {
      const promotion = result.promotions.map(list => convertPromotionList(list))
      return {
        length: result.total,
        values: promotion
      }
    })
}

export function getAdsList(id){
  return apiClient.campaign.getCampaignDetail(id)
    .then(result => {
      const ads = result.advertisements.map(ads => convertAdsList(ads))
      return {
        length: result.advertisements.length,
        values: ads
      }
    })
}

export function getCampaignDetail(id) {
  return apiClient.campaign.getCampaignDetail(id)
    .then(result => {
      const startDate = moment(result.start_date)
      const endDate = moment(result.end_date)
      const duration = endDate.diff(startDate, 'days')
      const booking_ratio = result.visitor !== 0 ? (result.book / result.visitor) * 100 : 0
      return {
        campaign_name: result.name,
        spend_budget: result.total_cost,
        cost_per_book: result.cost_per_book,
        booked: result.book,
        booking_ratio: booking_ratio,
        duration: duration,
        visitor: result.visitor,
        cost_per_visit: result.cost_per_visit,
        budget: result.budget
      }
    })
}

export function createCampaign() {
  return createPromise({status: 200});
  //Use for test with mock data
}

function convertCampaignList(data) {
  const startDate = moment(data.start_date)
  const endDate = moment(data.end_date)
  const duration = endDate.diff(startDate, 'days')
  let status
  if(startDate.diff(moment(),'hours') > 0 || endDate.diff(moment(), 'hours') < 0){
    status = 'inactive'
  } else {
    status = 'active'
  }
  return {
    id: data.id,
    campaign_name: data.name,
    spend_budget: data.total_cost,
    cost_per_book: data.cost_per_book,
    booked: data.book,
    duration: duration,
    visitor: data.visitor,
    status: status
  }
}

function convertAdsList(data) {
  const startDate = moment(data.start_date)
  const endDate = moment(data.end_date)
  const duration = endDate.diff(startDate, 'days')
  const cost = data.cost.substring(0, data.cost.length - 3).split(',').join('')

  return {
    id: data.id,
    ads_name: data.name,
    ads_type: data.type === 1 ? 'ออฟไลน์' : 'ออนไลน์',
    cost: parseFloat(cost),
    duration: duration,
    booked: data.book,
    visitor: data.visitor,
    start_date: data.start_date,
    end_date: data.end_date
  }
}

function convertPromotionList(data) {
  const startDate = moment(data.start_date)
  const endDate = moment(data.end_date)
  const duration = endDate.diff(startDate, 'days')
  return {
    id: data.id,
    promotion_name: data.name,
    cost: data.cost,
    duration: duration,
    start_date: data.start_date,
    end_date: data.end_date
  }
}