import React, { Component } from 'react'
import PropTypes from 'prop-types'

import DocumentList from '../DocumentList'
import BankDocumentListHeader from './BankDocumentListHeader'
import BankDocumentListFooter from './BankDocumentListFooter'

export default class BankDocumentList extends Component {
    render() {
        const { 
            bankInfo, 
            uploadHandler, 
            deleteHandler, 
            addDocumentHandler, 
            updateBankLoanInfoHandler, 
            deleteDocumentHandler, 
            downloadHandler 
        } = this.props

        return (
            <div>
                <DocumentList 
                    documents={ bankInfo.documents } 
                    uploadHandler={ uploadHandler }
                    deleteHandler={ deleteHandler }
                    addDocumentHandler={ filename => addDocumentHandler(bankInfo.id, filename) }
                    deleteDocumentHandler={ deleteDocumentHandler }
                    downloadHandler={ downloadHandler }
                    header={ <BankDocumentListHeader 
                        title={ bankInfo.name } 
                        icon={ bankInfo.icon }
                        count={ bankInfo.documents.length }
                        missing={ bankInfo.notUploadedCount }
                        selfSubmission={ bankInfo.selfSubmission }
                    /> }
                    footer={ <BankDocumentListFooter 
                        status={ bankInfo.status }
                        estimatedAmount={ bankInfo.estimatedAmount }
                        requestedAmount={ bankInfo.requestedAmount }
                        approvedAmount={ bankInfo.approvedAmount }
                        selfSubmission={ bankInfo.selfSubmission }
                        updateBankLoanInfoHandler={ (estimatedAmount, requestedAmount, approvedAmount, status, selfSubmission) => updateBankLoanInfoHandler(bankInfo.id, estimatedAmount, requestedAmount, approvedAmount, status, selfSubmission)}
                    /> }
                />
            </div>
        )
    }
}

BankDocumentList.propTypes = {
    bankInfo: PropTypes.object,
    uploadHandler: PropTypes.func,
    deleteHandler: PropTypes.func,
    addDocumentHandler: PropTypes.func,
    deleteDocumentHandler: PropTypes.func,
    downloadHandler: PropTypes.func,
    updateBankLoanInfoHandler: PropTypes.func
}