import React from 'react'
import styled from 'styled-components'
import {Colors} from '../../../../theme/colors'
import * as Fa from 'styled-icons/fa-solid'

export const Button = styled.button`
    padding: 0.725rem;
    color: ${props => props.disabled ? Colors.Grey : Colors.BillmeBlue};
    font-size: 1rem;
    flex: 1;
    border: none;
    outline: none;
    cursor: pointer;
    background: ${Colors.White};
    display: flex;
    align-items: center;
`

export const Container = styled.div`
    border: 1px solid;
    border-color: ${props => props.disabled ? Colors.Grey : Colors.BillmeBlue};
    display: flex;
    border-radius: 0.5rem;
    overflow: hidden;
    background: ${Colors.White};
`

export const ProjectSelection = ({profile, openProjectModal, ...props}) => (
    <Container {...props}>
        <Button onClick={openProjectModal}>
            <div style={{ flexGrow: 1, overflow: 'hidden' }}>{profile.current_project.name}</div>
            <Fa.AngleDown size={24} />
        </Button>
    </Container>
)

export default ProjectSelection 