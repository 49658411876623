import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Loading from '../Loading'

export const NO_FILTER_OPTION = {
    value: -1,
    label: 'ทั้งหมด'
}

export default class TableBase extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columnWidthMap: [],
        }

        this.renderPagination = this.renderPagination.bind(this)
    }

    componentWillMount() {
        this.setState({
            columnWidthMap: this.generateColumnWidthMap(this.props.columns),
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            columnWidthMap: this.generateColumnWidthMap(nextProps.columns),
        })
    }

    generateColumnWidthMap(columns) {
        const totalColumnWidth = columns.reduce((sum, c) => {
            sum += c.width ? c.width : 1
            return sum
        }, 0)

        return columns.map(c => {
            const width = c.width
            return width
                ? `${ width / totalColumnWidth * 100 }%`
                : `${ 1 / totalColumnWidth * 100 }%`
        })
    }

    renderFilters() {
        const { setFilter, filtersWithOptions } = this.props
        if (filtersWithOptions.length < 1) {
            return null
        }

        return (
            <div className="p-05">
                {filtersWithOptions.map((f, index) => {
                    return (
                        <selectWrap key={index} className="mr-1">
                            <select className="select-outline" ref={`filter-${f.key}`} onChange={() => setFilter(f.key)}>
                                {f.options.map((o, index) => {
                                    return <option key={index} value={o.value}>{o.label}</option>
                                })}
                            </select>
                        </selectWrap>
                    )
                })}
            </div>
        )
    }

    renderPagination() {
        const { page, length, selectPage, itemsPerPage } = this.props

        const pageCount = Math.ceil(length/itemsPerPage)
        const end = Math.min(page * itemsPerPage + itemsPerPage, length)
        const start = end === 0 ? 0 : page * itemsPerPage + 1

        return (
            <div className="d-flex flex-row align-items-center ml-a">
                <span className="mr-1" style={{ minWidth:"5rem" }}>{ start } - { end } of { length }</span>
                <button className="btn pagination-button-left mr-05" disabled={ page <= 0 } onClick={ () => selectPage(page - 1) }></button>
                <button className="btn pagination-button-right" disabled={ page + 1 >= pageCount } onClick={ () => selectPage(page + 1) }></button>
            </div>
        )
    }

    renderCell(render, row, accessor, subaccessor) {
        const value = row[accessor]
        if (!subaccessor) {
            return render ? render(value) : value
        }

        const multiSubAccessors = subaccessor.constructor === Array
        const subvalue = multiSubAccessors
            ? subaccessor.map(a => row[a])
            : row[subaccessor]

        return render
            ? render(value, subvalue, row.id, row)
            : <div>
                <p>{ value }</p>
                <p className="f14 grey">{ multiSubAccessors ? subvalue[0] : subvalue }</p>
            </div>
    }

    renderSortArrow(sortOrder){
        return sortOrder ? <i className="fa fa-caret-down" /> : <i className="fa fa-caret-up" />
    }

    render() {
        const { columns, rowClickCallback, values, sortColumn, noColumnHeader, noHeader, sortKey, sortOrder, setItemsPerPage, noItemsPerPageSelector } = this.props
        const cursor = rowClickCallback ? "pointer" : "cursor"
        return (
            <div>
                { noHeader
                    ? null
                    : <div className="d-flex flex-row justify-content-between align-items-center mb-1">
                        { this.renderFilters() }
                    </div>
                }
                <table className="table-main" style={{ tableLayout: "fixed", backgroundColor: "transparent" }}>
                    { noColumnHeader
                        ? null
                        : <thead className="table-clickable-head" >
                            <tr>
                                { columns.map((c, index) => {
                                    if (c.visible === false) {
                                        return null
                                    }
                                    else{
                                        if (c.unSortable) {
                                            return <th
                                            key={ index }
                                            style={{ width: this.state.columnWidthMap[index] }}>
                                                <span className="text-overflow-1">{ c.header } { sortKey === c.accessor ? (this.renderSortArrow(sortOrder)) : null }</span>
                                        </th>
                                        }
                                        else {
                                        return <th
                                        key={ index }
                                        onClick={ () => { sortColumn(c.accessor) }}
                                        style={{ width: this.state.columnWidthMap[index] }}>
                                            <span className="text-overflow-1">{ c.header } { sortKey === c.accessor ? (this.renderSortArrow(sortOrder)) : null }</span>
                                    </th>
                                        }
                                    }
                                }) }
                            </tr>
                        </thead>
                    }
                    { !values
                        ? <tbody><tr><td colSpan={ columns.length }><Loading /></td></tr></tbody>
                        : <tbody className={ rowClickCallback ? "table-clickable" : "" }>
                            { values.map((row, index) => {
                                return (
                                    <tr
                                        key={ index }
                                        style={{ cursor: cursor }}
                                        onClick={ () => {
                                            if (rowClickCallback) {
                                                rowClickCallback(row)
                                            }
                                        }}
                                    >
                                        { columns.map((c, index) => {
                                            if (c.visible === false) {
                                                return null
                                            }
                                            return <td
                                                key={ index }
                                                style={{
                                                    height: "73px",
                                                    width: this.state.columnWidthMap[index]
                                                }}
                                            >
                                                { this.renderCell(c.render, row, c.accessor, c.subaccessor) }
                                            </td>
                                        }) }
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    }
                </table>
                { noHeader
                    ? null
                    : <TableFooter
                        target={ this }
                        noHeader={ noHeader }
                        noItemsPerPageSelector={ noItemsPerPageSelector }
                        setItemsPerPage={ setItemsPerPage }
                    />
                }
            </div>
        )
    }
}

TableBase.propTypes = {
    columns: PropTypes.array.isRequired,
    values: PropTypes.array,
    page: PropTypes.number.isRequired,
    length: PropTypes.number.isRequired,
    sortcolumn: PropTypes.func,
    setFilter: PropTypes.func,
    setItemsPerPage: PropTypes.func,
    selectPage: PropTypes.func,
    rowClickCallback: PropTypes.func,
    itemsPerPage: PropTypes.number,
    filtersWithOptions: PropTypes.array,
    noColumnHeader: PropTypes.bool,
    noHeader: PropTypes.bool,
    sortKey: PropTypes.string,
    sortOrder: PropTypes.bool,
    noItemsPerPageSelector: PropTypes.bool,
}

TableBase.defaultValue = {
    sortColumn: accessor => null,
    setFilter: key => null,
    selectPage: page => null,
}

const TableFooter = ({ target, setItemsPerPage, noHeader, noItemsPerPageSelector }) => {
    return (
        <div>
            { noHeader
                ? null
                : <div className="d-flex flex-row justify-content-between align-items-center mb-2">
                    { noItemsPerPageSelector ? null : <ItemsPerPageSelector setItemsPerPage={ setItemsPerPage } /> }
                    { target.renderPagination() }
                </div>
            }
        </div>
    )
}

const ItemsPerPageSelector = ({ setItemsPerPage }) => {
    return (
        <div>
            จำนวนรายการ / หน้า
            <selectWrap className="ml-1">
                <select className="select-outline" onChange={ e => setItemsPerPage(e.target.value) }>
                    { ITEMS_PER_PAGE_OPTIONS.map(o => {
                        return <option key={ o.value } value={ o.value }>{ o.label }</option>
                    })}
                </select>
            </selectWrap>
        </div>
    )
}

const ITEMS_PER_PAGE_OPTIONS = [
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '30', value: 30 },
    { label: '40', value: 40 },
    { label: '50', value: 50 },
]
