import React, { Component } from 'react'
import _ from 'lodash'

import Wrapper from '../../layouts/Wrapper'
import datastore from '../../datastore'
import TextInput from '../../components/common/TextInput'

export default class SearchView extends Component {
    state = {
        searchResults: [],
        data: {
            query: '',
        },
    }

    componentWillMount() {
        this.props.setHeader('ค้นหาผู้สนใจซื้อ')
    }

    searchCustomer = () => {
        if (!this.state.data.query) {
            this.setState({
                searchResults: []
            })
        } else {
            datastore.lead.searchLeads(this.state.data.query)
                .then(leads => {
                    const searchResults = leads.values.map(c => {
                        return { 
                            leadId: c.id.toString(), 
                            // citizenId: '1001200230034', 
                            tel: c.tel,
                            name: `${c.first_name} ${c.last_name ? c.last_name : ''}`
                        }
                    })

                    this.setState({
                        searchResults
                    })
                })
        }
    }

    formatPhoneNumber = value => { 
        const tels = value.split(',')
        return tels.map((t, index) => <div className="text-overflow-2" key={index}>{t}</div>)
    }

    goToReserveStep = leadId => {
        this.props.router.push(`/lead/${leadId}/booking`)
    }

    goToAddCustomerStep = customerId => {
        this.props.router.push('/lead/add-lead')
    }

    render() {
        return <Wrapper>
            <div className="row h-100">
                <div className="col-6 offset-3 d-flex flex-column">
                    <div style={{ marginTop: "10rem" }} className="d-flex flex-row justify-content-between align-items-center mb-5">
                        <h3>ค้นหาผู้สนใจซื้อด้วยชื่อ</h3>
                        <button 
                            className="btn btn-main" 
                            onClick={this.goToAddCustomerStep}
                        >
                            เพิ่มผู้สนใจซื้อใหม่
                        </button>
                    </div>
                    <div className="input-group align-items-center">
                        <TextInput name="query" placeholder="ชื่อ" type="text" target={ this } stateKey="data" onChange={ this.searchCustomer } />
                        <i className="fa fa-search" style={{ color: "#00ACE1" }}></i>
                    </div>
                    { !_.isEmpty(this.state.searchResults) ? 
                        <div>
                            <div className="mt-2 mb-05">ผลการค้นหา</div>
                            <div className="p-05" style={{ backgroundColor: "rgba(0,0,0,0.1)", borderRadius: "10px" }}>
                                { this.state.searchResults.map((result, index) =>
                                    <div 
                                        className="d-flex clickable" 
                                        key={index} 
                                        onClick={() => this.goToReserveStep(result.leadId)}
                                    >
                                        <span>{result.name}</span>
                                        {/* <span className="ml-auto">{this.formatPhoneNumber(result.tel)}</span> */}
                                    </div>
                                )}
                            </div>
                        </div>
                    : null }
                </div>
            </div>
        </Wrapper>
    }
}