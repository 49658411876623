import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class CheckboxInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: false
        }
    }

    componentWillMount() {
        const { target, stateKey, nestedKey, name } = this.props

        const value = nestedKey 
            ? target.state[stateKey][nestedKey][name] 
            : target.state[stateKey][name]

        this.setState({
            value: value
        })
    }

    componentWillReceiveProps(nextProps) {
        const { target, stateKey, nestedKey, name } = nextProps
        const value = nestedKey
            ? target.state[stateKey][nestedKey][name]
            : target.state[stateKey][name]

        this.setState({
            value: value
        })
    }

    render() {
        const { 
            name, 
            target, 
            stateKey, 
            nestedKey,
            disabled,
            label } = this.props

        return (
            <div>
                <input 
                    className="input mr-05" 
                    type="checkbox"
                    checked={this.state.value}
                    name={name}
                    disabled={disabled}
                    onChange={e => {
                        const value = e.target.checked
                        this.setState({
                            value: value
                        })

                        const data = target.state[stateKey]
                        if (nestedKey) {
                            data[nestedKey] = data[nestedKey] || {} 
                            data[nestedKey][name] = value 
                        } else {
                            data[name] = value
                        }

                        target.setState({
                            [stateKey]: data
                        })
                    }}
                />
                <span>{label}</span>
            </div>
        )
    }
}

CheckboxInput.propTypes = {
    name: PropTypes.string.isRequired,
    target: PropTypes.object.isRequired,
    stateKey: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    nestedKey: PropTypes.string,
    label: PropTypes.string,
}
