import React, { Component } from 'react'
import PropTypes from 'prop-types'

import SelectInput from '../../../components/common/SelectInput'
import ConfirmModal from '../../../components/ConfirmModal'

class UpdateCustomerLoanTypeModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                incomeStatus: null,
                maritalStatus: null
            }
        }

        this.updateCustomerLoanType = this.updateCustomerLoanType.bind(this)
    }

    updateCustomerLoanType() {
        this.props.updateCustomerLoanTypeHandler(
            this.state.data.incomeStatus, 
            this.state.data.maritalStatus
        )
    }

    render() {
        const { options } = this.props
        return (
            <ConfirmModal
                title="เลือกประเภท"
                onConfirm={ this.updateCustomerLoanType }
                ref="modal"
            >
                <div className="row">
                    <div className="col-sm-6">
                        <selectWrap>
                            <SelectInput
                                name="incomeStatus"
                                placeholder="เลือกประเภทอาชีพ"
                                target={ this }
                                stateKey="data"
                                options={ options.income_statuses }
                            />
                        </selectWrap>
                    </div>
                    <div className="col-sm-6">
                        <selectWrap>
                            <SelectInput
                                name="maritalStatus"
                                placeholder="เลือกสถานะ"
                                target={ this }
                                stateKey="data"
                                options={ options.marital_statuses }
                            />
                        </selectWrap>
                    </div>
                </div>
            </ConfirmModal>
        )
    }
}

UpdateCustomerLoanTypeModal.propTypes = {
    options: PropTypes.object,
    updateCustomerLoanTypeHandler: PropTypes.func
}

export default UpdateCustomerLoanTypeModal