import React, { PureComponent } from 'react'
import { inModal } from '../../../hoc/inModal'
import ModalTitle from '../../../components/common/ModalTitle'
import TextInput from '../../../components/common/TextInput'
import datastore from '../../../datastore'

export class ResetPasswordForm extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      data: {
        password: '',
        rePassword: ''
      }
    }
  }

  update = () => {
    if (!this.state.data.password) {
      alert('กรุณากรอกรหัสผ่าน')
      return Promise.reject()
    } else if (!this.state.data.rePassword) {
      alert('กรุณากรอกยืนยันรหัสผ่าน')
      return Promise.reject()
    } else if (this.state.data.password !== this.state.data.rePassword) {
      alert('ยืนยันรหัสผ่านไม่ตรงกับรหัสผ่าน กรุณากรอกใหม่อีกครั้ง')
      return Promise.reject() 
    } else {
      const p = datastore.userManagement.resetPassword({
        ...this.state.data,
        id: this.props.account.id
      }).then(result => {
        if (result.cms_id === 11003) {
          alert('ระบบได้ทำการรีเซตรหัสผ่านเรียบร้อยแล้ว')
          this.props.closeModal()
        }
      })

      return p
    }
  }

  render() {
    const form = (
      <div className="d-flex flex-column" style={{ flexGrow: 1 }}>
        <div className="row">
          <div className="col-sm-6">
            <TextInput 
              name="password"
              type="password"
              placeholder="รหัสผ่าน"
              target={ this }
              stateKey="data"
              required={true}
            />
          </div>
          <div className="col-sm-6">
            <TextInput 
              name="rePassword"
              type="password"
              placeholder="ยืนยันรหัสผ่าน"
              target={ this }
              stateKey="data"
              required={true}
            />
          </div>
        </div>
      </div>
    )
    return (
      <div>
        <ModalTitle title={'รีเซตรหัสผ่าน'} />
        {form}
        <div style={{paddingTop: "24px"}} className="d-flex flex-row justify-content-center">
          <button 
              style={{padding: 0}}
              onClick={ () => {
                this.props.closeModal()
              }}
              className="m-05 btn btn-save-cancel btn-outline"
            >
              ยกเลิก
          </button>
          <button 
              style={{padding: 0}}
              onClick={ () => {
                this.update()
              }}
              className="m-05 btn btn-save-cancel btn-main"
            >
              บันทึก
          </button>
        </div>
      </div>
    )
  }
}

const ResetPasswordModal = inModal(ResetPasswordForm)
export default ResetPasswordModal;