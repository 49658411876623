import React, { PureComponent } from 'react';
import { inModal } from '../../hoc/inModal';
import ModalTitle from '../../components/common/ModalTitle';
import { Formatter } from '../../utilities/Formatter';
import { openNewWindowSameHost } from '../../common/util';

export class DownloadDefectSummaryForm extends PureComponent {

  constructor(props){
    super(props)

    this.state = {
      selectedDate: ''
    }
  }

  renderDateChoice = (dateList) => {
    const dateFilter = dateList.filter((date, index) => dateList.indexOf(date) === index)
    return dateFilter.map(date => {
        if(date === ''){
          return null;
        }
        return (
          <div key={date}>
            <input
              id="createdDate"
              type="radio"
              name="createdDate"
              value={date}
              onChange={event => this.setState({selectedDate: event.target.value})}
              />
            <label htmlFor="createdDate" style={{marginLeft: "16px"}}>
              {Formatter.formatDateTh(Formatter.createDate(date))}
            </label>
          </div>
        )
     })
  }

  accept = () => {
    const productId = this.props.productId
    const data = {
      target_date: this.state.selectedDate,
      sku_code: this.props.skuCode
    }
    openNewWindowSameHost(`/pinspect-summary/${productId}`, data)
  }

  render() {
    const filterFixedDate = this.props.fixedDate.filter(date => date !== null)
    return (
      <div>
        <ModalTitle title="เลือกวันที่ต้องการพิมพ์เอกสารสรุปการแก้ไข" onClick={() => this.props.closeModal()} />
        <div>
          {
            filterFixedDate.length > 0 ? this.renderDateChoice(filterFixedDate) : 'ยังไม่มีรายการที่ได้รับการแก้ไข'
          }
        </div>
        <div style={{paddingTop: "24px"}} className="d-flex flex-row justify-content-center">
          <button 
              style={{padding: 0}}
              onClick={ () => {
                this.props.closeModal()
              }}
              className="m-05 btn btn-save-cancel btn-outline"
            >
              ยกเลิก
          </button>
          <button 
            style={{padding: 0}}
            onClick={ () => {
              this.accept()
            }} 
            className="m-05 btn btn-save-cancel btn-outline"
            disabled={!this.state.selectedDate}
          >
            พิมพ์
          </button>
        </div>
      </div>
    )
  }
}

const DownloadDefectSummaryModal = inModal(DownloadDefectSummaryForm)
export default DownloadDefectSummaryModal