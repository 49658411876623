import React, { Component } from 'react'
import PropTypes from 'prop-types'

import DocumentList from '../DocumentList'
import GeneralDocumentListHeader from './GeneralDocumentListHeader'

export default class GeneralDocumentList extends Component {
    render() {
        const { 
            documents, 
            missing,
            incomeStatus,
            maritalStatus,
            uploadHandler, 
            deleteHandler,
            addDocumentHandler,
            deleteDocumentHandler,
            downloadHandler,
            updateCustomerLoanTypeHandler,
            changeCashOptionHandler,
            isCashOption
        } = this.props

        return (
            <div>
                <DocumentList 
                    documents={ documents } 
                    uploadHandler={ uploadHandler }
                    deleteHandler={ deleteHandler }
                    addDocumentHandler={ filename => addDocumentHandler(null, filename) }
                    deleteDocumentHandler={ deleteDocumentHandler }
                    downloadHandler={ downloadHandler }
                    header={ 
                        <GeneralDocumentListHeader 
                            count={ documents.length }
                            missing={ missing }
                            incomeStatus={ incomeStatus }
                            maritalStatus={ maritalStatus }
                            updateCustomerLoanTypeHandler={ updateCustomerLoanTypeHandler }
                            changeCashOptionHandler={ changeCashOptionHandler }
                            isCashOption={ isCashOption }
                        /> 
                    }
                />
            </div>
        )
    }
}

GeneralDocumentList.propTypes = {
    documents: PropTypes.array,
    uploadHandler: PropTypes.func,
    deleteHandler: PropTypes.func,
    addDocumentHandler: PropTypes.func,
    deleteDocumentHandler: PropTypes.func,
    downloadHandler: PropTypes.func,
    updateCustomerLoanTypeHandler: PropTypes.func,
    missing: PropTypes.number,
    incomeStatus: PropTypes.number,
    maritalStatus: PropTypes.number,
    changeCashOptionHandler: PropTypes.func,
    isCashOption: PropTypes.bool,
}