import React, { Component } from 'react'
import { connect } from 'react-redux'

import datastore from '../datastore'
import LazyTable from '../components/common/tables/LazyTable'
import Card from '../components/common/Card'
import Wrapper from '../layouts/Wrapper'
import ProductInfoModal from '../components/forms/ProductInfoModal'
import { translate } from '../common/util'
import AddProductModal from '../components/Modal/AddProductModal'
//import { TRANSFER_STATUS_NA, TRANSFER_STATUS_COMPLETED } from '../common/const'

class ProductsView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            query: ''
        }
        this.rowClickCallback = this.rowClickCallback.bind(this)
        this.searchCallback = this.searchCallback.bind(this)
        this.getValues = this.getValues.bind(this)
        this.handleExport = this.handleExport.bind(this)
        this.openAddProductModal = this.openAddProductModal.bind(this)
        this.columns = [
            {
                header: 'รหัสสินค้า',
                accessor: 'orderingSkuCode',
                width: 0.75,
            },
            // {
            //     header: 'โครงการ',
            //     accessor: 'project',
            //     filterAccessor: 'project',
            //     width: 2.5,
            //     render: value => <span className="text-overflow-2">{ value }</span>
            // },
            {
                header: 'รหัสตึก',
                accessor: 'buildingId',
                width: 2,
                render: value => <span className="text-overflow-2">{ translate('buildings', value.toString()) }</span>
            },
            {
                header: 'ประเภท',
                accessor: 'roomTypeId',
                // filterAccessor: 'roomTypes',
                width: 2,
                render: value => <span className="text-overflow-2">{ translate('room_types', value.toString()) }</span>
            },
            //{
                //header: 'สถานะการโอน',
                //accessor: 'isTransfer',
                //render: value => {
                    //const color = (value => {
                        //switch(value) {
                            //case TRANSFER_STATUS_COMPLETED:
                                //return 'green'
                            //case TRANSFER_STATUS_NA:
                                //return 'orange'
                        //}
                    //})(value)
                    //// for further change to use translation set
                    //const text = (value => {
                        //switch(value) {
                            //case TRANSFER_STATUS_COMPLETED:
                                //return 'โอนแล้ว'
                            //case TRANSFER_STATUS_NA:
                                //return 'ยังไม่โอน'
                            //default:
                                //return 'n/a'
                        //}
                    //})(value)

                    //return (
                        //<span className="text-overflow-2" style={{ color: color }}>
                            //{ text }
                        //</span> 
                    //)
                //}
            //},
            // {
            //     header: 'ขนาดห้อง',
            //     accessor: 'roomSizeId',
            //     render: value => translate('roomSizes', value)
            // },
            // {
            //     header: 'ตำแหน่งห้อง',
            //     accessor: 'roomLocationId',
            //     render: value => translate('roomLocations', value),
            //     width: 1.5,
            // },
            // {
            //     header: 'วิว',
            //     accessor: 'roomViewId',
            //     render: value => translate('roomViews', value)
            // },
            // {
            //     header: 'สถานะ',
            //     accessor: 'status',
            //     width: 1.5,
            //     render: value => {
            //         const indicatorColor = value === 0 ? 'green' : 'red'
            //         return (
            //             <span className="text-overflow-1">
            //                 <i className='fa fa-circle mr-1' style={{ color: indicatorColor }} />{ translate('productStatuses', value) }
            //             </span>
            //         )
            //     }
            // },
        ]
    }

    componentWillMount() {
        this.props.setHeader()
        this.props.setHeader(
            'รายการสินค้า',
            [this.handleExport, this.openAddProductModal],
            ['ดาวน์โหลด CSV', '+ เพิ่มสินค้า'],
            this.searchCallback,
            'ค้นหารหัสสินค้า'
        )
    }

    // ctaButtonCallback() {
    //     this.refs.infoModal.openModal()
    // }

    handleExport() {
        datastore.product.exportProduct()
    }

    openAddProductModal() {
        this.refs.addProductModal.openModal()
    }

    searchCallback(value) {
        this.setState({
            query: value
        })
    }

    getValues(offset, pageSize, sortKey, sortOrder, filters) { 
        return datastore.product.getProducts(offset, pageSize, sortKey ? sortKey : 'ordering_sku_code', sortOrder, filters, this.state.query)
    }

    rowClickCallback(row) {
        this.props.router.push(`/products-list/${row.id}/basic-info`)
    }

    render() {
        const { options } = this.props
        return (
            <Wrapper>
                <Card>
                    <LazyTable 
                        columns={ this.columns } 
                        getValues={ this.getValues } 
                        filterOptions={ options }
                        rowClickCallback={ this.rowClickCallback }
                        ref="productTable"
                    />
                </Card>
                <ProductInfoModal ref="infoModal" refreshFunc={ () => this.refs.productTable.refreshTable() } />
                <AddProductModal 
                    ref="addProductModal"
                />
            </Wrapper>
        )
    }
}

function mapStateToProps(state) {
    return {
        options: state.other.options,
    }
}

export default connect(mapStateToProps, null)(ProductsView)
