import { getRequest, postRequest } from '../common/util'

export function getTranslations() {
    return getRequest('/translation')
}

export function getDashboardData() {
    return getRequest('/dashboard')
}

export function lookupStaff(query) {
    return getRequest(`/lookup/staff/${query}`)
}

export function uploadFile(file) {
    const formData = new FormData()
    formData.append('name', file)
    
    return postRequest('/generic/upload', formData, true, true).then(JSON.parse)
}