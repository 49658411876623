import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import _ from 'lodash'
import {RadioGroup, Radio} from 'react-radio-group'
import {CheckboxGroup, Checkbox} from 'react-checkbox-group'
import withStyles from 'react-jss'
import {animateScroll} from 'react-scroll'
import classNames from 'classnames'
import {BounceLoader} from 'react-spinners'

import {QUESTION_TYPE_SINGLE, QUESTION_TYPE_MULTI, QUESTION_TYPE_TEXT, QUESTION_TYPE_TEL, QUESTION_TYPE_EMAIL} from '../../../common/const'
import Wrapper from '../../../layouts/Wrapper'
import Loading from '../../../components/common/Loading'
import datastore from '../../../datastore'
import ConfirmModal from '../../../components/ConfirmModal'
import Card from '../../../components/common/Card'
import TextInput from '../../../components/common/TextInput'
import styles from './styles'

class ChoiceQuestion extends Component {

  constructor(props) {
    super(props)

    this.state = {
      question: props.question,
      answers: props.answers || []
    }

    this.onChange = this
      .onChange
      .bind(this)
    this.onChoiceSelectChange = this
      .onChoiceSelectChange
      .bind(this)
  }

  componentWillReceiveProps(nextProps) {
    this.setState(state => ({
      question: nextProps.question || state.question,
      answers: nextProps.answers || state.answers
    }))
  }

  onChange = nextValue => {
    this.setState(state => {
      const nextState = {
        ...state,
        ...nextValue
      }
      if (typeof(this.props.onChange) === 'function') {
        this
          .props
          .onChange(nextState.answers)
      }
      return nextState
    })
  }

  onChoiceSelectChange = value => {
    let {question, answers} = this.state
    if(question.questionType === QUESTION_TYPE_SINGLE) {
      return this.onChange({ answers: [value.pop()] })
    }
    
    this.onChange({ answers: value })
  }

  render() {
    const {classes} = this.props
    const {question, answers} = this.state

    if (!question) {
      return (
        <div className={classes.question}>
          <div>
            <BounceLoader color="#00ACE1"/>
          </div>
        </div>
      )
    }

    const renderedChoices = _.map(question.choices, (question, i) => (
      <p key={i} className={this.props.classes.groupItem}>
        <label
          title={question.title}
          className={classNames({
          [classes.groupItemLabel]: true,
          'checkbox-container': true
        })}>
          <Checkbox value={question.id}/>
          <span className="check-mark"></span>
          {question.title}
        </label>
      </p>
    ))

    const renderedChoiceHint = question.questionType === QUESTION_TYPE_SINGLE
      ? 'เลือกได้ 1 คำตอบ'
      : 'เลือกได้หลายคำตอบ'

    return (
      <div className={classes.question}>
        <h3>
          <span>{question.title}</span>
          <span className={classes.small}>
            &nbsp;({renderedChoiceHint})
          </span>
        </h3>
        <div className={classes.questionChoice}>
          <CheckboxGroup
            name={`${question.id}`}
            value={answers}
            onChange={this.onChoiceSelectChange}
            className={classes.group}>
            {renderedChoices}
          </CheckboxGroup>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(ChoiceQuestion)