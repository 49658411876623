import React, { PureComponent } from 'react'
import LazyTable from '../../components/common/tables/LazyTable'
import Card from '../../components/common/Card'
import Wrapper from '../../layouts/Wrapper'
import datastore from '../../datastore'
import AddUserModal from './modals/AddUserModal'
import EditUserModal from './modals/EditUserModal'
import ResetPasswordModal from './modals/ResetPasswordModal'

export default class UserListView extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      selectedAccount: null,
      query: '',
      searchKey: 'email'
    }

    this.openAddUserModal = this.openAddUserModal.bind(this)
    this.searchCallback = this.searchCallback.bind(this)
    this.getValues = this.getValues.bind(this)
    this.columns = [
      {
        header: 'ชื่อผู้ใช้งาน',
        accessor: 'fullName',
        unSortable: true
      },
      {
        header: 'Email',
        accessor: 'email',
        unSortable: true
      },
      {
        header: 'สิทธิ์ของผู้ใช้งาน',
        accessor: 'role',
        unSortable: true
      },
      {
        header: '',
        accessor: 'id',
        subaccessor: ['fullName', 'email'],
        unSortable: true,
        render: (id, [fullName, email]) => {
          return (
            <div>
              <button
                className="m-05 btn btn-save-cancel btn-blue-no-border"
                style={{padding: 0}}
                onClick={() => {
                  this.setState({
                    selectedAccount: {
                      id: id,
                      name: fullName,
                      email: email
                    }
                  })
                  this.openEditUserModal()
                }}
              >
                แก้ไขข้อมูลผู้ใช้
              </button>
            </div>
          )
        }
      },
      {
        header: '',
        accessor: 'id',
        unSortable: true,
        render: (id) => {
          return (
            <div>
              <button
                className="m-05 btn btn-save-cancel btn-blue-no-border"
                style={{padding: 0}}
                onClick={() => {
                  this.setState({
                    selectedAccount: {
                      id: id
                    }
                  })
                  this.openResetPasswordModal()
                }}
              >
                รีเซตรหัสผ่าน
              </button>
            </div>
          )
        }
      }
    ]
  }

  componentWillMount() {
    this.props.setHeader(
      'จัดการผู้ใช้งาน',
      [this.openAddUserModal],
      ['+ เพิ่มผู้ใช้งาน'],
      this.searchCallback,
      'ค้นหาด้วยEmail'
    )
  }

  searchCallback(value, searchKey) {
    this.setState({
        query: value,
        searchKey
    })
  }

  openEditUserModal() {
    this.refs.editUserModal.openModal()
  }

  openAddUserModal() {
    this.refs.addUserModal.openModal()
  }

  openResetPasswordModal() {
    this.refs.resetPassModal.openModal()
  }

  getValues(offset, pageSize) {
    return datastore.userManagement.getUserList(offset, pageSize, this.state.query)
  }

  render() {
    return (
      <Wrapper>
          <Card>
              <LazyTable
                  columns={ this.columns } 
                  getValues={ this.getValues } 
                  ref="userTable"
              />
              <AddUserModal
                ref="addUserModal"
              />
              <EditUserModal 
                ref="editUserModal"
                account={this.state.selectedAccount}
              />
              <ResetPasswordModal
                ref="resetPassModal"
                account={this.state.selectedAccount}
              />
          </Card>
      </Wrapper>
    )
  }
}