import React from 'react'
import styled from 'styled-components'

export const GridContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100vw;
    height: calc(100vh - 140px);
    overflow: hidden;
    box-sizing: border-box;  
`

export default GridContainer