import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

import { translate, formatCurrencyNumber } from '../../../common/util'
import { APPROVED_STATUS } from '../../../common/const'
import TextWithLabel from '../../../components/common/TextWithLabel'
import UpdateBankLoanInfoModal from './UpdateBankLoanInfoModal'

class BankDocumentListFooter extends Component {
    render() {
        const { estimatedAmount, requestedAmount, approvedAmount, status, updateBankLoanInfoHandler, selfSubmission } = this.props
        return (
            <div>
                <hr className="mt-1 mb-0" style={{ marginLeft: "-1.5rem", marginRight: "-1.5rem" }} />
                <div className="d-flex flex-row justify-content-between align-items-center pt-1">
                    <div className="d-flex flex-row pl-45">
                        <div className="mr-25 d-flex flex-row align-items-start">
                            <TextWithLabel label="ราคาประเมิน (บาท)">
                                { formatCurrencyNumber(estimatedAmount) }
                            </TextWithLabel>
                        </div>
                        <div className="mr-25 d-flex flex-row align-items-start">
                            <TextWithLabel label="จำนวนที่ขอกู้ (บาท)">
                                { formatCurrencyNumber(requestedAmount) }
                            </TextWithLabel>
                        </div>
                        { renderStatus(status, approvedAmount) }
                    </div>
                    <Link 
                        className="link" 
                        onClick={ () => {
                            this.refs.updateModal.refs.modal.openModal() 
                        }} 
                    >
                        <div onClick={ () => this.refs.updateModal.refs.modal.openModal() }>
                            <i className="fa fa-edit mr-02" />
                            <span>แก้ไข</span>
                        </div>
                    </Link>
                </div>
                <UpdateBankLoanInfoModal 
                    ref="updateModal" 
                    estimatedAmount={ estimatedAmount }
                    requestedAmount={ requestedAmount }
                    approvedAmount={ approvedAmount }
                    status={ status }
                    selfSubmission={ selfSubmission }
                    updateBankLoanInfoHandler={ updateBankLoanInfoHandler } 
                />
            </div>
        )
    }
}

BankDocumentListFooter.propTypes = {
    estimatedAmount: PropTypes.string,
    requestedAmount: PropTypes.string,
    approvedAmount: PropTypes.string,
    status: PropTypes.number,
    updateBankLoanInfoHandler: PropTypes.func,
    selfSubmission: PropTypes.bool
}

export default BankDocumentListFooter

function renderStatus(value, approvedAmount) {
    const text = translate('loan_statuses', value)
    const isApproved = value === APPROVED_STATUS 

    const color = isApproved ? 'green' : 'orange'
    const approvedAmountText = isApproved 
        ? ` (${formatCurrencyNumber(approvedAmount) || '0.00'} บาท)`
        : null

    return <div className="mr-25 d-flex flex-row align-items-start">
        <TextWithLabel label="สถานะการอนุมัติ" color={ color }>
            { text }{ approvedAmountText }
        </TextWithLabel>
    </div>
}