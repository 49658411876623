import React, { Component } from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import Moment from 'moment-timezone'
import { extendMoment } from 'moment-range'
import datastore from '../../datastore';
import AssignedWorkModal from '../Modal/AssignedWorkModal'

//import { formatDate, formatDateTime, today, getStartDateOf, getEndDateOf } from '../common/util'

const moment = extendMoment(Moment)
const WEEK_DAYS = [
    'อาทิตย์',
    'จันทร์',
    'อังคาร',
    'พุธ',
    'พฤหัสบดี',
    'ศุกร์',
    'เสาร์',
]
//_.range(7).map(i => moment().day(i).format('dd'))
class Calendar extends Component {
    state = {
        currentMonth: moment.tz('Asia/Bangkok'),
        defect: null,
    }

    getDates = () => {
        const first = moment.tz(this.state.currentMonth, 'Asia/Bangkok').startOf('month')
        const last = moment.tz(this.state.currentMonth, 'Asia/Bangkok').endOf('month')

        first.subtract(first.day(), 'days')
        last.add(6 - last.day(), 'days')

        const dates = []
        for (let d of moment.range(first, last).by('day')) {
            dates.push(d)
        }

        const datesByWeek = []
        while (dates.length > 0) {
            datesByWeek.push(dates.splice(0, 7))
        }

        return datesByWeek
    }

    getDefectDetail = defectId => {
        const defect = datastore.warranty.getDefectDetail(defectId)
            .then(result => {
                this.setState({
                    defect: result
                })
            })
        return defect
    }

    updateMonth = offset => {
        this.setState({
            currentMonth: this.state.currentMonth.add(offset, 'month')
        }, () => { 
            if (this.props.onNavigate) {
                this.props.onNavigate(this.state.currentMonth)
            }
        })
    }

    // componentDidMount() {
    //     document.addEventListener('click', (e) => this.handleClick(e))
    // }

    // handleClick(e) {
    //     if (e.srcElement.className === "appointment") {
    //         if(this.refs.assignDetail){
    //             this.refs.assignDetail.openModal()
    //         }
    //     } else if (e.srcElement.className === "text-2 calendar " || e.srcElement.className === "text-2 calendar calendar-assigned") {
    //         if(this.props.onClickDate) {
    //             this.props.onClickDate(moment(e.target.id))
    //         }
    //     }
    // }

    render() {
        const { onClickDate, onClickMonth, DayComponent, selectedMonth, appointments } = this.props
        const url = window.location.href
        return <div>
            <div style={{ color: '#40c1e9' }} className="d-flex flex-row justify-content-start pb-2 h4">
                <div onClick={() => this.updateMonth(-1)} style={{ cursor:'pointer' }} ><i className="fa fa-angle-left fa-fw bold" /></div>
                <div onClick={() => this.updateMonth(1)} style={{ cursor:'pointer' }} ><i className="fa fa-angle-right fa-fw bold" /></div>
                <div 
                    className="bold" 
                    style={{ cursor: 'pointer', color: selectedMonth ? '#1AB394' : null }} 
                    onClick={() => {
                        if (onClickMonth) {
                            onClickMonth(this.state.currentMonth)
                        }
                    }}
                >
                    {this.state.currentMonth.format('MMMM YYYY')}
                </div>
            </div>
            <div className="d-flex flex-row justify-content-between">
                {WEEK_DAYS.map(d => {
                    return <div 
                        className="h-100 d-flex justify-content-center align-items-center text-2 bold"
                        style={{
                            width:'13%', 
                            border:'1px solid transparent', 
                            minHeight:'24px',
                        }}
                        key={d}
                    >
                        {d}
                    </div>
                })}
            </div>
            {this.getDates().map((week, index) => {
                return (
                    <div key={index} className="d-flex flex-wrap">
                        {week.map(d => {
                            const dateString = d.format('YYYY-MM-DD')
                            const apps = appointments[dateString] || []
                            return <div
                                className={`text-2 calendar ${apps.length > 0 ? 'calendar-assigned' : ''}`}
                                style={{ 
                                    height: '150px',
                                    width: '14.25%',
                                    border:'1px solid #C8CCD1',
                                    //display: 'inline-block'
                                }}
                                key={d}
                                id={dateString}
                            >
                                {DayComponent 
                                    ? <DayComponent date={d} label={d.format('D')} />
                                        : 
                                        <div>
                                            <div style={{ marginLeft: '5px', marginTop: '5px', width: '1px' }}>{d.format('D')}</div>
                                            {
                                                url.indexOf('?sku_code') > 0 ? 
                                                apps.length <= 0 ?
                                                <div
                                                    style={{width: 'fit-content', margin: 'auto'}}
                                                >
                                                    <button
                                                        className="btn btn-main"
                                                        style={{
                                                            height: '26px',
                                                            padding: '0 1.25rem'
                                                        }}
                                                        onClick={() => {
                                                            this.props.onClickDate(moment(dateString))
                                                        }}
                                                    >
                                                        เพิ่มงานลงวันนี้
                                                    </button>
                                                </div> :
                                                null : null
                                            }
                                        </div>
                                }
                                {
                                    apps.length > 0 ?
                                    <div>
                                        <div
                                            style={{width: 'fit-content', margin: 'auto'}}
                                        >
                                            <p 
                                                className="calendar-text"
                                                style={{margin: '0'}}
                                            >
                                                {`${apps.length} งาน`}
                                            </p>
                                        </div>
                                        <div
                                            style={{width: 'fit-content', margin: 'auto', marginBottom: '8px'}}
                                        >
                                            <button
                                                className="btn-blue-no-border btn-transparent"
                                                onClick={() => {
                                                    this.setState({
                                                        selectedDate: dateString,
                                                        selectedAppointment: apps
                                                    })
                                                    this.refs.assignedListModal.openModal()
                                                }}
                                            >
                                                ดูรายการงาน
                                            </button>
                                        </div>
                                        {
                                            url.indexOf('?sku_code') > 0 ? 
                                            <div
                                                style={{width: 'fit-content', margin: 'auto'}}
                                            >
                                                <button
                                                    className="btn btn-main"
                                                    style={{
                                                        height: '26px',
                                                        padding: '0 1.25rem'
                                                    }}
                                                    onClick={() => {
                                                        this.props.onClickDate(moment(dateString))
                                                    }}
                                                >
                                                    เพิ่มงานลงวันนี้
                                                </button>
                                            </div> :
                                            null
                                        }
                                        <AssignedWorkModal 
                                            ref="assignedListModal"
                                            appointments={this.state.selectedAppointment}
                                            date={this.state.selectedDate}
                                        />
                                    </div>
                                     :
                                    null
                                }
                                {/* {apps.map((a, index) => {
                                    return <div 
                                        className="appointment"
                                        key={index}
                                        style={{ 
                                            backgroundColor: '#FF6666', 
                                            color: 'white', 
                                            position: 'relative',
                                            marginBottom: '2px'
                                        }}
                                        id={a.id}
                                        onClick={() => {
                                            this.getDefectDetail(a.id)
                                        }}
                                    >
                                        {`${a.time}  ${a.skuCode} (${a.technicianName})`}
                                    </div>
                                })} */}
                            </div>
                        })}
                    </div>
                )
            })}
        </div>
    }
}

Calendar.propTypes = {
    onClickDate: PropTypes.func,
    onNavigate: PropTypes.func,
    DayComponent: PropTypes.func,
    // Hack
    selectedWeek: PropTypes.number
}


export default Calendar

