export const PRODUCTS_LIST = [
    {
        "productId": 1
        , "skuCode": "1111"
        , "project": "โครงการ แฮปปี้คอนโด ดอนเมือง"
        , "roomType": "1 bedroom, 1 restroom"
        , "roomView": "Pool view"
        , "roomSize": "35 sqm"
        , "roomLocation": "Fl.1 North"
        , "message": ""
        , "price": 1000000
        , "downPayment": 111111
        , "bookPrice": 11111
        , "status": "Available"
    }
    , {
        "productId": 2
        , "skuCode": "1112"
        , "project": "โครงการ แฮปปี้คอนโด ดอนเมือง"
        , "roomType": "1 bedroom, 1 restroom"
        , "roomView": "Pool view"
        , "roomSize": "35 sqm"
        , "roomLocation": "Fl.1 North"
        , "message": ""
        , "price": 1000000
        , "downPayment": 111111
        , "bookPrice": 11111
        , "status": "Available"
    }
    , {
        "productId": 3
        , "skuCode": "1113"
        , "project": "โครงการ แฮปปี้คอนโด ดอนเมือง"
        , "roomType": "1 bedroom, 1 restroom"
        , "roomView": "Pool view"
        , "roomSize": "35 sqm"
        , "roomLocation": "Fl.1 North"
        , "message": ""
        , "price": 1000000
        , "downPayment": 111111
        , "bookPrice": 11111
        , "status": "Available"
    }
]

export const OLD_PRODUCTS_LIST = [
    {
        skuCode: 'RM-A-101',
        project: 'แฮปปี้ คอนโด ดอนเมือง เดอะ เทอมินอล',
        type: '1 ห้องนอน 1 ห้องนํ้า',
        room_size: '35 ตร.ม',
        room_position: 'ชั้น 1 มุมฝั่งเหนือ',
        view: 'สระว่ายนํ้า',
        status: 'Available'
    },
    {
        skuCode: 'RM-A-102',
        project: 'แฮปปี้ คอนโด ดอนเมือง เดอะ เทอมินอล',
        type: '1 ห้องนอน 1 ห้องนํ้า',
        room_size: '35 ตร.ม',
        room_position: 'ชั้น 1 ฝั่งเหนือ',
        view: 'ตัวเมือง',
        status: 'Available'
    },
    {
        skuCode: 'RM-A-103',
        project: 'แฮปปี้ คอนโด ดอนเมือง เดอะ เทอมินอล',
        type: '1 ห้องนอน 1 ห้องนํ้า',
        room_size: '35 ตร.ม',
        room_position: 'ชั้น 1 มุมฝั่งเหนือ',
        view: 'ตัวเมือง',
        status: 'Unavailable'
    },
    {
        skuCode: 'RM-A-104',
        project: 'แฮปปี้ คอนโด ดอนเมือง เดอะ เทอมินอล',
        type: '2 ห้องนอน 1 ห้องนํ้า',
        room_size: '50 ตร.ม',
        room_position: 'ชั้น 1 มุมฝั่งเหนือ',
        view: 'ตัวเมือง',
        status: 'Available'
    },
    {
        skuCode: 'RM-A-105',
        project: 'แฮปปี้ คอนโด ดอนเมือง เดอะ เทอมินอล',
        type: '1 ห้องนอน 1 ห้องนํ้า',
        room_size: '35 ตร.ม',
        room_position: 'ชั้น 1 มุมฝั่งเหนือ',
        view: 'สระว่ายนํ้า',
        status: 'Available'
    },
    {
        skuCode: 'RM-A-106',
        project: 'แฮปปี้ คอนโด ดอนเมือง เดอะ เทอมินอล',
        type: '1 ห้องนอน 1 ห้องนํ้า',
        room_size: '35 ตร.ม',
        room_position: 'ชั้น 1 มุมฝั่งตะวันตก',
        view: 'ตัวเมือง',
        status: 'Unavailable'
    },
    {
        skuCode: 'RM-A-107',
        project: 'แฮปปี้ คอนโด ดอนเมือง เดอะ เทอมินอล',
        type: '2 ห้องนอน 1 ห้องนํ้า',
        room_size: '50 ตร.ม',
        room_position: 'ชั้น 1 มุมฝั่งตะวันตก',
        view: 'ตัวเมือง',
        status: 'Available'
    },
    {
        skuCode: 'RM-A-108',
        project: 'แฮปปี้ คอนโด ดอนเมือง เดอะ เทอมินอล',
        type: '2 ห้องนอน 1 ห้องนํ้า',
        room_size: '50 ตร.ม',
        room_position: 'ชั้น 1 มุมฝั่งตะวันออก',
        view: 'สระว่ายนํ้า',
        status: 'Unavailable'
    },
    {
        skuCode: 'RM-A-109',
        project: 'แฮปปี้ คอนโด ดอนเมือง เดอะ เทอมินอล',
        type: '1 ห้องนอน 1 ห้องนํ้า',
        room_size: '35 ตร.ม',
        room_position: 'ชั้น 1 มุมฝั่งตะวันออก',
        view: 'สระว่ายนํ้า',
        status: 'Unavailable'
    },
    {
        skuCode: 'RM-A-110',
        project: 'แฮปปี้ คอนโด ดอนเมือง เดอะ เทอมินอล',
        type: '1 ห้องนอน 1 ห้องนํ้า',
        room_size: '35 ตร.ม',
        room_position: 'ชั้น 1 มุมฝั่งตะวันออก',
        view: 'ตัวเมือง',
        status: 'Available'
    },
    {
        skuCode: 'RM-A-111',
        project: 'แฮปปี้ คอนโด ดอนเมือง เดอะ เทอมินอล',
        type: '2 ห้องนอน 1 ห้องนํ้า',
        room_size: '50 ตร.ม',
        room_position: 'ชั้น 1 มุมฝั่งใต้',
        view: 'สระว่ายนํ้า',
        status: 'Available'
    },
    {
        skuCode: 'RM-A-112',
        project: 'แฮปปี้ คอนโด ดอนเมือง เดอะ เทอมินอล',
        type: '1 ห้องนอน 1 ห้องนํ้า',
        room_size: '35 ตร.ม',
        room_position: 'ชั้น 1 มุมฝั่งใต้',
        view: 'ตัวเมือง',
        status: 'Unavailable'
    },
]