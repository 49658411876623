import React, { Component } from 'react'
import { connect } from 'react-redux'

import Wrapper from '../../layouts/Wrapper'
import LazyTable from '../../components/common/tables/LazyTable'
import Card from '../../components/common/Card'
import datastore from '../../datastore'

export default class LeadListsView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            query: '',
            searchKey: 'first_name'
        }
        this.rowClickCallback = this.rowClickCallback.bind(this)
        this.searchCallback = this.searchCallback.bind(this)
        this.goToAddLeadStep = this.goToAddLeadStep.bind(this)
        this.getValues = this.getValues.bind(this)
        this.handleExport = this.handleExport.bind(this)
        this.columns = [
            {
                header: 'Prospect',
                unSortable: true,
                accessor: 'prospect',
                render: value => {
                    return (
                        <div style={{textAlign: "center",width: "40%"}}>
                            <img src={this.renderProspect(value)} alt="prospect" />
                        </div>
                    )
                }
            },
            {
                header: 'ชื่อผู้สนใจ',
                accessor: 'first_name',
                unSortable: true
            },
            {
                header: 'นามสกุล',
                accessor: 'last_name',
                unSortable: true,
            },
            {
                header: 'เบอร์โทรศัพท์',
                accessor: 'phone_number',
                unSortable: true
            },
            {
                header: 'อายุ',
                accessor: 'age',
                unSortable: true
            },
            {
                header: 'รายได้/เดือน',
                accessor: 'income',
                unSortable: true
            },
            {
                header: 'อาชีพ',
                accessor: 'occupation',
                unSortable: true
            },
            {
                header: 'วันที่สร้าง',
                accessor: 'created',
                unSortable: true
            },
            {
                header: 'ผู้สร้าง',
                accessor: 'creatorName',
                unSortable: true
            }
        ]
    }

    componentWillMount() {
        this.props.setHeader(
            'รายชื่อผู้สนใจ',
            [this.handleExport, this.goToAddLeadStep],
            ['ดาวน์โหลด CSV', 'เพิ่มผู้สนใจใหม่'],
            this.searchCallback,
            'ค้นหาชื่อผู้สนใจ'
        )
    }

    searchCallback(value, searchKey) {
        this.setState({
            query: value,
            searchKey
        })
    }

    handleExport() {
        datastore.lead.exportLead()
    }

    goToAddLeadStep() {
        this.props.router.push('/lead/add-lead')
    }

    getValues(offset, pageSize) {
        return datastore.lead.getLeads(offset, pageSize, this.state.query)
    }

    rowClickCallback(row) {
        this.props.router.push(`/lead/${row.id}`)
    }

    renderProspect(value){
        let src = ""
        if(value === true){
            return src="/img/Active.svg"
        }
        else{
            return src="/img/Default.svg"
        }
    }

    render() {
        const { options } = this.props
        return (
            <Wrapper>
                <Card>
                    <LazyTable 
                        columns={ this.columns } 
                        getValues={ this.getValues } 
                        filterOptions={ options }
                        rowClickCallback={ this.rowClickCallback }
                        ref="listTable"
                    />
                </Card>
            </Wrapper>
        )
    }
}