import React from 'react'
import * as Fa from 'styled-icons/fa-solid'
import * as Md from 'styled-icons/material'
import moment from 'moment';
import apiClient from '../../../../apis'
import styled from 'styled-components'
import Table from '../../../../components/common/tables/Table'
import {IconTextGroup} from '../../../../theme/components/IconTextGroup'
import {Span} from '../../../../theme/components/Span'
import { Colors } from '../../../../theme';
import { Styles } from '../../../../theme/styles';
import {translate, phoneNumberFormatter} from '../../../../common/util'
import { Formatter } from '../../../../utilities/Formatter';

export const TableColumnContainer = styled.div`
    color: ${Colors.BillmeBlue};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
`

export const ActivityEntry = ({data, ...props}) => {
    const color = ({
        'booking': Colors.Green,
        'canceled': Colors.Red
    }[String(data.activity).toLowerCase()] || Colors.BillmeBlue)

    const icon = ({
        'booking': <Md.CheckCircle size={24} />,
        'call in': <Md.PhoneCallback size={24} />,
        'call out': <Md.PhoneForwarded size={24} />,
        'online registration': <Fa.Globe size={24} />,
        'walk': <Md.Person size={24} />,
        'line': <Fa.MobileAlt size={24}/>,
        'canceled': <Fa.TimesCircle size={24} />,
        'revisit': <Fa.Undo size={24} />,
        'contract': <Fa.FileAlt size={24} />
    }[String(data.activity).toLowerCase()] || <Md.Info size={24} />)
    const createdDate = `${Formatter.formatDateTh(Formatter.createDate(data.created))} ${moment(data.created).add(7, 'hours').format('HH:mm:ss')}`

    return (
        <IconTextGroup color={color} icon={icon}>
            <Span>{data.activity}</Span>
            <span style={Styles.Smaller}>{createdDate}</span>
        </IconTextGroup>
    )
}


export const LeadListsTable = React.memo(({data, showCustomerInfo, router, ...props}) => {

    const columns = [
        {
            header: 'ชื่อผู้สนใจ',
            accessor: 'first_name',
            subaccessor: ['last_name', 'phone_number', 'age', 'gender', 'income', 'occupation', 'line_id', 'email'],
            width: 1,
            render: (firstname, [lastname, phone, age, gender, income, occupation, line_id, email]) => (
                <IconTextGroup>
                    <Span>{firstname} {lastname}</Span>
                    <span>เพศ <b>{translate('genders', gender)}</b> อายุ <b>{age === 0 ? 'ไม่ระบุ' : age}</b> ปี</span>
                    <span>อาชีพ: {occupation}</span>
                    <span>รายได้: {income}</span>
                    <span>โทร: {phoneNumberFormatter(phone) || '-'}</span>
                    <span>อีเมล์: {email || '-'}</span>
                    <span>Line: {line_id || '-'}</span>
                </IconTextGroup>
            )
        },
        {
            header: 'กิจกรรม',
            accessor: 'activities',
            unSortable: true,
            width: 1,
            render: activities => (
                <div>
                    {activities.map(activity => <ActivityEntry data={activity} />)}
                </div>
            )
        },
        {
            header: 'เซลล์ผู้รับผิดชอบ',
            accessor: 'activities',
            unSortable: true,
            width: 1,
            render: activities => (
                <div>
                {activities.map(activity => <IconTextGroup>
                    <Span>{activity.sale_full_name}</Span>
                    <span style={Styles.Smaller}>&nbsp;</span>
                </IconTextGroup>)}
                </div>
            )
        },
        {
            header: 'สินค้าที่เกี่ยวข้อง',
            accessor: 'activities',
            unSortable: true,
            width: 1,
            render: activities => (
                <div>
                {activities.map(activity => <IconTextGroup>
                    <Span>{activity.interest || '-'}</Span>
                    <span style={Styles.Smaller}>{activity.concern}&nbsp;</span>
                </IconTextGroup>)}
                </div>
            )
        },
        {
            header: '',
            accessor: 'id',
            subaccessor: 'first_name',
            unSortable: true,
            width: 1,
            render: (customerId, x, y, customer) => {
                return (
                    <TableColumnContainer>
                        <div>
                            <a
                                href=""
                                onClick={e => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    showCustomerInfo(customer)
                                }}
                                style={{display: 'flex', color: `${Colors.BillmeBlue}`, marginBottom: '16px'}}
                            >
                                <Fa.UserCircle
                                    size={24}
                                />
                                <p style={{paddingLeft: '12px'}}>ดูประวัติผู้สนใจ</p>
                            </a>
                            <a 
                                href=""  
                                onClick={e => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    router.push(`/sale-mode/leads/${customerId}/add-activity`)
                                }}
                                style={{display: 'flex', color: `${Colors.BillmeBlue}`, marginBottom: '16px'}}
                            >
                                <Fa.Calendar
                                    size={24}
                                />
                                <p style={{paddingLeft: '12px'}}>สร้างกิจกรรม</p>
                            </a>
                            <a  
                                href=""   
                                onClick={e => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    router.push(`/sale-mode/leads/${customerId}/book`)
                                }}
                                style={{display: 'flex', color: `${Colors.BillmeBlue}`, marginBottom: '16px'}}
                            >
                                <Fa.Building 
                                    size={24}
                                />
                                <p style={{paddingLeft: '12px'}}>จองห้อง</p>
                            </a>
                            {
                                customer.interest === 't' ?
                                <a
                                    href=""
                                    onClick={e => {
                                        e.preventDefault()
                                        e.stopPropagation()

                                        const confirmButton = window.confirm('ยืนยันที่จะยกเลิกการติดตาม?')
                                        if (confirmButton) {
                                            apiClient.lead.changeInterestStatus(customerId)
                                            .then(res => {
                                                const SUCCESS = 204
                                                if (res.status === SUCCESS) {
                                                    window.location.reload()
                                                } else {
                                                    alert('มีบางอย่างผิดพลาด กรุณาลองใหม่')
                                                }
                                            })
                                        }
                                    }}
                                    style={{display: 'flex', color: `${Colors.Red}`, marginBottom: '16px'}}
                                >
                                    <Fa.TimesCircle
                                        size={24}
                                    />
                                    <p style={{paddingLeft: '12px'}}>ยกเลิกการติดตาม</p>
                                </a> :
                                <p style={{paddingLeft: '12px', color: `${Colors.Grey}`}}>ยกเลิกการติดตามแล้ว</p>
                             }
                        </div>
                    </TableColumnContainer>
                )
            }
        },
    ]

    return (
        <Table 
            columns={columns} 
            values={data}
            itemsPerPage={10}
        />
)})

export default LeadListsTable