import React, { PureComponent } from 'react';
import DatePicker from  'react-datepicker';
import { inModal } from '../../hoc/inModal';
import ModalTitle from '../common/ModalTitle';
import SelectInput from '../common/SelectInput';
import moment from 'moment'
import apis from '../../apis';

export class ChangeSaleStatusForm extends PureComponent {
  constructor(props){
    super(props)

    this.state = {
      data: {
        selectedStatus: null,
        target_date: null
      },
      record: this.props.record ? this.props.record : null,
      currentStatus: this.props.record ? this.props.record.saleRecordStatus : null,
      statusOption: []
    }

  }

  componentWillMount() {
    this.optionSelector(this.state.currentStatus)
  }

  optionSelector(status) {
    const statusOption = this.state.statusOption
    if(status === 0) {
      statusOption.push(
        {
          value: 1,
          label: 'ทำสัญญา'
        },
        {
          value: 2,
          label: 'ยกเลิก'
        }
      )
    } else if (status === 1) {
      statusOption.push(
        {
          value: 3,
          label: 'โอนแล้ว'
        },
        {
          value: 2,
          label: 'ยกเลิก'
        }
      )
    }
    this.setState({
      statusOption: statusOption
    })
  }

  mapStatus(status) {
    switch(status){
      case -1:
        return 'ยกเลิก'
      case 0:
        return 'จอง'
      case 1:
        return 'ทำสัญญา'
      case 2:
        return 'โอนแล้ว'
      default:
        return 'ยกเลิก'
    }
  }

  handleDateChange = datetime => {
    const data = this.state.data
    data.target_date = datetime.format('YYYY-MM-DD HH:mm:ss')
    this.setState({
        target_date: datetime
    })
  }

  onConfirmChange = (selectedStatus) => {
    if(selectedStatus){
      selectedStatus = selectedStatus.toString()
    } else {
      selectedStatus = '0'
    }

    if(selectedStatus === '1') {
      const send = apis.sale.updateContractInfo(this.props.record.id, this.state.data.target_date)
      send.then(result => {
        if(result){
          alert('เปลี่ยนสถานะเป็น ทำสัญญา เรียบร้อยแล้ว')
          this.props.closeModal()
          window.location.reload() //TODO: Will Change if have better solution for this
        }
      }).catch(error => {
        alert('เปลี่ยนสถานะไม่สำเร็จ กรุณาลองใหม่อีกครั้ง')
        console.error(error)
        return Promise.reject()
      })

      return send
    } else if(selectedStatus === '2') {
      const send = apis.sale.cancelSaleRecord(this.props.record.id, this.state.data.target_date)
      send.then(result => {
        if(result){
          alert('เปลี่ยนสถานะ ยกเลิก เรียบร้อยแล้ว')
          this.props.closeModal()
          window.location.reload() //TODO: Will Change if have better solution for this
        }
      }).catch(error => {
        alert('เปลี่ยนสถานะไม่สำเร็จ กรุณาลองใหม่อีกครั้ง')
        console.error(error)
        return Promise.reject()
      })

      return send
    } else if (selectedStatus === '3') {
      const send = apis.sale.updateTransferInfo(this.props.record.id, this.state.data.target_date)
      send.then(result => {
        if(result){
          alert('เปลี่ยนสถานะเป็น โอนแล้ว เรียบร้อยแล้ว')
          this.props.closeModal()
          window.location.reload() //TODO: Will Change if have better solution for this
        }
      }).catch(error => {
        alert('เปลี่ยนสถานะไม่สำเร็จ กรุณาลองใหม่อีกครั้ง')
        console.error(error)
        return Promise.reject()
      })

      return send
    } else {
      alert("กรุณาเลือกสถานะที่ต้องการเปลี่ยน")
      return Promise.reject()
    }
  }

  render() {
    return (
      <div>
        <ModalTitle title="เปลี่ยนสถานะการขาย" onClick={() => this.props.closeModal()} />
        <div className="d-flex flex-column" style={{ flexGrow: 1 }}>
          <div className="row">
            <div className="col-sm-7" style={{marginTop: "8px"}}>
              คุณต้องการเปลี่ยนสถานะการขายของห้อง <strong>{this.state.record.skuCode}</strong> จาก <strong>{this.mapStatus(this.state.currentStatus)}</strong> เป็น
            </div>
            <div className="col-sm-3.5"> 
              <selectWrap>
                <SelectInput
                  name="selectedStatus"
                  placeholder="เลือกสถานะ"
                  target={ this }
                  stateKey="data"
                  options={ this.state.statusOption }
                  onChange={(value) => {
                    this.setState({
                      selectedStatus: value
                    })
                  }}
                />
                </selectWrap>
                <DatePicker
                  className="form-control input w-fit-content calendar-image"
                  placeholderText="เลือกวันที่และเวลา"
                  selected={this.state.target_date}
                  onChange={ this.handleDateChange }
                  dateFormat="YYYY-MM-DD เวลา HH:mm"
                  timeIntervals={15}
                  timeFormat="HH:mm"
                  showTimeSelect
                  shouldCloseOnSelect={ false }
                  disabledKeyboardNavigation
                />
            </div>
          </div>
        </div>
        <div style={{paddingTop: "16px"}} className="d-flex flex-row justify-content-center">
          <button 
            style={{padding: 0}}
            onClick={ () => {
              this.props.closeModal()
            }}
            className="m-05 btn btn-save-cancel btn-outline"
          >
              ยกเลิก
          </button>
          <button 
            style={{padding: 0}}
            onClick={ () => {
              this.onConfirmChange(this.state.data.selectedStatus)
            }} 
            className="m-05 btn btn-save-cancel btn-outline"
            disabled={this.state.data.target_date ? false : true}
          >
            ยืนยัน
          </button>
        </div>
      </div>
    )
  }
}

const ChangeSaleStatusModal = inModal(ChangeSaleStatusForm)
export default ChangeSaleStatusModal