import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class UserProfile extends Component {
    render() {
        const { name, affiliation } = this.props

        return (
            <div className="mb-05 text-center">
                <p className="f13 mb-02" style={{ fontWeight: "500" }}>{ name }</p>
                <p className="f13 grey">{ affiliation }</p>
            </div>
        )
    }
}

UserProfile.propTypes = {
    name: PropTypes.string,
    affiliation: PropTypes.string,
}
