import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ConfirmModal from '../../../components/ConfirmModal'

class ConfirmDeleteDocumentModal extends Component {
    render() {
        const { deleteHandler, text } = this.props
        return (
            <ConfirmModal
                ref="modal"
                title="ยืนยันการลบไฟล์" 
                onConfirm={ deleteHandler }
            >
                <div className="d-flex flex-column justify-content-around">
                    { text }
                </div>
            </ConfirmModal>
        )
    }
}

ConfirmDeleteDocumentModal.propTypes = {
    text: PropTypes.string,
    deleteHandler: PropTypes.func.isRequired
}

export default ConfirmDeleteDocumentModal