import React, { Component } from 'react'
import Modal from 'react-modal'

import { getDisplayName } from '../common/util'
import { ModalStyle } from '../common/const'

export function inModal(ModalContentComponent, isSelectProjectModal = false) {
    class InModal extends Component {
        constructor(props) {
            super(props)
            this.state = {
                showModal: props.show || false
            }

            this.openModal = this.openModal.bind(this)
            this.closeModal = this.closeModal.bind(this)
            this.toggleModal = this.toggleModal.bind(this)
        }

        openModal() {
            this.setState({
                showModal: true
            })
        }

        closeModal() {
            const { onClose } = this.props
            if (onClose) {
                onClose()
            }

            this.setState({
                showModal: false
            })
        }

        toggleModal() {
            this.setState({
                showModal: !this.state.showModal
            })
        }

        render() {
            const {show, ...passThroughProps } = this.props
            const noBackground = this.props.noBackground
            return (
                <Modal
                    isOpen={ show || this.state.showModal }
                    onRequestClose={ this.closeModal }
                    contentLabel="modal"
                    style={ModalStyle(noBackground, isSelectProjectModal)}
                >
                    <ModalContentComponent 
                        closeModal={ this.closeModal } 
                        openModal={ this.openModal }
                        { ...passThroughProps }
                    />
                </Modal>
            )
        }
    }

    InModal.displayName = `InModal(${getDisplayName(ModalContentComponent)})`
    return InModal
}
