import React, { Component } from 'react'
import PropTypes from 'prop-types'

import TextInput from '../../components/common/TextInput'
import ConfirmModal from '../../components/ConfirmModal'
import FileInput from '../../components/common/FileInput';

class AddDocumentModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                filename: null,
                file: null
            }
        }
    }

    addDocument() {
        this.props.addDocumentHandler(this.state.data.filename, this.state.data.file)
        const data = this.state.data
        data.filename = ''
        this.setState({data})
    }

    onCancel() {
        const data = this.state.data
        data.filename = ''
        this.setState({data})
    }

    render() {
        return (
            <ConfirmModal
                ref="modal"
                title="เพิ่มเอกสาร"
                onConfirm={ () => this.addDocument() }
                onCancel={() => this.onCancel() }
            >
                <TextInput 
                    name="filename" 
                    type="text" 
                    target={ this } 
                    stateKey="data" 
                    placeholder="ชื่อเอกสาร"
                />
                <div style={{marginTop: "16px"}}>
                    <input 
                        type="file"
                        onChange={event => {
                            const data = this.state.data
                            data.file = event.target.files

                            this.setState({
                                data: data
                            })
                        }}
                    />
                </div>
            </ConfirmModal>
        )
    }
}

AddDocumentModal.propTypes = {
    addDocumentHandler: PropTypes.func
}

export default AddDocumentModal