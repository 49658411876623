import moment from 'moment'

import mocks from '../mocks'
import apiClient from '../apis'
import { createPromise, translate } from '../common/util'
import { Formatter } from '../utilities/Formatter';

const IS_MOCK = false

export function getProducts(offset, pageSize, sortKey, sortOrder, filters, query) {
    if (IS_MOCK) {
        const mockedWarrantyProducts = mocks.warranty.PRODUCTS_LIST
        const products = mockedWarrantyProducts.products.map(p => convertProductInfo(p))
        return createPromise({
            length: mockedWarrantyProducts.total,
            values: products
        })
    }

    return apiClient.warranty.getProducts(offset, pageSize, sortKey, sortOrder, filters, query)
        .then(res => {
            const products = res.values.map(p => convertProductInfo(p))
            return {
                length: res.length,
                values: products
            }
        })
}

export function exportWarranty() {
    return apiClient.warranty.exportWarranty()
}

export function getDefects(productId, offset, pageSize, sortKey, sortOrder, filters, query) {
    if (IS_MOCK) {
        const mockedDefects = mocks.warranty.DEFECTS_LIST
        const defects = mockedDefects.defects.map(d => convertDefectInfo(d))
        return createPromise({
            length: mockedDefects.total,
            values: defects,
            product: convertProductInfo(mockedDefects.product)
        })
    }

    return apiClient.warranty.getDefects(productId, offset, pageSize, sortKey, sortOrder, filters, query)
        .then(res => {
            return {
                length: res.length,
                values: res.values.map((d, index) => convertDefectInfo(d, index))
            }
        })
}

export function getDefectDetail (defectId) {
    return apiClient.warranty.getDefectDetail(defectId)
        .then(result => {
            return convertDefectDetail(result)
        })
}

export function getDefectLocation(projectId) {
    return apiClient.warranty.getDefectLocation(projectId)
}

export function cancelDefect(defectId, reasonId) {
    return apiClient.warranty.cancelDefect(defectId, reasonId)
}

export function createDefect(productId, defectInfo) {
    return apiClient.warranty.createDefect(productId, defectInfo)
}

export function getDefectsToAssign(productId) {
    return apiClient.warranty.getDefectsToAssign(productId)
}

export function getTechnicians(projectId) {
    return apiClient.warranty.getTechnicians(projectId)
}

export function getAssignedDefects() {
    return apiClient.warranty.getAssignedDefects()
}

export function assign(defectId, datetime, technicianId) {
    return apiClient.warranty.assign(defectId, datetime, technicianId)
}

export function getDefectsToReassign(productId) {
    return apiClient.warranty.getDefectsToReassign(productId)
}

function convertProductInfo(p) {
    if (!p) {
        return null
    }

    return {
        id: p.id,
        customerName: p.customer_name,
        skuCode: p.sku_code,
        status: p.defect_report_status,
        severity: p.severity,
        warrantyStatus: p.warranty_status,
        staffName: p.staff_name,
        dueDate: dateFormatter(p.due_date),
    }
}

function dateFormatter(date) {
    if(date){
        return Formatter.formatDateTh(moment(date, 'YYYY-MM-DD HH:mm:ss+Z'))
    }
    else {
        return 'ยังไม่มอบหมายงาน'
    }
}

function convertDefectDetail(defect) {

    const elapsedDays = moment().diff(moment(defect.report_date), 'days')

    return {
        skuCode: defect.sku_code,
        description: defect.description,
        defectType: translate('defect_types', defect.defect_type.toString()),
        warrantyType: translate('warranty_types', defect.warranty_type),
        location: translate('defect_locations', defect.location_id.toString()),
        status: defect.status,
        slaDays: defect.sla_days,
        elapsedDays: elapsedDays,
        slaPercentage: elapsedDays / defect.sla_days,
        createdDate: moment(defect.created_date),
        technicianName: defect.tech_name,
        before: defect.before_link,
        after: defect.after_link,
    }
}

function convertDefectInfo(defect, index) {
    if (!defect) {
        return null
    }

    const elapsedDays = moment().diff(moment(defect.created_date), 'days')

    return {
        id: defect.id,
        index: index+1,
        description: defect.description,
        defectType: translate('defect_types', defect.defect_type.toString()),
        warrantyType: translate('warranty_types', defect.warranty_type),
        // warrantyType: translate('warranty_types', 2),
        location: locationIdTranslator(defect.location_id),
        slaDays: defect.sla_days,
        elapsedDays: elapsedDays,
        slaPercentage: elapsedDays / defect.sla_days,
        createdDate: moment(defect.created_date),
        technicianName: defect.technician_name,
        technicianPhone: defect.technician_phone,
        technicianPicture: defect.technician_picture,
        before: defect.before_image,
        after: defect.after_image,
        fixedDate: defect.fixed_date
    }
}

function locationIdTranslator(id) {
    switch(id) {
        case 1:
            return 'ห้องนอน'
        case 2:
            return 'ห้องน้ำ'
        case 3:
            return 'ภายนอกอาคาร'
        case 4:
            return 'ส่วนนั่งเล่น'
        case 5:
            return 'WC. B'
        case 6:
            return 'ส่วนครัว'
        case 7:
            return 'ระเบียง'
        case 8:
            return 'ที่จอดรถ'
        case 9:
            return 'ส่วนรับประทานอาหาร'
        case 10:
            return 'ห้องเก็บของ'
        case 11:
            return 'ห้องนอนใหญ่'
        case 12:
            return 'ห้องนอนเล็ก (ติดบันได)'
        case 13:
            return 'ห้องนอนเล็ก'
        case 14:
            return 'โถงบันได'
        case 15:
            return 'ลานซักล้าง'
        case 16:
            return 'ห้องน้ำชั้นล่าง'
        case 17:
            return 'ห้องน้ำชั้นบน'
        case 18:
            return 'ห้องอเนกประสงค์ ชั้น 1'
        case 19:
            return 'ห้องน้ำห้องนอนใหญ่'
        default:
            return 'ไม่ระบุ'
    }
}