import React, { Component } from 'react';
import '../styles/SubCard.css';

export class SubCard extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="Dashboard__status-sub-card" style={{color: `${this.props.color ? this.props.color : '#404040'}`}}>
        <div style={{display: 'flex', width: '100%'}}>
          <p className="Dashboard__status-sub-card-title" style={{width: '55%'}}>{this.props.title}</p>
          <p className="Dashboard__status-sub-card-data" style={{width: '50%', textAlign: 'right'}}>{this.props.data}</p>
        </div>
        {
          this.props.note ?
          <div>
            <p className="Dashboard__status-sub-card-note">{this.props.note}</p>
          </div> :
          null
        }
      </div>
    )
  }
}