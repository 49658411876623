import React, { Component } from 'react'
import { connect } from 'react-redux'

import TextInput from '../common/TextInput'
import apiClient from '../../apis'
import { inModal } from '../../hoc/inModal'
import ModalTitle from '../common/ModalTitle'
import ModalFooter from '../common/ModalFooter'
import FormNewLine from '../common/FormNewLine'
import _ from 'lodash'
import SelectInput from '../common/SelectInput';
import { formatNumberWithComma, phoneNumberFormatter } from '../../common/util'

export class BookingInfoForm extends Component {
    constructor(props){
        super(props)

        this.state = {
            data: {
                first_name: '',
                last_name: '',
                citizen_id: '',
                mobile: '',
                product_id: '',
                sale_amount: '',
                source: 0
            },
            show: {
                interest: ''
            },
            source_choice: '',
            products: [],
            isClick: false
        }

        apiClient.campaign.getAds()
        .then(result => {
            const choice = result.advertisements.map(option => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
            this.setState({source_choice: choice})
        })

        if(this.props.leadId !== undefined){
            apiClient.lead.getLead(this.props.leadId)
            .then(lead => {
                const data = this.state.data
                data.first_name = lead.first_name
                data.last_name = lead.last_name
                data.mobile = lead.phone_number
                this.setState({data : data})
            })
        }
    }

    render() {
        const { options,noHeaderAndFooter } = this.props

        const form = (
            <div className="d-flex flex-column" style={{ flexGrow: 1 }}>
                <div className="row">
                    <h3 className="col-sm-4" style={{color: 'grey'}}>ข้อมูลผู้จอง</h3>
                    <FormNewLine />
                    <div className="col-sm-6">
                        <TextInput 
                            name="first_name"
                            placeholder="ชื่อ"
                            target={ this }
                            stateKey="data"
                            required={true}
                        />
                    </div>
                    <div className="col-sm-6">
                        <TextInput 
                            name="last_name"
                            placeholder="นามสกุล"
                            target={ this }
                            stateKey="data"
                            required={true}
                        />
                    </div>
                    <FormNewLine />
                    <div className="col-sm-6">
                        <TextInput
                            name="mobile"
                            placeholder="เบอร์โทรศัพท์"
                            renderFormatter={value => phoneNumberFormatter(value)}
                            dataFormatter={value => value.replace(/-/g, '')}
                            target={ this }
                            stateKey="data"
                            required={true}
                        />
                    </div>
                    <div className="col-sm-6">
                        <TextInput
                            name="sale_amount"
                            placeholder="ราคา"
                            target={ this }
                            stateKey="data"
                            renderFormatter={value => formatNumberWithComma(value)}
                            dataFormatter={value => value.replace(/,/g, '')} 
                            required={true}
                        />
                    </div>
                    <FormNewLine />
                    <div className="col-sm-6">
                        <TextInput
                            name="interest"
                            placeholder="ห้องที่สนใจ"
                            stateKey="show"
                            target={ this }
                            required={true}
                            onChange={() => this.searchProduct() }
                        />
                        {
                            !_.isEmpty(this.state.products) ?
                            !this.state.isClick ?
                            (
                                <div>
                                    <div className="mt-2 mb-05">ผลการค้นหา</div>
                                    <div className="p-05" style={{ backgroundColor: "rgba(0,0,0,0.1)", borderRadius: "10px" }}>
                                        { this.state.products.map(product => {
                                            return (
                                            <SearchResultItem
                                                key={product.value}
                                                product={product}
                                                selectProduct={product => this.selectProduct(product)}
                                            />
                                            )
                                        })}
                                    </div>
                                </div>
                            ) :
                            null :
                            null
                        }
                    </div>
                    {/*
                    <FormNewLine />
                    <div className="col-sm-6">
                        <selectWrap>
                            <SelectInput
                                name="source"
                                placeholder="สื่อโครงการ"
                                target={ this }
                                stateKey="data"
                                options={ this.state.source_choice }
                            />
                        </selectWrap>
                    </div>
                    */}
                </div>
            </div>
        )

        if (noHeaderAndFooter) {
            return form
        }

        return (
            <div className="d-flex flex-column">
                <ModalTitle title="เพิ่มกิจกรรม" onClick={ () => this.props.closeModal() } />
                {form}
                <ModalFooter>
                    <button onClick={ () => this.save() } className="btn btn-main">บันทึก</button>
                </ModalFooter>
            </div>
        )
    }

    save = (skipRefreshFunc = false) => {
        if(!this.state.data.first_name 
            || !this.state.data.last_name
            || !this.state.data.product_id 
            || !this.state.data.mobile
            //|| !this.state.data.source 
            || !this.state.data.sale_amount){
            alert("กรุณากรอกข้อมูลให้ครบ")
            return Promise.reject()
        }
        else{
            const data = {
                ...this.state.data,
                lead_id: this.props.leadId,
                source: 0
            }
            const p = apiClient.booking.createBooking(data)
            return p
        }
    }

    searchProduct = () => {
        if (!this.state.show.interest) {
            this.setState({
                products: [],
                isClick: false
            })
        }  else {
            apiClient.activity.getProductSearch(this.state.show.interest)
                .then(res => {
                    const product = res.products.map(product => {
                        return {
                            product_id: product.key,
                            value: product.value
                        }
                    })
                    const data = this.state.data
                    data.product_id = ''
                    this.setState({
                        products: product,
                        isClick: false,
                        product_id: data
                    })
                })
        }
    }

    selectProduct = product => {
        const data = this.state.data
        const show = this.state.show
        show.interest = product.value
        data.product_id= product.product_id
        this.setState({
            interest: show,
            product_id: data,
            isClick : true
        })
    }

}

function mapStateToProps(state) {
    return {
        options: state.other.options,
    }
}

BookingInfoForm = connect(mapStateToProps, null, null, { withRef: true })(BookingInfoForm)

const BookingInfoModal = inModal(BookingInfoForm)
export default BookingInfoModal

const SearchResultItem = ({product, selectProduct}) => {
    return <div className="clickable" onClick={() => selectProduct(product)}>
        <p className="m-0 p-02">
            {product.value}
        </p>
    </div>
}
