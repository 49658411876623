import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment';
import datastore from '../../datastore'
import LazyTable from '../../components/common/tables/LazyTable'
import Card from '../../components/common/Card'
import Wrapper from '../../layouts/Wrapper'
import DefectInfoModal from './DefectInfoModal'
import { RESTRICTED_KEYWORD } from '../../common/const'
import { openNewWindowSameHost } from '../../common/util'
import DownloadDefectSummaryModal from './DownloadDefectSummaryModal';
import DownloadStartDefectDateModal from './DownloadStartDefectDateModal';

class WarrantyInfoView extends Component {
    productId = null
    state = {
        query: null,
        selectedDefect: null,
        createdDate: [],
        fixedDate: [],
        product: null
    }

    columns = [
        {
            header: '#',
            accessor: 'index',
            width: 0.75,
        },
        {
            header: 'รายละเอียดข้อบกพร่อง',
            accessor: 'description',
            width: 2,
            render: value => <span className="text-overflow-2">{value}</span>
        },
        {
            header: 'ชนิดงาน',
            accessor: 'defectType',
            width: 2,
            render: value => <span className="text-overflow-2">{value}</span>
        },
        {
            header: 'ตำแหน่ง',
            accessor: 'location',
            // filterAccessor: 'roomTypes',
            width: 2,
            render: value => <span className="text-overflow-2">{value}</span>
        },
        {
            header: 'ช่างที่รับผิดชอบ',
            accessor: 'technicianName',
            width: 2,
        },
        {
            header: 'สถานะ',
            accessor: 'slaPercentage',
            subaccessor: ['elapsedDays', 'fixedDate'],
            width: 2,
            render: (percentage, [days, fixedDate]) => {
                const indicatorColor = percentage < 0.5 ? 'yellow' : 'red'
                return fixedDate 
                    ? <span><i className='fa fa-circle mr-1' style={{color: 'green'}} />แก้ไขแล้ว</span>
                    : <span><i className='fa fa-circle mr-1' style={{color: indicatorColor}} />{days} วัน</span>
            }
        },
    ]

    componentWillMount() {
        const productId = this.props.params.productId
        this.productId = productId
        datastore.product.getProduct(productId)
            .then(p => {
                this.props.setHeader(
                    `รับประกัน - ${p.orderingSkuCode}`,
                    [this.onClickReassignWork, this.onClickAssignWork, this.onClickDownloadSummary, this.onClickDownloadOpenDefect/*, this.print */],
                ['แก้ไขวันที่มอบหมายงาน' ,'มอบหมายงาน', 'พิมพ์เอกสารสรุปการแก้ไข','พิมพ์เอกสารรับเรื่องแจ้งซ่อม'/* , 'พิมพ์ใบแจ้งรายการรับประกัน' */],
                )

                this.setState({ product: p })
            })
        datastore.warranty.getDefects(productId, 0, 1000, null, null, null, this.state.query) // TODO: edit fixed pageSize
            .then(defect => {
                const created = defect.values.map(detail => {
                    return moment(detail.createdDate).format('YYYY-MM-DD')
                })
                const fixed = defect.values.map(detail => {
                    return detail.fixedDate ? moment(detail.fixedDate).format('YYYY-MM-DD') : null
                })
                this.setState({
                    createdDate: created,
                    fixedDate: fixed
                })
            })
    }

    print = () => {
        openNewWindowSameHost(`/pwarranty-summary/${this.productId}`, null)
    }

    onClickDownloadSummary = () => {
        this.refs.downloadSummary.openModal();
    }

    onClickDownloadOpenDefect = () => {
        this.refs.downloadStart.openModal();
    }

    onClickAssignWork = () => {
        this.props.router.push(`/warranty-assign?sku_code=${this.state.product.orderingSkuCode}&product_id=${this.props.params.productId}`);
    }

    onClickReassignWork = () => {
        this.props.router.push(`/warranty-assign?sku_code=${this.state.product.orderingSkuCode}&reassign=true&product_id=${this.props.params.productId}`)
    }

    getValues = (offset, pageSize, sortKey, sortOrder, filters) => { 
        return datastore.warranty.getDefects(this.productId, offset, pageSize, sortKey, sortOrder, filters, this.state.query)
    }

    rowClickCallback = row => {
        this.setState({
            selectedDefect: row
        }, this.refs.infoModal.openModal)
    }

    refresh = () => {
        this.setState({ selectedDefect: null })
    }

    isRestricted = () => {
        return this.props.router.location.pathname.indexOf(RESTRICTED_KEYWORD) !== -1
    }

    render() {
        const { options } = this.props

        return (
            <Wrapper>
                <Card>
                    <LazyTable 
                        columns={this.columns} 
                        getValues={this.getValues} 
                        filterOptions={options}
                        rowClickCallback={this.rowClickCallback}
                    />
                </Card>
                <DefectInfoModal 
                    ref="infoModal" 
                    defect={this.state.selectedDefect}
                    product={this.state.product}
                    uploadHandler={v => console.log(v)}
                    refresh={this.refresh}
                    canDelete={!this.isRestricted()}
                />
                <DownloadDefectSummaryModal 
                    ref="downloadSummary"
                    fixedDate={this.state.fixedDate}
                    productId={ this.props.params.productId }
                    skuCode={this.state.product ? this.state.product.orderingSkuCode : null}
                />
                <DownloadStartDefectDateModal 
                    ref="downloadStart"
                    createdDate={this.state.createdDate}
                    productId={ this.props.params.productId }
                    skuCode={this.state.product ? this.state.product.orderingSkuCode : null}
                />
            </Wrapper>
        )
    }
}

function mapStateToProps(state) {
    return {
        options: state.other.options,
    }
}

export default connect(mapStateToProps, null)(WarrantyInfoView)
