export const Colors = {
    Default: '#00ACE1',
    BillmeBlue: '#00ACE1',
    Red: '#F9777F',
    Yellow: '#FFB400',
    Green: '#00B777',
    Orange: '#FF8300',
    Black: '#404040',
    Graphite: '#8E8E93',
    Grey: '#D1D1D6',
    MidGrey: '#E4E4E6',
    LightGrey: '#F3F3F4',
    White: '#FFFFFF',
}