import React, { Component } from 'react'

import Wrapper from '../../layouts/Wrapper'
import datastore from '../../datastore'
import apiClient from '../../apis'
import LazyTable from '../../components/common/tables/LazyTable'
import BasicLeadInfoView from '../basicInfo/basicLeadInfo'
import Card from '../../components/common/Card';

export default class LeadInfo extends Component {
   constructor(props) {
        super(props)
        this.state = {
            record: null,
            answered: [],
            questions: [],
            sources_list: [],
            answered_source: []
        }

        this.goToBooking = this.goToBooking.bind(this)
        this.goToCreateActivity = this.goToCreateActivity.bind(this)
        this.editInfo = this.editInfo.bind(this)
        this.getValues = this.getValues.bind(this)
        this.checkboxRender = this.checkboxRender.bind(this)
        this.deleteLead = this.deleteLead.bind(this)
        this.columns = [
            {
                header: 'กิจกรรม',
                accessor: 'activity'
            },
            {
                header: 'รายการสินค้า',
                accessor: 'interest'
            },
            {
                header: 'เหตุที่ยังไม่ตัดสินใจ',
                accessor: 'concern'
            },
            {
                header: 'หมายเหตุ',
                accessor: 'remark'
            }
        ]
        apiClient.lead.getQuestionsForEdit(this.props.params.leadId).then(res => {
            const questions = res[0].questions.map(question => {
                return {
                    question_id: question.id,
                    title: question.title,
                    answers: question.answers
                }
            })
            this.setState({
                questions: questions,
                answered: res[1].answer_list.map(answers => {
                    return {
                        question_id: answers.question_id,
                        answers: answers.answers
                    }
                })
            })
        })

        apiClient.campaign.getAds().then(res => {
            const ads = res.advertisements.map(list =>{
                return {
                    id: list.id,
                    values: list.name
                }
            })
            this.setState({
                sources_list: ads
            })
        })
    }

    componentWillMount() {
        const leadId = this.props.params.leadId
        datastore.lead.getLead(leadId)
            .then(record => {
                if(record.last_name){
                    this.props.setHeader(`${record.first_name} ${record.last_name}`, [this.editInfo,this.goToBooking ,this.goToCreateActivity, this.deleteLead], ['แก้ไขข้อมูล','จองห้อง', 'สร้างกิจกรรม', 'ลบรายชื่อ'])
                } else {
                    this.props.setHeader(`${record.first_name}`, [this.editInfo,this.goToBooking ,this.goToCreateActivity, this.deleteLead], ['แก้ไขข้อมูล','จองห้อง', 'สร้างกิจกรรม', 'ลบรายชื่อ'])
                }
                this.setState({
                    record: record,
                    answered_source: record.sources
                })
            })
    }

    editInfo() {
        const leadId = this.props.params.leadId
        this.props.router.push(`/lead/${leadId}/edit-info`)
    }

    deleteLead() {
        const leadId = this.props.params.leadId
        const isConfirm = window.confirm(`คุณต้องการลบผู้สนใจนี้ใช่หรือไม่?`)
        if (isConfirm) {
            const p = apiClient.lead.deleteLead(leadId).then(res => {
                this.props.router.push('/lead')
            })
        }
    }

    isSelectedSource(answered_source, id) {
        const answered = answered_source.filter(answered => answered === id.toString());
        if (answered.length > 0){
            return true;
        } else {
            return false;
        }
    }

    renderSourceCheckbox = (sources, answered_source) => {
        return sources.map((source, index) => {
            return (
                <div key={`ad-index-${index}`}>
                    <input 
                        className="mr-05" 
                        type="checkbox" 
                        value={source.values} 
                        id={source.id} 
                        checked={this.isSelectedSource(answered_source, source.id)}
                        disabled
                    />
                    <label htmlFor={source.id}>{source.values}</label>
                </div>
            )
        })
    }

    checkboxRender = (answers, id, answered) =>{
        return answers.map((value,index) => {
            return  (
                <div key={index}>
                    <input 
                        className="mr-05"
                        type="checkbox"
                        value={value}
                        id={id}
                        disabled
                        checked={this.isChecked(answered, id, value)}
                    />
                    <label htmlFor={id}>{value}</label>
                </div>
            )
        })
    }

    isChecked(answer_list, id, value) {
        const answered = answer_list.filter(answer => answer.question_id.toString() === id.toString());
        if (answered.length > 0) {
            return answered.flatMap(allAnswer => allAnswer.answers.map(choice => choice)).indexOf(value) > -1 ? true : false
        } else {
            return null;
        }
    }

    goToBooking() {
        const leadId = this.props.params.leadId
        this.props.router.push(`/lead/${leadId}/booking`)
    }

    goToCreateActivity() {
        const leadId = this.props.params.leadId
        this.props.router.push(`/lead/${leadId}/add-activities`)
    }

    getValues(offset,pageSize){
        const leadId = this.props.params.leadId
        return datastore.activity.getActivity(leadId,offset,pageSize)
    }

    render() {
        const { options } = this.props
        return (
            <div>
                <Wrapper>
                    <BasicLeadInfoView record={ this.state.record } />
                    <h1>กิจกรรม</h1>
                    <div className="mb-2 px-15 py-1">
                        <Card>
                            <div className="leadinfo-lazytable">
                                <LazyTable 
                                    columns={ this.columns }
                                    getValues={ this.getValues }
                                    filterOptions={ options }
                                    ref="listTable"
                                />
                            </div>
                        </Card>
                    </div>
                    <h1>แบบสำรวจ</h1>
                    <div className="mb-2 px-15 py-1">
                        <Card>
                        <div className="row px-15" style={{paddingTop: "1.5rem"}}>
                        {
                            this.state.questions.map((question, index) => {
                                return (
                                    <div className={`col-sm-${index + 3}`} key={`question-${index}`}>
                                        <h5>{`${index + 1}.`} {this.state.questions.length === 0 ? "" : this.state.questions[index].title}</h5>
                                        <br />
                                        {this.checkboxRender(question.answers, this.state.questions.length > 0 ? this.state.questions[index].question_id : '', this.state.answered)}
                                    </div>
                                )
                            })
                        }
                        </div>
                        </Card>
                    </div>
                    <h1>สื่อโครงการ</h1>
                    <div className="mb-2 px-15 py-1">
                        <Card>
                        <div className="row px-15" style={{paddingTop: "1.5rem"}}>
                            <div className="col-sm-6">
                                {this.renderSourceCheckbox(this.state.sources_list, this.state.answered_source.length > 0 ? this.state.answered_source : [])}
                            </div>
                        </div>
                        </Card>
                    </div>
                </Wrapper>
            </div>
        )
    }
}


