import React from 'react'

const Loading = () => {
    return (
        <div className="w-100 text-center hp-my-2">
            <img alt="" src="/img/loading.gif" style={{ width:'50px' }} />
        </div>
    )
}

export default Loading