import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class TextWithLabel extends Component {
    render() {
        const { children, className, label, color } = this.props
        return (
            <div className={className}>
                <p className="f13 grey m-0 mb-02">{ label }</p>
                <p className="mb-0" style={{ color: color }}>{ children }</p>
            </div>
        )
    }
}

TextWithLabel.propTypes = {
    label: PropTypes.string,
    color: PropTypes.string,
    // children: PropTypes.string,
}