import React,{PureComponent} from 'react';
import Wrapper from '../../layouts/Wrapper';
import { CreateCampaignForm } from '../../components/forms/CreateCampaignForm';

export default class CreateCampaignView extends PureComponent {

  componentWillMount() {
    this.props.setHeader('สร้างแคมเปญ')
  }

  goToCampaignList() {
    this.props.router.push('/campaign')
  }

  render() {
    return (
      <Wrapper>
        <div className="row">
          <div className="col-sm-12">
              <CreateCampaignForm
                ref="form"
              />
              <div className="d-flex flex-row my-3 justify-content-center">
                <button
                  onClick={() => this.goToCampaignList()}
                  className="m-05 btn btn-save-cancel btn-outline"
                  style={{padding: 0}}
                >
                    ยกเลิก
                </button>
                <button
                  onClick={() => {
                    this
                    .refs
                    .form.getWrappedInstance()
                    .save()
                    .then(this.goToCampaignList.bind(this))
                  }}
                  className="m-05 btn btn-save-cancel btn-outline"
                  style={{padding: 0}}
                >
                  บันทึก
                </button>
            </div>
          </div>
        </div>
      </Wrapper>
    )
  }
}