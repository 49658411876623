import { postRequest } from "../common/util";
import * as Request from '../common/customUtil';
import config from "../common/config";

export function getSummaryData() {
  return postRequest('/summary/product');
}

export function getAdsData(year) {
  const data = {
    year: year
  }
  return Request.getRequest(config.shoyuApiUrl, '/web/dashboard/advertisements', data);
}

export function getLeadSourceData(year) {
  const data = {
    year: year
  }
  return Request.getRequest(config.shoyuApiUrl, '/web/dashboard/lead_sources', data);
}

export function getInterestProductData(year) {
  const data = {
    year: year
  }
  return Request.getRequest(config.shoyuApiUrl, '/web/dashboard/interest_products', data);
}

export function getConcernData(year) {
  const data = {
    year: year
  }
  return Request.getRequest(config.shoyuApiUrl, '/web/dashboard/concerns', data);
}

export function getWalkAndBookingData(year) {
  const data = {
    year: year
  }
  return Request.getRequest(config.shoyuApiUrl, '/web/dashboard/walk_and_booking', data);
}

export function getTotalWalkData() {
  return Request.getRequest(config.shoyuApiUrl, '/web/dashboard/total_walk');
}

export function getTransferData(year) {
  const data = {
    year: year
  }
  return postRequest('/summary/transfer_product', data)
}

export function getSummaryConversion() {
  return postRequest('/summary/conversion');
}

export function getCostPerWalkData(campaignId) {
  let data = {}
  if (campaignId.toString() !== '0') {
    data = {
      campaign_id: campaignId
    }
  }

  return Request.getRequest(config.shoyuApiUrl, '/web/dashboard/advertisements/cost_and_walk', data);
}