import { getRequest,postRequest, putRequest, deleteRequest } from '../common/customUtil'
import * as Request from '../common/util'
import moment from 'moment'
import config from '../common/config'

export function createActivityToday(leadId,activityInfo){
    return postRequest(config.shoyuApiUrl,`/web/leads/${leadId}/activities`,convertActivityToday(activityInfo))
}

export function createActivityWithNextAction(leadId,activityInfo){
    return postRequest(config.shoyuApiUrl,`/web/leads/${leadId}/next_actions`,convertActivityWithNextAction(activityInfo))
}

export function createTestActivity(leadId,info){
    return Promise.resolve(convertActivityToday(info))
}

export function getActivities(offset,pageSize){
    const data = {
        offset: offset,
        pageSize: pageSize
    }
    return getRequest(config.shoyuApiUrl,`/web/schedule/today`,data)
            .then(res => {
                return { values: res.table_data_rows, length: res.total }
            })
            .catch(err => {
                console.error(`getActivities failed with ${err}`)
            })
}

export function getAllActivities(offset,pageSize, creator_id, period) {
    let data = {
        offset: offset,
        limit: pageSize,
        period: period
    }
    if(creator_id) {
        data = {
            ...data,
            creator_id: creator_id
        }
    }

    return getRequest(config.shoyuApiUrl,`/web/activity_reminders`, data)
            .then(res => {
                return { values: res.table_data_rows, length: res.total }
            })
            .catch(err => {
                console.error(`getActivities failed with ${err}`)
            })
}

export function getProducts(name){
    const data = {
        sku_code: name
    }
    return Request.getRequest(`/product/search`, data)
}

export function getProductSearch(name) {
    const data = {
        name: name
    }
    return getRequest(config.shoyuApiUrl, '/web/products/search', data)
}

export function getActivityChoices(){
    return getRequest(config.shoyuApiUrl,'/web/constant/activity')
}
export function getActivity(leadId,offset,pageSize){
    const data = {
        offset: offset,
        limit: pageSize
    }
    return getRequest(config.shoyuApiUrl,`/web/leads/${leadId}/activities`,data)
            .then(res => {
                return { values: res.table_data_rows, length: res.total }
            })
            .catch(err => {
                console.error(`getActivity failed with ${err}`)
            })
}

export function deleteActivity(leadId, activityId) {
    return deleteRequest(config.shoyuApiUrl, `/web/leads/${leadId}/activities/${activityId}`)
}

export function informActivity(activityId, data) {
    return putRequest(config.shoyuApiUrl, `/web/activity_reminder/${activityId}/informing`, data)
}

export function permitActivity(activityId) {
    return putRequest(config.shoyuApiUrl, `/web/activity_reminder/${activityId}/close`, {})
}

export function declineActivity(activityId, data) {
    return putRequest(config.shoyuApiUrl, `/web/activity_reminder/${activityId}/reject`, data)
}

export function updateActivity(activityId, data) {
    return postRequest(config.shoyuApiUrl, `/web/activity_reminders/${activityId}/close`, data)
}

export function updateSaveActivity(leadId, activityId, data) {
    return putRequest(config.shoyuApiUrl, `/web/leads/${leadId}/activities/${activityId}`, convertActivityToday(data))
}

export function totalActivity() {
    return getRequest(config.shoyuApiUrl, `/web/activity_reminders/today/total`)
}

function convertActivityToday(info) {
    return {
        activity: info.activity,
        concern: info.concern,
        remark: info.remark,
        interest: info.interest
    }
}

function convertActivityWithNextAction(info){
    let target_date = moment(info.target_date._d).format('YYYY-MM-DD HH:mm:ss')
    return {
        activity: info.activity,
        concern: info.concern,
        interest: info.interest,
        remark: info.remark,
        target_date: target_date,
    }
}