import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class CardTitle extends Component {
    render() {
        const { children, title, large, className, hasLine, onEditButton } = this.props
        const cardTitle = large ? <h2>{ title }</h2> : <h4>{title}</h4>
        const style = hasLine ? { borderBottom: "2px solid #E9E9E9" } : null

        return (
            <div className={`mb-15 d-flex justify-content-between ${className}`} style={style} >
                { cardTitle }
                { children }
                {
                    onEditButton ? 
                    <button
                        className="btn btn-outline"
                        onClick={() => onEditButton()}
                    >
                        แก้ไข
                    </button> :
                    null
                }
            </div>
        )
    }
}

CardTitle.propTypes = {
    enabled: PropTypes.bool
}
