import React, { Component } from 'react'
import { connect } from 'react-redux'

import { inModal } from '../../hoc/inModal'
import TextAreaInput from '../common/TextAreaInput'
import TextInput from '../common/TextInput'
import SelectInput from '../common/SelectInput'
import ModalTitle from '../common/ModalTitle'
import ModalFooter from '../common/ModalFooter'
import apiClient from '../../apis'

class ProductInfoForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                // TODO: Fix the default values once we know exact requirements.
                id: -1,
                projectId: 1,
                skuCode: "",
                standardPrice: 1,
                roomTypeId: 1,
                roomViewId: 1,
                message: "",
                addressNo: "",
                currentStatus: 1, 
                // created: "0001-01-01 00:00:00",
                creatorId: 1,

                standardDown: 1,
                standardBook: 1,
            }
        }

        this.save = this.save.bind(this)
    }

    save() {
        apiClient.product.createProduct(this.state.data)
            .then(res => {
                if (this.props.refreshFunc) {
                    this.props.refreshFunc()
                }

                this.props.closeModal()
            })
    }

    render() {
        const { closeModal, options } = this.props
        return (
            <div className="d-flex flex-column">
                <ModalTitle title="เพิ่มสินค้า" onClick={ () => closeModal() } />
                <div className="d-flex flex-column  justify-content-around" style={{ flexGrow: 1 }}>
                    <div className="row">
                        {/* <div className="col-sm-3">
                            <TextInput 
                                name="created" 
                                placeholder="วันที่" 
                                type="date" target={ this } 
                                stateKey="data" 
                                formatter={ v => moment(v).format('YYYY-MM-DD HH:mm:ss') } 
                            />
                        </div>

                        <div className="w-100 mb-4" /> */}
                        <div className="col-sm-6">
                            <TextInput 
                                name="skuCode" 
                                placeholder="รหัสสินค้า" 
                                target={ this } 
                                stateKey="data" 
                            />
                        </div>
                        <div className="col-sm-6">
                            <selectWrap>
                                <SelectInput
                                    name="roomTypeId"
                                    placeholder="ชนิดห้อง"
                                    target={ this }
                                    stateKey="data"
                                    options={ options.roomTypes }
                                />
                            </selectWrap>
                        </div>
                        <div className="w-100 mb-4" />
                        <div className="col-sm-6">
                            <TextInput 
                                name="standardPrice" 
                                placeholder="ราคา" 
                                type="number"
                                target={ this } 
                                stateKey="data" 
                            />
                        </div>

                        <div className="w-100 mb-4" />
                        <div className="col-sm-4">
                            <selectWrap>
                                <SelectInput
                                    name="roomSizeId"
                                    placeholder="ขนาดห้อง"
                                    target={ this }
                                    stateKey="data"
                                    options={ options.roomSizes }
                                />
                            </selectWrap>
                        </div>
                        <div className="col-sm-4">
                            <selectWrap>
                                <SelectInput
                                    name="roomLocationId"
                                    placeholder="ตำแหน่ง"
                                    target={ this }
                                    stateKey="data"
                                    options={ options.roomLocations }
                                />
                            </selectWrap>
                        </div>
                        <div className="col-sm-4">
                            <selectWrap>
                                <SelectInput
                                    name="roomViewId"
                                    placeholder="วิว"
                                    target={ this }
                                    stateKey="data"
                                    options={ options.roomViews }
                                />
                            </selectWrap>
                        </div>

                        <div className="w-100 mb-4" />
                        <div className="col-sm-6">
                            <TextAreaInput 
                                name="addressNo" 
                                placeholder="บ้านเลขที่" 
                                rows={ 4 } 
                                target={ this }
                                stateKey="data"
                            />
                        </div>
                        <div className="col-sm-6">
                            <TextAreaInput 
                                name="message" 
                                placeholder="หมายเหตุ" 
                                rows={ 4 } 
                                target={ this }
                                stateKey="data"
                            />
                        </div>
                        <div className="w-100 mb-4" />
                        <div className="col-sm-6">
                            <selectWrap>
                                <SelectInput
                                    name="currentStatus"
                                    placeholder="สถานะ"
                                    target={ this }
                                    stateKey="data"
                                    options={ options.productStatuses }
                                />
                            </selectWrap>
                        </div>
                        {/* <div className="col-sm-6">
                            <TextInput 
                                name="creatorId" 
                                placeholder="รหัสพนักงาน" 
                                type="number"
                                target={ this } 
                                stateKey="data" 
                            />
                        </div> */}

                    </div>
                </div>
                <ModalFooter>
                    <button onClick={ () => this.save() } className="btn btn-main ml-auto">บันทึก</button>
                </ModalFooter>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        options: state.other.options,
    }
}

ProductInfoForm = connect(mapStateToProps, null)(ProductInfoForm)

const ProductInfoModal = inModal(ProductInfoForm)
export default ProductInfoModal