import React, {Component} from 'react'
import {Link} from 'react-router'
import _ from 'lodash'
import withStyles from 'react-jss'
import classNames from 'classnames'
import uuidv4 from 'uuid/v4'
import {RadioGroup, Radio} from 'react-radio-group'
import {BounceLoader} from 'react-spinners'
import '@trendmicro/react-dropdown/dist/react-dropdown.css'
import {DropdownToggle, DropdownMenu, DropdownMenuWrapper, MenuItem, DropdownButton} from '@trendmicro/react-dropdown'

import {
  QUESTION_TYPE_SINGLE,
  QUESTION_TYPE_MULTI,
  QUESTION_TYPE_TEXT,
  QUESTION_TYPE_TEL,
  QUESTION_TYPE_EMAIL,
  SURVEY_STATUS_ACTIVE,
  SURVEY_STATUS_DRAFT
} from '../../../common/const'
import Wrapper from '../../../layouts/Wrapper'
import datastore from '../../../datastore'
import TextWithLabel from '../../../components/common/TextWithLabel'
import TextInput from '../../../components/common/TextInput'
import FileInput from '../../../components/common/FileInput'
import TextAreaInput from '../../../components/common/TextAreaInput'
import styles from './styles'
import ChoiceQuestion from './questionTypeChoice'
import TextQuestion from './questionTypeText'

class SurveyFormView extends Component {
  state = {
    survey: {
      title: '',
      alias: ''
    },
    questions: [],
    activeViewIndex: 0
  }
  constructor(props) {
    super(props)

    this.renderForm = this
      .renderForm
      .bind(this)
    this.onAddQuestionClick = this
      .onAddQuestionClick
      .bind(this)
  }

  componentWillMount() {
    const surveyId = this.props.params.surveyId

    if (surveyId === 'create') {
      this
        .props
        .setHeader('แบบสำรวจใหม่')
      return
    }

    datastore
      .survey
      .getSurveyInfo(surveyId)
      .then(survey => {

        let title = survey.title
        if(survey.status !== SURVEY_STATUS_ACTIVE) {
          title = <span>{title} <i className={this.props.classes.small}>(ยังไม่เผยแพร่)</i></span>
        }
        this
          .props
          .setHeader(title)
        this.setState({
          survey,
          questions: survey.questions || []
        })
      })
  }

  changeView = index => {
    this.setState({activeViewIndex: index})
  }

  parseAlias = text => String(text).replace(/[\s\W]+/ig, '-')

  updateRecord = record => {
    this.setState({record: record})
  }

  onFileChange = e => {
    _.forEach(e.currentTarget.files, file => {
      this.setState(state => ({
        survey: {
          ...state.survey,
          coverFile: file,
          coverUrl: URL.createObjectURL(file)
        }
      }))
    })
  }

  onAddQuestionClick = type => e => {
    this.setState(state => {
      state
        .questions
        .push({id: uuidv4(), questionType: type, choices: [], order: state.questions.length})
      return {questions: state.questions}
    })
  }

  onRemoveQuestionClick = question => e => {
    this.setState(state => ({
      questions: _.filter(state.questions, q => q.id !== question.id)
    }))
  }

  onSaveClick = async e => {
    this.setState({processing: true})
    const {survey, questions} = this.state
    let uploadResult

    const payload = {
      ...survey,
      alias: this.parseAlias(survey.alias || survey.title),
      questions: this.state.questions
    }

    if (survey.coverFile) {
      uploadResult = await datastore
        .other
        .uploadImage(survey.coverFile)

      console.log(uploadResult)
      if (uploadResult) {
        payload.coverUrl = uploadResult.file_url
      }
    }

    const saveResult = await datastore
      .survey
      .saveSurvey(payload)

    this.setState({processing: false})
    this
      .props
      .router
      .push('/survey-list/')
  }

  onCancelClick = e => {
    this
      .props
      .router
      .push('/survey-list/')
  }

  onPublishClick = async e => {
    this.setState({processing: true})
    const publishResult = await datastore
      .survey
      .publishSurvey(this.state.survey.id)
    this.setState({processing: false})
  }

  onUnpublishClick = async e => {
    this.setState({processing: true})
    const publishResult = await datastore
      .survey
      .unpublishSurvey(this.state.survey.id)
    this.setState({processing: false})
  }

  onQuestionChange = index => value => {
    this.setState(state => {
      state.questions[index] = value

      return state
    })
  }

  onCoverClick = e => {
    if (this.coverFileInput) {
      this
        .coverFileInput
        .click()
    }
  }

  renderQuestions = () => {
    const {questions} = this.state
    const {classes} = this.props

    const renderedQuestions = _.map(questions, (question, i) => ([QUESTION_TYPE_SINGLE, QUESTION_TYPE_MULTI].indexOf(question.questionType) !== -1
      ? <ChoiceQuestion key={i} value={question} onChange={this.onQuestionChange(i)}/>
      : <TextQuestion key={i} value={question} onChange={this.onQuestionChange(i)}/>))

    const renderedPlusIcon = <i className="fa fa-plus"></i>

    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="flex">
            <h2 className={classes.questionSectionTitle}>
              <span>คำถาม</span>
              <span>
                <DropdownButton
                  className={classNames({"btn": true})}
                  btnSize="xs"
                  title={renderedPlusIcon}
                  onSelect={type => this.onAddQuestionClick(type)()}>
                  <MenuItem eventKey={QUESTION_TYPE_SINGLE}>แบบคำตอบเดียว</MenuItem>
                  <MenuItem eventKey={QUESTION_TYPE_MULTI}>แบบหลายคำตอบ</MenuItem>
                  <MenuItem eventKey={QUESTION_TYPE_TEXT}>แบบกล่องข้อความ</MenuItem>
                </DropdownButton>
              </span>
            </h2>

          </div>

          <div className="row">
            <div className="col-sm-12">{renderedQuestions}</div>
          </div>
        </div>
      </div>
    )
  }

  renderForm = () => {
    const {classes} = this.props
    const {survey} = this.state
    const renderedCover = survey.coverUrl
      ? <img
          src={survey.coverUrl}
          style={{
          width: '100%',
          maxWidth: '900px'
        }}/>
      : <span>เลือกภาพ Cover</span>

    return (
      <div>
        <div className="row">
          <div className="col-sm-12">
            <TextWithLabel label="รูปหน้าปก">
              <a href="#" onClick={this.onCoverClick} className={classes.cover}>
                {renderedCover}
              </a>
              <input
                type="file"
                name="name"
                id="file"
                onChange={e => this.onFileChange(e)}
                ref={el => this.coverFileInput = el}
                style={{
                display: 'none'
              }}/>
            </TextWithLabel>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <p>
              <TextWithLabel label="หัวข้อแบบสำรวจ">
                <TextInput
                  name="title"
                  placeholder="หัวข้อแบบสำรวจ"
                  target={this}
                  stateKey="survey"
                  renderValue={survey.title}/>
              </TextWithLabel>
            </p>
            <p>
              <TextWithLabel label="ชื่อย่อ URL">
                <TextInput
                  name="alias"
                  placeholder="ชื่อย่อ URL"
                  target={this}
                  stateKey="survey"
                  renderValue={survey.alias || this.parseAlias(survey.title)}/>
              </TextWithLabel>
            </p>
            <p>
              <TextWithLabel label="รายละเอียด">
                <TextAreaInput
                  name="description"
                  placeholder="รายละเอียด"
                  target={this}
                  stateKey="survey"
                  renderValue={survey.description}/>
              </TextWithLabel>
            </p>
          </div>
        </div>
      </div>
    )
  }

  renderProcessing = () => (
    <div className={this.props.classes.overlay}>
      <div>
        <BounceLoader color="#00ACE1"/>
        <p>working...</p>
      </div>
    </div>
  )

  render() {
    const {classes} = this.props
    const {processing, survey} = this.state
    const renderedProcessing = this.renderProcessing()
    const renderedSavePublishBtn = (
      <button
        onClick={e => this.setState({
        survey: {
          ...survey,
          status: SURVEY_STATUS_ACTIVE
        }
      }, () => this.onPublishClick(e))}
        className="m-05 py-1 btn btn-outline">
        เผยแพร่
      </button>
    )
    const renderedUnpublishBtn = (
      <button
        onClick={e => this.setState({
        survey: {
          ...survey,
          status: SURVEY_STATUS_DRAFT
        }
      }, () => this.onUnpublishClick(e))}
        className="m-05 py-1 btn btn-outline">
        ยกเลิกการเผยแพร่
      </button>
    )

    return (
      <div>
        <TabBar
          activeViewIndex={this.state.activeViewIndex}
          changeView={this.changeView}/>
        <Wrapper className={classes.wrapper}>
          {this.renderForm()}
          {this.renderQuestions()}
          <div className={classes.command}>
            <div className={classes.commandInner}>
              <button
                onClick={this.onCancelClick}
                className="m-05 py-1 btn btn-outline btn-red">
                ยกเลิก
              </button>
              <button onClick={this.onSaveClick} className="m-05 py-1 btn btn-outline">
                บันทึก
              </button>
              {survey.status === SURVEY_STATUS_ACTIVE
                ? renderedUnpublishBtn
                : renderedSavePublishBtn}
            </div>
          </div>
          {processing
            ? renderedProcessing
            : null}

        </Wrapper>

      </div>
    )
  }
}

const TabBar = ({activeViewIndex, changeView}) => {
  const columnClassName = "text-center text-none py-1 mr-02 w-15"
  return (
    <div
      className="d-flex flex-row w-100 pl-1"
      style={{
      height: '',
      boxShadow: "2px 2px 4px 0 rgba(0,0,0,0.25)"
    }}>
      <Link
        className={`${columnClassName} ${isActive(0, activeViewIndex)}`}
        onClick={() => changeView(0)}>ข้อมูลทั่วไป</Link>
    </div>
  )
}

const ActiveView = ({index, record, updateRecord}) => {
  return null
}

const isActive = (index, activeIndex) => {
  return index === activeIndex
    ? 'tab-active'
    : 'tab-inactive'

}

export default withStyles(styles)(SurveyFormView)
