export const releaseNote = [
  {
    version: 'Version 1.8.15',
    date: '2022-03-08',
    feature: [],
    updated: [],
    fixed: [
      'แก้ไขปัญหา การ์ด เลือกโครงการแสดงผลไม่ครบถ้วน'
    ],
  },
  {
    version: 'Version 1.8.14',
    date: '2021-08-23',
    feature: [],
    updated: [],
    fixed: [
      'แก้ไขปัญหา ลายเซ็น ขึ้นไม่ครบถ้วน'
    ],
  },
  {
    version: 'Version 1.8.13',
    date: '2021-08-09',
    feature: [],
    updated: [],
    fixed: [
      'แก้ไขปัญหา รายการเอกสาร ขึ้นไม่ครบถ้วน'
    ],
  },
  {
    version: 'Version 1.8.12',
    date: '2021-07-14',
    feature: [],
    updated: [],
    fixed: [
      'แก้ไขปัญหา เพศ ของข้อมูลผู้ทำสัญญาแสดงไม่ถูกต้อง'
    ],
  },
  {
    version: 'Version 1.8.11',
    date: '2021-03-15',
    feature: [],
    updated: [],
    fixed: [
      'แก้ไขปัญหา ลายเซ็น ไม่ขึ้นในเอกสารแจ้งข้อบกพร่อง และเอกสารรับมอบงานแก้ไขข้อบกพร่อง'
    ],
  },
  {
    version: 'Version 1.8.10',
    date: '2021-02-17',
    feature: [],
    updated: [],
    fixed: [
      'แก้ไขปัญหา รายการข้อบกพร่อง ไม่แสดงเวลากดมอบหมายงาน'
    ],
  },
  {
    version: 'Version 1.8.9',
    date: '2020-08-25',
    feature: [],
    updated: [
      'ปรับปรุงการค้นหาผู้สนใจใน หน้าจอขาย ด้วยวันที่ หากเปลี่ยนไปที่หน้าอื่นแล้ว การค้นหาวันที่เดิมจะยังคงอยู่'
    ],
    fixed: [],
  },
  {
    version: 'Version 1.8.7',
    date: '2020-08-24',
    feature: [],
    updated: [
      'ปรับเปลี่ยนข้อมูลที่ว่างของ ผู้สนใจ ให้เป็น ไม่ระบุ'
    ],
    fixed: [
      'แก้ไขเมื่อกดแก้ไขข้อมูลผู้สนใจแล้ว ตัวเลือกข้อมูลรายได้เป็น ไม่ระบุ'
    ],
  },
  {
    version: 'Version 1.8.6',
    date: '2020-08-17',
    feature: [],
    updated: [],
    fixed: [
      'แก้ไขเวลาค้นหาชื่อผู้สนใจแล้ว นามสกุลเป็น undefined'
    ],
  },
  {
    version: 'Version 1.8.5',
    date: '2020-07-24',
    feature: [],
    updated: [],
    fixed: [
      'แก้ไขหน้า กิจกรรม เวลาเปลี่ยนจำนวนรายการ / หน้า แล้วข้อมูลแสดงไม่ครบ'
    ],
  },
  {
    version: 'Version 1.8.4',
    date: '2020-07-23',
    feature: [],
    updated: [],
    fixed: [
      'แก้ไขหน้า หน้่าจอขาย เวลาดูประวัติผู้สนใจแล้ว Pagination จะถูกรีเซ็ตกลับมาหน้าแรก'
    ],
  },
  {
    version: 'Version 1.8.3',
    date: '2020-07-22',
    feature: [],
    updated: [],
    fixed: [
      'แก้ไขหน้า พิมพ์เอกสารรับเรื่องแจ้งซ่อม และ พิมพ์เอกสารสรุปการแก้ไข เวลาปริ้นแล้วตกขอบ'
    ],
  },
  {
    version: 'Version 1.8.2',
    date: '2020-07-20',
    feature: [],
    updated: [],
    fixed: [
      'แก้ไขหน้า พิมพ์เอกสารรับเรื่องแจ้งซ่อม แสดงข้อมูลแจ้งซ่อมไม่ตรงตามวันที่เลือก'
    ],
  },
  {
    version: 'Version 1.8.1',
    date: '2020-07-03',
    feature: [],
    updated: [
     'ปรับการแสดงผลของหน้า ตารางงาน'
    ],
    fixed: [],
  },
  {
    version: 'Version 1.8.0',
    date: '2020-07-02',
    feature: [
      'เพิ่มเมนู จัดการผู้ใช้งาน',
      'เพิ่มฟังก์ชั่นเพิ่มรายการสินค้า',
      'เพิ่มฟังก์ชั่นแก้ไขรายการสินค้า',
      'เพิ่มฟังก์ชั่นลบแคมเปญ',
      'เพิ่มฟังก์ชั่นลบโปรโมชั่น',
      'เพิ่มฟังก์ชั่นลบบันทึกการขาย',
      'เพิ่มฟังก์ชั่นแก้ไขข้อมูลกิจกรรม'
    ],
    updated: [
      'ปรับรูปแบบของวันที่ของระบบเป็น วัน/เดือน/ปี',
      'เอาปุ่ม พิมพ์ใบแจ้งรายการรับประกัน ออกในหน้ารับประกันสินค้า',
      'ปรับสีปุ่ม เมื่อมีงานของ Sale ค้างอยู่',
      'เอาลายเซ็นในเอกสารสรุปการแก้ไขออก',
      'ปรับเพิ่มปุ่มลบผู้สนใจในหน้ารายละเอียดผู้สนใจซื้อ',
      'เอารูปหลังการแก้ไขออกจากเอกสารรับเรื่องแจ้งซ่อม'
    ],
    fixed: [
      'แก้ไขปัญหาค้นหาชื่อผู้สนใจซื้อด้วยการ Copy & Paste ไม่ได้',
      'แก้ไขปัญหาปริ้นเอกสารแล้วตกขอบ'
    ],
  },
  {
    version: 'Version 1.7.0',
    date: '2020-02-21',
    feature: [
      'เพิ่มส่วนแสดง Conversion Rate ในภาพรวมโครงการแบบใหม่',
      'เพิ่มฟังก์ชั่นการแกไขวันที่มอบหมายงาน'
    ],
    updated: [],
    fixed: [
      'แก้ไขให้หน้าพิมพ์เอกสารสรุปการแก้ไข แสดงเฉพาะรายการซ่อมที่แก้ไขแล้ว',
      'แก้ไข Format ของวันที่ในหน้ารายละเอียดของLead'
    ],
  },
  {
    version: 'Version 1.6.0',
    date: '2019-12-25',
    feature: [
      'ภาพรวมโครงการแบบใหม่ ที่ช่วยเพิ่มประสิทธิภาพในการติดตามโครงการได้ดียิ่งขึ้น',
    ],
    updated: [
      'ปรับปรุงการใช้งานในส่วนของกิจกรรมของเซลล์',
    ],
    fixed: [],
  },
  {
    version: 'Version 1.5.0',
    date: '2019-09-27',
    feature: [
      'เพิ่มเมนู หน้าจอขาย',
      'เพิ่มหน้า ดูรายการสินค้า สำหรับหน้าจอขาย'
    ],
    updated: [
      'เรียงลำดับแทบเมนูใหม่',
      'ปรับฟอร์มการเพิ่มผู้สนใจใหม่'
    ],
    fixed: [],
  },
  {
    version: 'Version 1.4.7',
    date: '2019-07-25',
    feature: [],
    updated: [],
    fixed: [
      'แก้ไขข้อมูลบ้านเลขที่ไม่แสดงในหน้าพิมพ์เอกสารรับแจ้งซ่อม',
      'แก้ไขข้อมูลบ้านเลขที่ไม่แสดงในหน้าพิมพ์เอกสารสรุปการแก้ไข'
    ],
  },
  {
    version: 'Version 1.4.6',
    date: '2019-07-24',
    feature: [],
    updated: [],
    fixed: [
      'แก้ไขข้อมูลรหัสสินค้าในหน้าเพิ่มรายการข้อบกพร่องไม่แสดง'
    ],
  },
  {
    version: 'Version 1.4.5',
    date: '2019-06-12',
    feature: [],
    updated: [],
    fixed: [
      'แก้ไขข้อมูลรหัสสินค้าในหน้ารายละเอียดสินค้าไม่ขึ้น',
      'แก้ไขข้อมูลชื่อรหัสสินค้าหน้าพิมพ์เอกสารตรวจคุณภาพไม่ขึ้น'
    ],
  },
  {
    version: 'Version 1.4.4',
    date: '2019-05-31',
    feature: [],
    updated: [
      'เพิ่มช่อง ห้องที่สนใจ ในหน้าสร้างผู้สนใจ'
    ],
    fixed: [],
  },
  {
    version: 'Version 1.4.3',
    date: '2019-04-01',
    feature: [],
    updated: [],
    fixed: [
      'แก้ไขหน้าพิมพ์เอกสารรับเรื่องแจ้งซ่อม และ พิมพ์เอกสารสรุปการแก้ไขแสดงผลทับกัน'
    ],
  },
  {
    version: 'Version 1.4.2',
    date: '2019-03-25',
    feature: [],
    updated: [
      'ปรับตัวเลือกของตำแหน่งห้องเวลาสร้างรายการข้อบกพร่องเป็นภาษาไทย',
      'ปรับการพิมพ์เอกสารการแจ้งซ่อมและเอกสารสรุปการแก้ไขให้มีรายละเอียดของการซ่อมด้วย'
    ],
    fixed: [],
  },
  {
    version: 'Version 1.4.1',
    date: '2019-03-22',
    feature: [],
    updated: [
      'เพิ่มเติม วันที่ทำสัญญา และ วันที่โอน ในบันทึกการขาย',
    ],
    fixed: [
      'แก้ไขรูปลายเซ็นหายเวลาพิมพ์เอกสารการแจ้งซ่อม และพิมพ์เอกสารสรุปการแก้ไข',
    ],
  },
  {
    version: 'Version 1.4.0',
    date: '2019-03-22',
    feature: [
      'เพิ่มการสร้าง/แก้ไขโปรโมชั่นในส่วนของแคมเปญ'
    ],
    updated: [
      'สามารถขอข้อมูลเพิ่มเติมสำหรับกิจกรรมก่อนทำการอนุมัติได้',
      'เพิ่มเติมสถานะในบันทึกการขาย'
    ],
    fixed: [
      'แก้ไขถ้าไม่มีที่อยู่ของผู้ทำสัญญา จะไม่สามารถแก้ไขข้อมูลผู้ทำสัญญาได้',
      'แก้ไขสถานะกิจกรรม หากเหลือสามวันแล้วสถานะเป็นสีแดง'
    ],
  },
  {
    version: 'Version 1.3.0',
    date: '2019-03-18',
    feature: [
      'สามารถพิมพ์เอกสารสรุปการแก้ไข และ เอกสารรับเรื่องแจ้งซ่อมได้',
      'เปลี่ยนแปลงเมนูกิจกรรมใหม่'
    ],
    updated: [
      'ปฏิทินในหน้ามอบหมายงาน สามารถกดดูรายละเอียดของงานที่มอบหมายแล้วได้',
      'เพิ่มวันที่สร้างและผู้สร้างในหน้าผู้สนใจซื้อ',
      'เพิ่มวันที่จองในหน้าบันทึกการขายและรายละเอียดของบันทึกการขาย',
      'เพิ่มปุ่มเปลี่ยนสถานะการขายในหน้าข้อมูลบันทึกการขาย'
    ],
    fixed: [],
  },
  {
    version: 'Version 1.2.1',
    date: '2019-03-01',
    feature: [],
    updated: [
      'สามารถแก้ไขข้อมูลสื่อโฆษณาของผู้สนใจซื้อได้',
      'เพิ่มช่องแสดงรายละเอียดของสื่อโฆษณาที่ผู้สนใจเลือกไปแล้ว',
    ],
    fixed: [
      'แก้ไขเวลากดสร้างผู้สนใจแล้วไม่มีแจ้งเตือนว่าสร้างผู้สนใจสำเร็จหรือไม่'
    ],
  },
  {
    version: 'Version 1.2.0',
    date: '2019-02-25',
    feature: [
      'เพิ่มการสร้างแคมเปญและสื่อโฆษณา',
      'เปลี่ยนการจองห้องโดยการค้นหาชื่อลูกค้า เป็นค้นหาจากผู้สนใจซื้อ'
    ],
    updated: [
      'สามารถค้นหาผู้สนใจซื้อจากเมนูผู้สนใจซื้อได้',
      'เพิ่มการเลือกสื่อในหน้าเพิ่มผู้สนใจ ให้สามารถเลือกสื่อที่สร้างจากแคมเปญได้',
      'เพิ่มปุ่ม บันทึกและจองห้อง ในหน้าเพิ่มผู้สนใจ',
      'เพิ่มการใส่CommaในFieldราคาให้อัตโนมิติ'
    ],
    fixed: [],
  },
  {
    version: 'Version 1.1.0',
    date: '2019-02-04',
    feature: [],
    updated: [
      'เพิ่มสถานะการขาย ปกติ/ยกเลิก ในรายการบันทึกการขาย',
      'ปรับการเรียงข้อมูลรายการรับประกันสินค้า ให้เรียงจากวันที่ถูกสร้างขึ้นมาก่อน',
      'ปรับการเรียงข้อมูลรายการสินค้า ให้ค่าเริ่มต้นเรียงจากน้อย - มาก',
      'เพิ่มปุ่มมอบหมายงาน ในหน้าต่างรายละเอียดข้อบกพร่อง'
    ],
    fixed: [
      'แก้ไขการค้นหาแล้วติดข้อมูลจากโครงการอื่นมาด้วย',
    ],
  },
  {
    version: 'Version 1.0.0',
    date: '2019-01-11',
    feature: [
      'ปรับ Visual ของ Application ทั้งWeb App และMobile Appเป็นสีน้ำเงิน',
      'สร้าง Project ใหม่ สำหรับบริษัทใหม่ที่จะมาใช้ระบบ',
      'Export ข้อมูลรายการสินค้า บันทึกการขาย ลูกค้า ผู้สนใจซื้อ และ การรับประกัน เป็นไฟล์ CSV',
      'เพิ่มเมนู Dashboard บน Side Menu',
      'Import ข้อมูลสินค้า รายการสินค้า ผู้สนใจซื้อ และลูกค้า จากไฟล์ CSV'

    ],
    updated: [
      'แก้ไขข้อมูลผู้ทำสัญญาได้',
      'Customize คำถามและตัวเลือกของผู้สนใจซื้อ',
      'เพิ่มหน้าสำหรับการเพิ่มเอกสารที่เกี่ยวข้อง',
      'ซ่อนปุ่ม สรุปการแก้ไข เมื่อไม่มีจุดแก้ไขเพิ่มเพิ่มในหน้า Quality Control',
      'ปรับให้แสดง ชื่อ และ ตำแหน่ง ตาม Account ของ User',
      'เพิ่มสถานะของ Quality Control ในหน้าข้อมูลสินค้า'
    ],
    fixed: [
      'กดดูข้อมูลของลูกค้าที่จองห้องผ่านทางหน้าผู้สนใจซื้อแล้วดูข้อมูลไม่ได้ ในหน้าบันทึกการขาย',
      'ข้อมูลที่อยู่ปัจจุบันในหน้าข้อมูลผู้ทำสัญญาขึ้นว่า ไม่ระบุ',
      'Sort ข้อมูลในหน้า List ต่างๆแล้วเรียงข้อมูลไม่ถูกต้อง',
      `กด "พิมพ์ใบแจ้งรายการรับประกันสินค้า" ในหน้ารับประกันสินค้า แล้วไม่แสดงผลลัพธ์`,
      'สถานะการซ่อมในรายละเอียด Inspection และ Warranty ขึ้นไม่ตรงกับสถานะจริง',
      'ตัวเลือกเพิ่มธนาคารในหน้าเอกสารขอกู้มีชื่อธนาคารซ้ำกัน',
      'แก้ไขการมอบหมายงานการรับประกันสินค้าให้ถูกต้องในกรณีที่มีการมอบหมายงานให้ช่าง ในลำดับวันที่ต่างกัน'
    ],
  }
];
