import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import Wrapper from '../../../layouts/Wrapper'
import Table from '../../../components/common/tables/Table'
import Card from '../../../components/common/Card'
import datastore from '../../../datastore'
import LeadInfoModal from './leadInfoModal'

const PERIOD = {
    DAY: 0,
    WEEK: 1,
    MONTH: 2
}

const MONTHS = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 }
]

const FILTER = {
    WALK: 1,
    CALL_IN: 2,
    CALL_OUT: 3,
    REVISIT: 4,
    PROSPECT: 5,
    QUALIFIED: 6,
    BOTH: 7,
    BOOKED: 8,
    CANCELED: 9,
    ONLINE: 10,
    LINE: 11,
    CONTRACT: 12
}

const FILTER_ICON_MAP = {
    [FILTER.WALK]: 'fa-male',
    [FILTER.CALL_IN]: 'fa-phone',
    [FILTER.CALL_OUT]: 'fa-phone-square',
    [FILTER.REVISIT]: 'fa-undo',
    [FILTER.BOOKED]: 'fa-check-circle',
    [FILTER.CANCELED]: 'fa-times-circle',
    [FILTER.ONLINE]: 'fa-globe',
    [FILTER.LINE]: 'fa-mobile',
    [FILTER.CONTRACT]: 'fa-file-text'
}

export function getActivityIcon(activity) {
    switch (activity) {
        case 'Walk':
            return FILTER_ICON_MAP[FILTER.WALK]
        case 'Call in':
            return FILTER_ICON_MAP[FILTER.CALL_IN]
        case 'Call out':
            return FILTER_ICON_MAP[FILTER.CALL_OUT]
        case 'Revisit':
            return FILTER_ICON_MAP[FILTER.REVISIT]
        case 'Booking':
            return FILTER_ICON_MAP[FILTER.BOOKED]
        case 'Canceled':
            return FILTER_ICON_MAP[FILTER.CANCELED]
        case 'Online registration':
            return FILTER_ICON_MAP[FILTER.ONLINE]
        case 'Line':
            return FILTER_ICON_MAP[FILTER.LINE]
        case 'Contract':
            return FILTER_ICON_MAP[FILTER.CONTRACT]
        default:
            return ''
    }
}

class LeadListsView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            query: '',
            searchKey: 'first_name',
            summary: {},
            customers: [],
            filter: null,
            period: 2,
            targetMonth: 3
        }
        this.columns = [
            //{
                //header: 'Prospect',
                //unSortable: true,
                //accessor: 'prospect',
                //render: value => {
                    //return (
                        //<div style={{textAlign: "center",width: "40%"}}>
                            //<img src={this.renderProspect(value)} alt="prospect" />
                        //</div>
                    //)
                //}
            //},
            {
                header: 'ชื่อผู้สนใจ',
                accessor: 'first_name',
                subaccessor: ['last_name', 'phone_number'],
                width: 1,
                render: (firstname, [lastname, phone]) => {
                    return (
                        <div 
                            //style={{ height: '100%' }} 
                            className="d-flex flex-row align-items-center"
                        >
                            <div>
                                <div>{`${firstname} ${lastname}`}</div> 
                                <div>{`${phone}`}</div>
                            </div>
                        </div>
                    )
                }
            },
            {
                header: 'Activities',
                accessor: 'activities',
                unSortable: true,
                width: 3,
                render: activities => {
                    return <div>
                        {activities.map((a, idx) => {
                            const icon = getActivityIcon(a.activity)
                            return <div key={idx} className="lead-activity-card">
                                <div className="d-flex flex-row align-items-center">
                                    <i 
                                        className={`fa ${icon} grey`}  
                                        style={{ fontSize: '36px', padding: '12px' }}
                                    />
                                </div>
                                <div>
                                    <div>
                                        {`วันที่: ${moment(a.created).format('YYYY-MM-DD')}, เซลล์: ${a.sale_full_name}`}
                                    </div>
                                    <div>{`สินค้า: ${a.interest}`}</div>
                                    <div className="d-flex">
                                      <div className="mr-05">{`เซลล์โน้ต:`}</div> 
                                      <div>{a.concern && `- ${a.concern}`}<br />{a.remark && `- ${a.remark}`}</div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                }
            },
            {
                header: '',
                accessor: 'id',
                subaccessor: 'first_name',
                unSortable: true,
                width: 0.75,
                render: (customerId, x, y, customer) => {
                    return (
                        <div 
                            className="d-flex flex-row align-items-center"
                            style={{ justifyContent: 'space-evenly' }}
                        >
                            <i 
                                className={`fa fa-file-text-o lead-action-icon`} 
                                onClick={e => { 
                                    e.preventDefault()
                                    e.stopPropagation()
                                    this.showCustomerInfo(customer)
                                }}
                            />
                            <i 
                                className={`fa fa-play-circle-o lead-action-icon`} 
                                onClick={e => { 
                                    e.preventDefault()
                                    e.stopPropagation()
                                    this.props.router.push(`/sale-mode/leads/${customerId}/add-activity`)
                                }}
                            />
                            <i 
                                className={`fa fa-pencil lead-action-icon`} 
                                onClick={e => { 
                                    e.preventDefault()
                                    e.stopPropagation()
                                    this.props.router.push(`/sale-mode/leads/${customerId}/book`)
                                }}
                            />
                        </div>
                    )
                }
            },
        ]
    }

    componentWillMount() {
        this.props.setHeader('รายชื่อผู้สนใจ')
        this.updateLeadInfo()
    }

    showCustomerInfo = selectedCustomer => {
        this.setState({ selectedCustomer }, this.refs.modal.openModal)
    }

    updateLeadInfo = () => {
        const { profile } = this.props
        const { period, targetMonth } = this.state
        const projectId = profile.current_project.id.toString()
        this.setState({ summary: {}, customers: [] }, () => {
            datastore.lead.getLeadSummary(projectId, period, targetMonth)
                .then(summary => {
                    this.setState({ summary })
                })

            datastore.lead.getLeadActivities(projectId, period, targetMonth)
                .then(activities => {
                    this.setState({ customers: activities.customers })
                })
        })
    }

    goToAddLead = () => {
        this.props.router.push('/lead-v2/add-lead')
    }

    goToAddActivity = () => {
        this.props.router.push('/lead-v2/reserve/search')
    }

    goToAddActivityWithLeadId = leadId => {
        this.props.router.push(`/lead-v2/${leadId}/add-activities`)
    }

    goToReserve = () => {
        this.props.router.push('/reserve/search')
    }

    goToProductList = () => {
        this.props.router.push('/sale-mode/products-list')
    }

    renderProspect(value){
        let src = ""
        if(value === true){
            return src="/img/Active.svg"
        }
        else{
            return src="/img/Default.svg"
        }
    }

    filterCustomersByActivity(activity) {
        const { customers } = this.state    
        return customers
            .filter(c => {
                return c.activities.some(a => a.activity === activity)
            })
            .map(c => ({
                ...c,
                activities: c.activities.filter(a => a.activity === activity)
            }))
    }

    filterCustomers() {
        const { customers, filter } = this.state
        switch (filter) {
            case FILTER.WALK:
                return this.filterCustomersByActivity('Walk')
            case FILTER.CALL_IN:
                return this.filterCustomersByActivity('Call in')
            case FILTER.CALL_OUT:
                return this.filterCustomersByActivity('Call out')
            case FILTER.REVISIT:
                return this.filterCustomersByActivity('Revisit')
            case FILTER.BOOKED:
                return this.filterCustomersByActivity('Reserved')
            case FILTER.CANCELED:
                return this.filterCustomersByActivity('Canceled')
            case FILTER.PROSPECT:
                return customers.filter(c => c.prospect === 't')
            case FILTER.QUALIFIED:
                return customers.filter(c => c.qualified === 't')
            case FILTER.BOTH:
                return customers.filter(c => c.prospect === 't' && c.qualified === 't')
            default:
                return customers
        }
    }

    selectPeriod(period) {
        this.setState({ period }, this.updateLeadInfo)
    }

    setFilter(filter) {
        if (this.state.filter === filter) {
            this.setState({ filter: null })
        } else {
            this.setState({ filter })
        }
    }

    render() {
        const { options, openProjectModal, profile } = this.props
        const { summary, customers, filter, period, selectedCustomer } = this.state

        const filtered = this.filterCustomers()
        return (
            <Wrapper className="d-flex flex-column flex-xl-row">
                <Card className="lead-filter-panel mr-1">
                    <div 
                        className="pb-1 mb-1" 
                        style={{ borderBottom: '1px solid #ccc' }}
                    >
                        <div className="d-flex flex-row align-items-center justify-content-between mb-15">
                            <div 
                                className="btn btn-main d-flex flex-row align-items-center justify-content-between" 
                                onClick={openProjectModal}
                                style={{ maxWidth: '70%' }}
                            >
                                <div style={{ flexGrow: 1, overflow: 'hidden' }}>{profile.current_project.name}</div>
                                <i 
                                    className={`fa fa-chevron-down white ml-05`} 
                                    style={{ fontSize: "12px" }} 
                                />
                            </div>
                            <i 
                                className={`fa fa-refresh grey`} 
                                style={{ fontSize: "24px", cursor: 'pointer' }} 
                                onClick={this.updateLeadInfo}
                            />
                        </div>
                        <div className="d-flex flex-row">
                            <PeriodTile
                                label="D" 
                                onClick={() => this.selectPeriod(PERIOD.DAY)}
                                selected={period === PERIOD.DAY}
                            />
                            <PeriodTile
                                label="W" 
                                onClick={() => this.selectPeriod(PERIOD.WEEK)}
                                selected={period === PERIOD.WEEK}
                            />
                            <PeriodTile
                                label="M" 
                                onClick={() => this.selectPeriod(PERIOD.MONTH)}
                                selected={period === PERIOD.MONTH}
                            />
                            <selectWrap className="">
                                <select 
                                    className="select-outline" 
                                    disabled={period !== PERIOD.MONTH}
                                    onChange={e => this.setState({ 
                                        targetMonth: e.target.value
                                    }, this.updateLeadInfo)}
                                >
                                    { MONTHS.map(m => {
                                        return <option key={m.value} value={m.value}>
                                            {m.label}
                                        </option>
                                    })}
                                </select>
                            </selectWrap>
                        </div>
                    </div>
                    <div className="lead-filter-panel-section mb-1 pb-1">
                        <FilterTile 
                            label="Walk" 
                            icon="fa fa-male" 
                            count={summary.walk}
                            onClick={() => this.setFilter(FILTER.WALK)}
                            selected={filter === FILTER.WALK}
                        />
                        <FilterTile 
                            label="Call-In" 
                            icon="fa fa-phone" 
                            count={summary.call_in}
                            onClick={() => this.setFilter(FILTER.CALL_IN)}
                            selected={filter === FILTER.CALL_IN}
                        />
                        <FilterTile 
                            label="Call-Out" 
                            icon="fa fa-phone-square" 
                            count={summary.call_out}
                            onClick={() => this.setFilter(FILTER.CALL_OUT)}
                            selected={filter === FILTER.CALL_OUT}
                        />
                        <FilterTile 
                            label="Re-Visit" 
                            icon="fa fa-undo" 
                            count={summary.revisit}
                            onClick={() => this.setFilter(FILTER.REVISIT)}
                            selected={filter === FILTER.REVISIT}
                        />
                    </div>
                    <div className="lead-filter-panel-section mb-1 pb-1">
                        <FilterTile 
                            label="Booked" 
                            icon="fa fa-check-circle" 
                            count={summary.booking}
                            onClick={() => this.setFilter(FILTER.BOOKED)}
                        />
                        <FilterTile 
                            label="Cancel" 
                            icon="fa fa-times-circle" 
                            count={summary.cancel}
                            onClick={() => this.setFilter(FILTER.CANCELED)}
                        />
                    </div>
                    <div className="lead-filter-panel-section mb-1 pb-1">
                        <FilterTile 
                            label="Prospect" 
                            icon="fa fa-star" 
                            count={summary.prospect}
                            onClick={() => this.setFilter(FILTER.PROSPECT)}
                            selected={filter === FILTER.PROSPECT}
                        />
                        <FilterTile 
                            label="Qualified" 
                            icon="fa fa-check" 
                            count={summary.qualified}
                            onClick={() => this.setFilter(FILTER.QUALIFIED)}
                            selected={filter === FILTER.QUALIFIED}
                        />
                        <FilterTile 
                            label="Both" 
                            icon="fa fa-trophy" 
                            count={summary.both}
                            onClick={() => this.setFilter(FILTER.BOTH)}
                            selected={filter === FILTER.BOTH}
                        />
                    </div>
                </Card>
                <Card>
                    <Table 
                        columns={this.columns} 
                        values={filtered}
                        itemsPerPage={500}
                        //rowClickCallback={this.rowClickCallback}
                        ref="listTable"
                    />
                </Card>
                <LeadInfoModal
                    ref={'modal'}
                    customer={selectedCustomer}
                />
            </Wrapper>
        )
    }
}

function mapStateToProps(state) {
    return { profile: state.auth.profile }
}

export default connect(mapStateToProps, null)(LeadListsView)

const PeriodTile = ({ label, onClick, selected }) => {
    return (
        <div 
            className="lead-filter-panel-period-tile"
            onClick={onClick}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                backgroundColor: selected ? '#ccc' : null
            }}
        >
            <div>{label}</div>
        </div>
    )
}

const FilterTile = ({ label, icon, onClick, count, selected }) => {
    return <div 
        className="lead-filter-panel-tile"
        onClick={onClick}
        style={{ marginRight: '6px', backgroundColor: selected ? '#ccc' : null }}
    >
        <div 
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
            }}
        >
            <i 
                className={`${icon} grey mr-1`} 
                style={{ fontSize: "24px" }} 
            />
            <span>{count}</span>
        </div>
        {/*<div style={{ width: '100%' }}>{label}</div>*/}
        <div>{label}</div>
    </div>
}
