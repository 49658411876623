export const LEADS_INFO = {
    length: 1,
    values: [
        {
            id: 7375320420846328423,
            full_name: "Darun Uahchinkul",
            email: "darun@billme.co.th",
            phone_number: "0823315589",
            gender: 2,
            age: 21,
            income: 1000,
            occupation: "Designer102",
            address: "34 ถนนอ่อนนุช",
            sub_district: "ดอกไม้",
            district: "ประเวศ",
            province: "กรุงเทพมหานคร",
            postal_code: "10150"
        },
        {
            full_name: "Thanathat Surakhup",
            phone_number: "0809887239",
            age: 22,
            income: 10000,
            occupation: "Programmer101"
        }
    ]
}

export const LEAD_INFO = {
        id: 7375320420846328423,
        full_name: "Darun Uahchinkul",
        email: "darun@billme.co.th",
        phone_number: "0823315589",
        qualified: false,
        gender: 2,
        age: 21,
        income: 1000,
        occupation: "Designer102",
        address: "34 ถนนอ่อนนุช",
        sub_district: "ดอกไม้",
        district: "ประเวศ",
        province: "กรุงเทพมหานคร",
        postal_code: "10"
}

export const LEAD_ACTIVITY = {
    values: [
        {
            duration: "2สัปดาห์ก่อน",
            activity: "เยี่ยมชมครั้งที่1",
            product: "A310",
            reason: "ห้องเล็กเกินไป",
            salesman: "ธนทัต สุรคุปต์"
        },
        {
            duration: "สัปดาห์ก่อน",
            activity: "เยี่ยมชมครั้งที่2",
            product: "แบบห้อง 50 - B",
            reason: "ต้องปรึกษาคุณพ่อคุณแม่",
            salesman: "ธนทัต สุรคุปต์"
        }
    ],
    length: 2
}