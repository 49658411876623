import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { PRODUCT_STATUS } from '../../../../common/const';
import ProductModal from './ProductModal';

const getProductStatusData = status => {
  switch (status) {
    case PRODUCT_STATUS.TRANSFERED:
      return { bgColor: 'red', text: 'โอนแล้ว' };
    case PRODUCT_STATUS.AVAILABLE:
      return { bgColor: 'green', text: 'ห้องว่าง' };
    case PRODUCT_STATUS.BOOKED:
    case PRODUCT_STATUS.UNAVAILABLE:
    default:
      return { bgColor: 'yellow', text: 'จองแล้ว' };
  }
};

class ProductButtonView extends Component {
  handleOpenModal = () => {
    this.refs.modal.openModal();
  };
  handleBook = () => {
    this.props.router.push('/reserve/search');
  };

  render() {
    const { building, floor, productData } = this.props;
    const { x, y, id, type, status, name, floorPlanUrl, price, crossOutPrice } = productData;
    const statusData = getProductStatusData(status);
    const { bgColor, text } = statusData;

    if(x === 0 && y === 0) {
      return null
    } else {
      return (
        <div
          key={id}
          onClick={this.handleOpenModal}
          className={`${bgColor}-bg`}
          style={{
            padding: '12px',
            borderRadius: '50%',
            position: 'absolute',
            transform: 'translate(-50%,-50%)',
            top: y,
            left: x,
            cursor: 'pointer',
          }}
        >
          <ProductModal
            ref={'modal'}
            building={building}
            floor={floor}
            type={type}
            status={statusData}
            name={name}
            imgUrl={floorPlanUrl}
            price={price}
            crossOutPrice={crossOutPrice}
            disabled={status !== PRODUCT_STATUS.AVAILABLE}
            onConfirm={this.handleBook}
          />
        </div>
      );
    }
  }
}

export default withRouter(ProductButtonView);
