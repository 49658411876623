import React, { Component } from 'react'

import Wrapper from '../../layouts/Wrapper'
import { CustomerInfoForm } from '../../components/forms/CustomerInfoModal'

export default class AddCustomerView extends Component {
    componentWillMount() {
        this.props.setHeader('เพิ่มลูกค้า')
    }

    goToReserveStep = customerId => {
        this.props.router.push(`/reserve/select-product/${customerId}`)
    }

    goToSuccessStep = () => {
        this.props.router.push('/reserve/success')
    }

    goToSearchStep = () => {
        this.props.router.push('/reserve/search')
    }

    render() {
        return <Wrapper>
            <div className="row">
                <div className="col-sm-12">
                    <CustomerInfoForm 
                        ref="form" 
                        noHeaderAndFooter 
                        refreshFunc={res => {
                            const customerId = String(res.payload.id)
                            this.goToReserveStep(customerId)
                        }}
                    />
                    <div className="d-flex flex-row my-3 justify-content-end">
                        <button 
                            onClick={() => this.goToSearchStep()} 
                            className="m-05 py-1 btn btn-outline btn-red" 
                        >
                            ยกเลิก
                        </button>
                        <button 
                            onClick={() => {
                                this
                                    .refs
                                    .form
                                    .getWrappedInstance()
                                    .save(true)
                                    .then(this.goToSuccessStep)
                            }} 
                            className="m-05 py-1 btn btn-outline" 
                        >
                            บันทึก
                        </button>
                        <button 
                            onClick={() => {
                                this.refs.form.getWrappedInstance().save()
                            }} 
                            className="m-05 py-1 btn btn-main" 
                        >
                            บันทึกและจองห้อง
                        </button>
                    </div>
                </div>
            </div>
        </Wrapper>
    }
}