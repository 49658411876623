import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './hack.css'

import ConfirmModal from '../../components/ConfirmModal'

import datastore from '../../datastore'
import AsyncSelectInput from '../../components/common/AsyncSelectInput'

class ScheduleInspectionForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                // date: '',
                // time: '',
                datetime: null,
                staffId: null,
            }
        }
    }

    componentWillMount() {
        this.updateAssignmentData(this.props.datetime, this.props.staffId)
    }

    componentWillReceiveProps(nextProps) {
        this.updateAssignmentData(nextProps.datetime, nextProps.staffId)
    }

    updateAssignmentData = (datetime, staffId) => {
        if (datetime) {
            this.setState({
                data: {
                    // date: datetime.format("DD-MM-YYYY"),
                    // time: datetime.format("HH:mm"),
                    datetime: datetime,
                    staffId: staffId
                }
            })
        }
    }

    assignInspection() {
        // const combinedDateTime = `${this.state.data.date} ${this.state.data.time}:00`
        const formattedDatetime = this.state.data.datetime.format("YYYY-MM-DD HH:mm:ss") 
        const engineerName= ''

        this.props.assignInspection(
            // combinedDateTime,
            formattedDatetime,
            this.state.data.staffId,
            engineerName,
        )
    }

    getStaffOptions = query => {
        return datastore.other.lookupStaff(query)
            .then(res => {
                const staffOptions = res.results.map(s => (
                    { value: s[0], label: s[1] }
                )) 

                return {
                    options: staffOptions
                }
            })
    }

    isReadyToSubmit = () => {
        return Boolean(this.state.data.datetime) && Boolean(this.state.data.staffId)
        // return Boolean(this.state.data.date) && Boolean(this.state.data.time) && Boolean(this.state.data.staffId)
    }

    _handleDateChange = datetime => {
        console.log(datetime)
        const data = this.state.data
        data.datetime = datetime
        this.setState({
            data
        })
    }

    render() {
        return (
            <ConfirmModal
                ref="modal"
                title="ตั้งวันนัด"
                disabled={ !this.isReadyToSubmit() }
                onConfirm={ () => this.assignInspection() }
            >
                <p className="mb-15">
                    ระบบจะทำการส่งวันนัดไปยังอีเมลของลูกค้า
                </p>
                <div className="d-flex flex-column  justify-content-around" style={{ flexGrow: 1 }}>
                    <div className="row">
                        <div className="col-sm-6">
                            <DatePicker
                                inline
                                selected={ this.state.data.datetime }
                                onChange={ this._handleDateChange }
                                minDate={ moment() }
                                showTimeSelect
                                timeFormat="HH:mm"
                            />

                            {/* <TextInput 
                                name="date" 
                                placeholder="วันนัด" 
                                type="date" 
                                target={ this } 
                                stateKey="data" 
                                formatter={ v => moment(v, 'DD-MM-YYYY').format('DD-MM-YYYY') }
                            /> */}
                        </div>
                        <div className="col-sm-6">
                            <AsyncSelectInput 
                                name="staffId" 
                                placeholder="เลือกเจ้าหน้าที่นำตรวจ" 
                                target={ this } 
                                stateKey="data"
                                loadOptions={ this.getStaffOptions }
                            /> 
                        </div>
                        {/* <div className="col-sm-6">
                            <TextInput 
                                name="time" 
                                placeholder="เวลา" 
                                type="time" 
                                target={ this } 
                                stateKey="data" 
                                formatter={ v => moment(v, 'HH:mm').format('HH:mm') }
                            />
                        </div> */}
                        {/* <FormNewLine/> */}
                    </div>
                </div>
            </ConfirmModal>
        )
    }
}

ScheduleInspectionForm.propTypes = {
    assignInspection: PropTypes.func,
    staffId: PropTypes.string,
}

export default ScheduleInspectionForm