import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import SelectInput from '../common/SelectInput'
import TextInput from '../common/TextInput'
import apiClient from '../../apis'
import datastore from '../../datastore'
import { inModal } from '../../hoc/inModal'
import ModalTitle from '../common/ModalTitle'
import ModalFooter from '../common/ModalFooter'
import FormNewLine from '../common/FormNewLine'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import _ from 'lodash'
import { Formatter } from '../../utilities/Formatter'

export class ActivityInfoForm extends Component {
    constructor(props){
        super(props)

        this.state = {
            data: {
                activity: null,
                concern: null,
                remark: "",
                interest: "",
            },
            data_next_action: {
                activity: null,
                remark: "",
                target_date: null
            },
            options: [],
            products: [],
            isClick: false
        }

        apiClient.activity.getActivityChoices().then(result => {
            const options = result.activity_types.map(activity => {
                return {
                    value: activity.key,
                    label: activity.value
                }
            })
            this.setState({options: options})
        })
    }

    save = (skipRefreshFunc=false) => {
        const p = apiClient.activity.createActivityToday(this.props.leadId,this.state.data)
        if(this.state.data_next_action.target_date === null){
            p.then(res => {
                if (this.props.closeModal) {
                        this.props.closeModal()
                    }
            })
            return p
        }
        else{
            const data_next_action = {
                ...this.state.data_next_action,
                interest: this.state.data.interest,
                concern: this.state.data.concern
            }
            const s = apiClient.activity.createActivityWithNextAction(this.props.leadId,data_next_action)
            p.then(res => {
                if (this.props.closeModal) {
                        this.props.closeModal()
                    }
            })
            s.then(res => {
                if(this.props.closeModal){
                    this.props.closeModal()
                }
            })
            return Promise.all([p,s])
        }
    }

    searchProduct = () => {
        if (!this.state.data.interest) {
            this.setState({
                products: [],
                isClick: false
            })
        }  else {
            apiClient.activity.getProducts(this.state.data.interest)
                .then(res => {
                    const product = res.products.map(product => {
                        return {
                            product_id: product.key,
                            value : product.value
                        }
                    })
                    this.setState({
                        products: product,
                        isClick: false
                    })
                })
        }
    }

    selectProduct = product => {
        const data = this.state.data
        data.interest = product.value
        this.setState({
            interest: product,
            isClick : true
        })
    }

    handleDateChange = datetime => {
        const data = this.state.data_next_action
        data.target_date = datetime
        this.setState({
            target_date: datetime
        })
    }

    render() {
        const { options,noHeaderAndFooter } = this.props
        const form = (
            <div className="d-flex flex-column" style={{ flexGrow: 1 }}>
                <div className="row">
                    <h3 className="col-sm-4" style={{color: 'grey'}}>บันทึกการเยี่ยมชม</h3>
                    <FormNewLine />
                    <div className="col-sm-6">
                        <selectWrap>
                            <SelectInput
                                name="activity"
                                placeholder="กิจกรรม"
                                target={ this }
                                stateKey="data"
                                options={ this.state.options }
                            />
                        </selectWrap>
                    </div>
                    <div className="col-sm-6">
                        <selectWrap>
                            <SelectInput
                                name="concern"
                                placeholder="เหตุที่ยังไม่ตัดสินใจ"
                                target={ this }
                                stateKey="data"
                                options={ options.concerns }
                            />
                        </selectWrap>
                    </div>
                    <FormNewLine />
                    <div className="col-sm-6">
                        <TextInput
                            name="interest"
                            placeholder="ห้องที่สนใจ"
                            target={ this }
                            stateKey="data"
                            onChange={() => this.searchProduct() }
                        />
                        {
                            !_.isEmpty(this.state.products) ?
                            !this.state.isClick ?
                            (
                                <div>
                                    <div>ผลการค้นหา</div>
                                    <div className="p-05" style={{ backgroundColor: "rgba(0,0,0,0.1)", borderRadius: "10px" }}>
                                        { this.state.products.map(product => {
                                            return (
                                            <SearchResultItem
                                                key={product.value}
                                                product={product}
                                                selectProduct={product => this.selectProduct(product)}
                                            />
                                            )
                                        })}
                                    </div>
                                </div>
                            ) :
                            null :
                            null
                        }
                    </div>
                    <div className="col-sm-6">
                        <TextInput
                            name="remark"
                            type="text"
                            placeholder="หมายเหตุ"
                            target={ this }
                            stateKey="data"
                        />
                    </div>
                    <FormNewLine />
                    <h3 className="col-sm-5" style={{color: 'grey'}}>การเยี่ยมชมครั้งถัดไป</h3>
                    <FormNewLine />
                    <div className="col-sm-6">
                        <selectWrap>
                                <SelectInput
                                    name="activity"
                                    placeholder="กิจกรรม"
                                    target={ this }
                                    stateKey="data_next_action"
                                    options={ this.state.options }
                                />
                            </selectWrap>
                    </div>
                    <div className="col-sm-4">
                        <DatePicker
                            className="form-control input w-fit-content calendar-image"
                            placeholderText="วันที่เยี่ยมชมครั้งถัดไป"
                            selected={ this.state.target_date }
                            onChange={ this.handleDateChange }
                            dateFormat={this.state.target_date ? `${Formatter.formatDateTh(this.state.target_date)} เวลา HH:mm` : ''}
                            timeIntervals={15}
                            timeFormat="HH:mm"
                            minDate={ moment() }
                            showTimeSelect
                            shouldCloseOnSelect={ false }
                            disabledKeyboardNavigation
                            disabled={ this.state.data_next_action.activity === null ? true : false }
                        />
                    <FormNewLine />
                    </div>
                    <div className="col-sm-12">
                        <TextInput
                            name="remark"
                            type="text"
                            placeholder="หมายเหตุ"
                            target={ this }
                            stateKey="data_next_action"
                        />
                    </div>
                </div>
            </div>
        )

        if (noHeaderAndFooter) {
            return form
        }

        return (
            <div className="d-flex flex-column">
                <ModalTitle title="เพิ่มกิจกรรม" onClick={ () => this.props.closeModal() } />
                {form}
                <ModalFooter>
                    <button onClick={ () => this.save() } className="btn btn-main">บันทึก</button>
                </ModalFooter>
            </div>

        )
    }
}
ActivityInfoForm.propTypes = {
    options: PropTypes.object,
    leadId: PropTypes.string.isRequired,
    refreshFunc: PropTypes.func,
    noHeaderAndFooter: PropTypes.bool,
}

function mapStateToProps(state) {
    return {
        options: state.other.options,
    }
}

ActivityInfoForm = connect(mapStateToProps, null, null, { withRef: true })(ActivityInfoForm)

const ActivityInfoModal = inModal(ActivityInfoForm)
export default ActivityInfoModal

const SearchResultItem = ({product, selectProduct}) => {
    return <div className="clickable" onClick={() => selectProduct(product)}>
        <p className="m-0 p-02">
            {product.value}
        </p>
    </div>
}