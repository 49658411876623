import apis from "../apis";

export function getUserList(offset, pageSize, query) {
  return apis.userManagement.getUserList(offset, pageSize, query)
    .then(users => {
      const accounts = users.values.map(account => convertUserList(account))
      return {
        length: users.length,
        values: accounts
      }
    })
}

export function createUser(userData) {
  return apis.userManagement.createUser(convertUserForm(userData))
}

export function createUserShoyu(userData) {
  return apis.userManagement.createUserShoyu(convertShoyuUserForm(userData))
}

export function updateUser(userData) {
  return apis.userManagement.updateUser(convertUpdateUserForm(userData))
}

export function updateUserShoyu(userData) {
  return apis.userManagement.updateUserShoyu(userData.id, convertShoyuUpdateUserForm(userData))
}

export function deactivateUser(accountId) {
  return apis.userManagement.deactivateUser({id: accountId})
}

export function resetPassword(passwordData) {
  return apis.userManagement.resetPassword(convertPasswordForm(passwordData))
}

function convertUserList(userList) {
  return {
    id: userList.id.toString(),
    email: userList.email,
    fullName: userList.full_name,
    role: mapRoleToRoleName(userList.role)
  }
}


function convertUserForm(form) {
  return {
    id: form.id,
    full_name: form.fullName,
    email: form.email,
    password: form.password,
    role: parseInt(form.role)
  }
}

function convertShoyuUserForm(form) {
  return {
    id: form.id,
    full_name: form.fullName,
    email: form.email,
  }
}

function convertUpdateUserForm(form) {
  return {
    id: form.id,
    full_name: form.fullName,
    email: form.email
  }
}

function convertShoyuUpdateUserForm(form) {
  return {
    email: form.email,
    full_name: form.fullName
  }
}

function convertPasswordForm(form) {
  return {
    id: form.id,
    password: form.password
  }
}

function mapRoleToRoleName(role) {
  switch(role) {
    case 0:
      return 'Sales Represent'
    case 1:
      return 'Sales Manager'
    case 2:
      return 'Admin'
    case 3:
      return 'Management'
    case 4:
      return 'Technician'
  }
}