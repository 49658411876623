import { getRequest, postRequest, camelToSnake, getReport } from '../common/util'
import config from '../common/config';

export function getProducts(offset, pageSize, sortKey, sortOrder, filters, query) {
    const data = {
        offset: offset,
        page_size: pageSize
    }

    if (sortKey) {
        data.order = {
            name: camelToSnake(sortKey),
            reverse: sortOrder ? false : true
        }
    }

    if (filters) {
        data.filters = filters.map(f => {
            return {
                name: camelToSnake(f.key),
                operator: '=',
                value: f.value
            }
        })
    }
    
    if (query) {
        const f = data.filters || []
        f.push({
            name: camelToSnake('skuCode'),
            operator: 'like',
            value: `%${query}%`
        })

        data.filters = f
    }

    return postRequest('/warranty/defect/list', data)
        .then(res => {
            return { values: res.products, length: res.total }
        })
        .catch(err => {
            console.error(`getProducts failed with ${err}`)
        })
}

export function exportWarranty() {
    return getReport(config.baseApiUrl, '/warranty/defect/list/export')
}

export function getDefects(productId, offset, pageSize, sortKey, sortOrder, filters, query) {
    const data = {
        offset: offset,
        page_size: pageSize
    }

    if (sortKey) {
        data.order = {
            name: camelToSnake(sortKey),
            reverse: sortOrder ? false : true
        }
    }

    if (filters) {
        data.filters = filters.map(f => {
            return {
                name: camelToSnake(f.key),
                operator: '=',
                value: f.value
            }
        })
    }
    
    if (query) {
        const f = data.filters || []
        f.push({
            name: camelToSnake('skuCode'),
            operator: 'like',
            value: `%${query}%`
        })

        data.filters = f
    }

    return postRequest(`/warranty/defect/product/${productId}`, data)
        .then(res => {
            return { values: res.defects, length: res.total }
        })
        .catch(err => {
            console.error(`getDefects failed with ${err}`)
        })
}

export function cancelDefect(defectId, reasonId) {
    const data = {
        defect_id: defectId,
        reason_id: reasonId
    }

    return postRequest('/warranty/defect/cancel', data)
}

export function getDefectDetail(defectId) {
    return getRequest(`/warranty/defect/${defectId}`);
}

export function getDefectsToAssign(productId) {
    const data = {
        offset: 0,
        page_size: 1000
    }

    return postRequest(`/warranty/defect/assign/list${productId ? `?product_id=${productId}` : ''}`, data)
}

export function getTechnicians(projectId) {
    return getRequest(`/project/${projectId}/technicians`)
}

export function getAssignedDefects() {
    const data = {
        offset: 0,
        page_size: 1000
    }

    return postRequest('/warranty/defect/assigned/list', data)
}

export function getDefectLocation(projectId) {
    return getRequest(`/project/${projectId}/defect_locations`)
}

export function getInspectionDocument(productId, date) {
    const data = {
        target_date: date
    }

    return getRequest(`/warranty/defect/product/${productId}/letter/inspect`, data)
}

export function getInspectionSummary(productId, date) {
    const data = {
        target_date: date
    }

    return getRequest(`/warranty/defect/product/${productId}/letter/fixed_inspect`, data)
}

export function assign(defectId, dateTime, technicianId) {
    const data = {
        defect_id: defectId,
        date_time: dateTime,
        technician_id: technicianId
    }
    return postRequest('/warranty/defect/assign', data)
}

export function createDefect(productId, defectInfo) {
    const data = {
        product_id: productId,
        description: defectInfo.description,
        defect_type: defectInfo.defectType,
        location_id: defectInfo.location,
        warranty_type: defectInfo.warrantyType,
        before_image: defectInfo.imageUrl,
        urgent: defectInfo.urgent
    }

    return postRequest('/warranty/defect/create', data)
}

export function getDefectsToReassign(productId) {
    const data = {
        offset: 0,
        page_size: 1000
    }

    return postRequest(`/warranty/defect/product/${productId}/assigned`, data)
}