import _ from 'lodash'
import mocks from '../mocks'
import {createPromise} from '../common/util'
import apiClient from '../apis'

const IS_MOCK = false

const parseSurveyInfo = survey => (survey
  ? {
    id: String(survey.id),
    sessionId: survey.session_id,
    title: survey.title,
    alias: survey.alias,
    description: survey.description,
    coverUrl: survey.cover_url,
    questions: _.map(survey.questions, parseQuestionInfo),
    status: survey.status
  }
  : {
    id: null
  })

const parseQuestionInfo = info => ({
  id: info.id,
  title: info.title,
  description: info.description,
  questionType: info.question_type,
  order: info.order,
  choices: _.map(info.choices, parseChoiceInfo)
})

const parseChoiceInfo = info => ({id: info.id, order: info.order, title: info.title})

export const getSurveys = (offset, pageSize, sortKey, sortOrder, filters, query) => apiClient
  .survey
  .getSurveys(offset, pageSize, sortKey, sortOrder, filters, query)

export const getSurveyInfo = surveyId => apiClient
  .survey
  .getSurvey(surveyId)
  .then(parseSurveyInfo)

export const getSurveyInfoByAlias = alias => apiClient
  .survey
  .getSurveyByAlias(alias)
  .then(parseSurveyInfo)

export const submitSurvey = answer => apiClient
  .survey
  .submitSurvey(answer)

export const saveSurvey = surveyInfo => apiClient
  .survey
  .createOrUpdateSurvey(surveyInfo)

export const publishSurvey = surveyId => apiClient
  .survey
  .setSurveyPublishStatus(surveyId, true)

export const unpublishSurvey = surveyId => apiClient
  .survey
  .setSurveyPublishStatus(surveyId, false)