import React from 'react'
import { Route, IndexRoute } from 'react-router'
import { Cookies } from 'react-cookie'

import { setToken } from './actions/auth'
import { LAYOUT } from './common/const'
import config from './common/config'
import AppLayout from './layouts/AppLayout'
import BlankLayout from './layouts/BlankLayout'
import LoginView from './views/login'
import ProjectsView from './views/projects'
import NotFoundView from './views/notfound'
import NoAccessView from './views/noaccess'
import CustomersView from './views/customers'
import ProductsView from './views/products'
import WarrantiesView from './views/warranties'
import WarrantyInfoView from './views/warrantyInfo'
import AssignView from './views/assign'
import SaleRecordsView from './views/saleRecords'
import SaleRecordInfoView from './views/saleRecordInfo'
import CustomerInfoView from './views/customerInfo'
import ProductInfoView from './views/productInfo'
import DashboardView from './views/dashboard'
import DashboardViewV2 from './views/dashboardV2'
import ExecutiveDashboardView from './views/executiveDashboard'
import PrintableDefectList from './views/printableDefectList'
import PrintableDefectSummary from './views/printableDefectSummary'
import PrintableWarrantySummary from './views/printableWarrantySummary'
import PrintableQcSummary from './views/qualityControlInfo/printableQcSummary'
import PrintableQcList from './views/qualityControlInfo/printableQcList'
import ReserveAddCustomerView from './views/reserve/addCustomer'
import ReserveSelectProductView from './views/reserve/selectProduct'
import ReserveSearchView from './views/reserve/search'
import ReserveSuccessView from './views/reserve/success'
import SurveyListView from './views/survey/surveyListView'
import SurveyFormView from './views/survey/surveyFormView'
import SurveyPublicView from './views/survey/surveyPublicView'
import SelectProject from './views/selectProject'
import LeadListsView from './views/lead/leadLists'
import AddLeadView from './views/lead/addLead'
import LeadInfo from './views/lead/leadInfo'
import AddActivityView from './views/activities/addActivity'
import ActivityListView from './views/activities/activityLists'
import AddBookingView from './views/booking/addBooking';
import EditInfoView from './views/lead/editInfo';
import CustomerInfoEdit from './views/customerInfoEdit';
import { ReleaseNotePage } from './views/ReleaseNotes';
import CampaignListView from './views/campaign/CampaignLists';
import CreateCampaignView from './views/campaign/CreateCampaign';
import CampaignDetailView from './views/campaign/CampaignDetail';
import EditCampaignView from './views/campaign/EditCampaign';
import { PrintableStartInspect } from './views/warrantyInfo/NewPrintableStartInspect';
import { PrintableInspectSummary } from './views/warrantyInfo/NewPrintableInspectSummary';

import ProductsViewV2 from './views/saleMode/productsV2'
import LeadListsViewV2 from './views/saleMode/leadV2/leadListsV2'
import LeadListsViewV3 from './views/saleMode/leadV2/LeadListsV3'
import AddActivityV2View from './views/saleMode/leadV2/addActivity'
import AddLeadV2View from './views/saleMode/leadV2/addLead'
import SearchLeadView from './views/saleMode/leadV2/search'
import BookV2View from './views/saleMode/leadV2/addBooking'
import UserListView from './views/users/userList'

function requireToken(store, dispatch) {
    return (nextState, replace, callback) => {
        const cookies = new Cookies()

        const storeToken = store.getState().auth.token
        const cookieToken = cookies.get(config.tokenCookiesName)
        const storeRole = store.getState().auth.role
        const cookieRole = cookies.get(config.roleCookiesName)

        if (!storeToken && !cookieToken) {
            replace('/login')
        } else if (!storeToken) {
            store.dispatch(setToken(cookieToken, cookieRole))
        }

        const role = storeRole || cookieRole || -999
        const permissions = config.permissions[role] || []

        if (nextState.location.pathname === '/') {
            const defaultUrl = permissions.length > 0 ? permissions[0] : '/no-access'
            replace(defaultUrl)
            return callback()
        }

        // Check permission.
        const matchedRoute = nextState.routes.find(r => r.onEnter)
        const matchedPath = matchedRoute ? matchedRoute.path || '/' : null

        if (!matchedPath || !permissions.includes(matchedPath)) {
            replace('/no-access')
        }

        return callback()
    }
}

const securedRoutes = [
    {
        layout: LAYOUT.APP,
        paths: [
            // { path: '/dashboard', component:  DashboardView },
            { path: '/dashboard', component:  ExecutiveDashboardView },
            { path: '/dashboard-v2', component:  DashboardViewV2 },
            { path: '/exec-dashboard', component: ExecutiveDashboardView},
            { path: '/projects', component:  ProjectsView },
            { path: '/sale-record', component:  SaleRecordsView },
            { path: '/sale-record/:recordId', component:  SaleRecordInfoView },
            { path: '/sale-record/:recordId/customer/:customerId/edit', component:  CustomerInfoEdit},
            { path: '/customers-list', component:  CustomersView },
            { path: '/customers-list/:customerId/basic-info', component: CustomerInfoView },
            { path: '/products-list', component: ProductsView },
            { path: '/products-list/:productId/basic-info', component: ProductInfoView },
            { path: '/campaign', component: CampaignListView },
            { path: '/create-campaign', component: CreateCampaignView },
            { path: '/campaign/:campaignId', component: CampaignDetailView },
            { path: '/campaign/:campaignId/edit-campaign', component: EditCampaignView },
            { path: '/lead', component: LeadListsView },
            { path: '/lead/add-lead', component: AddLeadView },
            { path: '/lead/:leadId', component: LeadInfo },
            { path: '/lead/:leadId/add-activities', component: AddActivityView } ,
            { path: '/lead/:leadId/booking', component: AddBookingView },
            { path: '/lead/:leadId/edit-info', component: EditInfoView },
            { path: '/schedule', component: ActivityListView },
            { path: '/reserve/search', component: ReserveSearchView },
            { path: '/reserve/success', component: ReserveSuccessView },
            { path: '/reserve/add-customer', component: ReserveAddCustomerView },
            { path: '/reserve/select-product/:customerId', component: ReserveSelectProductView },
            { path: '/survey-list', component: SurveyListView },
            { path: '/survey-list/:surveyId', component: SurveyFormView },
            { path: '/warranty-assign', component: AssignView },
            { path: '/warranty-list', component: WarrantiesView },
            { path: '/warranty-list/restricted', component: WarrantiesView },
            { path: '/warranty-list/partial', component: WarrantiesView },
            { path: '/warranty-list/:productId/restricted', component: WarrantyInfoView },
            { path: '/warranty-list/:productId', component: WarrantyInfoView },
            { path: '/sale-mode/products-list', component: ProductsViewV2, hideNavbar: true },
            { path: '/sale-mode/leads', component: LeadListsViewV3, hideNavbar: true },
            { path: '/sale-mode/add-lead', component: AddLeadV2View, hideNavbar: true },
            { path: '/sale-mode/search-lead', component: SearchLeadView, hideNavbar: true },
            { path: '/sale-mode/leads/:leadId/add-activity', component: AddActivityV2View, hideNavbar: true } ,
            { path: '/sale-mode/leads/:leadId/book', component: BookV2View, hideNavbar: true } ,
            { path: '/users', component: UserListView},
        ]
    },
    {
        layout: LAYOUT.BLANK,
        paths: [
            { path: '/pdefect-list/:recordId/:inspectionId', component: PrintableDefectList },
            { path: '/pdefect-summary/:recordId/:inspectionId', component: PrintableDefectSummary },
            { path: '/pinspect-start/:productId', component: PrintableStartInspect },
            { path: '/pinspect-summary/:productId', component: PrintableInspectSummary},
            { path: '/pqc-summary/:productId/:inspectionId', component: PrintableQcSummary },
            { path: '/pqc-list/:productId/:inspectionId', component: PrintableQcList },
            { path: '/pwarranty-summary/:productId', component: PrintableWarrantySummary },
            { path: '/select-project', component: SelectProject },
        ]
    }
]

export default function getRoutes(store) {
    const appPaths = securedRoutes.find(r => r.layout == LAYOUT.APP).paths
    const blankPaths = securedRoutes.find(r => r.layout == LAYOUT.BLANK).paths

    return (
        <Route path="/">
            <Route component={AppLayout}>
                <IndexRoute exact component={DashboardView} onEnter={requireToken(store)} />
                {appPaths.map(r => (
                    <Route exact path={r.path} component={r.component} onEnter={requireToken(store)} key={r.path} hideNavbar={r.hideNavbar} />
                ))}
                <Route path="/no-access" component={NoAccessView} />
            </Route>
            <Route component={AppLayout}>
            </Route>
            <Route component={BlankLayout}>
                {blankPaths.map(r => (
                    <Route exact path={r.path} component={r.component} onEnter={requireToken(store)} key={r.path} />
                ))}
            </Route>
            <Route component={BlankLayout}>
                <Route path="/login" component={LoginView} />
                <Route path="/release-notes" component={ReleaseNotePage} />
                <Route path="/survey/:alias" component={SurveyPublicView} />
                <Route path="*" component={NotFoundView} />
            </Route>
        </Route>
    )
}
