import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class ModalTitle extends Component {
    render() {
        const { title, onClick } = this.props
        return (
            <div className="d-flex flex-row justify-content-between">
                <h2 className="mb-25">{ title }</h2>
                {onClick && (
                    <i onClick={ onClick }  className="fa fa-times grey btn-modal-close fa-2x"></i>
                )}
            </div>
        )
    }
}

ModalTitle.propTypes = {
    title: PropTypes.string
}
