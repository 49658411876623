import React  from 'react'

export default class TextAreaInput extends React.Component {
    state = {
        renderValue: ""
    }

    // Careful! The default value here will render from data instead of compute data from rendered value ...
    componentWillMount() {
        this.updateValue(this.props)
    }

    componentWillReceiveProps(nextProps) {
        this.updateValue(nextProps)
    }

    updateValue = props => {
        const { target, stateKey, nestedKey, name, renderFormatter } = props
        let { renderValue } = props

        const value = nestedKey
            ? target.state[stateKey][nestedKey][name]
            : target.state[stateKey][name]

        renderValue = renderValue || (renderFormatter
            ? renderFormatter(value)
            : value)

        this.setState({
            renderValue: renderValue
        })
    }

    render() {
         const {name, placeholder, rows, target, stateKey, nestedKey} = this.props
        return <textarea
            className="form-control input w-100"
            style={{fontFamily: 'Prompt'}}
            placeholder={ placeholder }
            name={ name }
            rows={ rows }
            onChange={ (e) => {
                const data = target.state[stateKey]

                if (nestedKey) {
                    data[nestedKey] = data[nestedKey] || {}
                    data[nestedKey][e.target.name] = e.target.value
                } else {
                    data[e.target.name] = e.target.value
                }

                target.setState({
                    [stateKey]: data
                })
            }}
            value={this.state.renderValue}
        />
    }
}
