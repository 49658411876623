import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import apiClient from '../../apis'
import { inModal } from '../../hoc/inModal'
import TextInput from '../common/TextInput'
import SelectInput from '../common/SelectInput'
import ModalTitle from '../common/ModalTitle'
import ModalFooter from '../common/ModalFooter'


class SaleRecordInfoForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                // TODO: Fix the default values once we know exact requirements.
                productSaleId: -1,
                productSaleForm: {
                    id: -1,
                    productId: 1, // Done.
                    customerId: 1, // Done.
                    isCurrent: true,
                    isCancel: false,
                    cancelDate: "0001-01-01 00:00:00",
                    cancelUser: 0,
                    created: "0001-01-01 00:00:00",
                    creatorId: 0
                },
                saleStageId: 0,
                saleType: 1,
                saleAmount: 0,
                isCancel: false,
                selectedLoan: 0,
                cancelDate: "0001-01-01 00:00:00",
                cancelUser: 0,
                created: "0001-01-01 00:00:00",
                creatorId: 0
            }
        }

        this.save = this.save.bind(this)
    }

    save() {
        apiClient.sale.createSaleRecord(this.state.data)
            .then(res => {
                if (this.props.refreshFunc) {
                    this.props.refreshFunc()
                }

                this.props.closeModal()
            })
    }

    render() {
        const { closeModal, options } = this.props
        return (
            <div className="d-flex flex-column">
                <ModalTitle title="บันทึกการขาย" onClick={ () => closeModal() } />
                <div className="d-flex flex-column  justify-content-around" style={{ flexGrow: 1 }}>
                    <div className="row">
                        <div className="col-sm-6">
                            <TextInput 
                                name="created" 
                                placeholder="วันที่" 
                                type="date" target={ this } 
                                stateKey="data" 
                                dataFormatter={ v => moment(v).format('YYYY-MM-DD HH:mm:ss') } 
                            />
                        </div>
                        <div className="w-100 mb-4" />
                        {/* <div className="col-sm-6">
                            <TextInput 
                                name="creatorId" 
                                placeholder="รหัสพนักงาน" 
                                type="number"
                                target={ this } 
                                stateKey="data" 
                            />
                        </div> */}
                        <div className="col-sm-6">
                            <TextInput 
                                name="customerId" 
                                placeholder="รหัสลูกค้า" 
                                type="number"
                                target={ this } 
                                stateKey="data" 
                                nestedKey="productSaleForm"
                            />
                        </div>
                        <div className="col-sm-6">
                            <TextInput 
                                name="productId" 
                                placeholder="รหัสสินค้า" 
                                type="number"
                                target={ this } 
                                stateKey="data" 
                                nestedKey="productSaleForm"
                            />
                        </div>

                        <div className="w-100 mb-4" />
                        <div className="col-sm-6">
                            <selectWrap>
                                <SelectInput
                                    name="saleStageId"
                                    placeholder="สถานะ"
                                    target={ this }
                                    stateKey="data"
                                    options={ options.saleStages || [] }
                                />
                            </selectWrap>
                        </div>
                    </div>
                </div>
                <ModalFooter>
                    <button onClick={ () => this.save() } className="btn btn-main ml-auto">บันทึก</button>
                </ModalFooter>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        options: state.other.options,
    }
}

SaleRecordInfoForm = connect(mapStateToProps, null)(SaleRecordInfoForm)

const SaleRecordInfoModal = inModal(SaleRecordInfoForm)
export default SaleRecordInfoModal