import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import JSONBig from 'json-bigint'
import SelectInput from '../common/SelectInput'
import TextInput from '../common/TextInput'
import apiClient from '../../apis'
import { inModal } from '../../hoc/inModal'
import ModalTitle from '../common/ModalTitle'
import ModalFooter from '../common/ModalFooter'
import FormNewLine from '../common/FormNewLine'
import CheckboxInput from '../common/CheckboxInput';

export class EditInfoForm extends Component {
    constructor(props){
        super(props)
        this.state = {
            data: {
                first_name: "",
                last_name: "",
                email: "",
                gender: undefined,
                age: "",
                phone_number: "",
                qualified: undefined,
                prospect: undefined,
                income: null,
                occupation: null,
                address: "",
                sub_district: "",
                district: "",
                province: "",
                postal_code: "",
                line_id: "",
                sources: [],
                occupation_detail: ""
            },
            occupations: [],
            salary_ranges: [],
            answer_list: [
                {
                    question_id: "",
                    answers: [],
                },
            ],
            questions: [],
            answered: [],
            online_sources_list: [],
            offline_sources_list: [],
            answered_source: []
        }

        apiClient.lead.getLead(this.props.leadId)
        .then(lead => {
            const data = this.state.data
            data.first_name = lead.first_name
            data.last_name = lead.last_name
            data.email = lead.email
            data.phone_number = lead.phone_number
            data.age = lead.age
            data.qualified = lead.qualified
            data.prospect = lead.prospect
            data.gender = lead.gender
            data.income = this.convertIncome(lead.income)
            data.occupation = this.convertOccupation(lead.occupation)
            data.address = lead.address
            data.sub_district = lead.sub_district
            data.district = lead.district
            data.province = lead.province
            data.postal_code = lead.postal_code
            data.line_id = lead.line_id
            data.sources = lead.sources
            data.occupation_detail = lead.occupation_detail
            this.setState({
                data: data,
                answered_source: lead.sources
            })
        })
        apiClient.lead.getLeadOptions().then(result => {
            const occupations = result.occupations.map(option => {
                return {
                    value: option.key,
                    label: option.value
                }
            })
            const salary = result.salary_ranges.map(option => {
                return {
                    value: option.key,
                    label: option.value
                }
            })
            this.setState({
                occupations: occupations,
                salary_ranges: salary
            })
        })
        apiClient.lead.getQuestionsForEdit(this.props.leadId).then(res => {
            const questions = res[0].questions.map(question => {
                return {
                    question_id: question.id,
                    title: question.title,
                    answers: question.answers
                }
            })
            this.setState({
                questions: questions,
                answer_list: res[1].answer_list.map(answers => {
                    return {
                        question_id: answers.question_id,
                        answers: answers.answers
                    }
                }),
                answered: res[1].answer_list.map(answers => {
                    return {
                        question_id: answers.question_id,
                        answers: answers.answers
                    }
                })
            })
        })

        apiClient.campaign.getAds().then(res => {
            const onlineAds = res.advertisements.filter(list => list.type === 'online').map(list => {
                return {
                    id: list.id,
                    values: list.name,
                    type: list.type
                }
            })

            const offlineAds = res.advertisements.filter(list => list.type === 'offline').map(list => {
                return {
                    id: list.id,
                    values: list.name,
                    type: list.type
                }
            })

            this.setState({
                online_sources_list: onlineAds,
                offline_sources_list: offlineAds
            })
        })
    }

    save = (skipRefreshFunc=false) => {
        if(this.state.data.full_name === ""){
            alert("กรุณากรอกชื่อ-นามสกุล")
            return Promise.reject()
        }
        else if(this.state.data.phone_number === ""){
            alert("กรุณากรอกเบอร์โทรศัพท์")
            return Promise.reject()
        }
        else{
            const p = apiClient.lead.updateLeadInfo(this.props.leadId,this.state.data)
            if (this.state.answer_list.filter(answer => answer.answers.length !== 0).length === 0) {
                p.then(res => {
                    if (this.props.closeModal) {
                            this.props.closeModal()
                        }
                })
                return p
            } else {
                if(this.state.answered.length !== 0){
                    return apiClient.lead.updateAnswers(this.props.leadId, this.state.answer_list)
                }
                else{
                    return apiClient.lead.createAnswer(this.props.leadId, this.state.answer_list)
                }
            }
        }
    }

    convertIncome(income) {
        switch(income){
            case 'ไม่ระบุ':
                return 0
            case 'ไม่เกิน 20,000 บาท' :
                return 1
            case '20,001 - 30,000 บาท':
                return 2
            case '30,001 - 40,000 บาท':
                return 3
            case '40,001 - 50,000 บาท':
                return 4
            case '50,001 - 60,000 บาท':
                return 5
            case '60,001 - 70,000 บาท':
                return 6
            case '70,001 - 80,000 บาท':
                return 7
            case '80,001 - 90,000 บาท':
                return 8
            case '90,001 - 100,000 บาท':
                return 9
            case 'มากกว่า 100,000 บาท':
                return 10
            default:
                return 0
        }
    }

    convertOccupation(occupation) {
        switch(occupation){
            case 'ไม่ระบุ':
                return 0
            case 'พนักงานเอกชน':
                return 2
            case 'พนักงานรัฐวิสาหกิจ' :
                return 3
            case 'รับราชการ':
                return 4
            case 'อาชีพอิสระ':
                return 5
            case 'ธุรกิจส่วนตัว':
                return 6
            default:
                return 0
        }
    }

    checkboxRender = (answers, id , answer_list) =>{
        return answers.map((value,index) => {
            return  (
                <div key={index}>
                    <input
                        className="mr-05"
                        type="checkbox"
                        value={value}
                        id={id}
                        onChange={this.onChoiceSelect}
                        checked={this.isCheck(answer_list, id, value)}
                    />
                    <label htmlFor={id}>{value}</label>
                </div>
            )
        })
    }

    renderSourceCheckbox = (sources, answered_source) => {
        return sources.map((source, index) => {
            return (
                <div key={`ad-index-${index}`}>
                    <input 
                        className="mr-05" 
                        type="checkbox" 
                        value={source.values} 
                        id={source.id} 
                        onChange={this.onSelectSource} 
                        checked={this.isSelectedSource(answered_source, source.id)}
                    />
                    <label htmlFor={source.id}>{source.values}</label>
                </div>
            )
        })
    }

    onSelectSource = (event) => {
        let source = this.state.data.sources
        if(event.target.checked) {
            source.push(event.target.id)
        }
        else {
            const position = source.indexOf(event.target.id)
            source.splice(position, 1)
        }
        this.setState({source})
    }

    onChoiceSelect = (event) => {
        let answer_list = this.state.answer_list
        if(answer_list.length === 0){
            answer_list.push({
                question_id: JSONBig.parse(event.target.id),
                answers: [event.target.value]
            })
        } else if (this.findQuestionId(event.target.id) === 0) {
            answer_list.push({
                question_id: JSONBig.parse(event.target.id),
                answers: [event.target.value]
            })
        } else {
            answer_list.map((answer) => {
                if(event.target.checked === true){
                    if(event.target.id == answer.question_id){
                        answer.answers.push(event.target.value)
                    }
                }
                else{
                    if(event.target.id == answer.question_id){
                        const index = answer.answers.indexOf(event.target.value)
                        answer.answers.splice(index, 1)
                    }
                }
            })
        }
        this.setState({answer_list})
    }

    findQuestionId(question_id){
        let answer_list = this.state.answer_list
        return answer_list.filter(answer => answer.question_id.toString() === question_id.toString()).length
    }

    isCheck(answer_list, id, value) {
        const answered = answer_list.filter(answer => answer.question_id.toString() === id.toString());
        if (answered.length > 0) {
            return answered.flatMap(allAnswer => allAnswer.answers.map(choice => choice)).indexOf(value) > -1 ? true : false
        } else {
            return null;
        }
    }

    isSelectedSource(answered_source, id) {
        const answered = answered_source.filter(answered => answered === id.toString());
        if (answered.length > 0){
            return true;
        } else {
            return false;
        }
    }

    render() {
        const { options, noHeaderAndFooter } = this.props
        const form = (
            <div className="d-flex flex-column" style={{ flexGrow: 1 }}>
                <div className="row">
                    <FormNewLine/>
                    <div className="col-sm-3">
                        <TextInput
                            name="first_name"
                            placeholder="ชื่อ"
                            type="text"
                            target={ this }
                            stateKey="data"
                            required={true}
                        />
                    </div>
                    <div className="col-sm-3">
                        <TextInput
                            name="last_name"
                            placeholder="นามสกุล"
                            type="text"
                            target={ this }
                            stateKey="data"
                        />
                    </div>
                    <div className="col-sm-2" style={{lineHeight: 3.5}}>
                        <CheckboxInput
                            name="qualified"
                            target={ this }
                            stateKey="data"
                            label="Qualified"
                        />
                    </div>
                    <div className="col-sm-2" style={{lineHeight: 3.5}}>
                        <CheckboxInput 
                            name="prospect"
                            target={ this }
                            stateKey="data"
                            label="Prospect"
                            disabled={ this.state.data.qualified === true? false : true }
                        />
                    </div>
                    <FormNewLine />
                    <div className="col-sm-5">
                        <TextInput
                            name="phone_number"
                            placeholder="เบอร์โทรศัพท์"
                            type="number"
                            target={ this }
                            stateKey="data"
                            required={true}
                        />
                    </div>

                    <div className="col-sm-5">
                        <TextInput
                            name="email"
                            placeholder="E-mail"
                            type="email"
                            target={ this }
                            stateKey="data"
                        />
                    </div>

                    <FormNewLine />
                    <div className="col-sm-3">
                        <selectWrap>
                            <SelectInput
                                name="gender"
                                placeholder="เพศ"
                                target={ this }
                                stateKey="data"
                                options={ options.genders }
                            />
                        </selectWrap>
                    </div>
                    <div className="col-sm-3">
                        <TextInput
                            name="age"
                            placeholder="อายุ"
                            type="number"
                            target={ this }
                            stateKey="data"
                        />
                    </div>
                    <div className="col-sm-3">
                        <TextInput
                            name="line_id"
                            placeholder="Line ID"
                            type="text"
                            target={ this }
                            stateKey="data"
                        />
                    </div>

                    <FormNewLine />
                    <div className="col-sm-3">
                        <TextInput
                            name="address"
                            placeholder="บ้านเลขที่"
                            target={ this }
                            stateKey="data"
                        />
                    </div>

                    <div className="col-sm-3">
                        <TextInput
                            name="sub_district"
                            placeholder="แขวง/ตำบล"
                            target={ this }
                            stateKey="data"
                            />
                    </div>

                    <div className="col-sm-3">
                        <TextInput
                            name="district"
                            placeholder="เขต/อำเภอ"
                            target={ this }
                            stateKey="data"
                        />
                    </div>

                    <FormNewLine />
                    <div className="col-sm-3">
                        <TextInput
                            name="province"
                            placeholder="จังหวัด"
                            target={ this }
                            stateKey="data"
                        />
                    </div>

                    <div className="col-sm-3">
                        <TextInput
                            name="postal_code"
                            placeholder="รหัสไปรษณีย์"
                            target={ this }
                            stateKey="data"
                        />
                    </div>
                    <FormNewLine />
                    <div className="col-sm-3">
                        <selectWrap>
                            <SelectInput
                                name="occupation"
                                placeholder="อาชีพ"
                                target={ this }
                                stateKey="data"
                                options={ this.state.occupations }
                            />
                        </selectWrap>
                    </div>
                    <div className="col-sm-6">
                        <TextInput
                            name="occupation_detail"
                            placeholder="รายละเอียดของอาชีพ"
                            target={ this }
                            stateKey="data"
                        />
                    </div>
                    <FormNewLine />
                    <div className="col-sm-6">
                        <selectWrap>
                            <SelectInput
                                name="income"
                                placeholder="รายได้ / เดือน(บาท)"
                                target={ this }
                                stateKey="data"
                                options={ this.state.salary_ranges }
                            />
                        </selectWrap>
                    </div>
                    <FormNewLine />
                    <div className="col-sm-6">
                        <h3>แบบสอบถาม</h3>
                    </div>
                    <FormNewLine />
                    {
                        this.state.questions.map((question, index) => {
                            return (
                                <div className={`col-sm-${index + 3}`} key={`question-${index}`}>
                                    <h5>{`${index + 1}.`} {this.state.questions.length === 0 ? "" : this.state.questions[index].title}</h5>
                                    <br />
                                    {this.checkboxRender(question.answers, this.state.questions.length > 0 ? this.state.questions[index].question_id : '', this.state.answer_list)}
                                </div>
                            )
                        })
                    }
                    <div className="col-sm-6" style={{marginTop: "40px"}}>
                        <h3>สื่อโครงการ</h3>
                    </div>
                    <FormNewLine />
                    {
                        this.state.online_sources_list.length > 0 ?
                        <div className="col-sm-3">
                            <h5>สื่อOnline</h5>
                            {this.renderSourceCheckbox(this.state.online_sources_list, this.state.answered_source.length > 0 ? this.state.answered_source : [])}
                        </div>
                        : null
                    }
                    {
                        this.state.offline_sources_list.length > 0 ?
                        <div>
                            <h5>สื่อOffline</h5>
                            {this.renderSourceCheckbox(this.state.offline_sources_list, this.state.answered_source.length > 0 ? this.state.answered_source : [])}
                        </div>
                        : null
                    }
                </div>
            </div>
        )

        if (noHeaderAndFooter) {
            return form
        }

        return (
            <div className="d-flex flex-column">
                <ModalTitle title="แก้ไขข้อมูล" onClick={ () => this.props.closeModal() } />
                {form}
                <ModalFooter>
                    <button onClick={ () => this.save() } className="btn btn-main">บันทึก</button>
                </ModalFooter>
            </div>

        )
    }
}
EditInfoForm.propTypes = {
    options: PropTypes.object,
    refreshFunc: PropTypes.func,
    customerId: PropTypes.string,
    noHeaderAndFooter: PropTypes.bool,
}

function mapStateToProps(state) {
    return {
        options: state.other.options,
    }
}

EditInfoForm = connect(mapStateToProps, null, null, { withRef: true })(EditInfoForm)

const EditInfoModal = inModal(EditInfoForm)
export default EditInfoModal