import apiClient from '../apis'
import { createPromise } from '../common/util'
import mocks from '../mocks'
import moment from 'moment'
import { Formatter } from '../utilities/Formatter';

const IS_MOCK = false

export function getActivities(offset,pageSize){
    return apiClient.activity.getActivities(offset,pageSize)
        .then(res => {
            const leads = res.values.map(c => convertTodayActivities(c))
            return {
                length: res.length,
                values: leads
            }
        })
}

export function getAllActivities(offset, pageSize, accountId, period){
    return apiClient.activity.getAllActivities(offset, pageSize, accountId, period)
        .then(res => {
            const leads = res.values.map(c => convertTodayActivities(c))
            return {
                length: res.length,
                values: leads
            }
        })
}

export function getActivity(leadId,offset,pageSize){
    if(IS_MOCK){
        return createPromise(mocks.activity.ACTIVITY_INFO)
    }
    else{
        return apiClient.activity.getActivity(leadId,offset,pageSize)
            .then(res => {
                const leads = res.values.map(c => convertActivity(c))
                return {
                    length: res.length,
                    values: leads
                }
            })
    }
}

export function createActivity(){
    return apiClient.activity.createTestActivity()
}

export function informActivity(activityId, data) {
    return apiClient.activity.informActivity(activityId, convertInformDetail(data))
}

export function declineActivity(activityId, data) {
    return apiClient.activity.declineActivity(activityId, convertDeclineDetail(data))
}

export function updateActivity(activityId, data) {
    return apiClient.activity.updateActivity(activityId, convertUpdateActivityData(data))
}

function convertActivity(activity) {
    return {
        activity: activity.activity,
        interest: activity.interest,
        remark: activity.remark,
        concern: activity.concern,
    }
}

function convertInformDetail(data) {
    return {
        detail: data.activityDetail
    }
}

function convertDeclineDetail(data) {
    return {
        reject_reason: data.declineDetail
    }
}

function convertUpdateActivityData(data) {
    if (data.isSuccess === '1') {
        return {
            concern: data.concern,
            remark: data.remark,
            is_success: true
        }
    } else {
        return {
            concern: data.concern ? data.concern : '',
            remark: data.remark,
            is_success: false
        }
    }
}

function convertTodayActivities(activity){
    const target_date = moment(activity.target_date).format("HH:mm")
    const created = moment(activity.created, "YYYY-MM-DD HH:mm:ss+Z").add(7, 'hours')
    return {
        id: activity.id,
        targetDate: activity.target_date,
        activity: activity.detail,
        product: activity.product,
        customerName: activity.customer_full_name,
        approverName: activity.approver_full_name ? activity.approver_full_name : 'ไม่ระบุ',
        status: activity.status,
        type: activity.type,
        rejectReason: activity.reject_reason ? activity.reject_reason : '-'
    }
}

function convertChoices(choices) {
    return {
        activity_types: choices.activity_types
    }
}