import React, { Component } from 'react'

export default class ProjectsView extends Component {
    render() {
        return (
            <div className="animated fadeInRight">
                <div className="row">
                    <div className="w-100 p-3 mt-3"><h5>Condominium</h5></div>
                    <div className="col-4 d-flex flex-column justify-content-center align-items-start">
                        <div className="clickable-card">
                            <img width="100%" alt="" src="/img/test1.png" />
                            <div className="p-3" style={{backgroundColor:"white"}}>
                                <h4>Project 1</h4>
                                <div className="c1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 d-flex flex-column justify-content-center align-items-start">
                        <div className="clickable-card">
                            <img width="100%" alt="" src="/img/test2.png" />
                            <div className="p-3" style={{backgroundColor:"white"}}>
                                <h4>Project 2</h4>
                                <div className="c1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 d-flex flex-column justify-content-center align-items-start">
                        <div className="clickable-card">
                            <img width="100%" alt="" src="/img/test3.png" />
                            <div className="p-3" style={{backgroundColor:"white"}}>
                                <h4>Project 3</h4>
                                <div className="c1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="w-100 p-3 mt-3"><h5>Housing</h5></div>
                    <div className="col-4 d-flex flex-column justify-content-center align-items-start">
                        <div className="clickable-card">
                            <img width="100%" alt="" src="/img/test3.png" />
                            <div className="p-3" style={{backgroundColor:"white"}}>
                                <h4>Project 1</h4>
                                <div className="c1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 d-flex flex-column justify-content-center align-items-start">
                        <div className="clickable-card">
                            <img width="100%" alt="" src="/img/test1.png" />
                            <div className="p-3" style={{backgroundColor:"white"}}>
                                <h4>Project 2</h4>
                                <div className="c1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 d-flex flex-column justify-content-center align-items-start">
                        <div className="clickable-card">
                            <img width="100%" alt="" src="/img/test2.png" />
                            <div className="p-3" style={{backgroundColor:"white"}}>
                                <h4>Project 3</h4>
                                <div className="c1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}