import { GET_TRANSLATIONS } from './types'
import datastore from '../datastore'

export function getTranslations() {
    return dispatch => {
        datastore.other.getTranslations()
            .then(res => {
                dispatch({
                    type: GET_TRANSLATIONS,
                    translations: res
                })
            })
    }
}