import { getRequest, postRequest, camelToSnake, getReport, deleteRequest } from '../common/util'
import config from '../common/config';

export function getSaleRecords(offset, pageSize, sortKey, sortOrder, filters, query) {
    const data = {
        offset: offset,
        page_size: pageSize
    }

    if (sortKey) {
        data.order = {
            name: camelToSnake(sortKey),
            reverse: sortOrder ? false : true
        }
    }

    if (filters) {
        data.filters = filters
            .filter(f => f.value !== null)
            .map(f => {
                return {
                    name: camelToSnake(f.key),
                    operator: '=',
                    value: f.value
                }
            })
    }

    if (query) {
        const f = data.filters || []
        if (query[1]) {
            f.push({
                // name: camelToSnake('customerName'),
                name: camelToSnake(query[0]),
                operator: 'like',
                value: `%${query[1]}%`
            })
        }

        data.filters = f
    }

    return postRequest('/saleRecord/list', data)
        .then(res => {
            return { values: res.sale_records, length: res.total }
        })
        .catch(err => {
            console.error(`getSaleRecords failed with ${err}`)
        })
}

export function exportSaleRecord() {
    return getReport(config.baseApiUrl, '/saleRecord/list/export')
}

export function getSaleRecord(recordId) {
    return getRequest(`/saleRecord/${recordId}`)
}

export function deleteSaleRecord(recordId) {
    return deleteRequest(`/saleRecord/${recordId}`);
}

export function assignInspection(recordId, inspectionDate, staffId, engineerName) {
    const data = {
        sale_record_id: recordId,
        inspection_date: inspectionDate,
        reception_id: staffId,
        staff_name: engineerName
    }

    return postRequest('/inspection/assign', data)
}

export function createSaleRecord(recordInfo) {
    // TODO: Should be validation on server? Proper default values?
    const productId = recordInfo.productSaleForm.productId 
    const customerId = recordInfo.productSaleForm.customerId 
    recordInfo.productSaleForm.prouductId = productId || 1
    recordInfo.productSaleForm.customerId = customerId || 1

    return postRequest('/saleRecord/create', recordInfo)
}

export function quickSell(firstName, lastName, productId) {
    const data = {
        first_name: firstName,
        last_name: lastName,
        product_id: productId
    }

    return postRequest('/sell', data)
}

export function updateContractInfo(saleRecordId, date) {
    const data = {
        target_date: date
    }

    return postRequest(`/saleRecord/${saleRecordId}/contract`, data)
}

export function updateTransferInfo(saleRecordId, date) {
    const data = {
        target_date: date
    }

    return postRequest(`/saleRecord/${saleRecordId}/transfer`, data)
}

export function cancelSaleRecord(saleRecordId, date) {
    const data = {
        target_date: date
    }
    return postRequest(`/saleRecord/${saleRecordId}/cancel`, data)
}
