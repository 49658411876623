import React, { PureComponent } from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { connect } from 'react-redux'
import FormNewLine from '../common/FormNewLine';
import TextInput from '../common/TextInput';
import apiClient from '../../apis'
import { formatNumberWithComma } from '../../common/util';

export class EditCampaignForm extends PureComponent {
  constructor(props){
    super(props);

    this.state = {
      data: {
        campaignName: '',
        startDate: '',
        endDate: '',
        budget: ''
      }
    }

    apiClient.campaign.getCampaignDetail(this.props.campaignId)
    .then(result => {
      if(result){
        const data = this.state.data
        data.campaignName = result.name
        data.startDate = result.start_date
        data.endDate = result.end_date
        data.budget = result.budget

       this.setDataState(data)
      }
    })
  }

  setDataState(data) {
    this.setState({
      data: data,
      campaignName: data.name,
      startDate: moment(data.start_date),
      endDate: moment(data.end_date),
      budget: data.budget
    })
  }

  handleDateChange(datetime, key) {
    const data = this.state.data
    data[key] = moment(datetime._d).format('YYYY-MM-DD')
    this.setState({
        [key]: datetime
    })
  }

  save = (skipRefreshFunc=false) => {
    if(!this.state.data.campaignName){
      alert("กรุณากรอกชื่อแคมเปญ");
      return Promise.reject();
    }
    else if(!this.state.data.budget) {
      alert("กรุณากรอกงบประมาณ");
      return Promise.reject();
    }
    else if(!this.state.data.startDate || !this.state.data.endDate){
      alert("กรุณากรอกวันที่ให้ครบ");
      return Promise.reject();
    }
    else if(moment(this.state.data.endDate, 'YYYY-MM-DD') < moment(this.state.data.startDate, 'YYYY-MM-DD')){
      alert("วันที่ไม่ถูกต้อง กรุณาใส่วันที่ใหม่");
      return Promise.reject();
    }
    else{
      const p = apiClient.campaign.updateCampaign(this.state.data, this.props.campaignId).then((result,err) => {
        if(result.key) {
          return result
        }
        else {
          return console.error(err)
        }
      })
      return p
    }
  }

  delete = () => {
    const campaignId = this.props.campaignId
    const isConfirm = window.confirm(`คุณต้องการลบแคมเปญ ${this.state.data.campaignName} ใช่หรือไม่?`)
    if (isConfirm) {
      const p = apiClient.campaign.deleteCampaign(campaignId)
      return p;
    }
  }

  render() {
    return (
      <div className="d-flex flex-column" style={{ flexGrow: 1 }}>
          <div className="row">
              <h3 className="col-sm-4" style={{color: 'grey'}}>ข้อมูลแคมเปญ</h3>
              <FormNewLine />
              <div className="col-sm-6">
                <TextInput 
                  name="campaignName"
                  placeholder="ชื่อ"
                  target={ this }
                  stateKey="data"
                  required={true}
                />
              </div>
              <div className="col-sm-2.5">
                <DatePicker
                  className="form-control input w-fit-content calendar-image"
                  placeholderText="วันที่เริ่ม"
                  selected={ moment(this.state.data.startDate) }
                  onChange={ (date) => this.handleDateChange(date ? date : '', 'startDate') }
                  dateFormat="YYYY-MM-DD"
                  shouldCloseOnSelect={ true }
                  disabledKeyboardNavigation
                  required={ true }
                />
              </div>
              <div className="col-sm-3">
                <DatePicker
                  className="form-control input w-fit-content calendar-image"
                  placeholderText="วันที่สิ้นสุด"
                  selected={ moment(this.state.data.endDate) }
                  dateFormat="YYYY-MM-DD"
                  onChange={ (date) => this.handleDateChange(date ? date : '', 'endDate') }
                  minDate={ moment(this.state.data.startDate, 'YYYY-MM-DD').add(1, 'day') }
                  shouldCloseOnSelect={ true }
                  disabledKeyboardNavigation
                  disabled={ this.state.data.startDate ? false : true }
                  required={ true }
                />
              </div>
              <FormNewLine />
              <div className="col-sm-6">
                <TextInput
                  name="budget"
                  placeholder="งบประมาณที่ใช้ (บาท)"
                  target={ this }
                  renderFormatter={value => formatNumberWithComma(value)}
                  dataFormatter={value => value.replace(/,/g, '')}
                  stateKey="data"
                  required={true}
                />
              </div>
          </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
      options: state.other.options,
  }
}

EditCampaignForm = connect(mapStateToProps, null, null, { withRef: true })(EditCampaignForm)

export default EditCampaignForm;