import React, { Component } from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { removeToken } from '../../actions/auth'
import apiClient from '../../apis'
import config from '../../common/config'

import NavLink from './NavLink'
import UserProfile from './UserProfile'

class NavBar extends Component {
    isLinkActive = pathName => {
        return this.props.router.location.pathname.indexOf(pathName) !== -1
    }

    logout = () => {
        apiClient.auth.signOut()
        localStorage.removeItem('username')
        localStorage.removeItem('accountId')
        this.props.removeToken()
        this.props.router.push('/login')
    }

    allNavbars = [
        { url: '/dashboard', label: 'ภาพรวมโครงการ', icon: 'fa fa-bar-chart' },
        //{ url: '/customers-list', label: 'รายการลูกค้า' },
        { url: '/sale-mode/leads', label: 'หน้าจอขาย', icon: 'fa fa-search' },
        { url: '/schedule', label: 'กิจกรรม', icon: 'fa fa-calendar' },
        { url: '/sale-record', label: 'บันทึกรายการขาย', icon: 'fa fa-male' },
        { url: '/warranty-list', label: 'รับประกันสินค้า', icon: 'fa fa-wrench' },
        { url: '/warranty-list/restricted', label: 'รับประกันสินค้า', icon: 'fa fa-wrench' },
        { url: '/warranty-list/partial', label: 'รับประกันสินค้า', icon: 'fa fa-wrench' },
        { url: '/campaign' , label: 'แคมเปญ', icon: 'fa fa-bullhorn'},
        { url: '/products-list', label: 'รายการสินค้า', icon: 'fa fa-users' },
        { url: '/lead', label: 'ผู้สนใจซื้อ', icon: 'fa fa-search' },
        { url: '/users', label: 'จัดการผู้ใช้งาน', icon: 'fa fa-gear'},
        { url: '/survey-list', label: 'แบบสำรวจ', icon: 'fa fa-question-circle' },
    ]

    render() {
        const { isLinkActive } = this
        const { profile, openProjectModal, role } = this.props
        const perms = role.toString() ? config.permissions[role.toString()] || [] : []
        const ctaNavBar = { url: '/reserve/search', label: 'จองห้อง', icon: 'fa fa-tags' }
        const navbars = this.allNavbars.filter(n => perms.includes(n.url))
        const profileImage = JSON.parse(profile.current_project.meta_json).branch_logo_url

        return (
            <nav className="navbar-toggleable-md " style={{ zIndex: "1100" }}>
                {/* <button className="navbar-toggler navbar-toggler-left" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" }}>
                    <div className="navbar-toggler-icon fa fa-bars fa-2x" ></div>
                </button> */}
                {/* <div className="collapse navbar-collapse" id="navbarNav"> */}
                    <div className="d-flex flex-column pb-15 sticky-top" style={{ height: "100vh", width: "12rem", boxShadow: "0 2px 10px 0 rgba(0,0,0,0.25)", paddingTop:"1.2rem" ,overflow: "scroll"}}>
                        <div style={{padding: '0 1rem', textAlign: 'center'}}>
                            <h6 className="mb-02 text-center"><strong>{profile.current_project ? profile.current_project.name : "ไม่มีโครงการปัจจุบัน"}</strong></h6>
                            <UserProfile 
                                name={localStorage.getItem('username')}
                                affiliation={ mapRole(role.toString()) }
                            />
                        </div>

                        <Link to={ctaNavBar.url} style={{textDecoration: "none", marginBottom: '1rem', width: '180px', marginLeft: '6px'}} className="navbar-cta-button">
                            <div className="py-05" 
                                style={{textAlign: "center", color: '#FFFFFF', borderRadius: '8px'}}>
                                <i className={`lightgrey`} style={{width: "22px"}} />{ctaNavBar.label}
                            </div>
                        </Link>
                        <div style={{padding: '0 1rem'}}>
                            {navbars.map(n => (
                                <NavLink key={n.label} to={n.url} active={isLinkActive(n.url)}>
                                    <i className={`${n.icon} lightgrey mr-1`} style={{width: "18px"}} />{n.label}
                                </NavLink>
                            ))}
                        </div>

                        <div className="mt-auto" style={{padding: '0 1rem'}}>
                            <NavLink>
                                <div onClick={openProjectModal}>
                                    <i className="fa fa-building lightgrey mr-1" style={{ width: "18px" }} />เปลี่ยนโครงการ
                                </div>
                            </NavLink>
                            <div className={ 'navbar-link-logout' }>
                                <div className="pb-15" onClick={ this.logout }>
                                    <i className="fa fa-sign-out mr-1" style={{ width: "18px" }} />ออกจากระบบ
                                </div>
                            </div>
                        </div>

                        <p style={{padding: '0 1.3rem', fontSize: "14px", color: "#c4c4c6"}}>{config.version}</p>
                    </div>
            </nav>
        )
    }
}

function mapStateToProps(state) {
    return {
        profile: state.auth.profile,
        role: state.auth.role
    }
}

function mapRole(role) {
    switch (role) {
        case '0':
            return 'Sales Represent'
        case '1':
            return 'Sales Manager'
        case '2':
            return 'Admin'
        case '3':
            return 'Management'
        case '4':
            return 'Technician'
        default:
            break;
    }
}

export default connect(mapStateToProps, { removeToken })(NavBar)
