import apiClient from '../apis'
import mocks from '../mocks'
import { createPromise } from '../common/util'

const IS_MOCK = false

export function getTranslations() {
    if (IS_MOCK) {
        return createPromise(_convertTranslations(mocks.other.TRANSLATIONS))
    }

    return apiClient.other.getTranslations()
        .then(translations => {
            return _convertTranslations(translations)
        })
}

export function getDashboardData() {
    if (IS_MOCK) {
        return createPromise(mocks.other.DASHBOARD_INFO)
    }

    return apiClient.other.getDashboardData()
        .then(data => {
            return _convertDashboardData(data.data)
        })
}

export function lookupStaff(query) {
    if (IS_MOCK) {
        return createPromise(mocks.other.STAFF_LOOKUP)
    }

    return apiClient.other.lookupStaff(query)
}

function _convertTranslations(data) {

    return { ...data, activities: [[1,"โทรมาสอบถาม"],[2,"อีเมลมาสอบถาม"],[3,"Call out"],[4,"เยี่ยมชมครั้งแรก"],[5,"เยี่ยมชมอีกครั้ง"]],
                      concerns: [["เปรียบเทียบโครงการอื่น","เปรียบเทียบโครงการอื่น"],["ปรึกษาครอบครัว","ปรึกษาครอบครัว"]
                    ,["แบบห้องไม่ถูกใจ","แบบห้องไม่ถูกใจ"],["ยังไม่ชอบทำเล","ยังไม่ชอบทำเล"],["ราคาเกินกว่างบที่ตั้งไว้","ราคาเกินกว่างบที่ตั้งไว้"]
                    ,["ตำแหน่งห้องว่างไม่ถูกใจ","ตำแหน่งห้องว่างไม่ถูกใจ"],["เกรงว่าจะไม่ผ่านการอนุมัติสินเชื่อ","เกรงว่าจะไม่ผ่านการอนุมัติสินเชื่อ"]
                    ,["ภาระเยอะ/เกรงจะผ่อนไม่ไหว","ภาระเยอะ/เกรงจะผ่อนไม่ไหว"], ["รอพาคนตัดสินใจมาดู (พ่อ-แม่-ญาติ)", "รอพาคนตัดสินใจมาดู (พ่อ-แม่-ญาติ)"]
                    ,["รอพาคนตัดสินใจมาดู (แฟน-คู่สมรส)", "รอพาคนตัดสินใจมาดู (แฟน-คู่สมรส)"], ["การดีไซน์รูปแบบอาคาร", "การดีไซน์รูปแบบอาคาร"]
                    ,["สิ่งอำนวยความสะดวก/ส่วนกลางภายในโครงการ", "สิ่งอำนวยความสะดวก/ส่วนกลางภายในโครงการ"], ["เกรงว่าจะยื่นสินเชื่อไม่เต็มวงเงิน","เกรงว่าจะยื่นสินเชื่อไม่เต็มวงเงิน"]
                    , ["อื่นๆ","อื่นๆ"]],
                      room_sizes: [["Type A: 24 ตรม.","Type A: 24 ตรม."],["Type B: 28 ตรม.","Type B: 28 ตรม."], ["Pool Terrace 35","Pool Terrace 35"]]

            }
}

// TODO: Refactor to not have any config hardcoded in client-side.
function _convertDashboardData(data) {
    const converted = data.map(d => {
        return {
            title: d.project_summary.name,
            transfer: {
                total: d.project_summary.total,
                actual: d.project_summary.sold
            },
            documentStatus: {
                notStarted: _getAmount(d.document_status.statuses, "ยังไม่เริ่มยื่นสินเชื่อ"),
                waitingForDocument: _getAmount(d.document_status.statuses, "รอเอกสาร"),
                waitingForBankSubmission: _getAmount(d.document_status.statuses, "รอส่งธนาคาร"),
                waitingForResult: _getAmount(d.document_status.statuses, "รอผลอนุมัติ"),
                receivedUnofficialResult: _getAmount(d.document_status.statuses, "ได้รับผลอนุมัติแบบไม่เป็นทางการ"),
                receivedResult: _getAmount(d.document_status.statuses, "ได้รับผลอนุมัติ"),
                cash: _getAmount(d.document_status.statuses, "จ่ายเงินสด"),
            },
            resultStatus: {
                rejected: _getAmount(d.document_status.final_status, "ไม่อนุมัติ"),
                requestDocument: _getAmount(d.document_status.final_status, "ขอเอกสาร"),
                incompleteLimit: _getAmount(d.document_status.final_status, "ไม่เต็มวงเงิน"),
                completeLimit: _getAmount(d.document_status.final_status, "เต็มวงเงิน"),
            },
            inspectStatus: {
                waiting: _getAmount(d.inspection_status, "รอตรวจรับ"),
                first: _getAmount(d.inspection_status, "ครั้งที่ 1"),
                second: _getAmount(d.inspection_status, "ครั้งที่ 2"),
                third: _getAmount(d.inspection_status, "ครั้งที่ 3 ขึ้นไป"),
                passed: _getAmount(d.inspection_status, "ตรวจรับผ่าน"),
            }
        }
    })
    
    return converted
}

function _getAmount(collection, name) {
    const target = collection.find(e => e.name === name)
    return target ? target.amount : 0
}

export const uploadImage = file => {
    return apiClient.other.uploadFile(file)
}