import React, { PureComponent } from 'react';
import { inModal } from '../../../hoc/inModal';
import ModalTitle from '../../../components/common/ModalTitle';
import TextInput from '../../../components/common/TextInput';
import datastore from '../../../datastore';

export class EditUserForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        id: '',
        fullName: '',
        email: ''
      }
    }
  }

  componentWillMount() {
    const data = this.state.data
    if (this.props.account) {
      data.id = this.props.account.id
      data.fullName = this.props.account.name
      data.email = this.props.account.email
    }

    this.setState({data})
  }

  update = () => {
    if (!this.state.data.fullName) {
      alert('กรุณากรอกชื่อผู้ใช้งาน')
      return Promise.reject()
    } else if (!this.state.data.email) {
      alert('กรุณากรอกอีเมล')
      return Promise.reject()
    } else {
      const p = datastore.userManagement.updateUser(this.state.data)
        .then(result => {
          if(result.cms_id === 11003) {
            datastore.userManagement.updateUserShoyu(this.state.data)
            .then(res => {
              alert('อัพเดตข้อมูลผู้ใช้งานเรียบร้อยแล้ว')
              window.location.reload()
            })
          } else {
            alert('อัพเดตข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง')
          }
        })

      return p
    }
  }

  deactivate = () => {
    const isConfirm = window.confirm(`คุณต้องการที่จะลบผู้ใช้ ${this.props.account.name} ใช่หรือไม่ ?`)
    if (isConfirm) {
      const p = datastore.userManagement.deactivateUser(this.state.data.id)
        .then(result => {
          if(result.cms_id === 11005) {
            alert('ลบผู้ใช้สำเร็จแล้ว')
            window.location.reload()
          }
        })

      return p
    }
  }

  render() {
    const form = (
      <div className="d-flex flex-column" style={{ flexGrow: 1 }}>
        <div className="row">
          <div className="col-sm-6">
            <TextInput 
              name="fullName"
              placeholder="ชื่อผู้ใช้งาน"
              target={ this }
              stateKey="data"
              required={true}
            />
            <p style={{fontSize: '14px', marginTop: '8px', color: '#8A8A8F'}}>
              ชื่อ - นามสกุล ของผู้ใช้งาน
            </p>
          </div>
          <div className="col-sm-6">
            <TextInput 
              name="email"
              placeholder="Email"
              target={ this }
              stateKey="data"
              required={true}
            />
            <p style={{fontSize: '14px', marginTop: '8px', color: '#8A8A8F'}}>
              อีเมลที่ใช้สำหรับเข้าใช้งาน
            </p>
          </div>
        </div>
      </div>
    )

    return (
      <div>
        <ModalTitle title={'แก้ไขข้อมูลผู้ใช้'} />
        {form}
        <div className="d-flex flex-row justify-content-center">
          <button 
              style={{padding: 0}}
              onClick={ () => {
                this.props.closeModal()
              }}
              className="m-05 btn btn-save-cancel btn-outline"
            >
              ยกเลิก
          </button>
          <button 
              style={{padding: 0}}
              onClick={ () => {
                this.update()
              }}
              className="m-05 btn btn-save-cancel btn-main"
            >
              บันทึก
          </button>
        </div>
        <div style={{textAlign: 'center'}}>
          <button
              onClick={() => {
                  this.deactivate()
              }}
              className="m-05 btn btn-save-cancel btn-red-no-border"
              style={{padding: 0}}
          >
              ลบผู้ใช้งาน
          </button>
        </div>
      </div>
    )
  }
}

const EditUserModal = inModal(EditUserForm)
export default EditUserModal;