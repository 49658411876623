import _ from 'lodash'

export const LOAN_INFO = {
    income_status: 1,
    marital_status: 2,
    documents: [
        {
            id: "AA89C0BA26C",
            name: "สำเนาบัตรประชาชน 2 ฉบับ",
            uploaded: true,
        },
        {
            id: "AA89C0VA26C",
            name: "สำเนาทะเบียนบ้าน 2 ฉบับ",
            uploaded: true 
        },
        {
            id: null,
            name: "หนังสือรับรองเงินเดือน (เดือนล่าสุด)",
            uploaded: false
        },
    ],
    banks: [
        {
            id: 1,
            name: "ธนาคารกสิกรไทย",
            icon: "/img/KK-Bank.png",
            estimated_amount: 4000000,
            requested_amount: 4200000,
            status: 0,
            documents: [
                {
                    id: "AA89FR0A26C",
                    name: "สำเนาทะเบียนการค้า/ใบพาณิชย์",
                    uploaded: true
                },
                {
                    id: "AA89C0AF6C",
                    name: "สำเนาใบประกอบวิชาชีพ",
                    uploaded: true
                },
                {
                    id: null,
                    name: "งบการเงินย้อนหลัง",
                    uploaded: false
                },
                {
                    id: null,
                    name: "แผนที่สถานที่ประกอบธุรกิจ",
                    uploaded: false
                },
                {
                    id: null,
                    name: "แบบบ้าน+ผังโครงการ",
                    uploaded: false
                },
            ],
        },
        {
            id: 2,
            name: "ธนาคารไทยพาณิชย์",
            icon: "/img/SCB.png",
            estimated_amount: 5000000,
            requested_amount: 4200000,
            status: 0,
            documents: [
                {
                    id: "AA49C0A26C",
                    name: "สำเนาทะเบียนการค้า/ใบพาณิชย์",
                    uploaded: true
                },
                {
                    id: "AA89C9A26C",
                    name: "สำเนาใบประกอบวิชาชีพ",
                    uploaded: true
                },
                {
                    id: null,
                    name: "งบการเงินย้อนหลัง",
                    uploaded: false
                },
                {
                    id: null,
                    name: "แผนที่สถานที่ประกอบธุรกิจ",
                    uploaded: false
                },
            ],
        },
    ] 
}

export const BANK_TEMPLATES = [
    {
        id: 1,
        name: 'SCB',
        templates: [
            {
                id: 1,
                name: "SCB-template-1"
            },
            {
                id: 2,
                name: "SCB-template-2"
            },
        ]
    },
    {
        id: 2,
        name: 'KBANK',
        templates: [
            {
                id: 3,
                name: "KBANK-template-1"
            },
            {
                id: 4,
                name: "KBANK-template-2"
            },
            {
                id: 5,
                name: "KBANK-template-3"
            }
        ]
    },
    {
        id: 3,
        name: 'KRUNGSRI',
        templates: [
            {
                id: 6,
                name: "KRUNGSRI-template-1"
            },
        ]
    },
]

export function addNewDocumentToMock(bankId, filename) {
    const documents = !bankId
        ? LOAN_INFO.documents
        : (_.find(LOAN_INFO.banks, e => e.id === bankId)).documents

    documents.push({
        name: filename,
        filename: null,
        uploaded: false
    })
}

export function deleteDocumentFromMock(bankId, documentId) {
    const documents = !bankId
        ? LOAN_INFO.documents
        : (_.find(LOAN_INFO.banks, e => e.id === bankId)).documents

    const document = _.find(documents, d => d.name === documentId)
    document.uploaded = false
    document.id = null
}

export function uploadDocumentToMock(bankId, filename) {
    const documents = !bankId
        ? LOAN_INFO.documents
        : (_.find(LOAN_INFO.banks, e => e.id === bankId)).documents

    const document = _.find(documents, d => d.name === filename)
    document.uploaded = true
    document.id = "ASDFBSLJKF"
}
