import React, { Component } from 'react'
import _ from 'lodash'

import datastore from '../datastore'
import { parseAddressAndLogo } from '../common/util'
import Loading from '../components/common/Loading'
import { PrintableList, CoverPage } from '../components/printable'

export default class PrintableDefectList extends Component {
    state = {
        record: null,
        inspection: null,
        project: null,
    }

    componentWillMount() {
        const recordId = this.props.params.recordId
        const inspectionId = this.props.params.inspectionId
        datastore.sale.getSaleRecord(recordId)
            .then(record => {
                const inspection = _.find(record.inspections, ['id', inspectionId])
                this.setState({
                    record: record,
                    inspection: inspection
                })

                return datastore.product.getProduct(record.productId)
            })
            .then(product => {
                this.setState({
                    product
                })
            })


        datastore.user.getProfile()
            .then(res => {
                this.setState({
                    project: res.current_project
                })
            })
    }

    render() {
        if (!this.state.record || !this.state.project || !this.state.product) {
            return <Loading />
        }

        const { address, logoUrl } = parseAddressAndLogo(this.state.project.meta_json)
        return <div> 
            <CoverPage
                customerName={this.state.record.customerName}
                skuCode={this.state.record.skuCode}
                projectName={this.state.record.projectName}
                defectsCount={this.state.inspection.defects.length} 
                staffName={this.state.inspection.staffName}
                witnessName={this.state.inspection.witnessName}
                buildingType={this.state.inspection.buildingType} 
                nextInspectionDate={this.state.inspection.nextInspectionDate}
                customerSignature={this.state.inspection.customerSignatureUrl}
                staffSignature={this.state.inspection.staffSignatureUrl}
                witnessSignature={this.state.inspection.witnessSignatureUrl}
                productType={this.state.product.productTypeId}
                addressNo={this.state.product.addressNo}
                logo={logoUrl}
                address={address}
            />
            <PrintableList 
                skuCode={this.state.record.skuCode}
                projectName={this.state.record.projectName}
                address={address}
                logoUrl={logoUrl}
                inspection={this.state.inspection}
                defects={this.state.inspection.defects}
                firstPageNumber={2}
            />
        </div>
    }
}
