import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router'

import { translate } from '../../../common/util'
import UpdateCustomerLoanTypeModal from './UpdateCustomerLoanTypeModal'

class GeneralDocumentListHeader extends Component { 
    state = {
        isCashOption: false
    }

    componentWillMount() {
        this.setState({
            isCashOption: this.props.isCashOption
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            isCashOption: nextProps.isCashOption
        })
    }

    render() {
        const { 
            count, 
            missing, 
            incomeStatus, 
            maritalStatus,
            options,
            updateCustomerLoanTypeHandler,
            changeCashOptionHandler
        } = this.props

        return (
            <div className="d-flex flex-row align-items-center w-100">
                <img alt="" src={ DOCUMENT_ICON } style={{ maxWidth: "56px", maxHeight: "56px" }} />
                <div className="ml-1">
                    <h5 className="d-inline">เอกสารทั่วไป</h5>
                    <p className="d-inline ml-05 f13 gray">
                        { translate('income_statuses', incomeStatus) }
                    </p>
                    <p className="d-inline ml-05 f13 gray">
                        { translate('marital_statuses', maritalStatus) }
                    </p>

                    <div className="d-inline">
                        <Link 
                            className="link ml-05"
                            onClick={ () => { 
                                this.refs.updateModal.refs.modal.openModal() 
                            }} 
                        >
                            <i className="fa fa-edit" />
                        </Link>
                    </div>

                    <p className="mb-0 grey">
                        จำนวน { count } เอกสาร (ขาด { missing } เอกสาร)
                    </p>
                </div>
                <div className="ml-a mr-1 d-flex flex-row align-items-center">
                    <input 
                        className="input mr-05" 
                        type="checkbox"
                        checked={ this.state.isCashOption }
                        onChange={ e => {
                            changeCashOptionHandler(e.target.checked) 
                            this.setState({ isCashOption: !this.state.isCashOption })
                        }}
                    />
                    <h5 className="m-0">โอนเงินสด</h5>
                </div>
                <UpdateCustomerLoanTypeModal 
                    ref="updateModal" 
                    updateCustomerLoanTypeHandler={ updateCustomerLoanTypeHandler }
                    options={ options }
                />
            </div>
        )
    }
}

GeneralDocumentListHeader.propTypes = {
    count: PropTypes.number,
    missing: PropTypes.number,
    incomeStatus: PropTypes.number,
    maritalStatus: PropTypes.number,
    updateCustomerLoanTypeHandler: PropTypes.func,
    changeCashOptionHandler: PropTypes.func,
    isCashOption: PropTypes.bool,
}

function mapStateToProps(state) {
    return {
        options: state.other.options,
    }
}

export default connect(mapStateToProps, null)(GeneralDocumentListHeader)

const DOCUMENT_ICON = '/img/document.png'