import { postRequest, getRequest, putRequest, deleteRequest } from '../common/customUtil'
import moment from 'moment';
import  config  from '../common/config'

export function createCampaign(data) {
  return postRequest(config.shoyuApiUrl, '/web/campaigns', convertCampaign(data))
}

export function getCampaignList(offset, pageSize) {
  const data = {
    offset: offset,
    limit: pageSize
  }
  return getRequest(config.shoyuApiUrl, '/web/campaigns', data)
}

export function updateCampaign(data, id) {
  return putRequest(config.shoyuApiUrl, `/web/campaigns/${id}`, convertCampaign(data))
}

export function deleteCampaign(campaignId) {
  return deleteRequest(config.shoyuApiUrl, `/web/campaigns/${campaignId}`);
}

export function getAds() {
  return getRequest(config.shoyuApiUrl, '/web/campaigns/advertisements')
}

export function getCampaignDetail(id) {
  return getRequest(config.shoyuApiUrl, `/web/campaigns/${id}`);
}

export function getPromotionList(campaignId, offset, pageSize){
  const data = {
    offset: offset,
    limit: pageSize
  }
  return getRequest(config.shoyuApiUrl, `/web/campaigns/${campaignId}/promotions`, data)
}

export function getPromotionDetail(campaignId, promotionId){
  return getRequest(config.shoyuApiUrl, `/web/campaigns/${campaignId}/promotions/${promotionId}`)
}

export function createPromotion(campaignId, data) {
  return postRequest(config.shoyuApiUrl,`/web/campaigns/${campaignId}/promotions`, convertPromotion(data))
}

export function updatePromotion(campaignId, promotionId, data){
  return putRequest(config.shoyuApiUrl, `/web/campaigns/${campaignId}/promotions/${promotionId}`, convertPromotion(data))
}

export function deletePromotion(campaignId, promotionId) {
  return deleteRequest(config.shoyuApiUrl, `/web/campaigns/${campaignId}/promotions/${promotionId}`)
}

export function createCampaignAds(id, data) {
  return postRequest(config.shoyuApiUrl, `/web/campaigns/${id}/advertisements`, convertCampaignAds(data))
}

export function updateCampaignAds(campaignId, adsId, data) {
  return putRequest(config.shoyuApiUrl, `/web/campaigns/${campaignId}/advertisements/${adsId}`, convertCampaignAds(data))
}

export function deleteCampaignAds(campaignId, adsId) {
  return deleteRequest(config.shoyuApiUrl, `/web/campaigns/${campaignId}/advertisements/${adsId}`)
}

function convertCampaign (data) {
  return {
    name: data.campaignName,
    budget: data.budget,
    start_date: moment(data.startDate).format('YYYY-MM-DD'),
    end_date: moment(data.endDate).format('YYYY-MM-DD')
  }
}

function convertCampaignAds(data) {
  return {
    name: data.adsName,
    cost: data.cost,
    start_date: moment(data.startDate).format('YYYY-MM-DD'),
    end_date: moment(data.endDate).format('YYYY-MM-DD'),
    type: data.adsType
  }
}

function convertPromotion(data) {
  return {
    name: data.promotionName,
    cost: data.cost,
    start_date: moment(data.startDate).format('YYYY-MM-DD'),
    end_date: moment(data.endDate).format('YYYY-MM-DD'),
  }
}