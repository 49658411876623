import React, { Component } from 'react'
import _ from 'lodash'

import datastore from '../datastore'
import Loading from '../components/common/Loading'
import { parseAddressAndLogo, formatDateTime } from '../common/util'
import { WarrantyHeader, PrintableStartInspect } from '../components/printable'

export default class PrintableWarrantyStartInspect extends Component {
    state = {
        product: null,
        defects: null,
        project: null,
    }

    componentWillMount() {
        const productId = this.props.params.productId
        const pathName = window.location.pathname
        const url = new URL(window.location.href)
        const targetDate = url.searchParams.get('target_date')
        datastore.warranty.getDefects(productId, 0, 1000)
            .then(defects => {
                let defect = defects.values
                if (pathName.indexOf('/pinspect-summary') > -1) {
                    defect = defects.values.filter(d => d.fixedDate)
                }

                defect = defects.values.filter(d => d.createdDate.format('YYYY-MM-DD') === targetDate)

                this.setState({ defects: defect })
            })

        datastore.product.getProduct(productId)
            .then(product => {
                this.setState({ product })
                return datastore.user.getProfile()
                    .then(res => {
                        const project = res.projects.find(p => p.id.toString() === product.projectId) 
                        this.setState({ project })
                    })
            })
    }

    render() {
        if (!this.state.defects || !this.state.product || !this.state.project) {
            return <Loading />
        }

        const { address, logoUrl } = parseAddressAndLogo(this.state.project.meta_json)
        const formattedDefects = this.state.defects.map(d => {
            return {
                before: d.before,
                after: d.after,
                title: d.description,
                location: d.location || "ไม่ระบุ"
            }
        })

        const aDefect = this.state.defects.length > 0 ? this.state.defects[0] : {}

        const header = <WarrantyHeader
            projectName={this.state.project.name}
            skuCode={this.state.product.orderingSkuCode}
            logoUrl={logoUrl}
            staffName={aDefect.technicianName}
            date={formatDateTime(aDefect.createdDate)}
        />

        return <PrintableStartInspect 
            header={header}
            skuCode={this.state.product.orderingSkuCode}
            projectName={this.state.project.name}
            address={address}
            logoUrl={logoUrl}
            inspection={formattedDefects}
            defects={formattedDefects}
            firstPageNumber={this.props.firstPageNumber ? this.props.firstPageNumber: 1}
        />
    }
}
