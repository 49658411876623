import React, { PureComponent } from 'react'
import apis from '../../../apis'
import ModalTitle from '../../../components/common/ModalTitle'
import TextInput from '../../../components/common/TextInput'
import { inModal } from '../../../hoc/inModal'
import FormNewLine from '../../../components/common/FormNewLine'
import SelectInput from '../../../components/common/SelectInput'
import _ from 'lodash'
import connect from 'react-redux/lib/connect/connect'

export class EditActivityForm extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      data: {
        activity: null,
        concern: null,
        remark: "",
        interest: "",
      },
      options: [],
      products: [],
      isClick: false
    }

    apis.activity.getActivityChoices().then(result => {
      const options = result.activity_types.map(activity => {
          return {
              value: activity.key,
              label: activity.value
          }
      })
      this.setState({options: options})
    })

    if (this.props.selectedActivity) {
      const data = this.state.data
      
      data.remark = this.props.selectedActivity.remark
      data.interest = this.props.selectedActivity.interest
      data.concern = this.props.selectedActivity.concern
      data.activity = this.props.selectedActivity.activity === 'Booking' ? null : this.mapActivityToValue(this.props.selectedActivity.activity)

      this.setState({data})
    }
  }

  searchProduct = () => {
    if (!this.state.data.interest) {
        this.setState({
            products: [],
            isClick: false
        })
    }  else {
        apis.activity.getProducts(this.state.data.interest)
            .then(res => {
                const product = res.products.map(product => {
                    return {
                        product_id: product.key,
                        value : product.value
                    }
                })
                this.setState({
                    products: product,
                    isClick: false
                })
            })
    }
  }

  mapActivityToValue(activity) {
    switch (activity) {
      case 'Call in':
        return '0'
      case 'Call out':
        return '1'
      case 'Walk':
        return '2'
      case 'Other':
        return '5'
      case 'Contract':
        return '6'
      case 'Pre approve':
        return '7'
      case 'Online registration':
        return '8'
      case 'Line':
        return '9'
      case 'Canceled':
        return '10'
    }
  }

  selectProduct = product => {
      const data = this.state.data
      data.interest = product.value
      this.setState({
          interest: product,
          isClick : true
      })
  }

  deleteActivity() {
    const leadId = this.props.leadId
    const isConfirm = window.confirm('คุณต้องการลบกิจกรรมนี้ใช่หรือไม่?')
    if (isConfirm) {
      const p = apis.activity.deleteActivity(leadId, this.props.selectedActivity.id).then(() => {
        this.props.onUpdateActivity()
        this.props.closeModal()
      })

      return p 
    }
  }

  updateActivity() {
    const leadId = this.props.leadId
    const p = apis.activity.updateSaveActivity(leadId, this.props.selectedActivity.id, this.state.data).then(result => {
      alert('อัพเดตกิจกรรมสำเร็จแล้ว')
      this.props.onUpdateActivity()
      this.props.closeModal()
    })

    return p
  }

  render() {
    console.log(this.props.selectedActivity)
    const { options } = this.props
    const form = (
      <div className="d-flex flex-column" style={{ flexGrow: 1 }}>
          <div className="row">
              <div className="col-sm-6">
                  <selectWrap>
                      <SelectInput
                          name="activity"
                          placeholder="กิจกรรม"
                          target={ this }
                          stateKey="data"
                          options={ this.state.options }
                          disabled={this.props.selectedActivity.activity === 'Booking' ? true : false}
                      />
                  </selectWrap>
              </div>
              <div className="col-sm-6">
                  <selectWrap>
                      <SelectInput
                          name="concern"
                          placeholder="เหตุที่ยังไม่ตัดสินใจ"
                          target={ this }
                          stateKey="data"
                          options={ options.concerns }
                          disabled={this.props.selectedActivity.activity === 'Booking' ? true : false}
                      />
                  </selectWrap>
              </div>
              <FormNewLine />
              <div className="col-sm-6">
                  <TextInput
                      name="interest"
                      placeholder="ห้องที่สนใจ"
                      target={ this }
                      stateKey="data"
                      onChange={() => this.searchProduct() }
                      disabled={this.props.selectedActivity.activity === 'Booking' ? true : false}
                  />
                  {
                      !_.isEmpty(this.state.products) ?
                      !this.state.isClick ?
                      (
                          <div>
                              <div>ผลการค้นหา</div>
                              <div className="p-05" style={{ backgroundColor: "rgba(0,0,0,0.1)", borderRadius: "10px" }}>
                                  { this.state.products.map(product => {
                                      return (
                                      <SearchResultItem
                                          key={product.value}
                                          product={product}
                                          selectProduct={product => this.selectProduct(product)}
                                      />
                                      )
                                  })}
                              </div>
                          </div>
                      ) :
                      null :
                      null
                  }
              </div>
              <div className="col-sm-6">
                  <TextInput
                      name="remark"
                      type="text"
                      placeholder="หมายเหตุ"
                      target={ this }
                      stateKey="data"
                      disabled={this.props.selectedActivity.activity === 'Booking' ? true : false}
                  />
              </div>
          </div>
      </div>
    )

    return (
      <div className="d-flex flex-column">
        <ModalTitle title="แก้ไขกิจกรรม" onClick={ () => this.props.closeModal() } />
        {form}
        <div style={{paddingTop: "24px"}} className="d-flex flex-row justify-content-center">
          <button 
              style={{padding: 0}}
              onClick={ () => {
                this.props.closeModal()
              }}
              className="m-05 btn btn-save-cancel btn-outline"
            >
              ยกเลิก
          </button>
          <button 
            style={{padding: 0}}
            onClick={ () => {
              this.updateActivity()
            }}
            className="m-05 btn btn-save-cancel btn-outline"
            disabled={this.props.selectedActivity.activity === 'Booking' ? true : false}
          >
            บันทึก
          </button>
        </div>
        <div style={{textAlign: 'center'}}>
          <button
              onClick={() => {
                  this.deleteActivity()
              }}
              className="m-05 btn btn-save-cancel btn-red-no-border"
              style={{padding: 0}}
          >
              ลบกิจกรรม
          </button>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
      options: state.other.options,
  }
}

EditActivityForm = connect(mapStateToProps, null, null, { withRef: true })(EditActivityForm)

const EditActivityModal = inModal(EditActivityForm)
export default EditActivityModal

const SearchResultItem = ({product, selectProduct}) => {
  return <div className="clickable" onClick={() => selectProduct(product)}>
      <p className="m-0 p-02">
          {product.value}
      </p>
  </div>
}