import React, { PureComponent } from 'react'
import { inModal } from '../../../hoc/inModal'
import TextInput from '../../../components/common/TextInput'
import ModalTitle from '../../../components/common/ModalTitle'
import FormNewLine from '../../../components/common/FormNewLine'
import SelectInput from '../../../components/common/SelectInput'
import apis from '../../../apis'
import datastore from '../../../datastore'

export class AddUserForm extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      data: {
        fullName: '',
        email: '',
        role: null,
        password: '',
        rePassword: ''
      }
    }

    this.roleChoices = [
      {
        label: 'Sales Represent',
        value: '0'
      },
      {
        label: 'Sales Manager',
        value: '1'
      },
      {
        label: 'Admin',
        value: '2'
      },
      {
        label: 'Management',
        value: '3'
      },
      {
        label: 'Technician',
        value: "4"
      }
    ]
  }

  save = () => {
    if(!this.state.data.fullName) {
      alert('กรุณากรอกชื่อผู้ใช้งาน')
      return Promise.reject()
    } else if (!this.state.data.email) {
      alert('กรุณากรอกอีเมล')
      return Promise.reject()
    } else if (!this.state.data.password) {
      alert('กรุณากรอกรหัสผ่าน')
      return Promise.reject()
    } else if (!this.state.data.rePassword) {
      alert('กรุณากรอกยืนยันรหัสผ่าน')
      return Promise.reject()
    } else if (!this.state.data.role) {
      alert('กรุณาเลือกสิทธิของผู้ใช้งาน')
      return Promise.reject()
    } else if (this.state.data.password !== this.state.data.rePassword) {
      alert('ยืนยันรหัสผ่านไม่ตรงกับรหัสผ่าน กรุณากรอกใหม่อีกครั้ง')
      return Promise.reject()
    } else {
      const generateId = apis.userManagement.generateUserId()
      generateId.then(id => {
        if(id) {
          datastore.userManagement.createUser({
            ...this.state.data,
            id: id
          }).then(result => {
            if (result.cms_id === 11102) {
              datastore.userManagement.createUserShoyu({
                ...this.state.data,
                id: id
              }).then(res => {
                alert('เพิ่มผู้ใช้งานเรียบร้อยแล้ว')
                this.props.closeModal()
                window.location.reload()
              })
            } else {
              alert('เพิ่มผู้ใช้งานไม่สำเร็จ กรุณาลองใหม่อีกครั้ง')
              return Promise.reject()
            }
          })
        }
      })
    }
  }

  render() {
    const form = (
      <div className="d-flex flex-column" style={{ flexGrow: 1 }}>
          <div className="row">
            <div className="col-sm-6">
              <selectWrap>
                <SelectInput
                  name="role"
                  placeholder="สิทธิของผู้ใช้งาน"
                  target={ this }
                  stateKey="data"
                  options={ this.roleChoices }
                />
              </selectWrap>
            </div>
            <FormNewLine />
            <div className="col-sm-6">
              <TextInput 
                name="fullName"
                placeholder="ชื่อผู้ใช้งาน"
                target={ this }
                stateKey="data"
                required={true}
              />
              <p style={{fontSize: '14px', marginTop: '8px', color: '#8A8A8F'}}>
                ชื่อ - นามสกุล ของผู้ใช้งาน
              </p>
            </div>
            <div className="col-sm-6">
              <TextInput 
                name="email"
                placeholder="Email"
                target={ this }
                stateKey="data"
                required={true}
              />
              <p style={{fontSize: '14px', marginTop: '8px', color: '#8A8A8F'}}>
                อีเมลที่ใช้สำหรับเข้าใช้งาน
              </p>
            </div>
            <div className="col-sm-6">
              <TextInput 
                name="password"
                type="password"
                placeholder="รหัสผ่าน"
                target={ this }
                stateKey="data"
                required={true}
              />
            </div>
            <div className="col-sm-6">
              <TextInput 
                name="rePassword"
                type="password"
                placeholder="ยืนยันรหัสผ่าน"
                target={ this }
                stateKey="data"
                required={true}
              />
            </div>
          </div>
      </div>
    )
    return (
      <div>
        <ModalTitle title={'เพิ่มผู้ใช้งาน'} />
        {form}
        <div style={{paddingTop: "24px"}} className="d-flex flex-row justify-content-center">
          <button 
              style={{padding: 0}}
              onClick={ () => {
                this.props.closeModal()
              }}
              className="m-05 btn btn-save-cancel btn-outline"
            >
              ยกเลิก
          </button>
          <button 
              style={{padding: 0}}
              onClick={ () => {
                this.save()
              }}
              className="m-05 btn btn-save-cancel btn-main"
            >
              บันทึก
          </button>
        </div>
      </div>
    )
  }
}

const AddUserModal = inModal(AddUserForm)
export default AddUserModal;
