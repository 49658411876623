import React, { Component } from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import apiClient from '../apis'

const Icon = ({ icon }) => (
    <i className={`fa ${icon} white`} style={{ fontSize: "24px" }} />
)

// const SALE_MODE_OPTIONS = [
//     {
//         label: 'กลับหน้าหลัก',
//         path: '/schedule'
//     },
//     {
//         label: 'ดูรายการสินค้า',
//         path: '/sale-mode/products-list',
//     }
// ]

export default class TitleBar extends Component {
    state = {
        selectedOption: null,
        saleModeOptions: [
            {
                label: 'กลับหน้าหลัก',
                path: '/schedule'
            },
            {
                label: 'ดูรายการสินค้า',
                path: '/sale-mode/products-list',
            }
        ],
        totalActivity: null
    }

    componentWillMount() {
        apiClient.activity.totalActivity().then(result => {
            const saleModeOptions = this.state.saleModeOptions
            saleModeOptions.push({
                label: `งานของเซลล์ (${result.total})`,
                path: '/schedule'
            })
            this.setState({ 
                saleModeOptions,
                totalActivity: result.total
            })
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.options) {
            this.setState({
                selectedOption: nextProps.options[0].value
            })
        }
    }

    handleOptionChange = selectedOption => {
        this.setState({ selectedOption })
    }

    render() {
        const { title, searchCallback, searchPlaceholder, options, backButton, router, leftCtas, hideNavbar } = this.props
        let { ctaButtonCallback, buttonName } = this.props

        const finalLeftCtas = leftCtas

        if (ctaButtonCallback && !Array.isArray(ctaButtonCallback)) {
            ctaButtonCallback = [ctaButtonCallback] 
        }

        if (buttonName && !Array.isArray(buttonName)) {
            buttonName = [buttonName] 
        }

        ctaButtonCallback = ctaButtonCallback ? ctaButtonCallback.filter(c => c !== null) : null
        buttonName = buttonName ?  buttonName.filter(b => b !== null) : null

        if (ctaButtonCallback && buttonName && ctaButtonCallback.length !== buttonName.length) {
            console.err('length of ctaButtonCallback and buttonName should be the same')
        }

        if (hideNavbar) {
            ctaButtonCallback = ctaButtonCallback || []
            buttonName = buttonName || []
            ctaButtonCallback = [
                ...ctaButtonCallback, 
                ...this.state.saleModeOptions.map(o => () => router.push(o.path))
            ]
            buttonName = [
                ...buttonName,
                ...this.state.saleModeOptions.map(o => o.label)
            ]
        }

        return (
            <div className="d-flex flex-row align-items-center px-15 py-1" style={{ height: '72px', backgroundColor: "white" }}>
                {backButton && (
                    <i 
                        className={`fa fa-arrow-left grey mr-1`} 
                        style={{ cursor: 'pointer', fontSize: "24px" }} 
                        onClick={router.goBack}
                    />
                )}
                <h2 className="m-0">{ title }</h2>
                {finalLeftCtas && (
                    <div>
                        {finalLeftCtas.map((cta, index) => (
                            <div 
                                key={index} 
                                className="btn btn-main ml-05" 
                                onClick={cta.callback}
                                style={{ padding: '0.6rem', backgroundColor: cta.color }}
                            >
                                {cta.label}
                            </div>
                        ))}
                    </div>
                )}
                <span className="ml-auto"></span>
                {options 
                    ? <div className="d-flex flex-row">
                        {options.map(v => {
                            return <label key={v.value} className="mr-1 mb-0 d-flex flex-row align-items-center">
                                <input 
                                    type="radio" 
                                    value={v.value} 
                                    checked={v.value === this.state.selectedOption}
                                    onChange={e => this.handleOptionChange(e.target.value)}
                                    className="mr-05" 
                                />
                                {v.label}
                            </label>
                        })}
                    </div> 
                    : null 
                }
                {searchCallback 
                    ? <div className="search-fill">
                        <div className="d-flex flex-row align-items-center">
                            <i className="fa fa-search blue mr-05" ></i>
                            <input type="text" className="form-control input-search" onChange={ e => searchCallback(e.target.value, this.state.selectedOption) } placeholder={ searchPlaceholder } />
                        </div>
                    </div>
                    : null 
                }
                {ctaButtonCallback ? 
                window.location.pathname.indexOf('sale-mode') > 0 ? 
                ctaButtonCallback.map((cb, index) => {
                    return (
                        <div 
                            key={index} 
                            className={`btn ${buttonName[index].includes('งานของเซลล์') ? this.state.totalActivity > 0 ? 'btn-main' : 'btn-outline' : 'btn-outline'} ml-05`}
                            onClick={() => cb()}
                        >
                            {buttonName[index]}
                        </div> 
                    )
                }) :
                ctaButtonCallback.map((cb, index) => {
                        return (
                            <div 
                                key={index} 
                                className={`btn ${buttonName[index].includes('ลบรายชื่อ') ? 'btn-red-outline' : 'btn-main' } ml-05`}
                                onClick={() => cb()}
                            >
                                {buttonName[index]}
                            </div> 
                        )
                    }) : null 
                }
            </div>
        )
    }
}

TitleBar.propTypes = {
    title: PropTypes.string.isRequired,
    ctaButtonCallback: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
    buttonName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    searchCallback: PropTypes.func,
    searchPlaceholder: PropTypes.string,
}
