import { getRequest, postRequest, deleteRequest, openNewWindow, createPromise } from '../common/util'
import { postWithUpload } from '../common/customUtil';

export function getLoanInfo(saleRecordId) {
    return getRequest(`/loan/${saleRecordId}`)
}

export function addNewDocument(loanId, bankId, filename) {
    const data = {
        loan_id: loanId,
        bank_id: bankId,
        name: filename,
        creator_id: 1
    }

    return postRequest('/loan/addDocument', data)
}

// This deletes the document from required document list.
export function deleteDocument(documentId) {
    return deleteRequest(`/loan/removeDocument/${documentId}`, {})
}

export function uploadDocument(documentId, file) {
    return postRequest(`/loan/upload/${documentId}`, file, true, true)
}

// This deletes the uploaded file.
export function deleteUploadedDocument(documentId) {
    return deleteRequest(`/loan/upload/${documentId}`, {})
}

export function downloadDocument(documentId) {
    openNewWindow(`/loan/document/${documentId}`, null)
    return createPromise()
}

export function addBankTemplate(loanId, bankId, templateId, amount, selfSubmission) {
    const data = {
        loan_id: loanId,
        bank_id: bankId,
        template_id: templateId,
        creator_id: 1,
        estimated_amount: 0,
        requested_amount: amount,
        self_submission: selfSubmission,
    }

    return postRequest('/loan/addBankTemplate', data)
}

export function updateCustomerLoanType(loanId, incomeStatus, maritalStatus) {
    const data = {
        loan_id: loanId,
        income_type: incomeStatus,
        marriage_status: maritalStatus,
        creator_id: 1
    }

    return postRequest('/loan/update', data)
}

export function selectPaymentOption(recordId, isCash=true) {
    const data = {
        sale_record_id: recordId,
        sale_amount: 0
    }

    if (isCash) {
        return postRequest('/saleRecord/cash', data)
    } else {
        return postRequest('/saleRecord/loan', data)
    }
}

export function updateBankLoanInfo(loanId, bankId, estimatedAmount, requestedAmount, approvedAmount, status, selfSubmission) {
    const amountData = {
        loan_id: loanId,
        bank_id: bankId,
        estimated_amount: estimatedAmount || 0,
        requested_amount: requestedAmount || 0,
        approved_amount: approvedAmount || 0,
        self_submission: selfSubmission
    }

    const statusData = {
        loan_id: loanId,
        bank_id: bankId,
        status: status
    }

    return Promise.all([
            postRequest('/loan/amount', amountData),
            postRequest('/loan/status', statusData)
        ])
}