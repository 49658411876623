import React from 'react'
import './printable.css'

const InspectionHeader = ({ inspection, projectName, skuCode, logoUrl }) => {
    return <div className="header-section">
        <div>
            <p className="h1">{projectName}</p>
            <p className="h2">ห้อง {skuCode}</p>
            <table className="header-info">
                <tbody>
                    <tr>
                        <td>ครั้งที่ตรวจ: {inspection.inspectionNo}</td>
                        <td>วันที่ตรวจ: {inspection.inspectionDate.split(' ')[0]}</td>
                    </tr>
                    <tr>
                        <td>ผู้ตรวจ: {inspection.customerName}</td>
                        <td>ผู้นำตรวจ: {inspection.staffName}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <img alt="" src={logoUrl} className="logo-project" />
    </div>
}

const WarrantyHeader = ({ projectName, skuCode, logoUrl, staffName, date }) => {
    return <div className="header-section">
        <div>
            <p className="h1">{projectName}</p>
            <p className="h2">ห้อง {skuCode}</p>
            <table className="header-info">
                <tbody>
                    <tr>
                        <td>ผู้ลงบันทึก: {staffName}</td>
                        <td>วันที่ลงบันทึก: {date.split(' ')[0]}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <img alt="" src={logoUrl} className="logo-project" />
    </div>
}

export { InspectionHeader, WarrantyHeader }
