import React, { PureComponent } from 'react';
import { inModal } from '../hoc/inModal';
import ModalTitle from './common/ModalTitle';
import TextWithLabel from './common/TextWithLabel';

export class AssignDetail extends PureComponent {
  constructor(props) {
    super(props)
  }

  render() {
    const { defect } = this.props;
    return (
      <div>
        <ModalTitle title="" onClick={() => this.props.closeModal()} />
        <div className="row justify-content-center">
        {
          defect ?
          <Detail 
            skuCode={defect.skuCode}
            description={defect.description}
            technicianName={defect.technicianName}
            location={defect.location}
            defectType={defect.defectType}
            warrantyType={defect.warrantyType}
            status={defect.status}
            before={defect.before}
            after={defect.after}
            elapsedDays={defect.elapsedDays}
          /> :
          null
        }
          
        </div>
      </div>
    )
  }
}

const AssignDetailModal = inModal(AssignDetail)
export default AssignDetailModal


const Detail = ({ 
  skuCode,
  description, 
  location,
  elapsedDays,
  technicianName,
  status, 
  defectType, 
  warrantyType, 
  before, 
  after,
  uploadHandler }) => (
      <div className="col-12 pr-2 h-100" style={{ maxWidth: "760px" }}>
          <div 
              className="row no-gutters justify-content-between mb-1" 
          >
              <h4>รายละเอียด</h4>
          </div>
          <div className="row no-gutters justify-content-between mb-2">
              <TextWithLabel label="บ้านเลขที่">{skuCode}</TextWithLabel>
              <Status status={status} />
              <TextWithLabel label="วันนับจากแจ้ง">{elapsedDays} วัน</TextWithLabel>
          </div>
          <hr />
          <div className="row no-gutters justify-content-between mb-2">
              <TextWithLabel label="รายละเอียดข้อบกพร่อง">{description}</TextWithLabel>
          </div>
          <div className="row no-gutters justify-content-between mb-2">
              <TextWithLabel label="ชนิดงาน">{defectType}</TextWithLabel>
              <TextWithLabel label="ตำแหน่ง">{location}</TextWithLabel>
              <TextWithLabel label="ประเภท">{warrantyType}</TextWithLabel>
          </div>
          <DefectImages 
              before={before}
              after={after}
          />
          <hr />
          <div className="row no-gutters justify-content-between mb-2">
              <TextWithLabel label="ช่าง">{technicianName}</TextWithLabel>
          </div>
      </div>
  )

const DefectImages = ({ before, after }) => (
  <div className="row no-gutters justify-content-between align-items-start">
      <div>
          <p className="f13 grey">ก่อนแก้ไข</p>
          <img src={ before } width="240px" height="240px" alt="" className="img-rounded" />
      </div>
      <div className="d-flex flex-column justify-content-around">
          {after ? <p className="f13 grey">หลังแก้ไข</p> : null}
          {after ? <img src={ after } width="240px" height="240px" alt="" className="img-rounded" /> : null}
      </div>
  </div>
)

const Status = status => {
  let color, text
  switch (status.status) {
      case 1:
          color = 'red'
          text = 'ยังไม่ได้แก้ไข'
          break
      default:
          color = 'green'
          text = 'แก้ไขแล้ว'
          break
  }
  return <TextWithLabel label="สถานะ" color={ color }>{ text }</TextWithLabel>
}
