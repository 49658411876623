import React, { PureComponent } from 'react';
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { inModal } from '../../../hoc/inModal';
import ModalTitle from '../../../components/common/ModalTitle';
import TextInput from '../../../components/common/TextInput';
import FormNewLine from '../../../components/common/FormNewLine';
import { formatNumberWithComma } from '../../../common/util';
import apis from '../../../apis';
import { Formatter } from '../../../utilities/Formatter';

export class PromotionForm extends PureComponent {
  constructor(props){
    super(props)

    this.state = {
      data: {
        promotionName: props.selectedRecord ? props.selectedRecord.promotion_name : '',
        startDate: props.selectedRecord ? props.selectedRecord.start_date : '',
        endDate: props.selectedRecord ? props.selectedRecord.end_date : '',
        cost: props.selectedRecord ? props.selectedRecord.cost.toString() : ''
      }
    }
  }

  handleDateChange(datetime, key) {
    const data = this.state.data
    data[key] = moment(datetime._d).format('YYYY-MM-DD')
    this.setState({
      [key]: datetime
    })
  }

  create = () => {
    if(!this.state.data.promotionName){
      alert("กรุณากรอกชื่อสื่อโฆษณา");
      return Promise.reject();
    }
    else if(!this.state.data.startDate || !this.state.data.endDate){
      alert("กรุณากรอกวันที่ให้ครบ");
      return Promise.reject();
    }
    else if(moment(this.state.data.endDate, 'YYYY-MM-DD') < moment(this.state.data.startDate, 'YYYY-MM-DD')){
      alert("วันที่ไม่ถูกต้อง กรุณาใส่วันที่ใหม่");
      return Promise.reject();
    }
    else{
      const campaignId = this.props.campaignId
      const p = apis.campaign.createPromotion(campaignId, this.state.data).then(() => {
        this.props.closeModal()
        this.props.onCreatePromotion()
      })

      return p
    }
  }

  update = () => {
    if(!this.state.data.promotionName){
      alert("กรุณากรอกชื่อสื่อโฆษณา");
      return Promise.reject();
    }
    else if(!this.state.data.startDate || !this.state.data.endDate){
      alert("กรุณากรอกวันที่ให้ครบ");
      return Promise.reject();
    }
    else if(moment(this.state.data.endDate, 'YYYY-MM-DD') < moment(this.state.data.startDate, 'YYYY-MM-DD')){
      alert("วันที่ไม่ถูกต้อง กรุณาใส่วันที่ใหม่");
      return Promise.reject();
    }
    else{
      const campaignId = this.props.campaignId
      const p = apis.campaign.updatePromotion(campaignId, this.props.selectedRecord.id, this.state.data).then(() => {
        this.props.closeModal()
        this.props.onCreatePromotion()
      })

      return p
    }
  }

  delete = () => {
    const campaignId = this.props.campaignId
    const isConfirm = window.confirm(`คุณต้องการที่จะลบโปรโมชัน ${this.props.selectedRecord.promotion_name} ใช่หรือไม่?`)
    if (isConfirm) {
      const p = apis.campaign.deletePromotion(campaignId, this.props.selectedRecord.id).then(() => {
        this.props.closeModal()
        this.props.onCreatePromotion()
      })

      return p
    }
  }

  render() {
    const form = (
      <div className="d-flex flex-column" style={{ flexGrow: 1 }}>
          <div className="row">
              <div className="col-sm-6">
                <TextInput 
                  name="promotionName"
                  placeholder="ชื่อ"
                  target={ this }
                  stateKey="data"
                  required={true}
                />
              </div>
              <FormNewLine />
              <div className="col-sm-3 date-picker-position">
                <DatePicker
                  className="form-control input w-fit-content calendar-image"
                  selected={this.state.data.startDate ? moment(this.state.data.startDate) : this.state.data.startDate}
                  placeholderText="วันที่เริ่ม"
                  onChange={ (date) => this.handleDateChange(date ? date : '', 'startDate') }
                  dateFormat={this.state.data.startDate ? `${Formatter.formatDateTh(Formatter.createDate(this.state.data.startDate))}` : ''}
                  shouldCloseOnSelect={ true }
                  disabledKeyboardNavigation
                  required={ true }
                />
              </div>
              <div className="col-sm-3 date-picker-position">
                <DatePicker
                  className="form-control input w-fit-content calendar-image"
                  selected={this.state.data.endDate ? moment(this.state.data.endDate) : this.state.data.endDate}
                  placeholderText="วันที่สิ้นสุด"
                  onChange={ (date) => this.handleDateChange(date ? date : '', 'endDate') }
                  dateFormat={this.state.data.endDate ? `${Formatter.formatDateTh(Formatter.createDate(this.state.data.endDate))}` : ''}
                  minDate={ moment(this.state.data.startDate, 'YYYY-MM-DD').add(1, 'day') }
                  shouldCloseOnSelect={ true }
                  disabledKeyboardNavigation
                  disabled={ this.state.data.startDate ? false : true }
                  required={ true }
                />
              </div>
              <div className="col-sm-6">
                <TextInput
                  name="cost"
                  placeholder="ค่าใช้จ่าย (บาท)"
                  target={ this }
                  renderFormatter={value => formatNumberWithComma(value)}
                  dataFormatter={value => value.replace(/,/g, '')}
                  stateKey="data"
                />
              </div>
          </div>
      </div>
    )
    return (
      <div>
        <ModalTitle
          title={this.props.selectedRecord ? 'แก้ไขโปรโมชั่น' : 'สร้างโปรโมชั่น'}
          onClick={ () => {
            this.props.closeModal()
          }}
        />
        {form}
        <div style={{paddingTop: "24px"}} className="d-flex flex-row justify-content-center">
          <button 
              style={{padding: 0}}
              onClick={ () => {
                this.props.closeModal()
              }}
              className="m-05 btn btn-save-cancel btn-outline"
            >
              ยกเลิก
          </button>
          <button 
            style={{padding: 0}}
            onClick={ () => {
              if (this.props.selectedRecord) {
                this.update()
              } else {
                this.create()
              }
              this.props.closeModal()
            }}
            className="m-05 btn btn-save-cancel btn-outline"
          >
            บันทึก
          </button>
          {
            this.props.selectedRecord ?
            <button 
              style={{padding: 0, width: '125px'}}
              onClick={ () => {
                this.delete()
              }}
              className="m-05 btn btn-red-outline"
            >
              ลบโปรโมชัน
            </button> :
            null
          }
        </div>
      </div>
    )
  }
}

const PromotionModal = inModal(PromotionForm)
export default PromotionModal