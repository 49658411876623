import React, { PureComponent } from 'react';
import ModalTitle from '../common/ModalTitle';
import { inModal } from '../../hoc/inModal';
import TextInput from '../common/TextInput';
import FormNewLine from '../common/FormNewLine';
import SelectInput from '../common/SelectInput';
import { store } from '../..';
import apis from '../../apis';
import { formatNumberWithComma } from '../../common/util';

export class AddProductForm extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      data: {
        skuCode: '',
        standardPrice: '',
        standardDown: '',
        roomTypeId: null,
        buildingId: null,
        floor: null,
        statusId: null
      },
      buildingTypes: [],
      roomTypes: []
    }

    this.statusChoices = [
      {
        label: 'เปิดขาย',
        value: 4
      },
      {
        label: 'ไม่เปิดขาย',
        value: 3
      }
    ]

    this.floorChoices = [
      {
        label: 'ชั้น 1',
        value: 1
      },
      {
        label: 'ชั้น 2',
        value: 2
      },
      {
        label: 'ชั้น 3',
        value: 3
      },
      {
        label: 'ชั้น 4',
        value: 4
      },
      {
        label: 'ชั้น 5',
        value: 5
      },
      {
        label: 'ชั้น 6',
        value: 6
      },
      {
        label: 'ชั้น 7',
        value: 7
      },
      {
        label: 'ชั้น 8',
        value: 8
      }
    ]

    if (this.props.product) {
      const data = this.state.data
      const product = this.props.product
      const detail = this.props.detail
      data.skuCode = product.orderingSkuCode
      data.buildingId = product.buildingId.toString()
      data.roomTypeId = product.roomTypeId.toString()
      data.standardDown = product.standardDown.toString()
      data.standardPrice = product.standardPrice.toString()
      data.floor = detail.floor
      data.statusId = detail.status

      this.setState({data})
    }

  }

  componentWillMount() {
    const translations = store.getState().other.translations
    const buildingTypes = translations.buildings
    const roomTypes = translations.room_types
    Object.entries(buildingTypes).forEach(([key, value]) => {
      this.state.buildingTypes.push({
        label: value,
        value: key
      })
    })
    Object.entries(roomTypes).forEach(([key, value]) => {
      this.state.roomTypes.push({
        label: value,
        value: key
      })
    })
  }

  save = () => {
    if (!this.state.data.skuCode) {
      alert('กรุณากรอกรหัสสินค้า')
      return Promise.reject()
    } else if (!this.state.data.standardPrice) {
      alert('กรุณากรอกราคาขายจริง')
      return Promise.reject()
    } else if (!this.state.data.roomTypeId) {
      alert('กรุณาเลือกประเภท')
      return Promise.reject()
    } else if (!this.state.data.buildingId) {
      alert('กรุณาเลือกรหัสตึก')
      return Promise.reject()
    } else if (!this.state.data.floor) {
      alert('กรุณาเลือกชั้น')
      return Promise.reject()
    } else if (!this.state.data.statusId) {
      alert('กรุณาเลือกสถานะของสินค้า')
      return Promise.reject()
    } else {
      const getId = apis.product.randomId()
      const projectId = store.getState().auth.profile.current_project.id

      getId.then(id => {
        if(id) {
          apis.product.createProduct(
            {
              ...this.state.data,
              id: id,
              projectId: projectId.toString()
            }
          ).then(result => {
            if(result.cms_id === 22002) {
              apis.product.createProductShoyu(
                {
                  ...this.state.data,
                  id: id
                }
              ).then(result => {
                alert('เพิ่มสินค้าเรียบร้อยแล้ว')
                this.props.closeModal()
                window.location.reload()
              })
            }
          })
        }
      })
    }
  }

  update = () => {
    if (!this.state.data.skuCode) {
      alert('กรุณากรอกรหัสสินค้า')
      return Promise.reject()
    } else if (!this.state.data.standardPrice) {
      alert('กรุณากรอกราคาขายจริง')
      return Promise.reject()
    } else if (!this.state.data.roomTypeId) {
      alert('กรุณาเลือกประเภท')
      return Promise.reject()
    } else if (!this.state.data.buildingId) {
      alert('กรุณาเลือกรหัสตึก')
      return Promise.reject()
    } else if (!this.state.data.floor) {
      alert('กรุณาเลือกชั้น')
      return Promise.reject()
    } else if (!this.state.data.statusId) {
      alert('กรุณาเลือกสถานะของสินค้า')
      return Promise.reject()
    } else {
      const projectId = store.getState().auth.profile.current_project.id
      apis.product.updateProduct(this.props.product.id, {
        ...this.state.data,
        id: this.props.product.id,
        projectId: projectId.toString()
      }).then(result => {
        if (result.cms_id === 22003) {
          apis.product.updateProductShoyu({
            ...this.state.data,
            productId: this.props.product.id,
          }).then(result => {
              alert('อัพเดตสินค้าเรียบร้อยแล้ว')
              this.props.closeModal()
              window.location.reload()
          })
        }
      })
    }
  }

  delete = () => {
    const isConfirm = window.confirm(`คุณต้องการที่จะลบสินค้า ${this.state.data.skuCode} ใช่หรือไม่ ?`)
    if (isConfirm) {
      return apis.product.deleteProduct(this.props.product.id)
      .then(result => {
        if (result.cms_id === 22005) {
          apis.product.deleteProductFromShoyu(this.props.product.id)
          .then(() => {
            alert('ลบสินค้าสำเร็จแล้ว')
            this.props.closeModal()
            this.props.router.push('/products-list')
          })
        }
      })
    }
  }

  render() {
    return (
      <div className="d-flex flex-column" style={{ flexGrow: 1 }}>
        <ModalTitle title={`${this.props.product ? 'แก้ไขสินค้า' : 'เพิ่มสินค้าใหม่'}`} />
        <div className="row">
          <div className="col-sm-6">
            <TextInput 
              name="skuCode"
              type="text"
              placeholder="รหัสสินค้า"
              target={ this }
              stateKey="data"
              required={true}
            />
          </div>
          <div className="col-sm-6">
            <selectWrap>
              <SelectInput
                name="statusId"
                placeholder="สถานะ"
                target={ this }
                stateKey="data"
                options={ this.statusChoices }
              />
            </selectWrap>
          </div>
          <FormNewLine />
          <div className="col-sm-6">
            <TextInput 
              name="standardPrice"
              type="text"
              placeholder="ราคาขายจริง"
              target={ this }
              renderFormatter={value => formatNumberWithComma(value)}
              dataFormatter={value => value.replace(/,/g, '')} 
              stateKey="data"
              required={true}
            />
          </div>
          <div className="col-sm-6">
            <TextInput 
              name="standardDown"
              type="text"
              placeholder="ราคาแสดงหลอก"
              renderFormatter={value => formatNumberWithComma(value)}
              dataFormatter={value => value.replace(/,/g, '')} 
              target={ this }
              stateKey="data"
            />
            <p style={{fontSize: '14px', marginTop: '8px', color: '#8A8A8F'}}>
              ใส่ค่าที่มากกว่าราคาขายจริง เพื่อแสดงให้เหมือนลดราคาให้ลูกค้าดู
            </p>
          </div>
          <div className="col-sm-6">
            <selectWrap>
              <SelectInput
                name="roomTypeId"
                placeholder="ประเภท"
                target={ this }
                stateKey="data"
                options={ this.state.roomTypes }
              />
            </selectWrap>
          </div>
          <FormNewLine />
          <div className="col-sm-6">
            <selectWrap>
              <SelectInput
                name="buildingId"
                placeholder="รหัสตึก"
                target={ this }
                stateKey="data"
                options={ this.state.buildingTypes }
              />
            </selectWrap>
          </div>
          <div className="col-sm-6">
            <selectWrap>
              <SelectInput
                name="floor"
                placeholder="ชั้น"
                target={ this }
                stateKey="data"
                options={ this.floorChoices }
              />
            </selectWrap>
          </div>
        </div>
        <div style={{paddingTop: "24px"}} className="d-flex flex-row justify-content-center">
          <button 
              style={{padding: 0}}
              onClick={ () => {
                this.props.closeModal()
              }}
              className="m-05 btn btn-save-cancel btn-outline"
            >
              ยกเลิก
          </button>
          <button 
            style={{padding: 0}}
            onClick={ () => {
              if (this.props.product) {
                this.update()
              } else {
                this.save()
              }
            }}
            className="m-05 btn btn-save-cancel btn-outline"
          >
            บันทึก
          </button>
        </div>
        {
            this.props.product ?
            <div style={{textAlign: 'center'}}>
              <button
                  onClick={() => {
                    if(this.props.product.isTransferred) {
                      alert('ไม่สามารถลบสินค้าที่อยู่ในสถานะ "โอนแล้ว" ได้')
                    } else {
                      this.delete()
                    }
                  }}
                  className="m-05 btn btn-save-cancel btn-red-no-border"
                  style={{padding: 0}}
              >
                  ลบสินค้า
              </button>
            </div> :
            null
        }
      </div>
    )
  }
}

const AddProductModal = inModal(AddProductForm)
export default AddProductModal;