import React from 'react'
import _ from 'lodash'

import Page from './page'
import { InspectionHeader } from './header'
import './printable.css'

const DEFECTS_PER_PAGE = 6
const PrintableList = ({ skuCode, projectName, address, logoUrl, inspection, defects,firstPageNumber }) => {
    let count = firstPageNumber
    const groupedDefects = _.groupBy(defects, 'location')
    const header = <InspectionHeader
        inspection={inspection}
        projectName={projectName}
        skuCode={skuCode}
        logoUrl={logoUrl}
    />

    return <div>
        {Object.entries(groupedDefects).map(([location, defects]) => {
            const originalCount = count
            count += Math.ceil(defects.length / DEFECTS_PER_PAGE)
            const grouped = defects.reduce((acc, item, index) => {
                if (index % DEFECTS_PER_PAGE === 0) {
                    acc.push([])
                }

                const page = acc[acc.length-1]
                if (index % 2 === 0) {
                    page.push([])
                }

                const row = page[page.length-1]
                row.push({ image: item.before, title: item.title })
                return acc
            }, [])
            return grouped.map((p, index) => { 
                return <Page
                    header={header}
                    key={`${location}-${index}`}
                    generalInfo={inspection}
                    defects={p}
                    skuCode={skuCode}
                    location={location}
                    count={originalCount}
                    projectName={projectName}
                    address={address}
                    logo={logoUrl}
                />
            })
        })}
    </div>
}

export default PrintableList
