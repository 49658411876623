import React, { Component } from 'react'

import { ActivityInfoForm } from '../../components/forms/ActivityInfoForm'
import Wrapper from '../../layouts/Wrapper'



export default class AddActivityView extends Component {
    componentWillMount(){
        this.props.setHeader('สร้างกิจกรรม')
    }
    goToShowActivitiesStep() {
        this.props.router.goBack();
   }

    render() {
        return <Wrapper>
                <div className="row">
                    <div className="col-sm-12">
                        <ActivityInfoForm
                            ref="form"
                            noHeaderAndFooter
                            leadId={this.props.params.leadId}
                        />
                        <div className="d-flex flex-row my-3 justify-content-center">
                            <button
                                onClick={() => this.goToShowActivitiesStep()}
                                className="m-05 btn btn-save-cancel btn-outline"
                                style={{padding: 0}}
                            >
                                ยกเลิก
                            </button>
                            <button
                                onClick={() => {
                                    this
                                    .refs
                                    .form.getWrappedInstance()
                                    .save()
                                    .then(this.goToShowActivitiesStep.bind(this))
                                }}
                                className="m-05 btn btn-save-cancel btn-outline"
                                style={{padding: 0}}
                            >
                                บันทึก
                            </button>
                        </div>
                    </div>
                </div>
            </Wrapper>
    }
}