import React from 'react'
import styled from 'styled-components'
import { Colors } from '../../../../theme';
import * as Md from 'styled-icons/material'
import * as Fa from 'styled-icons/fa-solid'
import {ProjectSelection} from './ProjectSelection'

export const Container = styled.div`
    box-shadow: 0 2px 4px 2px rgba(0,0,0,0.15);
    width: 100vw;
    padding: 0.5em;
    box-sizing: border-box;
    display: flex;
    align-items: center;
`

export const LinkButton = styled.button`
    color: ${Colors.BillmeBlue};
    border: none;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 1rem;
    outline: none;
`

const ProjectSelector = styled(ProjectSelection)`
    flex: 1;
`

const LinkContainer = styled.div`
    flex: 3;
    display: flex;
    justify-content: flex-end;
`

export const LeadListHeader = ({router, profile, openProjectModal, ...props}) => (
    <Container>
        <ProjectSelector 
            profile={profile}
            openProjectModal={openProjectModal}
        />
        <LinkContainer>
            <LinkButton onClick={() => router.push('/sale-mode/search-lead?next=activity')}>
                <Md.Search size={24} color={Colors.BillmeBlue} />
                <span style={{paddingLeft: '0.5rem'}}>ค้นหาผู้สนใจ</span>
            </LinkButton>
            <LinkButton onClick={() => router.push('/sale-mode/search-lead?next=activity&activityId=2')}>
                <Md.Person size={24} color={Colors.BillmeBlue} />
                <span style={{paddingLeft: '0.5rem'}}>บันทึกรายการ Walk</span>
            </LinkButton>
            {/*<LinkButton onClick={() => router.push('/sale-mode/search-lead?next=activity&activityId=3')}>
                <Fa.Undo size={16} color={Colors.BillmeBlue} />
                <span style={{paddingLeft: '0.5rem'}}>บันทึกรายการ Re-Visit</span>
            </LinkButton>*/}
            <LinkButton onClick={() => router.push('/sale-mode/search-lead?next=activity&activityId=0')}>
                <Md.PhoneCallback size={24} color={Colors.BillmeBlue} />
                <span style={{paddingLeft: '0.5rem'}}>บันทึกรายการ Call In</span>
            </LinkButton>
            <LinkButton onClick={() => router.push('/sale-mode/search-lead?next=activity&activityId=8')}>
                <Fa.Globe size={24} color={Colors.BillmeBlue} />
                <span style={{paddingLeft: '0.5rem'}}>บันทึกรายการ Online</span>
            </LinkButton>
        </LinkContainer>
    </Container>
)

export default LeadListHeader