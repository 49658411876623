import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Card extends Component {

    render() {
        const { children, className } = this.props
        return (
            <div className={`mb-2 px-15 py-1 ${className}`} style={{ backgroundColor: "white", boxShadow: "0 2px 4px 2px rgba(0,0,0,0.15)", borderRadius: "8px" }}>
                { children }
            </div>
        )
    }
}

Card.propTypes = {
    enabled: PropTypes.bool
}