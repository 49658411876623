import React from 'react'
import './printable.css'

const Page = ({ header, defects, location, count, address, logo }) => {
    const newLineIndex = address.indexOf('เขต')
    const addressLine1 = address.slice(0, newLineIndex)
    const addressLine2 = address.slice(newLineIndex)

    return <div className="a4">
        <div className="print-container">
            <div>
                {header}
                <div className="content-section">
                    <p className="defect-header h1">{location}</p>
                    <table className="defect-table">
                        <tbody>
                            {defects.map((row, rowIndex) => {
                                return <tr key={rowIndex}>
                                    {row.map((d, index) => {
                                        return <td className="defect-info before" key={index}>
                                            <img alt="" src={d.image} className="defect-picture"/>
                                            <p>{d.title}</p>
                                        </td>
                                    })}
                                    {row.length < 1
                                        ? <td className="defect-info before">
                                            <img alt="" src="/img/blank.png" className="defect-picture"/>
                                        </td>
                                        : null
                                    }
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <div className="footer">
                    <img alt="" src={logo} className="logo-project"/>
                    <p className="footer-text">{addressLine1}<br />
                        {addressLine2}
                    </p>
                </div>
                <p className="page-num">{count}</p>
            </div>
        </div>
    </div>
}

export default Page
