import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {GridContainer} from './components/GridContainer'
import {FilterColumn, FILTER_TYPES} from './components/FilterColumn'
import {ContentColumn} from './components/ContentColumn'
import {LeadListHeader} from './components/LeadListHeader'
import ds from '../../../datastore'
import moment from 'moment'
import LeadInfoModal from './leadInfoModal'
import config from '../../../common/config'

const filterCustomersByActivity = activity => customers => customers
        .filter(c => {
            if((activity.includes('Call out')) || (activity.includes('Line'))){
               return c.activities.some(a => a.activity === 'Call out' || a.activity === 'Line')
            } else {
                return c.activities.some(a => a.activity === activity)
            }
        })
        .map(c => {
            if((activity.includes('Call out')) || (activity.includes('Line'))){
                return ({
                    ...c,
                    activities: c.activities.filter(a => a.activity === 'Call out' || a.activity === 'Line')
                })
            } else {
                return ({
                    ...c,
                    activities: c.activities.filter(a => a.activity === activity)
                })
            }
        })

const filterCustomers = filter => customers => {
    switch (filter) {
        case FILTER_TYPES.WALK:
            return filterCustomersByActivity('Walk')(customers)
        case FILTER_TYPES.CALL_IN:
            return filterCustomersByActivity('Call in')(customers)
        case FILTER_TYPES.ONLINE:
            return filterCustomersByActivity('Online registration')(customers)
        case FILTER_TYPES.REVISIT:
            return filterCustomersByActivity('Revisit')(customers)
        case FILTER_TYPES.BOOKED:
            return filterCustomersByActivity('Booking')(customers)
        case FILTER_TYPES.CANCELED:
            return filterCustomersByActivity('Canceled')(customers)
        case FILTER_TYPES.FOLLOW_UP:
            return filterCustomersByActivity('Call out')(customers)
        case FILTER_TYPES.PROSPECT:
            return customers.filter(c => c.prospect === 't')
        case FILTER_TYPES.QUALIFIED:
            return customers.filter(c => c.qualified === 't')
        case FILTER_TYPES.BOTH:
            return customers.filter(c => c.prospect === 't' && c.qualified === 't')
        default:
            return customers
    }
}

export const LeadListsView = ({profile, getLeadActivities, getLeadSummary, filterSummaryByDate, filterActivityByDate, setHeader, router, openProjectModal, ...props}) => {
    const url = new URL(window.location.href)
    const start_date = url.searchParams.get('start_date')
    const end_date = url.searchParams.get('end_date')
    const [filters, setFilters] = useState({type: 0, period: 0, month: moment().month() + 1})
    const [summary, setSummary] = useState({})
    const [customers, setCustomers] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [startDate, setStartDate] = useState(moment())
    const [endDate, setEndDate] = useState(moment())
    const [isFilter, setIsFilter] = useState(false)
    
    const projectId = profile.current_project.id.toString()
    
    useEffect(() => {

        setHeader('รายชื่อผู้สนใจ')
        if(start_date || end_date) {
            setStartDate(moment(start_date))
            setEndDate(moment(end_date))
            filterSummaryByDate(projectId, startDate.format('YYYYMMDD'), endDate.format('YYYYMMDD'))
            .then(setSummary)
            .then(setIsFilter(true))

            filterActivityByDate(projectId, startDate.format('YYYYMMDD'), endDate.format('YYYYMMDD'))
            .then(({customers}) => filterCustomers(filters.type)(customers))
            .then(setCustomers)
        }
        
        if(!isFilter) {
            getLeadSummary(projectId, filters.period, filters.month)
            .then(setSummary)
    
            getLeadActivities(projectId, filters.period, filters.month)
            .then(({customers}) => filterCustomers(filters.type)(customers))
            .then(setCustomers)
        } else {
            filterSummaryByDate(projectId, startDate.format('YYYYMMDD'), endDate.format('YYYYMMDD'))
            .then(setSummary)

            filterActivityByDate(projectId, startDate.format('YYYYMMDD'), endDate.format('YYYYMMDD'))
            .then(({customers}) => filterCustomers(filters.type)(customers))
            .then(setCustomers)
        }

        return () => {

        }
    }, [isFilter, filters, projectId])

    return (
        <React.Fragment>
            <LeadListHeader router={router} openProjectModal={openProjectModal} profile={profile} />
            <GridContainer>
                <FilterColumn 
                    data={summary}
                    filters={filters}
                    isFilterByDate={isFilter}
                    onSelectSection={section => setIsFilter(section)}
                    startDate={startDate}
                    endDate={endDate}
                    onStartDateChange={date => setStartDate(date)}
                    onEndDateChange={date => setEndDate(date)}
                    onFilterUpdate={filters => setFilters(filters)}
                    openProjectModal={openProjectModal}
                    onFilterByDate={() => {
                        filterSummaryByDate(projectId, startDate.format('YYYYMMDD'), endDate.format('YYYYMMDD'))
                        .then(setSummary)
                        .then(setIsFilter(true))
                        filterActivityByDate(projectId, startDate.format('YYYYMMDD'), endDate.format('YYYYMMDD'))
                        .then(({customers}) => filterCustomers(filters.type)(customers))
                        .then(setCustomers)

                        config.history.push(`/sale-mode/leads?start_date=${startDate.format('YYYYMMDD')}&end_date=${endDate.format('YYYYMMDD')}`)
                    }}
                />
                <ContentColumn 
                    data={customers} 
                    router={router} 
                    showCustomerInfo={setSelectedCustomer}  />
            </GridContainer>

            <LeadInfoModal
                router={router}
                show={selectedCustomer != null}
                customer={selectedCustomer}
                closeModal={() => setSelectedCustomer(null)}
            />
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    profile: state.auth.profile,
    LeadStore: {
        getLeadSummary: ds.get
    }
})

const mapActionToProps = dispatch => ({
    getLeadSummary: ds.lead.getLeadSummary,
    getLeadActivities: ds.lead.getLeadActivities,
    filterSummaryByDate: ds.lead.filterSummaryByDate,
    filterActivityByDate: ds.lead.filterActivityByDate
})

export default connect(mapStateToProps, mapActionToProps)(LeadListsView)