import React, { Component } from 'react'
import { Link } from 'react-router'

export default class NavLink extends Component {
    render() {
        const { to, children, active } = this.props
        let className
        if (active) {
            className = "navbar-link-active"
        } else {
            className = "navbar-link"
        }
        
        return (
            <Link to={ to } style={{ textDecoration: "none" }}>
                <div className={ `${className}` }>
                    <div className="py-05 mb-05">{ children }</div>
                </div>
            </Link>
        )
    }
}