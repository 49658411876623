import React, { Component } from 'react'
import Wrapper from '../../layouts/Wrapper'
import { EditInfoForm } from '../../components/forms/EditInfoForm'

export default class EditInfoView extends Component {
   componentWillMount() {
        this.props.setHeader('แก้ไขข้อมูลผู้สนใจ')
   }

   goToShowListStep() {
        this.props.router.goBack()
   }

   goToShowListAfterSucceed() {
       this.props.router.push('/lead')
   }

   render() {
        return <Wrapper>
                <div className="row">
                    <div className="col-sm-12">
                        <EditInfoForm
                            ref="form"
                            noHeaderAndFooter
                            leadId={this.props.params.leadId}
                        />
                        <div className="d-flex flex-row my-3 justify-content-center">
                            <button
                                onClick={() => this.goToShowListStep()}
                                className="m-05 btn btn-save-cancel btn-outline"
                                style={{padding: 0}}
                            >
                                ยกเลิก
                            </button>
                            <button
                                onClick={() => {
                                    this
                                    .refs
                                    .form.getWrappedInstance()
                                    .save()
                                    .then(this.goToShowListAfterSucceed.bind(this))
                                    .catch(err => console.log(err))
                                }}
                                className="m-05 btn btn-save-cancel btn-outline"
                                style={{padding: 0}}
                            >
                                บันทึก
                            </button>
                        </div>
                    </div>
                </div>
            </Wrapper>
   }
}

