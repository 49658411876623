import React, { Component } from 'react'
import PropTypes from 'prop-types'

import TextInput from '../../../components/common/TextInput'
import SelectInput from '../../../components/common/SelectInput'
import FormNewLine from '../../../components/common/FormNewLine'
import ConfirmModal from '../../../components/ConfirmModal'
import CheckboxInput from '../../../components/common/CheckboxInput'
import { getOptions, formatNumberWithComma } from '../../../common/util'
import { APPROVED_STATUS } from '../../../common/const'

class UpdateBankLoanInfoModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                newEstimatedAmount: null,
                newRequestedAmount: null,
                newApprovedAmount: null,
                newStatus: null,
                newSelfSubmission: null,
            }
        }

        this.updateBankLoanInfo = this.updateBankLoanInfo.bind(this)
    }

    componentWillMount() {
        this.setState({
            data: {
                newEstimatedAmount: this.props.estimatedAmount,
                newRequestedAmount: this.props.requestedAmount,
                newApprovedAmount: this.props.approvedAmount,
                newStatus: this.props.status,
                newSelfSubmission: this.props.selfSubmission
            }
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            data: {
                newEstimatedAmount: nextProps.estimatedAmount,
                newRequestedAmount: nextProps.requestedAmount,
                newApprovedAmount: nextProps.approvedAmount,
                newStatus: nextProps.status,
                newSelfSubmission: nextProps.selfSubmission
            }
        })
    }

    updateBankLoanInfo() {
        this.props.updateBankLoanInfoHandler(
            this.state.data.newEstimatedAmount,
            this.state.data.newRequestedAmount,
            this.state.data.newApprovedAmount,
            this.state.data.newStatus,
            this.state.data.newSelfSubmission
        )
    }

    render() {
        const isApprovedStatus = this.state.data.newStatus === APPROVED_STATUS || this.state.data.newStatus === APPROVED_STATUS.toString()

        return (
            <ConfirmModal
                title="แก้ไขราคาประเมินและราคาขอกู้"
                onConfirm={ this.updateBankLoanInfo }
                ref="modal"
            >
                <div className="d-flex flex-column" style={{ flexGrow: 1 }}>
                    <div className="row">
                        <div className="col-6">
                            <p className="f14 grey mb-05"> ราคาประเมิน </p>
                            <TextInput 
                                name="newEstimatedAmount" 
                                placeholder="ราคาประเมิน" 
                                target={this} 
                                stateKey="data"
                                renderFormatter={value => formatNumberWithComma(value)}
                                dataFormatter={value => value.replace(/,/g, '')} 
                            />
                        </div>
                        <div className="col-6">
                            <p className="f14 grey mb-05"> จำนวนที่ขอกู้ </p>
                            <TextInput 
                                name="newRequestedAmount" 
                                placeholder="จำนวนที่ขอกู้" 
                                target={this} 
                                stateKey="data" 
                                renderFormatter={value => formatNumberWithComma(value)}
                                dataFormatter={value => value.replace(/,/g, '')} 
                            />
                        </div>
                        <FormNewLine />
                        <div className="col-6">
                            <p className="f14 grey mb-05"> สถานะการอนุมัติ </p>
                            <selectWrap>
                                <SelectInput
                                    name="newStatus"
                                    placeholder="สถานะการอนุมัติ"
                                    target={this}
                                    stateKey="data"
                                    options={getOptions('loan_statuses')}
                                />
                            </selectWrap>
                        </div>
                        {isApprovedStatus 
                            ? <div className="col-6">
                                <p className="f14 grey mb-05"> จำนวนที่อนุมัติ </p>
                                <TextInput 
                                    name="newApprovedAmount" 
                                    placeholder="จำนวนที่อนุมัติ" 
                                    target={this} 
                                    stateKey="data" 
                                    renderFormatter={value => formatNumberWithComma(value)}
                                    dataFormatter={value => value.replace(/,/g, '')} 
                                />
                            </div>
                            : null
                        }
                        <FormNewLine />
                        <div className="col-12">
                            <CheckboxInput 
                                name="newSelfSubmission" 
                                label="ลูกค้ายื่นเอกสารโดยตรงกับธนาคาร"
                                target={this} 
                                stateKey="data"
                            />
                        </div>
                    </div>
                </div>
            </ConfirmModal>
        )
    }
}

UpdateBankLoanInfoModal.propTypes = {
    estimatedAmount: PropTypes.string,
    requestedAmount: PropTypes.string,
    approvedAmount: PropTypes.string,
    status: PropTypes.number,
    selfSubmission: PropTypes.bool,
    updateBankLoanInfoHandler: PropTypes.func,
}

export default UpdateBankLoanInfoModal