import JSONBig from 'json-bigint'

import config from './config'
import { store } from '../index'
import datastore from '../datastore'


function stringifyParams(params) {
    const keys = Object.keys(params)
    if (keys.length === 0) {
        return ""
    }

    const q = keys.reduce((acc, key) => {
        acc += `&${key}=${params[key]}`
        return acc
    }, "")

    return q.substring(1)
}

function getCurrentHost() {
    const baseUrl = window.location.protocol + '//'
        + window.location.hostname
        + (window.location.port ? ':' + window.location.port : '')

    return baseUrl
}

function getCurrentProjectId() {
    const profile = store.getState().auth.profile
    return profile && profile.current_project && profile.current_project.id
        ? profile.current_project.id.toString()
        : null
}

function validateProjectId(res) {
    if (!res.headers.has(config.projectIdHeaderName)) {
        return res
    }

    const newProjectId = res.headers.get(config.projectIdHeaderName)
    const currentProjectId = getCurrentProjectId()
    if (!newProjectId || currentProjectId === newProjectId) {
        return res
    }

    return datastore.user.switchProject(newProjectId)
        .then(res => {
            const currentPath = window.location.pathname + window.location.search
            reloadPage(currentPath)
        })
}

export function openNewWindow(urlPath, params) {
    const queryString = params ? stringifyParams(params) : ""
    window.open(`${config.baseApiUrl}${urlPath}?${queryString}`, '_blank')
}

export function openNewWindowSameHost(urlPath, params) {
    const baseUrl = getCurrentHost()
    const queryString = params ? stringifyParams(params) : ""

    window.open(`${baseUrl}${urlPath}?${queryString}`, '_blank')
}

export function reloadPage(urlPath, params) {
    const baseUrl = getCurrentHost()
    const queryString = params ? stringifyParams(params) : ""

    window.location = `${baseUrl}${urlPath}?${queryString}`
}

export function getRequest(hostName,urlPath, params, requiredToken=true, parseJson=true) {
    const branch_id = store.getState().auth.profile.current_project.id
    const queryString = params ? stringifyParams(params) : ""
    const headers = new Headers({
        'branch-id': branch_id,
    })
    if (requiredToken) {
        headers.append(config.tokenHeaderName, store.getState().auth.token)
    }

    return new Promise((resolve, reject) => {
        fetch(`${hostName}${urlPath}?${queryString}`, { 
            headers: headers,
            method: 'GET',
            mode: 'cors'
        })
            .then(res => {
                return validateProjectId(res)
            })
            .then(res => {
                return parseJson && res ? res.text() : res
            })
            .then(r => {
                if (parseJson) {
                    const parsed = JSONBig.parse(r)
                    resolve(parsed)
                } else {
                    resolve(r)
                }
            })
    })
}

export function postRequest(hostName, urlPath, data, requiredToken=true, isFormData=false) {
    const branch_id = store.getState().auth.profile.current_project.id
    const headers = isFormData 
        ? new Headers({'branch-id': branch_id})
        : new Headers({
            'Content-Type': 'application/json',
            'branch-id': branch_id
        })

    if (requiredToken) {
        headers.append(config.tokenHeaderName, store.getState().auth.token)
    }

    return new Promise((resolve, reject) => {
        fetch(`${hostName}${urlPath}`, { 
            method: 'POST',
            headers: headers,
            body: isFormData ? data : JSON.stringify(data),
            mode: 'cors'
        })
            .then(res => {
                return res.text()
            })
            .then(r => {
                const parsed = isFormData || !r ? r : JSONBig.parse(r)
                resolve(parsed) 
            })
            .catch(r => {
                console.error(r.message)
            })
    })
}

export function putRequest(hostName, urlPath, data, requiredToken=true, isFormData=false) {
    const branch_id = store.getState().auth.profile.current_project.id
    const headers = isFormData 
        ? new Headers({'branch-id': branch_id})
        : new Headers({
            'branch-id': branch_id,
            'Content-Type': 'application/json',
        })

    const token = store.getState().auth.token
    if (requiredToken) {
        headers.append(config.tokenHeaderName, token)
    }

    return new Promise((resolve, reject) => {
        fetch(`${hostName}${urlPath}`, { 
            method: 'PUT',
            headers: headers,
            body: isFormData ? data : JSON.stringify(data),
            mode: 'cors'
        })
            .then(res => {
                return res.text()
            })
            .then(r => {
                const parsed = isFormData || !r ? r : JSONBig.parse(r)
                resolve(parsed) 
            })
            .catch(r => {
                console.error(r.message)
                reject(r)
            })
    })
}

export function deleteRequest(hostName, urlPath, data, requiredToken = true) {
    const branch_id = store.getState().auth.profile.current_project.id
    const headers = new Headers({
        'branch-id': branch_id,
    })

    const token = store.getState().auth.token;
    if (requiredToken) {
      headers.append(config.tokenHeaderName, token);
    }

    return new Promise((resolve, reject) => {
      fetch(`${hostName}${urlPath}`, {
        method: 'DELETE',
        headers: headers,
        body: JSON.stringify(data),
        mode: 'cors',
      })
        .then(res => {
          return res.status;
        })
        .then(r => {
          const parsed = JSONBig.parse(r);
          resolve(parsed);
        })
        .catch(r => {
          console.error(r.message);
        });
    });
}

export function putRequestForUninterested(hostName, urlPath, data, requiredToken=true, isFormData=false) {
    const branch_id = store.getState().auth.profile.current_project.id
    const headers = isFormData 
        ? new Headers({'branch-id': branch_id})
        : new Headers({
            'branch-id': branch_id,
            'Content-Type': 'application/json',
        })

    const token = store.getState().auth.token
    if (requiredToken) {
        headers.append(config.tokenHeaderName, token)
    }

    return new Promise((resolve, reject) => {
        fetch(`${hostName}${urlPath}`, { 
            method: 'PUT',
            headers: headers,
            body: isFormData ? data : JSON.stringify(data),
            mode: 'cors'
        })
            .then(res => {
                return res
            })
            .then(r => {
                const parsed = isFormData || !r ? r : JSONBig.parse(r)
                resolve(parsed) 
            })
            .catch(r => {
                console.error(r.message)
                reject(r)
            })
    })
}

export function postWithUpload(path, file, form, requiredToken=true,isFormData=true) {
    console.log(form)
    var bodyData = new FormData();
    bodyData.append('name', file[0], file[0].name);
    bodyData.append('form', JSON.stringify(form));
    const SUCCESS = 200;

    const headers = new Headers({
      'Accept': 'application/*, image/*'
    });
    if (requiredToken) {
        headers.append(config.tokenHeaderName, store.getState().auth.token)
    }

    return new Promise((resolve, reject) => {
        fetch(
            `${config.baseApiUrl}${path}`,
            {
              method: 'POST',
              headers: headers,
              body: bodyData,
              mode: 'cors'
            }
          )
            .then(res => {
                return res.text()
            })
            .then(r => {
                const parsed = isFormData || !r ? r : JSONBig.parse(r)
                resolve(parsed) 
            })
            .catch(r => {
                console.error(r.message)
            })
    })
  }

export function getReport(host, path) {
    const headers = new Headers()
    const token = store.getState().auth.token
    headers.append(config.tokenHeaderName, token)

    return fetch(
      `${host}${path}`,
      {
        method: 'GET',
        headers: headers,
        mode: 'cors'
      }
    )
    .then(response => {

      response.blob()
      .then(result => {
        var url = window.URL.createObjectURL(result);
        var a = document.createElement('a');
        a.target = "_blank"
        a.href = url;
        a.click();
      });

      return true;
    })
    .catch(() => {
      return false;
    });
}


export function getReportWithBranchId(host, path) {
    const headers = new Headers()
    const token = store.getState().auth.token
    const branch_id = store.getState().auth.profile.current_project.id
    headers.append(config.tokenHeaderName, token)
    headers.append('branch-id', branch_id)

    return fetch(
      `${host}${path}`,
      {
        method: 'GET',
        headers: headers,
        mode: 'cors'
      }
    )
    .then(response => {

      response.blob()
      .then(result => {
        const name = response.headers.get('content-disposition').split('filename=').pop().replace(/"/g, '');
        var url = window.URL.createObjectURL(result);
        var a = document.createElement('a');
        a.download = name;
        a.href = url;
        a.click();
      });

      return true;
    })
    .catch(() => {
      return false;
    });
}

export function authenticatePostRequest(urlPath, data, requiredToken = true, isFormData = false) {
    const headers = isFormData
      ? new Headers()
      : new Headers({
          'Content-Type': 'application/json',
        });
  
    const token = store.getState().auth.token;
    if (requiredToken) {
      headers.append(config.tokenHeaderName, token);
    }
  
    return fetch(`${config.baseApiUrl}${urlPath}`, {
        method: 'POST',
        headers: headers,
        body: isFormData ? data : JSON.stringify(data),
        mode: 'cors',
      })
        .then(res => {
            return res.text()
        })
        .then(r => {
            const parsed = isFormData || !r ? r : JSONBig.parse(r)
            return parsed 
        })
        .catch(r => {
            console.error(r.message)
        })
  }