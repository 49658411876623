import React, { Component } from 'react'

import Wrapper from '../../layouts/Wrapper'
import BasicInfoContainer from '../../components/BasicInfoCard'
import Loading from '../../components/common/Loading'


export default class BasicCampaignDetailView extends Component {
    render() {
        const { record } = this.props
        if (!record) {
            return <Loading />
        }

        return (
            <Wrapper>
                <GeneralInfoCard record={ record } />
            </Wrapper>
        )
    }
}

class GeneralInfoCard extends Component{
    render(){
        const { record } = this.props
        const data = [
            [
                {
                    label: 'ชื่อแคมเปญ',
                    value: record.campaign_name,
                },
                {
                    label: 'ระยะเวลา',
                    value: record.duration,
                    render: (value) => {
                      return <span>{value} วัน</span>
                    }
                },
                {
                    label: '',
                },
            ],
            [
                {
                    label: 'ค่าใช้จ่ายต่อการเยี่ยมชม',
                    value: record.cost_per_visit,
                    render: value => {
                        return <span>{value} บาท</span>
                    }
                },
                {
                    label: 'ค่าใช้จ่ายต่อการจอง',
                    value: record.cost_per_book,
                    render: value => {
                        return <span>{value} บาท</span>
                    }
                },
                {
                    label: '% การจอง : การเยี่ยมชม',
                    value: record.booking_ratio,
                    render: (value) => {
                        return <span>{parseFloat(value).toFixed(2)}%</span>
                    }
                },
            ],
            [
                {
                    label: 'งบประมาณทั้งหมด',
                    value: record.budget,
                    render: value => {
                        return <span>{value} บาท</span>
                    }
                },
                {
                    label: 'งบประมาณที่ใช้ไป',
                    value: record.spend_budget,
                    render: value => {
                        return <span>{value} บาท</span>
                    }
                },
                {
                    label: '',
                },
            ]
        ]
        return <BasicInfoContainer title="ข้อมูลแคมเปญ" content={ data } />
    }
}