import { postRequest } from '../common/util'
import { authenticatePostRequest } from '../common/customUtil'

export function authenticate(email, password) {
    const data = {
        email: email,
        password: password
    }

    // return postRequest(`/authen/signin`, data, false)
    return authenticatePostRequest(`/authen/signin`, data, false)
}

export function signOut() {
    return postRequest(`/authen/signout`, {}, true)
}
