import { getRequest, postRequest, openNewWindow, createPromise} from "../common/util";
import { postWithUpload } from '../common/customUtil'

export function getDocumentInfo(saleRecordId) {
  return getRequest(`/saleRecord/${saleRecordId}/documents`)
}

export function addGeneralDocument(saleRecordId, fileName, file) {
  const data = {
      sale_record_id: saleRecordId,
      name: fileName
  }
  return postWithUpload(`/saleRecord/documents`,file , data)
}

export function deleteGeneralDocument(saleRecordId, documentId) {
  return postRequest(`/saleRecord/${saleRecordId}/documents/${documentId}/remove`, {})
}

export function downloadGeneralDocument(saleRecordId, documentId) {
  openNewWindow(`/saleRecord/${saleRecordId}/documents/${documentId}`)
  return createPromise()
}