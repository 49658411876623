import { postRequest, getRequest, putRequest } from "../common/util";
import * as Request from "../common/customUtil";
import config from "../common/config";

export function getUserList(offset, pageSize, query) {
  let data = {
    offset: offset,
    page_size: pageSize,
    status: 1
  }

  if (query !== '') {
    return postRequest('/lookup/staff/email', {email: query})
    .then(result => {
      return {
        values: result.accounts,
        length: result.total
      }
    })
  }

  return postRequest('/account/list', data)
    .then(result => {
      return {
        values: result.accounts,
        length: result.total
      }
    })
}


export function searchUser(email) {
  return postRequest('/lookup/staff/email', {email: email})
    .then(result => {
      return {
        values: result.results,
        length: result.results.length
      }
    })
}
export function createUser(userForm) {
  return postRequest('/account/create', userForm)
}

export function createUserShoyu(shoyuUserForm) {
  return Request.postRequest(config.shoyuApiUrl, '/web/accounts', shoyuUserForm)
}

export function updateUser(updateUserForm) {
  return putRequest('/account/update', updateUserForm)
}

export function updateUserShoyu(userId, shoyuUpdateForm) {
  return Request.putRequest(config.shoyuApiUrl, `/web/accounts/${userId}`, shoyuUpdateForm)
}

export function deactivateUser(accountId) {
  return putRequest('/account/deactivate', accountId)
}

export function resetPassword(resetPasswordForm) {
  return putRequest('/account/updatePassword', resetPasswordForm)
}

export function generateUserId() {
  return getRequest('/application/random/id')
  .then(result => {
      return result
  });
}
