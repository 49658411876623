import React from 'react'
import moment from 'moment'
import * as Fa from 'styled-icons/fa-solid'
import * as Md from 'styled-icons/material'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import {Colors} from '../../../../theme/colors'
import {IconButton} from '../../../../theme/components/IconButton'
import {Label} from '../../../../theme/components/Label'
import {Styles} from '../../../../theme/styles'
import {PeriodSelection} from './PeriodSelection'
import {MonthSelection} from './MonthSelection'
import { Formatter } from '../../../../utilities/Formatter'
import config from '../../../../common/config'

export const Container = styled.div`
    padding: 16px;
    width: 340px;
    background-color: ${Colors.LightGrey};
    box-shadow: 0 2px 4px 2px rgba(0,0,0,0.15);
    box-sizing: border-box;
    overflow: scroll;
`

export const FilterGroup = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    border-bottom: 1px solid ${Colors.Grey};
    padding-top: 16px;
    padding-bottom: 16px;
`

export const FILTER_TYPES = {
    WALK: 1,
    CALL_IN: 2,
    ONLINE: 3,
    REVISIT: 4,
    PROSPECT: 5,
    QUALIFIED: 6,
    BOTH: 7,
    BOOKED: 8,
    CANCELED: 9,
    FOLLOW_UP: 10
}

const FilterTile = ({ label, icon, onClick, count, selected }) => {
    return <div 
        className="lead-filter-panel-tile"
        onClick={onClick}
        style={{ marginRight: '6px', backgroundColor: selected ? '#ccc' : null }}
    >
        <div 
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
            }}
        >
            <i 
                className={`${icon} grey mr-1`} 
                style={{ fontSize: "24px" }} 
            />
            <span>{count}</span>
        </div>
        {/*<div style={{ width: '100%' }}>{label}</div>*/}
        <div>{label}</div>
    </div>
}

export const FilterColumn = ({data, filters, startDate, endDate, isFilterByDate, onSelectSection, onStartDateChange, onFilterUpdate, onEndDateChange, onFilterByDate, ...props}) => {
    return (
    <Container>
        <FilterGroup>
            <DatePicker 
                className="w-100"
                placeholderText="ตั้งแต่วันที่"
                selected={startDate}
                onChange={ onStartDateChange }
                dateFormat={Formatter.formatDateTh(startDate)}
                maxDate={moment()}
                shouldCloseOnSelect={ true }
                disabledKeyboardNavigation
            />
            <DatePicker 
                className="w-100"
                placeholderText="ถึงวันที่"
                selected={endDate}
                onChange={ onEndDateChange }
                dateFormat={Formatter.formatDateTh(endDate)}
                maxDate={moment()}
                minDate={startDate}
                shouldCloseOnSelect={ true }
                disabledKeyboardNavigation
            />
            <div></div>
            <button
                className="btn btn-outline w-100 h-75"
                onClick={onFilterByDate}
                disabled={ startDate && endDate ? false : true }
            >
                <label>ค้นหา</label>
            </button>
        </FilterGroup>
        <FilterGroup>
            <PeriodSelection 
                selected={filters.period} 
                onSelected={period => {
                    onSelectSection(false)
                    onFilterUpdate({...filters, period: period})
                    if (period === 0) {
                        config.history.push('/sale-mode/leads')
                        onStartDateChange(moment())
                        onEndDateChange(moment())
                    } else if (period === 1) {
                        config.history.push('/sale-mode/leads')
                        onStartDateChange(moment().startOf('week').add(1, 'days'))
                        onEndDateChange(moment().endOf('week').add(1, 'days'))
                    } else {
                        config.history.push('/sale-mode/leads')
                        onStartDateChange(moment(`${moment().year()}-${filters.month}-01`,'YYYY-MM-DD').startOf('month'))
                        onEndDateChange(moment(`${moment().year()}-${filters.month}-01`).endOf('month'))
                    }
                }}
                />
            <MonthSelection 
                selected={filters.month}
                disabled={filters.period !== 2 || isFilterByDate}
                onSelected={month => {
                    const startDate = moment(`${moment().year()}-${month}-01`,'YYYY-MM-DD').startOf('month')
                    const endDate = moment(`${moment().year()}-${month}-01`,'YYYY-MM-DD').endOf('month')
                    onSelectSection(false)
                    onFilterUpdate({...filters, month: month})
                    onStartDateChange(startDate)
                    onEndDateChange(endDate)
                }}
                />
        </FilterGroup>
        <FilterGroup>
            <IconButton 
                icon={<Md.Person size={32} />} 
                active={filters.type === FILTER_TYPES.WALK}
                onClick={() => {
                    if(filters.type !== FILTER_TYPES.WALK) {
                        onFilterUpdate({...filters, type: FILTER_TYPES.WALK})
                    } else {
                        onFilterUpdate({...filters, type: 0})
                    }
                }}
                >
                <span style={Styles.Small}>Walk</span>
                <Label>{data.walk} ครั้ง</Label>
            </IconButton>
            <IconButton 
                icon={<Md.PhoneCallback size={32} />}
                active={filters.type === FILTER_TYPES.CALL_IN}
                onClick={() => {
                    if(filters.type !== FILTER_TYPES.CALL_IN) {
                        onFilterUpdate({...filters, type: FILTER_TYPES.CALL_IN})
                    } else {
                        onFilterUpdate({...filters, type: 0})
                    }
                }}
                >
                <span style={Styles.Small}>Call In</span>
                <Label>{data.call_in} ครั้ง</Label>
            </IconButton>
            <IconButton 
                icon={<Fa.Undo size={32} />}
                active={filters.type === FILTER_TYPES.REVISIT}
                onClick={() => {
                    if(filters.type !== FILTER_TYPES.REVISIT) {
                        onFilterUpdate({...filters, type: FILTER_TYPES.REVISIT})
                    } else {
                        onFilterUpdate({...filters, type: 0})
                    }
                }}
                >
                <span style={Styles.Small}>Re-Visit</span>
                <Label>{data.revisit} ครั้ง</Label>
            </IconButton>
            <IconButton 
                icon={<Fa.Globe size={32} />}
                active={filters.type === FILTER_TYPES.ONLINE}
                onClick={() => {
                    if(filters.type !== FILTER_TYPES.ONLINE) {
                        onFilterUpdate({...filters, type: FILTER_TYPES.ONLINE})
                    } else {
                        onFilterUpdate({...filters, type: 0})
                    }
                }}
                >
                <span style={Styles.Small}>Online</span>
                <Label>{data.online_registration} ครั้ง</Label> 
            </IconButton>
        </FilterGroup>
        <FilterGroup>
            <IconButton 
                icon={<Fa.Running size={32} />}
                active={filters.type === FILTER_TYPES.FOLLOW_UP}
                onClick={() => {
                    if(filters.type !== FILTER_TYPES.FOLLOW_UP) {
                        onFilterUpdate({...filters, type: FILTER_TYPES.FOLLOW_UP})
                    } else {
                        onFilterUpdate({...filters, type: 0})
                    }
                }}
                >
                <span style={Styles.Small}>Follow Up</span>
                <Label>{data.follow_up} ครั้ง</Label> 
            </IconButton>
        </FilterGroup>
        <FilterGroup>
            <IconButton 
                icon={<Fa.CheckCircle size={32} />} 
                color={Colors.Green}
                active={filters.type === FILTER_TYPES.BOOKED}
                onClick={() => {
                    if(filters.type !== FILTER_TYPES.BOOKED) {
                        onFilterUpdate({...filters, type: FILTER_TYPES.BOOKED})
                    } else {
                        onFilterUpdate({...filters, type: 0})
                    }
                }}
                >
                <span style={Styles.Small}>Booking</span>
                <Label>{data.booking} ยูนิต</Label>
            </IconButton>
            <IconButton 
                icon={<Fa.TimesCircle size={32} />} 
                color={Colors.Red}
                onClick={() => {
                    if(filters.type !== FILTER_TYPES.CANCELED) {
                        onFilterUpdate({...filters, type: FILTER_TYPES.CANCELED})
                    } else {
                        onFilterUpdate({...filters, type: 0})
                    }
                }}
                active={filters.type === FILTER_TYPES.CANCELED}>
                <span style={Styles.Small}>Canceled</span>
                <Label>{data.cancel} ยูนิต</Label>
            </IconButton>
        </FilterGroup>
        <FilterGroup>
            <IconButton 
                icon={<Fa.Star size={32} />} 
                color={Colors.Orange}
                onClick={() => {
                    if(filters.type !== FILTER_TYPES.PROSPECT) {
                        onFilterUpdate({...filters, type: FILTER_TYPES.PROSPECT})
                    } else {
                        onFilterUpdate({...filters, type: 0})
                    }
                }}
                active={filters.type === FILTER_TYPES.PROSPECT}>
                <span style={Styles.Small}>Prospect</span>
                <Label>{data.prospect} คน</Label>
            </IconButton>
            <IconButton 
                icon={<Fa.ClipboardCheck size={32} />} 
                color={Colors.Orange}
                onClick={() => {
                    if(filters.type !== FILTER_TYPES.QUALIFIED) {
                        onFilterUpdate({...filters, type: FILTER_TYPES.QUALIFIED})
                    } else {
                        onFilterUpdate({...filters, type: 0})
                    }
                }}
                active={filters.type === FILTER_TYPES.QUALIFIED}>
                <span style={Styles.Small}>Qualified</span>
                <Label>{data.qualified} คน</Label>
            </IconButton>
        </FilterGroup>
    </Container>
    )
}

export default FilterColumn