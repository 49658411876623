import React,{ Component } from 'react'

import Wrapper from '../../layouts/Wrapper'
import LazyTable from '../../components/common/tables/LazyTable'
import Card from '../../components/common/Card'
import moment from 'moment'
import datastore from '../../datastore'
import AcceptActivityModal from './Modal/AcceptActivityModal';
import { store } from '../../index';
import { Formatter } from '../../utilities/Formatter';
import DeclineActivityModal from './Modal/DeclineActivityModal';
import ActivityDetailModal from './Modal/ActivityDetailModal'
import EditActivityDetailModal from './Modal/EditActivityDetailModal'
import connect from 'react-redux/lib/connect/connect'
import SelectInput from '../../components/common/SelectInput'
import './styles/activityLists.css'

export default class ActivityListsView extends Component {
    constructor(props){
        super(props)

        this.state = {
            activity: null,
            data: {
                period: 0
            },
            filterOptions: [
                {
                    label: 'วันนี้',
                    value: '0',
                },
                {
                    label: 'สัปดาห์นี้',
                    value: '1',
                },
                {
                    label: 'ทั้งหมด',
                    value: '2',
                }
            ]
        }

        this.onCancelDecline = this.onCancelDecline.bind(this)
        this.onEditDetail = this.onEditDetail.bind(this)
        this.onClickBack = this.onClickBack.bind(this)
        this.onUpdateActivity = this.onUpdateActivity.bind(this)
        this.rowClickCallback = this.rowClickCallback.bind(this)
        this.onCancelUpdate = this.onCancelUpdate.bind(this)
        this.getValues = this.getValues.bind(this)
        this.columns = [
            {
                header: 'สถานะ',
                accessor: 'targetDate',
                unSortable: true,
                render: value => {
                    const date = moment(value)
                    if(date.diff(moment().format('YYYY-MM-DD'), 'days') >= 3) {
                        return(
                            <div style={{margin: "3px 0 0 12px"}}>
                                <div className="green-status-indicator" />
                            </div>
                        )
                    } else if (date.diff(moment().format('YYYY-MM-DD'), 'days') < 3 && date.diff(moment().format('YYYY-MM-DD'), 'days') >= 0) {
                        return(
                            <div style={{margin: "3px 0 0 12px"}}>
                                <div className="yellow-status-indicator" />
                            </div>
                        )
                    } else {
                        return(
                            <div style={{margin: "3px 0 0 12px"}}>
                                <div className="red-status-indicator" />
                            </div>
                        )
                    }
                }
            },
            {
                header: 'ชื่อลูกค้า',
                accessor: 'customerName',
                unSortable: true
            },
            {
                header: 'กิจกรรม',
                accessor: 'type',
                unSortable: true
            },
            {
                header: 'สินค้า',
                accessor: 'product',
                unSortable: true
            },
            {
                header: 'ระยะเวลา',
                accessor: 'targetDate',
                unSortable: true,
                render: value => {
                    let color
                    const dateDiff = moment(value).diff(moment().format('YYYY-MM-DD'), 'days')
                    if(dateDiff >= 0) {
                        color = 'black'
                        return (
                            <div style={{color: color}}>
                                <span>{Formatter.formatDateTh(Formatter.createDate(value))} ({Math.abs(dateDiff)} วัน)</span>
                            </div>
                        )
                    } else {
                        color = 'red'
                        return (
                            <div style={{color: color}}>
                                <span>{Formatter.formatDateTh(Formatter.createDate(value))} ({Math.abs(dateDiff)} วัน)</span>
                            </div>
                        )
                    }
                }
            }
        ]
    }

    componentWillMount() {
        this.props.setHeader('กิจกรรม')
    }

    getValues(offset, pageSize) {
        return datastore.activity.getAllActivities(offset, pageSize, null, this.state.data.period)
    }

    onUpdateActivity(offset, pageSize) {
        return datastore.activity.getAllActivities(offset, pageSize, null, this.state.data.period)
            .then(record => {
            this.setState({
                record
            })
        })
    }

    onClickBack() {
        // this.refs.acceptModal.closeModal()
        // this.refs.declineModal.openModal()
        this.refs.detailModal.closeModal()
    }

    onCancelDecline() {
        this.refs.declineModal.closeModal()
        this.refs.detailModal.openModal()
    }

    onCancelUpdate() {
        this.refs.editDetailModal.closeModal()
        this.refs.detailModal.openModal()
    }

    onEditDetail() {
        this.refs.editDetailModal.openModal()
        this.refs.detailModal.closeModal()
    }

    rowClickCallback(row) {
        this.setState({
            activity: row
        })
        this.refs.detailModal.openModal()
    }

    render() {
        const { options } = this.props
        const role = store.getState().auth.role
        return (
            <Wrapper>
                <Card>
                    <div className="select-outline activity-filter">
                        <selectWrap>
                            <SelectInput 
                                name="period"
                                placeholder="สถานะ"
                                target={ this }
                                stateKey="data"
                                options={ this.state.filterOptions }
                            />
                        </selectWrap>
                    </div>
                    <LazyTable 
                        columns={ this.columns } 
                        getValues={ this.getValues } 
                        filterOptions={ options }
                        rowClickCallback={ this.rowClickCallback }
                        ref="listTable"
                    />
                </Card>
                <AcceptActivityModal 
                    ref="acceptModal"
                    role={role}
                    activity={this.state.activity}
                    onUpdateActivity={this.onUpdateActivity}
                    onDeclineActivity={this.onClickBack}
                />
                <DeclineActivityModal
                    ref="declineModal"
                    role={role}
                    activity={this.state.activity}
                    onUpdateActivity={this.onUpdateActivity}
                    onCancelDecline={this.onCancelDecline}
                />
                <ActivityDetailModal 
                    ref="detailModal"
                    activity={this.state.activity}
                    onUpdateActivity={this.onEditDetail}
                    onClickBack={this.onClickBack}
                />
                <EditActivityDetailModal 
                    ref="editDetailModal"
                    activity={this.state.activity}
                    onCancelUpdate={this.onCancelUpdate}
                    onUpdateActivity={this.onUpdateActivity}
                />
            </Wrapper>
        )
    }
}

function mapStateToProps(state) {
    return {
        options: state.other.options,
    }
}

ActivityListsView = connect(mapStateToProps, null)(ActivityListsView)
