import { combineReducers } from 'redux'

import authReducer from './auth'
import otherReducer from './other'

const rootReducer = combineReducers({
    auth: authReducer,
    other: otherReducer,
})

export default rootReducer
