import React, { Component } from 'react'
import PropTypes from 'prop-types'
//import Select from 'react-select'
//import 'react-select/dist/react-select.css'
import Async from 'react-select/lib/Async'

import { createPromise } from '../../common/util'

export default class AsyncSelectInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: null,
            cache: null
        }
    }

    componentWillMount() {
        const { target, stateKey, nestedKey, name } = this.props
        const value = nestedKey 
            ? target.state[stateKey][nestedKey][name] 
            : target.state[stateKey][name]

        this.setState({
            value: value
        })
    }

    loadOptionsWithCache = query => {
        const { loadOptions } = this.props

        if (!query) {
            return createPromise(this.state.cache)
        }

        return loadOptions(query)
            .then(({ options }) => {
                this.setState({
                    cache: options
                })

                console.log(options)

                return options
            })
    }

    render() {
        const { name, placeholder, target, stateKey, nestedKey, formatter, className } = this.props
        console.log(this.state.value)
        return (
            <div style={{ minHeight:"160px"}}>
                <Async
                    autoload={ false }
                    cache={ false }
                    className={ className }
                    name={ name }
                    loadOptions={ this.loadOptionsWithCache }
                    placeholder={ placeholder }
                    ignoreCase={ false }
                    clearable={ false }
                    onChange={ e => {
                        // TODO: check null.
                        const data = target.state[stateKey]

                        const value = !e
                            ? null
                            : formatter ? formatter(e.value) : e.value
                        this.setState({
                            value: value
                        })

                        if (nestedKey) {
                            data[nestedKey] = data[nestedKey] || {} 
                            data[nestedKey][name] = value 
                        } else {
                            data[name] = value
                        }

                        target.setState({
                            [stateKey]: data
                        })

                        return value
                    }}
                />
            </div>
        )
    }
}

AsyncSelectInput.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    options: PropTypes.array,
    target: PropTypes.object.isRequired,
    stateKey: PropTypes.string.isRequired,
    nestedKey: PropTypes.string,
    formatter: PropTypes.func,
    loadOptions: PropTypes.func
}
