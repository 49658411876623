import apiClient from '../apis'
import mocks from '../mocks'
import { createPromise, convertInspectionsInfo } from '../common/util'
import { NA_TEXT } from '../common/const'
import { Formatter } from '../utilities/Formatter';

const IS_MOCK = false

export function getSaleRecords(offset, pageSize, sortKey, sortOrder, filters, query) {
    if (IS_MOCK) {
        return createPromise(mocks.sale.SALE_RECORDS_LIST)
    }

    return apiClient.sale.getSaleRecords(offset, pageSize, sortKey, sortOrder, filters, query)
        .then(res => {
            const records = res.values.map(c => convertSaleRecord(c))
            return {
                length: res.length,
                values: records
            }
        })
}

export function exportSaleRecord() {
    return apiClient.sale.exportSaleRecord()
}

export function getSaleRecord(recordId) {
    if (IS_MOCK) {
        return createPromise(mocks.sale.SALE_RECORD_INFO)
    }

    return apiClient.sale.getSaleRecord(recordId)
        .then(res => {
            return convertSaleRecordInfo(res)
        })
}

export function createSaleRecord(recordInfo) {
    if (IS_MOCK) {
        console.log("Creating recordInfo: ")
        console.log(recordInfo)
        return createPromise()
    }

    return apiClient.sale.createSaleRecord(recordInfo)
}

export function assignInspection(recordId, inspectionDate, staffId, engineerName) {
    if (IS_MOCK) {
        console.log(`Assigning: ${recordId} - ${staffId} - ${engineerName}`)
        return createPromise()
    }

    return apiClient.sale.assignInspection(
        recordId, 
        inspectionDate, 
        staffId, 
        engineerName
    )
}

export function quickSell(firstName, lastName, productId) {
    return apiClient.sale.quickSell(firstName, lastName, productId)
}

export function updateTransferInfo(saleRecordId, date) {
    console.log(`${saleRecordId} -- ${date}`)
    return apiClient.sale.updateTransferInfo(saleRecordId, date)
}

export function cancelSaleRecord(recordId) {
    return apiClient.sale.cancelSaleRecord(recordId)
}

function convertSaleRecord(record) {
    if (!record) {
        return record
    }

    const created = Formatter.formatDateTh(Formatter.createDate(record.created))
    return {
        id: record.id,
        orderingSkuCode: record.sku_code,
        customerId: record.customer_id,
        customerName: record.customer_name,
        saleName: record.sale_name,
        documentNo: record.document_no,
        loans: record.loans,
        loanStatus: record.loan_status,
        inspectStatus: record.inspect_status,
        isTransfer: record.is_transfer?1:0,
        nextInspectionDate: record.next_inspection_date,
        isCancel: record.is_cancel,
        created: created
    }
}

const imagePrefix = 'https://storage.googleapis.com/bcrm/'
function convertSaleRecordInfo(record) {

    const inspections = convertInspectionsInfo(record.inspections)

    const projectName = inspections.length > 0
        ? inspections[0].project_name
        : NA_TEXT

    const staffName = inspections.length > 0
        ? inspections[0].inspector_name
        : NA_TEXT

    if (!record.sale_record_meta_detail) {
        record.sale_record_meta_detail = {}
    }

    return {
        id: record.id.toString(),
        skuCode: record.product.sku_code,
        customerId: record.customer.id,
        customerName: `${record.customer.first_name} ${record.customer.last_name}`,
        gender: record.customer.gender,
        occupation: record.customer.occupation,
        age: record.customer.age || NA_TEXT,
        telephone: record.customer.tel,
        email: record.customer.email || NA_TEXT,
        address: record.customer.addresses_json !== 'null' ? JSON.parse(record.customer.addresses_json).home : NA_TEXT,

        staffName: record.sale.full_name,
        documentNo: record.document_no,

        productId: record.product.product_id.toString(),
        buildingId: record.product.building_id.toString(),
        roomType: record.product.room_type_id,
        roomView: record.product.room_view_id,
        productAddress: record.product.address_no,
        projectName: projectName,

        contractNo: record.sale_record_meta_detail.contract_no || NA_TEXT,
        reserveNo: record.sale_record_meta_detail.reserve_no || NA_TEXT,
        contractDate: record.contract_date || NA_TEXT,
        contractType: record.sale_record_meta_detail.contract_type || NA_TEXT,
        contractStatus: record.sale_record_meta_detail.contract_status || NA_TEXT,
        contractAmount: record.sale_record_meta_detail.contract_amount || NA_TEXT,

        loans: record.loans,
        loanStatus: record.loan_status,
        inspectStatus: record.inspect_status,
        inspections: inspections,
        nextInspectionDate: record.next_inspection_date,

        isCashOption: record.is_cash,
        isTransferred: record.is_transfer,
        transferDate: record.transfer_date,
        saleRecordStatus: record.sale_record_status,
        created: record.created || NA_TEXT
    }
}
