import React, { Component } from 'react'
import PropTypes from 'prop-types'

import TextInput from '../../../components/common/TextInput'
import ConfirmModal from '../../../components/ConfirmModal'

class AddLoanDocumentModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                filename: null,
            }
        }
    }

    addDocument() {
        this.props.addDocumentHandler(this.state.data.filename)
    }

    render() {
        return (
            <ConfirmModal
                ref="modal"
                title="เพิ่มเอกสาร"
                onConfirm={ () => this.addDocument() }
            >
                <TextInput 
                    name="filename" 
                    type="text" 
                    target={ this } 
                    stateKey="data" 
                    placeholder="ชื่อเอกสาร"
                />
            </ConfirmModal>
        )
    }
}

AddLoanDocumentModal.propTypes = {
    addDocumentHandler: PropTypes.func
}

export default AddLoanDocumentModal