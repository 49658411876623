import React from 'react'
import styled, {css} from 'styled-components'
import {Styles, Combind} from '../styles'
import {Colors} from '../colors'

export const Label = styled.span.attrs({
    style: Combind([Styles.Label, Styles.Bold])
})`
    color: ${props => props.color || Colors.BillmeBlue};
`

export default Label