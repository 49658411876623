import React, { Component } from 'react'
import '../styles/ExecutiveDashboard.css'

export class SummaryCard extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className={`Dashboard__card ${this.props.type ? this.props.type : 'overall'}`}>
        <img src={this.props.icon} className="summary-icon" width="40" height="40" />
        <p>{this.props.title}</p>
        <h2 className="summary-text">{this.props.summaryText}</h2>
        <p>{this.props.summaryAmountText}</p>
      </div>
    )
  }
}