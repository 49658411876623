import React, { Component } from 'react'
import moment from 'moment'

import Wrapper from '../../layouts/Wrapper'
import datastore from '../../datastore'
import Card from '../../components/common/Card'
import ScheduleInspectionModal from './ScheduleInspectionModal'
import InspectionInfo from './InspectionInfo'
import Loading from '../../components/common/Loading'
import { INSPECTION_PASSED_STATUS } from '../../common/const'

export default class DefectInfoView extends Component {
    assignInspection = (inspectionDate, staffId, engineerName) => {
        datastore.sale.assignInspection(
            this.props.record.id,
            inspectionDate, 
            staffId, 
            engineerName
        )
            .then(res => {
                this.props.updateRecord()
            })
    }

    render() {
        const { record } = this.props
        if (!record) {
            return <Loading />
        }
        console.log(record)

        const inspections = record.inspections.filter(i => i.status !== '0')
        const inspectionCount = inspections.length
        const latestInspection = inspections[inspectionCount - 1]
        const otherInspections = inspections.slice(0, inspectionCount - 1)

        const isPassedInspection = inspections.reduce((isPassed, i) => {
            return isPassed || i.status === INSPECTION_PASSED_STATUS.toString()
        }, false)

        // TODO: This is a hack ...
        // const nextDate = record.nextInspectionDate
        const waitingInspections = record.inspections.filter(i => i.status === '0')

        let nextDate, staffId
        if (waitingInspections.length > 0) {
            const lastestWaitingInspection = waitingInspections[waitingInspections.length - 1]
            nextDate = lastestWaitingInspection.inspectionDate
        }

        // Hack to change CE to BE.
        const datetime = nextDate
            ? moment(nextDate, 'YYYY-MM-DD HH:mm:ss+Z')
            // ? moment(nextDate, 'YYYY-MM-DD HH:mm:ss+Z').add(-543, 'years')
            : null

        return (
            <Wrapper>
                { isPassedInspection
                    ? null
                    : <InspectionCreator 
                        datetime={ datetime }
                        openModal={ () => this.refs.infoModal.refs.modal.openModal() }
                    /> 
                }

                { !latestInspection 
                    ? null
                    : <InspectionContainer title="ตรวจรับครั้งล่าสุด">
                        <InspectionInfo 
                            inspection={ latestInspection } 
                            recordId={ record.id }
                        />
                    </InspectionContainer>
                }

                { otherInspections.length === 0
                    ? null
                    : <InspectionContainer title="ประวัติการตรวจรับ">
                        { otherInspections.map((i, index) => {
                            return <InspectionInfo 
                                inspection={ i } 
                                key={ index } 
                                recordId={ record.id }
                            />
                        })}
                    </InspectionContainer>
                }

                <ScheduleInspectionModal 
                    ref="infoModal" 
                    datetime={ datetime }
                    staffId={ staffId }
                    assignInspection={ this.assignInspection }
                />
            </Wrapper>
        )
    }
}

const InspectionCreator = ({ datetime, openModal }) => {
    if (!datetime) {
        return (
            <Card>
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="lightgrey" style={{ color: 'rgba(0,0,0,0.2)' }}>
                        ตั้งวันนัดให้กับลูกค้าของคุณเพื่อเข้ามาตรวจสอบความเรียบร้อย
                    </div>
                    <button 
                        className="btn btn-main" 
                        style={{ width: "10rem" }} 
                        onClick={ openModal }
                    >
                        ตั้งวันนัด
                    </button>
                </div>
            </Card> 
        )
    }

    const adjustedDatetime = datetime.clone().add(543, 'years')

    return (
        <Card>
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="col-sm-2">
                    <span className="field-title">วันที่นัด</span><br />{ adjustedDatetime.format("YYYY-MM-DD") }
                </div>
                <div className="col-sm-2">
                    <span className="field-title">เวลา</span><br />{ adjustedDatetime.format("HH:mm") }
                </div>
                <button 
                    className="btn btn-outline ml-auto" 
                    onClick={ openModal }
                >
                    แก้ไขวันนัด
                </button>
            </div>
        </Card>
    )
}

const InspectionContainer = ({ title, children }) => {
    return <Card>
        <div className="pb-05"><h5>{ title }</h5></div>
        { children }
    </Card>
}