import React from 'react'
import styled from 'styled-components'
import {Colors} from '../../../../theme/colors'

export const PeriodButton = styled.button`
    padding: 16px;
    border: none;
    background: ${props => props.active ? Colors.BillmeBlue : Colors.White};
    color: ${props => props.active ? Colors.White : Colors.BillmeBlue};
    font-size: 0.725rem;
    font-weight: ${props => props.active ? 'normal' : 'bold'};
    flex: 1;
    margin-left: 1px;
    outline: none;
    cursor: pointer;
`

export const Container = styled.div`
    border: 1px solid;
    border-color: ${Colors.BillmeBlue};
    background: ${Colors.BillmeBlue};
    display: flex;
    border-radius: 0.5rem;
    overflow: hidden;
    height: 50px;
    & ${PeriodButton}:nth-child(1) {
        margin-left: 0px;
    }
`


export const PeriodSelection = ({selected, onSelected, ...props}) => (
    <Container>
        <PeriodButton active={selected === 0} onClick={() => onSelected(0)}>D</PeriodButton>
        <PeriodButton active={selected === 1} onClick={() => onSelected(1)}>W</PeriodButton>
        <PeriodButton active={selected === 2} onClick={() => onSelected(2)}>M</PeriodButton>
    </Container>
)

export default PeriodSelection