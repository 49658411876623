import React, { Component } from 'react';
import * as Md from 'styled-icons/material'
import * as Fa from 'styled-icons/fa-solid'
import PropTypes from 'prop-types';
import moment from 'moment'
import apiClient from '../../../apis'
import { inModal } from '../../../hoc/inModal';
import ModalTitle from '../../../components/common/ModalTitle';
import ModalFooter from '../../../components/common/ModalFooter';
import TextWithLabel from '../../../components/common/TextWithLabel';
import { getActivityIcon } from './leadListsV2';
import { phoneNumberFormatter } from '../../../common/util';
import IconButton from '../../../theme/components/IconButton';
import Label from '../../../theme/components/Label';
import { Formatter } from '../../../utilities/Formatter';
import EditActivityModal from './EditActivityModal';

export class LeadInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activities: [],
            created: '',
            email: '',
            first_name: '',
            last_name: '',
            gender: '',
            income: '',
            interest: '',
            line_id: '',
            occupation: '',
            phone_number: '',
            prospect: '',
            qualified: '',
            age: '',
            sources: [],
            selectedActivity: null
        }

        this.onConfirm = this.onConfirm.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onUpdateActivity = this.onUpdateActivity.bind(this)
    }

    componentDidMount() {
        const pathName = window.location.pathname;
        if(pathName.indexOf('/book') > -1 || pathName.indexOf('/add-activity') > -1) {
            this.setState({
                created: this.props.customer.created,
                email: this.props.customer.email,
                first_name: this.props.customer.first_name,
                last_name: this.props.customer.last_name,
                gender: this.props.customer.gender,
                income: this.props.customer.income,
                interest: this.props.customer.interest,
                line_id: this.props.customer.line_id,
                occupation: this.props.customer.occupation,
                phone_number: this.props.customer.phone_number,
                age: this.props.customer.age,
                sources: this.props.customer.sources
            })
        } else {
            apiClient.lead.getLead_(0, this.props.customer.id)
            .then(result => {
               this.setState({
                activities: result.activities,
                created: result.created,
                email: result.email,
                first_name: result.first_name,
                last_name: result.last_name,
                gender: result.gender,
                income: result.income,
                interest: result.interest,
                line_id: result.line_id,
                occupation: result.occupation,
                phone_number: result.phone_number,
                prospect: result.prospect,
                qualified: result.qualified,
                age: result.age,
                sources: result.sources
               })
            })
        }
    }

    onUpdateActivity() {
        return apiClient.lead.getLead_(0, this.props.customer.id)
            .then(result => {
               this.setState({
                activities: result.activities,
                created: result.created,
                email: result.email,
                first_name: result.first_name,
                last_name: result.last_name,
                gender: result.gender,
                income: result.income,
                interest: result.interest,
                line_id: result.line_id,
                occupation: result.occupation,
                phone_number: result.phone_number,
                prospect: result.prospect,
                qualified: result.qualified,
                age: result.age,
                sources: result.sources
               })
            })
    }

    onConfirm() {
        const { onConfirm, closeModal } = this.props;
        if (onConfirm) {
            onConfirm();
        }
        closeModal();
    }

    onCancel() {
        const { onCancel, closeModal } = this.props;
        if (onCancel) {
            onCancel();
        }
        const confirm = window.confirm('เมื่อลบรายชื่อแล้วจะไม่สามารถเรียกข้อมูลของรายชื่อนี้ได้อีก ยืนยันที่ลบหรือไม่ ?')
        if(confirm === true) {
            apiClient.lead.deleteLead(this.props.customer.id)
            .then(result => {
                if (result === 204) {
                    closeModal()
                    window.location.reload()
                }
            })
        }
    }

    genderSelector(gender) {
        if (gender === '1') {
            return 'ชาย'
        } else if (gender === '2') {
            return 'หญิง'
        } else {
            return 'ไม่ระบุ'
        }
    }

    handleEditActivityModal() {
        this.refs.editActivity.openModal()
    }

    render() {
        const { closeModal, customer } = this.props;

        return (
            <div className="d-flex flex-column justify-content-between">
                <ModalTitle 
                    title={`${this.state.first_name} ${this.state.last_name}`} 
                    onClick={closeModal} 
                />
                <div className="d-flex justify-content-between">
                    <div style={{ flexGrow: 1 }}>
                        {this.state.prospect === 't' && (
                            <div 
                                style={{
                                    marginBottom: '8px',
                                    width: '50%'
                                }}
                                className="d-flex flex-row align-items-center"
                            >
                               <IconButton
                                    icon={<Fa.Star size={32} />} 
                                >
                                    <Label>Prospect</Label>
                                </IconButton>
                            </div>
                        )}
                        {this.state.qualified === 't' && (
                            <div 
                                style={{
                                    marginBottom: '16px',
                                    width: '50%'
                                }}
                                className="d-flex flex-row align-items-center"
                            >
                                 <IconButton
                                    icon={<Fa.ClipboardCheck size={32} />} 
                                    disable={true}
                                >
                                    <Label>Qualified</Label>
                                </IconButton>
                            </div>
                        )}
                        <TextWithLabel className="mb-1" label="เพศ">
                            {this.genderSelector(this.state.gender)}
                        </TextWithLabel>
                        <TextWithLabel className="mb-1" label="อายุ">
                            {this.state.age !== 0 ? this.state.age : 'ไม่ระบุ'}
                        </TextWithLabel>
                        <TextWithLabel className="mb-1" label="อาชีพ">
                            {this.state.occupation}
                        </TextWithLabel>
                        <TextWithLabel className="mb-1" label="Line ID">
                            {this.state.line_id ? this.state.line_id : 'ไม่ระบุ'}
                        </TextWithLabel>
                        <TextWithLabel className="mb-1" label="E-mail">
                            {this.state.email ? this.state.email : 'ไม่ระบุ'}
                        </TextWithLabel>
                        <TextWithLabel className="mb-1" label="รายได้">
                            {this.state.income}
                        </TextWithLabel>
                        {
                            this.state.sources.length > 0 ?
                            <TextWithLabel className="mb-1" label="สื่อที่มา">
                                {this.state.sources.map(source => {
                                    return <span>{source}<br /></span> 
                                })}
                            </TextWithLabel> :
                            <TextWithLabel className="mb-1" label="สื่อที่มา">
                                {'ไม่ระบุ'}
                            </TextWithLabel>
                        }
                    </div>
                    {customer.activities && (
                        <div className="d-flex flex-column  align-items-center" style={{ flexGrow: 1 }}>
                            <h3>Activities</h3>
                            <div className="lead-activity-container">
                                {this.state.activities.map((a, idx) => {
                                    const icon = getActivityIcon(a.activity)
                                    return <div key={idx} className="lead-activity-card" style={{ width: '100%' }}>
                                        <div className="d-flex flex-row align-items-center">
                                            <i
                                                className={`fa ${icon} ${icon === 'fa-check-circle' ? 'green-icon': icon === 'fa-times-circle' ? 'red' : 'blue'}`}
                                                style={{ fontSize: '36px', padding: '0px 24px 0px 16px' }}
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                {`วันที่: ${Formatter.formatDateTh(Formatter.createDate(a.created))}, เซลล์: ${a.sale_full_name}`}
                                            </div>
                                            <div>{`สินค้า: ${a.interest}`}</div>
                                            <div className="d-flex">
                                            <div className="mr-05">{`เซลล์โน้ต:`}</div> 
                                            <div>{a.concern && `- ${a.concern}`}{a.remark && `- ${a.remark}`}</div>
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => {
                                                this.setState({
                                                    selectedActivity: idx
                                                })
                                                this.handleEditActivityModal()
                                            }}
                                            style={{cursor: 'pointer'}}
                                        >
                                            <p style={{color: '#00ace1'}}>แก้ไข</p>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    )}
                </div>
                {closeModal && (
                    <ModalFooter>
                        <button 
                            onClick={() => this.props.router.push(`/sale-mode/leads/${customer.id}/add-activity`)} 
                            className="btn btn-outline mr-1"
                        >
                            เพิ่มกิจกรรม
                        </button>
                        <button 
                            onClick={() => this.props.router.push(`/sale-mode/leads/${customer.id}/book`)} 
                            className="btn btn-outline mr-1"
                        >
                            จองห้อง
                        </button>
                        <button 
                            onClick={() => this.onCancel()} 
                            className="btn btn-red-outline"
                        >
                            ลบรายชื่อ
                        </button>
                        {/*<button onClick={() => this.onConfirm()} className="btn btn-main" disabled={disabled}>
                จอง
              </button>
              */}
                    </ModalFooter>
                )}
                
                <EditActivityModal 
                    ref="editActivity"
                    selectedActivity={this.state.activities[this.state.selectedActivity]}
                    leadId={this.props.customer.id}
                    onUpdateActivity={this.onUpdateActivity}
                />
            </div>
        );
    }
}

const LeadInfoModal = inModal(LeadInfo);

export default LeadInfoModal;
