import PERMISSIONS from './permissions'
import { createBrowserHistory } from 'history';

const hostname = window && window.location && window.location.hostname
const baseApiUrl = process.env.REACT_APP_BASE_API_URL 
    || (hostname === 'crm.billme.co.th' ? 'https://bcrm.billme.co.th' 
        : hostname === 'staging-crm.billme.co.th' ? 'https://staging-bcrm.billme.co.th'
        : 'https://staging-bcrm.billme.co.th')
const shoyuApiUrl = process.env.REACT_APP_BASE_API_URL 
    || (hostname === 'crm.billme.co.th' ? 'https://shoyu.billme.co.th' 
        : hostname === 'staging-crm.billme.co.th' ? 'https://staging-shoyu.billme.co.th'
        : 'https://staging-shoyu.billme.co.th')

const permissions = {
    0: PERMISSIONS.STAFF,
    1: PERMISSIONS.JURISTIC,
    2: PERMISSIONS.SUPERUSER,
    3: PERMISSIONS.EXECUTIVE,
    4: PERMISSIONS.JURISTIC,
}

const history = createBrowserHistory()

const version = 'V1.8.15'

export default {
    baseApiUrl,
    shoyuApiUrl,
    itemsPerPage: 50,
    tokenCookiesName: 'api-token',
    roleCookiesName: 'user-role',
    tokenHeaderName: 'x-access-token',
    projectIdHeaderName: 'x-project-id',
    permissions,
    version,
    history
}