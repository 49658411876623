import _ from 'lodash'
import {
    SURVEY_STATUS_DRAFT,
    SURVEY_STATUS_ACTIVE,
    QUESTION_TYPE_SINGLE,
    QUESTION_TYPE_MULTI,
    QUESTION_TYPE_TEXT,
    QUESTION_TYPE_TEL,
    QUESTION_TYPE_EMAIL
} from '../common/const'

export const ANSWER_LIST = [
    {
        id: '1',
        questionId: '1',
        key: 'answer_1',
        title: 'Answer 1 of Question 1',
    },
    {
        id: '2',
        questionId: '1',
        key: 'answer_2',
        title: 'Answer 2 of Question 1',
    },
    {
        id: '3',
        questionId: '1',
        key: 'answer_3',
        title: 'Answer 3 of Question 1',
    },
    {
        id: '4',
        questionId: '2',
        key: 'answer_1',
        title: 'Answer 1 of Question 2',
    },
    {
        id: '5',
        questionId: '2',
        key: 'answer_2',
        title: 'Answer 2 of Question 2',
    },
    {
        id: '6',
        questionId: '1',
        key: 'answer_3',
        title: 'Answer 3 of Question 2',
    },
]

export const QUESTION_LIST = [
    {
        id: '1',
        surveyId: '1',
        type: QUESTION_TYPE_SINGLE,
        order: 1,
        title: 'Question 1',
        choices: _.filter(ANSWER_LIST, x => x.questionId === '1')
    },
    {
        id: '2',
        surveyId: '1',
        type: QUESTION_TYPE_MULTI,
        order: 2,
        title: 'Question 2',
        choices: _.filter(ANSWER_LIST, x => x.questionId === '2')
    },
    {
        id: '3',
        surveyId: '1',
        type: QUESTION_TYPE_TEXT,
        order: 3,
        title: 'Question 3'
    },
    {
        id: '4',
        surveyId: '1',
        type: QUESTION_TYPE_TEL,
        order: 4,
        title: 'Question 4'
    },
    {
        id: '5',
        surveyId: '1',
        type: QUESTION_TYPE_EMAIL,
        order: 5,
        title: 'Question 5'
    }
]

export const SURVEY_LIST = [
    {
        id: '1',
        title: "Surveyer 1",
        description: "This is a description1",
        cover_url: 'https://www.placehold.it/900x300',
        questions: _.filter(QUESTION_LIST, x => x.surveyId === '1'),
        status: SURVEY_STATUS_ACTIVE
    }
]