import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import Card from '../../components/common/Card'
import datastore from '../../datastore'
import Wrapper from '../../layouts/Wrapper'
import BankDocumentList from './BankDocumentList'
import GeneralDocumentList from './GeneralDocumentList'
import AddBankModal from './AddBankModal'
import Loading from '../../components/common/Loading'
import { FILE_STATUS } from './DocumentList'

class LoanInfoView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loanInfo: {
                documents: [],
                banks: []
            },
            waitingForAddingBank: false,
            isCashOption: false
        }

        this.recordId = ''
        this.addDocumentHandler = this.addDocumentHandler.bind(this)
        this.deleteDocumentHandler = this.deleteDocumentHandler.bind(this)
        this.deleteHandler = this.deleteHandler.bind(this)
        this.uploadHandler = this.uploadHandler.bind(this)
        this.addBankHandler = this.addBankHandler.bind(this)
        this.downloadHandler = this.downloadHandler.bind(this)
        this.updateCustomerLoanTypeHandler = this.updateCustomerLoanTypeHandler.bind(this)
        this.updateBankLoanInfo = this.updateBankLoanInfo.bind(this)
    }

    componentWillMount() {
        if (this.props.record && this.recordId !== this.props.record.id) {
            this._updateLoanInfo(this.props.record.id)
            this.setState({
                isCashOption: this.props.record.isCashOption
            })
        } else {
            this.recordId = ''
        }
    }

    _updateLoanInfo = recordId => {
        this.recordId = recordId
        this.setState({
            waitingForAddingBank: true
        })

        datastore.loan.getLoanInfo(recordId)
            .then(loanInfo => {
                this.setState({
                    loanInfo: loanInfo,
                    waitingForAddingBank: false,
                })
            })
    }

    addDocumentHandler(bankId, filename) {
        datastore.loan.addNewDocument(this.state.loanInfo.id, bankId, filename)
            .then(res => {
                return datastore.loan.getLoanInfo(this.recordId)
            })
            .then(loanInfo => {
                this.setState({
                    loanInfo: loanInfo
                })
            })
    }

    deleteDocumentHandler(documentId) {
        datastore.loan.deleteDocument(documentId)
            .then(res => {
                return datastore.loan.getLoanInfo(this.recordId)
            })
            .then(loanInfo => {
                this.setState({
                    loanInfo: loanInfo
                })
            })
    }

    deleteHandler(documentId) {
        datastore.loan.deleteUploadedDocument(documentId)
            .then(res => {
                return datastore.loan.getLoanInfo(this.recordId)
            })
            .then(loanInfo => {
                this.setState({
                    loanInfo: loanInfo
                })
            })
    }

    uploadHandler(documentId, file) {
        let loanInfo = this.state.loanInfo
        loanInfo.documents.forEach(doc => {
            if (doc.id === documentId) {
                doc.status = FILE_STATUS.uploading
            }
        })

        loanInfo.banks.forEach(bank => {
            bank.documents.forEach(doc => {
                if (doc.id === documentId) {
                    doc.status = FILE_STATUS.uploading
                }
            })
        })

        this.setState({ loanInfo: loanInfo })
        datastore.loan.uploadDocument(documentId, file)
            .then(res => {
                return datastore.loan.getLoanInfo(this.recordId)
            })
            .then(loanInfo => {
                this.setState({
                    loanInfo: loanInfo
                })
            })
    }

    addBankHandler(bankId, templateId, amount, selfSubmission) {
        this.setState({
            waitingForAddingBank: true
        })

        datastore.loan.addBankTemplate(
            this.state.loanInfo.id,
            bankId,
            templateId,
            amount,
            selfSubmission
        )
            .then(res => {
                return datastore.loan.getLoanInfo(this.recordId)
            })
            .then(loanInfo => {
                this.setState({
                    loanInfo: loanInfo,
                    waitingForAddingBank: false
                })
            })
    }

    downloadHandler(documentId) {
        datastore.loan.downloadDocument(documentId)
            .then(res => {
                console.log(`downloaded ${documentId}`)
                console.log(res)
            })
    }

    updateCustomerLoanTypeHandler(incomeStatus, maritalStatus) {
        datastore.loan.updateCustomerLoanType(
            this.state.loanInfo.id,
            incomeStatus,
            maritalStatus
        )
            .then(res => {
                return datastore.loan.getLoanInfo(this.recordId)
            })
            .then(loanInfo => {
                this.setState({
                    loanInfo: loanInfo
                })
            })
    }

    updateBankLoanInfo(bankId, estimatedAmount, requestedAmount, approvedAmount, status, selfSubmission) {
        datastore.loan.updateBankLoanInfo(
            this.state.loanInfo.id,
            bankId,
            estimatedAmount,
            requestedAmount,
            approvedAmount,
            status,
            selfSubmission
        )
            .then(res => {
                return datastore.loan.getLoanInfo(this.recordId)
            })
            .then(loanInfo => {
                this.setState({
                    loanInfo: loanInfo
                })
            })
    }

    changeCashOptionHandler = enable => {
        datastore.loan.selectPaymentOption(this.recordId, enable)
            .then(res => {
                this._updateLoanInfo(this.recordId)
            })

        this.setState({
            isCashOption: enable
        })
    }

    render() {
        const { bankOptions, record } = this.props
        const loanInfo = this.state.loanInfo

        if (!bankOptions || !loanInfo || !record) {
            return <Loading />
        }

        const banks = loanInfo.banks.filter(b => b.id)
        const existingBankIds = banks.map(b => b.id)

        const filteredBankOptions = {
            primary: bankOptions.primary.filter(b => !_.includes(existingBankIds, b.value.bankId)),
            secondary: bankOptions.secondary.filter(b => !_.includes(existingBankIds, b.value.bankId))
        }

        return (
            <Wrapper>
                <Card>
                    <GeneralDocumentList
                        missing={ loanInfo.docNotUploadedCount }
                        incomeStatus={ loanInfo.incomeStatus }
                        maritalStatus={ loanInfo.maritalStatus }
                        documents={ loanInfo.documents }
                        deleteHandler={ this.deleteHandler }
                        uploadHandler={ this.uploadHandler }
                        addDocumentHandler={ this.addDocumentHandler }
                        deleteDocumentHandler={ this.deleteDocumentHandler }
                        downloadHandler={ this.downloadHandler }
                        updateCustomerLoanTypeHandler={ this.updateCustomerLoanTypeHandler }
                        changeCashOptionHandler={ this.changeCashOptionHandler }
                        isCashOption={ this.state.isCashOption }
                    />
                </Card>

                { this.state.isCashOption
                    ? null
                    : <div>
                        { banks.map((data, index) => {
                            return <Card key={ index }>
                                <BankDocumentList
                                    bankInfo={ data }
                                    deleteHandler={ this.deleteHandler }
                                    uploadHandler={ this.uploadHandler }
                                    addDocumentHandler={ this.addDocumentHandler }
                                    deleteDocumentHandler={ this.deleteDocumentHandler }
                                    downloadHandler={ this.downloadHandler }
                                    updateBankLoanInfoHandler={ this.updateBankLoanInfo }
                                />
                            </Card>
                        })}

                        { this.state.waitingForAddingBank
                            ? <div className="mb-15">
                                <Loading />
                            </div>
                            : null
                        }

                        <AddBankCard
                            openAddBankModal={ () => {
                                this.refs.addBankModal.refs.modal.openModal()
                            }}
                        />

                        <AddBankModal
                            ref="addBankModal"
                            addBankHandler={ this.addBankHandler }
                            bankOptions={ filteredBankOptions }
                        />
                    </div>
                }
            </Wrapper>
        )
    }
}

function mapStateToProps(state) {
    return {
        options: state.other.options,
        bankOptions: state.other.bankOptions,
    }
}

export default connect(mapStateToProps, null)(LoanInfoView)

const AddBankCard = ({ openAddBankModal }) => <Card>
    <div
        className="clickable"
        onClick={ () => { openAddBankModal() }}
    >
        <div className="d-flex flex-row align-items-center">
            <i className="fa fa-plus fa-3x grey" style={{ maxWidth: "56px", maxHeight: "56px" }} />
            <h5 className="mb-0 ml-1">เพิ่มธนาคารที่ขอกู้</h5>
        </div>
    </div>
</Card>
