export const ModalStyle = (noBackground, isSelectProjectModal) => {
  if (!isSelectProjectModal) {
    return {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(64,64,64,0.5)',
        zIndex: 100000,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      content: {
        position: 'initial',
        top: 'initial',
        right: 'initial',
        down: 'initial',
        left: 'initial',
        width: '952px',
        maxHeight: '90vh',
        border: noBackground ? null : '1px solid #ccc',
        background: noBackground ? 'none' : '#fff',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '8px',
        outline: 'none',
        padding: '25px',
      },
    };
  } else {
    return {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(64,64,64,0.5)',
        zIndex: 100000,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      content: {
        position: 'initial',
        top: 'initial',
        right: 'initial',
        down: 'initial',
        left: 'initial',
        width: '100%',
        maxHeight: '90vh',
        border: noBackground ? null : '1px solid #ccc',
        background: noBackground ? 'none' : '#fff',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '8px',
        outline: 'none',
        padding: '25px',
      },
    };
  }
};

export const NA_TEXT = 'ไม่ระบุ';
export const APPROVED_STATUS = 7;
export const INSPECTION_PASSED_STATUS = 2;

// loan status constants
export const LOAN_STATUS_NA = 0;
export const LOAN_STATUS_NOT_APPROVED = 6;
export const LOAN_STATUS_APPROVED = 7;
export const LOAN_STATUS_CASH = 9;

// inspect status constants
export const INSPECT_STATUS_NA = 0;
export const INSPECT_STATUS_PENDING = 1;
export const INSPECT_STATUS_PASSED = 2;

// transfer status constants
export const TRANSFER_STATUS_NA = 0;
export const TRANSFER_STATUS_COMPLETED = 1;

// survey status constants
export const SURVEY_STATUS_DRAFT = 1;
export const SURVEY_STATUS_ACTIVE = 2;
export const SURVEY_STATUS_EXPIRE = 3;

// survey question type constants
export const QUESTION_TYPE_SINGLE = 1;
export const QUESTION_TYPE_MULTI = 2;
export const QUESTION_TYPE_TEXT = 3;
export const QUESTION_TYPE_TEL = 4;
export const QUESTION_TYPE_EMAIL = 5;

export const PRODUCT_TYPE_HOUSE = 2;
export const PRODUCT_TYPE_CONDO = 1;

export const DEFAULT_ADDRESS =
  'สำนักงานขายโครงการ แฮปปี้ คอนโด ดอนเมือง เดอะ เทอมินอล เลขที่ 1 ซอยสรงประภา 9/1 ถนนสรงประภา แขวงสีกัน เขตดอนเมือง กรุงเทพมหานคร 10210 โทร 02-152-0160-2 aa';
export const DEFAULT_LOGO_URL = '/img/happyCondoLogo.svg';

export const RESTRICTED_KEYWORD = 'restricted';
export const PARTIAL_KEYWORD = 'partial';

export const LAYOUT = {
  APP: 'app',
  BLANK: 'blank',
};

export const PRODUCT_STATUS = {
  TRANSFERED: 1,
  BOOKED: 2,
  UNAVAILABLE: 3,
  AVAILABLE: 4,
};
