import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import _ from 'lodash'
import {RadioGroup, Radio} from 'react-radio-group'
import {CheckboxGroup, Checkbox} from 'react-checkbox-group'
import withStyles from 'react-jss'
import {animateScroll} from 'react-scroll'
import classNames from 'classnames'
import {BounceLoader} from 'react-spinners'

import {QUESTION_TYPE_SINGLE, QUESTION_TYPE_MULTI, QUESTION_TYPE_TEXT, QUESTION_TYPE_TEL, QUESTION_TYPE_EMAIL} from '../../../common/const'
import Wrapper from '../../../layouts/Wrapper'
import Loading from '../../../components/common/Loading'
import datastore from '../../../datastore'
import ConfirmModal from '../../../components/ConfirmModal'
import Card from '../../../components/common/Card'
import TextInput from '../../../components/common/TextInput'
import styles from './styles'
class TextQuestion extends Component {

  constructor(props) {
    super(props)

    this.state = {
      question: props.question,
      answers: props.answers || '',
      data: {}
    }

    this.onChange = this
      .onChange
      .bind(this)
    this.onRemoveClick = this
      .onRemoveClick
      .bind(this)

  }

  componentWillReceiveProps(nextProps) {
    this.setState(state => ({
      question: nextProps.question || state.question,
      answers: nextProps.answers || state.answers
    }))
  }

  onRemoveClick = e => {
    if (typeof(this.props.onRemoveClick) === 'function') {
      this
        .props
        .onRemoveClick(this.state)
    }
  }

  onChange = nextValue => {
    this.setState(state => {
      const nextState = {
        ...state,
        ...nextValue
      }
      if (typeof(this.props.onChange) === 'function') {
        this
          .props
          .onChange(nextState.answers)
      }
      return nextState
    })
  }

  render() {
    const {classes} = this.props
    const {question, answers} = this.state

    if (!question) {
      return null
    }

    const type = (questionType => {
      switch (questionType) {
        case QUESTION_TYPE_EMAIL:
          return 'email'
        default:
          return 'text'
      }
    })(question.questionType)

    return (
      <div className={classes.question}>
        <h3>
          {question.title}
        </h3>
        <p>
          <TextInput
            name={'value'}
            placeholder={question.title}
            type={type}
            target={this}
            stateKey="data"
            renderValue={answers}
            onChange={e => this.onChange({ answers: e.target.value })}/>
        </p>
      </div>
    )
  }
}

export default withStyles(styles)(TextQuestion)