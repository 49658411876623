import * as sale from './sale'
import * as product from './product'
import * as customer from './customer'
import * as loan from './loan'
import * as other from './other'
import * as survey from './survey'
import * as warranty from './warranty'
import * as lead from './lead'
import * as activity from './activity'
import * as campaign from './campaign'

export default {
    sale,
    product,
    customer,
    loan,
    other,
    survey,
    warranty,
    lead,
    activity,
    campaign
}
