import React, { PureComponent } from 'react';
import { inModal } from '../../../hoc/inModal';
import ModalTitle from '../../../components/common/ModalTitle';
import TextWithLabel from '../../../components/common/TextWithLabel';
import TextInput from '../../../components/common/TextInput';
import FormNewLine from '../../../components/common/FormNewLine';
import apis from '../../../apis';
import datastore from '../../../datastore';

export class AcceptActivityForm extends PureComponent {
  constructor(props){
    super(props)

    this.state = {
      data: {
        activityDetail: ''
      },
      record: this.props.activity ? this.props.activity : null
    }
  }

  render() {
    const { role } = this.props

    return this.state.record.status.toString() === '0' ? this.renderInformModal() : this.renderDetailAfterInform(role)
  }

  renderInformModal() {
    return (
      <div className="activity-modal">
        <ModalTitle title='เสร็จสิ้นกิจกรรม' onClick={() => this.props.closeModal()} />
        <div className="row no-gutters justify-content-between mb-2" style={{maxWidth: "500px"}}>
          <TextWithLabel label="ลูกค้า">{this.state.record.customerName}</TextWithLabel>
          <TextWithLabel label="สินค้า">{this.state.record.product}</TextWithLabel>
          <TextWithLabel label="กิจกรรม">{this.state.record.type}</TextWithLabel>
        </div>
        <FormNewLine />
        <div className="row no-gutters justify-content-between mb-2" style={{maxWidth: "500px"}}>
          {
             this.state.record.rejectReason !== '-' ?
             <TextWithLabel label="ขอข้อมูลเพิ่มเติม">{this.state.record.rejectReason}</TextWithLabel>
             : null
          }
        </div>
        <FormNewLine />
        <div className="row no-gutters justify-content-between">
          <TextInput
            name="activityDetail"
            placeholder="รายละเอียดกิจกรรม"
            target={ this }
            stateKey="data"
          />
        </div>
        <div style={{paddingTop: "24px"}} className="d-flex flex-row justify-content-center">
          <button 
            style={{padding: 0}}
            onClick={ () => {
              this.props.closeModal()
            }}
            className="m-05 btn btn-save-cancel btn-outline"
          >
              ยกเลิก
          </button>
          <button 
            style={{padding: 0}}
            onClick={ () => {
              this.informActivity()
            }}
            className="m-05 btn btn-save-cancel btn-outline"
          >
            แจ้งผู้บริหาร
          </button>
        </div>
      </div>
    )
  }

  renderDetailAfterInform(role) {
    return (
      <div className="activity-modal">
        <ModalTitle title='รับทราบกิจกรรม' onClick={() => this.props.closeModal()} />
        <div className="row no-gutters justify-content-between mb-2" style={{maxWidth: "500px"}}>
          <TextWithLabel label="ลูกค้า">{this.state.record.customerName}</TextWithLabel>
          <TextWithLabel label="สินค้า">{this.state.record.product}</TextWithLabel>
          <TextWithLabel label="กิจกรรม">{this.state.record.type}</TextWithLabel>
        </div>
        <FormNewLine />
        <div className="row no-gutters justify-content-between">
          <TextWithLabel label="รายละเอียดกิจกรรม">{this.state.record.activity}</TextWithLabel>
        </div>
        <FormNewLine />
        <div className="row no-gutters justify-content-between">
        {
          this.state.record.rejectReason !== '-' ?
          <TextWithLabel label="ขอข้อมูลเพิ่มเติมครั้งก่อน">{this.state.record.rejectReason}</TextWithLabel>
          : null
        }
        </div>
        {
          this.state.record.status === 1 ? this.renderButton(role.toString()) : null
        }
      </div>
    )
  }

  informActivity = () => {
    const activityId = this.state.record.id
    const inform = datastore.activity.informActivity(activityId, this.state.data)
      .then(result => {
        this.props.closeModal()
        this.props.onUpdateActivity(0, 10)
      })
    return inform
  }

  permitActivity = () => {
    const activityId = this.state.record.id
    const confirm = apis.activity.permitActivity(activityId)
      .then(result => {
        this.props.closeModal()
        this.props.onUpdateActivity(0, 10)
      })
    return confirm
  }

  renderButton(role) {
    if(role === '0' || role === '4') {
      return null
    } else {
      return (
        <div style={{paddingTop: "16px"}} className="d-flex flex-row justify-content-center">
          <button 
            style={{padding: 0}}
            onClick={ () => {
              this.props.onDeclineActivity()
            }}
            className="m-05 btn btn-save-cancel btn-outline"
          >
            ขอข้อมูลเพิ่มเติม
          </button>
          <button 
            style={{padding: 0}}
            onClick={ () => {
              this.permitActivity()
            }} 
            className="m-05 btn btn-save-cancel btn-outline"
          >
            รับทราบ
          </button>
        </div>
      )
    }
  }

  isAbleToPermit(role) {
    if(role === '0' || role === '4') {
      return false
    } else {
       return true
    }
  }
}

const AcceptActivityModal = inModal(AcceptActivityForm)
export default AcceptActivityModal;