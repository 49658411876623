import { SET_TOKEN, REMOVE_TOKEN, GET_PROFILE } from './types'
import datastore from '../datastore'

export function setToken(token, role) {
    return {
        type: SET_TOKEN,
        token: token,
        role: role
    }
}

export function removeToken() {
    return {
        type: REMOVE_TOKEN,
    }
}

export function getProfile() {
    return dispatch => {
        datastore.user.getProfile()
            .then(res => {
                dispatch({
                    type: GET_PROFILE,
                    profile: res
                })
            })
    }
}
