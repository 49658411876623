import { Cookies } from 'react-cookie'

import { SET_TOKEN, REMOVE_TOKEN, GET_PROFILE } from '../actions/types'
import config from '../common/config'

const INITIAL_STATE = {
    profile: null,
    token: null,
    role: null,
}

export default function(state = INITIAL_STATE, action) {
    const cookies = new Cookies()
    switch (action.type) {
        case SET_TOKEN:
            cookies.set(config.tokenCookiesName, action.token, { path: '/'})
            cookies.set(config.roleCookiesName, action.role, { path: '/'})
            return { ...state, token: action.token, role: action.role }
        case REMOVE_TOKEN:
            cookies.remove(config.tokenCookiesName, { path: '/'})
            return { ...state, token: null, profile: null }
        case GET_PROFILE:
            return { ...state, profile: action.profile }
        default:
            return state
    }
}
