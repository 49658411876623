import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class TextInput extends Component {
    state = {
        renderValue: ""
    }

    // Careful! The default value here will render from data instead of compute data from rendered value ...
    componentWillMount() {
        this.updateValue(this.props)
    }

    componentWillReceiveProps(nextProps) {
        this.updateValue(nextProps)
    }

    updateValue = props => {
        const { target, stateKey, nestedKey, name, renderFormatter } = props
        let { renderValue } = props

        const value = nestedKey 
            ? target.state[stateKey][nestedKey][name] 
            : target.state[stateKey][name]

        renderValue = renderValue || (renderFormatter 
            ? renderFormatter(value) 
            : value)

        this.setState({
            renderValue: renderValue
        })
    }

    isRequired(){
        return this.props.required ? 'required' : ''
    }

    render() {
        const { 
            name, 
            placeholder, 
            type, 
            target, 
            stateKey, 
            nestedKey,
            required, 
            renderFormatter,
            dataFormatter,
            disabled } = this.props

        return (
            <div className={`w-100 ${this.isRequired()}`}>
                <input 
                    className="form-control input w-100" 
                    placeholder={placeholder}
                    type={type}
                    value={this.state.renderValue}
                    name={name}
                    autoComplete="new-password"
                    required={required}
                    disabled={disabled}
                    onChange={e => {
                        const data = target.state[stateKey]

                        const value = dataFormatter ? dataFormatter(e.target.value) : e.target.value
                        const renderValue = renderFormatter ? renderFormatter(value) : value

                        this.setState({
                            renderValue: renderValue
                        })

                        if (nestedKey) {
                            data[nestedKey] = data[nestedKey] || {} 
                            data[nestedKey][e.target.name] = value 
                        } else {
                            data[e.target.name] = value
                        }

                        target.setState({
                            [stateKey]: data
                        })

                        if (this.props.onChange) {
                            this.props.onChange(e)
                        }
                    }}
                />
            </div>
        )
    }
}

TextInput.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    target: PropTypes.object.isRequired,
    stateKey: PropTypes.string.isRequired,
    nestedKey: PropTypes.string,
    renderFormatter: PropTypes.func, // The input of this is output from dataFormatter().
    dataFormatter: PropTypes.func,
    onChange: PropTypes.func,
    renderValue: PropTypes.string
}
