import React, { Component } from 'react'
import Wrapper from '../../layouts/Wrapper'
import ConfirmModal from '../../components/ConfirmModal'
import { BookingInfoForm } from '../../components/forms/BookingInfoForm';

export default class AddBookingView extends Component {

    componentWillMount() {
        this.props.setHeader('จองห้อง')
    }

    render() {
        return <Wrapper>
                <div className="row">
                    <div className="col-sm-12">
                        <ConfirmModal 
                            ref="modal"
                            title="ยืนยัน"
                            onConfirm={() => {
                                this
                                .refs
                                .form.getWrappedInstance()
                                .save()
                                .then(this.goToSaleRecord.bind(this))}
                            }
                        >
                            <div>
                                <h3>กดปุ่มเพื่อยืนยันการจอง</h3>
                            </div>
                        </ConfirmModal>
                        <BookingInfoForm
                            ref="form"
                            noHeaderAndFooter
                            leadId={this.props.params.leadId}
                        />
                        <div className="d-flex flex-row my-3 justify-content-center">
                            <button
                                onClick={() => this.goToLeadInfo()}
                                className="m-05 btn btn-save-cancel btn-outline"
                                style={{padding: 0}}
                            >
                                ยกเลิก
                            </button>
                            <button
                                onClick={() => {
                                    this.refs.modal.openModal()
                                    }
                                }
                                className="m-05 btn btn-save-cancel btn-outline"
                                style={{padding: 0}}
                            >
                                บันทึก
                            </button>
                        </div>
                    </div>
                </div>
            </Wrapper>
    }

    goToLeadInfo() {
        this.props.router.goBack()
    }

    goToSaleRecord() {
        this.props.router.push('/sale-record')
    }
}