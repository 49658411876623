import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class FileInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            renderValue: ""
        }

        this.getFormData = this.getFormData.bind(this)
    }

    getFormData() {
        const formElement = document.forms.namedItem("fileform")
        console.log(formElement)
        const data = new FormData(formElement)
        console.log(data)
        return data
    }

    render() {
        const { 
            name, 
            placeholder, 
            target, 
            stateKey, 
            nestedKey, 
            renderFormatter } = this.props

        return (
            <form encType="multipart/form-data" name="fileform">
                <input 
                    className="form-control input w-100" 
                    placeholder={ placeholder }
                    type="file"
                    value={ this.state.renderValue }
                    name={ name }
                    onChange={ e => {
                        const data = target.state[stateKey]
                        const renderValue = renderFormatter ? renderFormatter(e.target.value) : e.target.value

                        this.setState({
                            renderValue: renderValue
                        })

                        if (nestedKey) {
                             data[nestedKey] = data[nestedKey] || {} 
                             data[nestedKey][e.target.name] = this.getFormData() 
                        } else {
                            data[e.target.name] = this.getFormData()
                        }

                        target.setState({
                            [stateKey]: data
                        })
                    }}
                />
            </form>
        )
    }
}

FileInput.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    target: PropTypes.object.isRequired,
    stateKey: PropTypes.string.isRequired,
    nestedKey: PropTypes.string,
    renderFormatter: PropTypes.func,
    dataFormatter: PropTypes.func, // The input of this is output from renderFormatter().
}