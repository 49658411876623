import React, { Component } from 'react'

import { ActivityInfoForm } from './ActivityInfoForm'
import { LeadInfo } from './leadInfoModal'
import Wrapper from '../../../layouts/Wrapper'
import apiClient from '../../../apis'
import Loading from '../../../components/common/Loading'
import Card from '../../../components/common/Card'

export default class AddActivityView extends Component {
    state = {
        lead: null,
        activityId: null
    }

    componentWillMount() {
        const leadId = this.props.params.leadId
        const activityId = this.props.location.query.activityId

        this.props.setHeader('สร้างกิจกรรม')
        this.setState({ activityId })

        apiClient.lead.getLead_(0,leadId)
            .then(lead => {
                this.setState({ lead })
            })
    }

    formatLeadData(lead) {
        return {
            created: lead.created,
            email: lead.email,
            first_name: lead.first_name,
            last_name: lead.last_name,
            gender: lead.gender,
            income: lead.income,
            interest: lead.interest,
            line_id: lead.line_id,
            occupation: lead.occupation,
            phone_number: lead.phone_number,
            prospect: lead.prospect,
            qualified: lead.qualified,
            age: lead.age,
            sources: lead.sources
        }
    }

    goToShowActivitiesStep = () => {
        this.props.router.push('/sale-mode/leads');
    }

    render() {
        const { lead, activityId } = this.state
        if (!lead) {
            return <Loading />
        }

        return (
            <Wrapper>
                <div className="row">
                    <div className="col-sm-9">
                        <Card>
                            <ActivityInfoForm
                                ref="form"
                                noHeaderAndFooter
                                leadId={this.props.params.leadId}
                                activityId={activityId}
                            />
                            <div className="d-flex flex-row my-3 justify-content-center">
                                <button
                                    onClick={() => this.goToShowActivitiesStep()}
                                    className="m-05 btn btn-save-cancel btn-outline"
                                    style={{padding: 0}}
                                >
                                    ยกเลิก
                                </button>
                                <button
                                    onClick={() => {
                                        this
                                        .refs
                                        .form.getWrappedInstance()
                                        .save()
                                        .then(this.goToShowActivitiesStep)
                                    }}
                                    className="m-05 btn btn-save-cancel btn-outline"
                                    style={{padding: 0}}
                                >
                                    บันทึก
                                </button>
                            </div>
                        </Card>
                    </div>
                    <div className="col-sm-3">
                        <Card>
                            <LeadInfo customer={this.formatLeadData(lead)} />
                        </Card>
                    </div>
                </div>
            </Wrapper>
        )
    }
}
