import apiClient from '../apis'
import { Formatter } from '../utilities/Formatter';

export function getDashboardData() {
  return apiClient.dashboard.getSummaryData()
    .then(result => {
      return convertDashboardData(result);
    })
}

export function getAdsData(year) {
  return apiClient.dashboard.getAdsData(year)
    .then(result => {
      return Promise.all([convertOnlineAdsData(result), convertOfflineAdsData(result)])
    })
}

export function getLeadSourceData(year) {
  return apiClient.dashboard.getLeadSourceData(year)
    .then(result => {
      return convertLeadSourceData(result)
    })
}

export function getInterestProductData(year) {
  return apiClient.dashboard.getInterestProductData(year)
    .then(result => {
      return convertInterestProductData(result);
    })
}

export function getConcernData(year) {
  return apiClient.dashboard.getConcernData(year)
    .then(result => {
      return convertConcernData(result);
    })
}

export function getWalkAndBookingData(year) {
  return apiClient.dashboard.getWalkAndBookingData(year)
    .then(result => {
      return Promise.all([convertWalkAndBookingData(result), convertValuation(result)]);
    })
}

export function getTotalWalk() {
  return apiClient.dashboard.getTotalWalkData()
    .then(result => {
      return result
    })
}

export function getTransferData(year) {
  return apiClient.dashboard.getTransferData(year)
    .then(result => {
      return Promise.all([convertTransferData(result), convertTransferValuation(result)]);
    })
}

export function getSummaryConversion() {
  return apiClient.dashboard.getSummaryConversion()
    .then(summaryResult => {
      return apiClient.dashboard.getTotalWalkData().then(walkResult => {
        return convertSummaryConversion(walkResult, summaryResult)
      })
    });
}

export function getCostPerWalkData(campaignId) {
  return apiClient.dashboard.getCostPerWalkData(campaignId)
    .then(result => {
      return convertCostPerWalkData(result)
    })
}

function convertDashboardData(data) {
  return {
    total: {
      count: data.total.count,
      valuation: `${data.total.valuation}`
    },
    sold: {
      count: data.sold.count,
      valuation: `${data.sold.valuation}`
    },
    free: {
      count: data.free.count,
      valuation: data.free.valuation < 0 ? '0' : `${data.free.valuation}`
    }
  }
}

function convertOnlineAdsData(data) {
  return {
    labels: data.online_advertisements.map(ads => {
      return ads.name
    }),
    datasets: [
      {
        label: "จำนวนครั้ง",
        data: data.online_advertisements.map(ads => {
          return ads.total.toString()
        }),
        backgroundColor: '#ffb400'
      }
    ]
  }
}

function convertOfflineAdsData(data) {
  return {
    labels: data.offline_advertisements.map(ads => {
      return ads.name
    }),
    datasets: [
      {
        label: "จำนวนครั้ง",
        data: data.offline_advertisements.map(ads => {
          return ads.total.toString()
        }),
        backgroundColor: '#00ace1'
      }
    ]
  }
}

function convertLeadSourceData(data) {
  return {
    labels: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.',
      'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.',
      'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
    datasets: [
      {
        label: "จำนวนผู้สนใจที่มาจากสื่อออนไลน์",
        data: data.online_sources.map(source => {
          return source
        }),
        fill: false,
        borderColor: '#ffb400',
        hoverBackgroundColor: '#e4e4e6',
        hoverRadius: 10,
        hoverBorderWidth: 10
      },
      {
        label: "จำนวนผู้สนใจที่มาจากสื่อออฟไลน์",
        data: data.offline_sources.map(source => {
          return source
        }),
        fill: false,
        borderColor: '#00ace1',
        hoverBackgroundColor: '#e4e4e6',
        hoverRadius: 10,
        hoverBorderWidth: 10
      }
    ]
  }
}

function convertInterestProductData(data) {
  return {
    labels: data.interest_products.map(product => {
      return product.sku_code
    }),
    datasets: [
      {
        label: "จำนวนคน",
        data: data.interest_products.map(product => {
          return product.total.toString()
        }),
        backgroundColor: '#00ace1'
      }
    ]
  }
}

function convertConcernData(data) {
  return {
    labels: data.concerns.map(concern => {
      return concern.concern
    }),
    datasets: [
      {
        label: "จำนวนคน",
        data: data.concerns.map(concern => {
          return concern.total.toString()
        }),
        backgroundColor: '#00b777'
      }
    ]
  }
}

function convertWalkAndBookingData(data) {
  return {
    labels: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.',
      'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.',
      'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
    datasets: [
      {
        label: "จำนวนการWalk",
        data: data.walk.map(walk => {
          return walk.toString()
        }),
        fill: false,
        borderColor: '#00ace1',
        hoverBackgroundColor: '#e4e4e6',
        hoverRadius: 10,
        hoverBorderWidth: 10
      },
      {
        label: "จำนวนการขาย",
        data: data.booking.map(book => {
          return book.toString()
        }),
        fill: false,
        borderColor: '#00b777',
        hoverBackgroundColor: '#e4e4e6',
        hoverRadius: 10,
        hoverBorderWidth: 10
      }
    ]
  }
}

function convertTransferData(data) {
  return {
    labels: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.',
      'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.',
      'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
    datasets: [
      {
        label: "จำนวนห้องที่โอน",
        data: data.transfer.map(transfer => {
          return transfer.total.toString()
        }),
        fill: false,
        borderColor: '#00ace1',
        hoverBackgroundColor: '#e4e4e6',
        hoverRadius: 10,
        hoverBorderWidth: 10
      }
    ]
  }
}

function convertTransferValuation(data) {
  return {
    total: data.transfer.map(transfer => {
      return transfer.total.toString()
    }),
    valuation: data.transfer.map(transfer => {
      return transfer.valuation.toString()
    })
  }
}

function convertValuation(data) {
  return {
    walk: data.walk.map(walk => {
      return walk.toString()
    }),
    valuation: data.valuation.map(value => {
      return value.toString()
    }),
    booking: data.booking.map(book => {
      return book.toString()
    })
  }
}

function convertSummaryConversion(walkData, conversionData) {
  return {
    walk: walkData,
    book: conversionData.book,
    loanApprove: conversionData.loan_approve,
    fixedInspection: conversionData.fixed_inspection,
    transfer: {
      total: conversionData.transfer.total,
      valuation: conversionData.transfer.valuation.toString()
    }
  }
}

function convertCostPerWalkData(data) {
  return {
    offline: {
      cost: Formatter.amountFormatter(data.offline.cost),
      totalWalk: data.offline.total_walk.toString(),
      costPerWalk: data.offline.total_walk === 0 ? '0' : Formatter.amountFormatter(data.offline.cost / data.offline.total_walk)
    },
    online: {
      cost: Formatter.amountFormatter(data.online.cost),
      totalWalk: data.online.total_walk.toString(),
      costPerWalk: data.online.total_walk === 0 ? '0' : Formatter.amountFormatter(data.online.cost / data.online.total_walk)
    }
  }
}
