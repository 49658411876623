export default {
  '@global' : {
    h1: {
      fontSize: '2rem',
      fontWeight: '400'
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: '400'
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: '400'
    }
  },
  root : {
    color: '#6d6d6d',
    fontSize: '14px',
    maxWidth: '900px',
    minHeight: 'calc(100vh - 100px)',
    margin: '0 auto',
    boxShadow: '#ccc 2px 2px 5px 0px'
  },
  small : {
    fontSize: '50%',
    color: '#656565'
  },
  wrapper : {
    marginTop: '3em'
  },
  question : {
    width: '100%',
    minHeight: '300px',
    // height: '100vh',
    marginBottom: '3em',
    display: 'flex',
    alignItems: 'center'
  },
  questionInner : {
    width: '100%'
  },
  group : {
    padding: '0',
    display: 'table',
    borderCollapse: 'collapse'
  },
  groupItem : {
    margin: 0,
    padding: 0,
    display: 'table-row',
    borderColor: '#dedede',
    borderStyle: 'solid',
    borderWidth: '1px',
    '&:hover': {
      borderColor: '#bbbbbb',
      backgroundColor: '#fdfdfd'
    }
  },
  groupItemLabel : {
    cursor: 'pointer',
    margin: 0,
    padding: '0.25em',
    paddingLeft: '45px',
    display: 'block',
    lineHeight: '2em',
    minWidth: '500px'
  },
  cover : {
    width: '100%',
    '& $img': {
      width: '100%'
    }
  },
  headline : {
    marginTop: '2em',
    padding: '2em'
  },
  stepperWrapper : {
    display: 'block',
    width: '100%',
    display: 'table'
  },
  stepperLeft : {
    display: 'table-cell'
  },
  stepperRight : {
    display: 'table-cell',
    textAlign: 'right'
  },
  overlay : {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  wrapper: {
    width: '100%',
    maxWidth: '900px',
    margin: '0 auto',
    padding: '1em'
  },
  label : {
    margin: '1em'
  },
  question : {
    margin: '1em',
    '& $h4': {
      fontSize: '1em'
    }
  },
  small : {
    fontSize: '50%',
    color: '#565656'
  },
  btnDelete : {
    color: 'red',
    padding: 0,
    fontSize: '1.25em'
  },
  overlay : {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  command: {
    position: 'fixed',
    width: '100%',
    bottom: 0,
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center'
  },
  commandInner: {
    width: '100%',
    maxWidth: '900px',
    textAlign: 'right'
  },
  questionSectionTitle: {
    display: 'flex'
  },
  descriptionP: {
    margin: 0
  }
}
