import _ from 'lodash'
import uuidv4 from 'uuid/v4'
import React, {Component} from 'react'
import withStyles from 'react-jss'
import classNames from 'classnames'
import styles from './styles'
import {QUESTION_TYPE_SINGLE, QUESTION_TYPE_MULTI} from '../../../common/const'

class ChoiceItem extends Component {

  constructor(props) {
    super(props)

    this.state = {
      value: {
        id: null,
        title: 'New Choice',
        order: 1,
        ...props.value
      }
    }

    this.onChange = this
      .onChange
      .bind(this)

  }

  componentWillReceiveProps(nextProps) {
    this.setState(state => ({
      value: nextProps.value || state.value
    }))
  }

  onChange = nextValue => {
    this.setState(state => {
      const nextState = {
        ...state.value,
        ...nextValue
      }
      console.log("choice on change ", state)
      if (typeof(this.props.onChange) === 'function') {
        this
          .props
          .onChange(nextState)
      }
      return {value: nextState}
    })
  }

  render() {
    const {value} = this.state

    return (
      <span>
        <input
          type="text"
          placeholder="คำตอบ"
          value={value.title}
          onChange={e => this.onChange({title: e.target.value})}/>
      </span>
    )
  }

}

class ChoiceQuestion extends Component {

  constructor(props) {
    super(props)

    this.state = {
      value: {
        id: null,
        title: 'New Quetion',
        order: 1,
        questionType: QUESTION_TYPE_SINGLE,
        choices: [],
        ...props.value
      }
    }

    this.onChange = this
      .onChange
      .bind(this)
    this.onRemoveClick = this
      .onRemoveClick
      .bind(this)
    this.onAddChoiceClick = this
      .onAddChoiceClick
      .bind(this)
    this.onRemoveChoiceClick = this
      .onRemoveChoiceClick
      .bind(this)
    this.onChoiceChange = this
      .onChoiceChange
      .bind(this)
  }

  componentWillReceiveProps(nextProps) {
    this.setState(state => ({
      value: nextProps.value || state.value
    }))
  }

  onRemoveClick = e => {
    if (typeof(this.props.onRemoveClick) === 'function') {
      this
        .props
        .onRemoveClick(this.state)
    }
  }

  onChange = nextValue => {
    this.setState(state => {
      const nextState = {
        ...state.value,
        ...nextValue
      }
      if (typeof(this.props.onChange) === 'function') {
        this
          .props
          .onChange(nextState)
      }
      return {value: nextState}
    })
  }

  onAddChoiceClick = e => {
    this.onChange({
      choices: [
        ...this.state.value.choices, {
          id: uuidv4(),
          order: this.state.value.choices.length
        }
      ]
    })
  }

  onRemoveChoiceClick = (index) => e => {

    this.setState(state => ({
      value: {
        ...state.value,
        choices: _.filter(state.value.choices, (choice, i) => i !== index)
      }
    }))

  }

  onChoiceChange = index => values => {
    this.state.value.choices[index] = values
    this.onChange({choices: this.state.value.choices})
  }

  render() {
    const {classes} = this.props
    const {value} = this.state

    const renderedChoices = _.map(value.choices, (choice, i) => (
      <li key={i}>
        <ChoiceItem value={this.state.value.choices[i]} onChange={this.onChoiceChange(i)}/>
        <span className={classes.deleteChoice}>
          <button
            className={classNames({
            'btn': true,
            [classes.btnDelete]: true
          })}
            onClick={this.onRemoveChoiceClick(i)}>
            <i className="fa fa-minus-circle"></i>
          </button>
        </span>
      </li>
    ))
    const renderedChoiceHint = value.questionType === QUESTION_TYPE_SINGLE
      ? 'เลือกได้ 1 คำตอบ'
      : 'เลือกได้หลายคำตอบ'
    return (
      <div className={classes.question}>
        <h4>
          <button
            className={classNames({
            'btn': true,
            [classes.btnDelete]: true
          })}
            onClick={this.onRemoveClick()}>
            <i className="fa fa-minus-circle"></i>
          </button>
          <input
            type="text"
            value={value.title}
            placeholder="คำถาม"
            onChange={e => this.onChange({title: e.target.value})}/>
          <span className={classes.small}>
            &nbsp;({renderedChoiceHint})

          </span>
        </h4>
        <div className={classes.questionChoice}>
          <ol>
            {renderedChoices}
          </ol>
          <div className={classes.questionChoiceCommand}>
            <button className="btn" onClick={this.onAddChoiceClick}>
              <i className="fa fa-plus"></i>
              <span>&nbsp;เพิ่มคำตอบ</span>
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(ChoiceQuestion)