import * as React from 'react';
import { releaseNote } from './ReleaseNote';
import { Formatter } from '../../utilities/Formatter';
import './ReleaseNote.css';

export class ReleaseNotePage
  extends React.Component {
    render() {
      return (
        <div className="Release-note-page">
          <div className="Release-note-page__container">
            <img className="billme-logo" src="/img/billme-brm-logo.png" />
            <h2 className="title">Release Notes</h2>
            <div className="Release-note-page__note">
              {releaseNote[0].version ? this.renderNote(releaseNote) : null}
            </div>
          </div>
          <p className="powered-by">POWERED BY BILLME.CO.TH</p>
        </div>
      );
    }

    renderNote(notes) {
      return notes.map((note, index) => {
        return (
          <div
            className="release-card"
            key={`release-card--${index}`}
            style={{borderBottom: index === notes.length - 1 ? 'none' : '2px solid #e5e5ea', marginLeft: '10px'}}
          >
            <div className="version-area">
              <h1 className="title">{note.version}</h1>
              <h6 className="release-date">
                {Formatter.formatDateTh(Formatter.createDate(note.date))}
              </h6>
            </div>
            <div className="detail-area">
              {this.renderDetail(`What's new`, note.feature)}
              {this.renderDetail('Enhancements', note.updated)}
              {this.renderDetail('Bug Fixes', note.fixed)}
            </div>
          </div>
        );
      });
    }

    renderDetail(title, detail) {
      if (detail.length) {
        return (
          <div className="detail-card">
            <h1 className="title">{title}</h1>
            <ul className="detail-list">
              {
                detail.map((note, index) => {
                  return <li className="item" key={`item-${index}`}><span>{note}</span></li>;
                })
              }
            </ul>
          </div>
        );
      } else {
        return null;
      }
    }
  }
