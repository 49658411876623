import apiClient from '../apis'
import { FILE_STATUS } from '../views/loanInfo/DocumentList'

export function getDocumentInfo(saleRecordId) {
  return apiClient.document.getDocumentInfo(saleRecordId)
      .then(documentInfo => {
          return convertDocumentInfo(documentInfo)
      })
}

export function addGeneralDocument(saleRecordId, fileName, file ) {
  return apiClient.document.addGeneralDocument(saleRecordId, fileName, file)
}

export function deleteGeneralDocument(saleRecordId, documentId) {
  return apiClient.document.deleteGeneralDocument(saleRecordId, documentId)
}

export function downloadGeneralDocument(saleRecordId, documentId) {
  return apiClient.document.downloadGeneralDocument(saleRecordId, documentId)
}

function convertDocumentInfo(documentInfo) {
  const info = { ...documentInfo }

  info.documents = info.documents
      ? info.documents.map(d => {
          d.status = FILE_STATUS.uploaded
          return {
              url: d.file_url,
              name: d.document_name,
              status: d.status,
              uploaded: true,
              id: d.id
          }
      })
      : []

  return info
}
