import React, { Component } from 'react'
import _ from 'lodash'

import datastore from '../../../datastore'
import Loading from '../../../components/common/Loading'
import { hackToParseMetaJson, formatDateTime } from '../../../common/util'
import { DEFAULT_ADDRESS, DEFAULT_LOGO_URL, PRODUCT_TYPE_CONDO } from '../../../common/const'
import './printable.css'

export default class PrintableQcList extends Component {
    count = 2
    state = {
        product: null,
        inspection: null,
        project: null,
    }

    componentWillMount() {
        const productId = this.props.params.productId
        const inspectionId = this.props.params.inspectionId
        datastore.product.getProduct(productId)
            .then(product => {
                const inspection = _.find(product.inspections, ['id', inspectionId])
                this.setState({
                    product: product,
                    inspection: inspection
                })
            })

        datastore.user.getProfile()
            .then(res => {
                this.setState({
                    project: res.current_project
                })
            })
    }

    render() {
        if (!this.state.product || !this.state.project) {
            return <Loading />
        }

        const meta = this.state.project.meta_json ? hackToParseMetaJson(this.state.project.meta_json) : null

        const address = meta && meta.branch_address ? meta.branch_address : DEFAULT_ADDRESS
        const logoUrl = meta && meta.branch_logo_url ? meta.branch_logo_url : DEFAULT_LOGO_URL

        const groupedDefects = _.groupBy(this.state.inspection.defects, 'location')
        return <div>
            <CoverPage
                projectName={this.state.project.name} 
                customerName={this.state.product.customerName} 
                skuCode={this.state.product.skuCode} 
                defectsCount={this.state.inspection.defects.length} 
                staffName={this.state.inspection.staffName}
                witnessName={this.state.inspection.witnessName}
                buildingType={this.state.inspection.buildingType} 
                nextInspectionDate={this.state.inspection.nextInspectionDate}
                customerSignature={this.state.inspection.customerSignatureUrl}
                staffSignature={this.state.inspection.staffSignatureUrl}
                witnessSignature={this.state.inspection.witnessSignatureUrl}
                logo={logoUrl}
                productType={this.state.product.productTypeId}
                address={address}
                addressNo={this.state.product.addressNo}
            />
            { Object.entries(groupedDefects).map(([location, defects]) => {
                const originalCount = this.count
                this.count += Math.ceil(defects.length / 6)
                return <PerLocation 
                    key={ location }
                    generalInfo={ this.state.inspection }
                    defects={ defects }
                    skuCode={ this.state.product.skuCode }
                    buildingId={ this.state.product.buildingId }
                    location={ location }
                    count={ originalCount }
                    projectName={ this.state.product.projectName }
                    address={ address }
                    logo={ logoUrl }
                />
            })}
        </div>
    }
}

const getProductTypeString = productType => productType === PRODUCT_TYPE_CONDO ? 'ห้องชุด' : 'บ้าน'
const getProductPrefixString = productType => productType === PRODUCT_TYPE_CONDO ? 'ห้อง' : 'บ้านเลขที่'

const InspectionTemplate = ({ customerName, projectName, skuCode, defectsCount, nextInspectionDate, productType, addressNo }) => (
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้าพเจ้า <strong>{customerName}</strong> ผู้จะซื้อ<strong>{getProductTypeString(productType)}</strong> โครงการ <strong>{projectName} {getProductPrefixString(productType)} {skuCode}</strong> {addressNo ? `(บ้านเลขที่ ${addressNo})` : null } ได้ตรวจสอบ<strong>{getProductTypeString(productType)}</strong>ดังกล่าวแล้ว มีรายการแก้ไขจำนวน <strong>{defectsCount}</strong> รายการ โดยผู้จะขายจะดำเนินการแก้ไขให้กับผู้จะซื้อให้เรียบร้อย และผู้จะขายจะเชิญผู้จะซื้อมาตรวจสอบการแก้ไขดังกล่าวครั้งต่อไป<strong>{nextInspectionDate === 'ไม่ระบุ' ? 'ในภายหลัง' : `ในวันที่ ${formatDateTime(nextInspectionDate)}`}</strong> จึงลงลายมือชื่อไว้ต่อหน้าพยาน</p>
)

const TransferTemplate = ({ customerName, projectName, skuCode, productType, addressNo }) => (
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้าพเจ้า <strong>{customerName}</strong> ผู้จะซื้อ<strong>{getProductTypeString(productType)}</strong> โครงการ <strong>{projectName} {getProductPrefixString(productType)} {skuCode}</strong> {addressNo ? `(บ้านเลขที่ ${addressNo})` : null } ได้ตรวจสอบ<strong>{getProductTypeString(productType)}</strong>ดังกล่าวแล้ว มีความเรียบร้อยสมบูรณ์ สามารถเข้าครอบครองและใช้ประโยชน์ในการพักอาศัย โดยไม่มีรายการอื่นใดที่ผู้จะขายจะต้องแก้ไขเพิ่มเติมอีก จึงลงลายมือชื่อไว้ต่อหน้าพยาน และยินยอมที่จะรับโอนกรรมสิทธิ์<strong>ห้องชุด</strong>จากผู้จะขายต่อไป</p>
)

const CoverPage = ({ projectName, customerName, skuCode, defectsCount, staffName, witnessName, customerSignature, staffSignature, witnessSignature, buildingType, nextInspectionDate, logo, productType, address, addressNo }) => {
    const newLineIndex = address.indexOf('เขต')
    const addressLine1 = address.slice(0, newLineIndex)
    const addressLine2 = address.slice(newLineIndex)
    return (
    <div className="a4">
        <div className="print-container">
            <div>
                <div className="header">
                    <img alt="" src="/img/infiniteLogo.svg" className="logo-infinite"/>
                    <p className="h3">{defectsCount > 0 ? 'เอกสารตรวจรับ' : 'เอกสารรับมอบ'}{getProductTypeString(productType)}</p>
                    <p className="h3">โครงการ {projectName}</p>
                </div>
                <div className="summary-content">
                    { defectsCount > 0
                        ? <InspectionTemplate
                            customerName={customerName}
                            projectName={projectName}
                            skuCode={skuCode}
                            defectsCount={defectsCount}
                            nextInspectionDate={nextInspectionDate}
                            productType={productType}
                            addressNo={addressNo}
                        />
                        : <TransferTemplate
                            customerName={customerName}
                            projectName={projectName}
                            skuCode={skuCode}
                            productType={productType}
                            addressNo={addressNo}
                        />
                    }
                </div>
                <div className="summary-signature">
                    <div className="signature">
                        <p>ลงชื่อ <img alt="" src={customerSignature || '/img/blank.png'} /> ผู้จะซื้อ</p>
                        <p className="signature-name"><span>(</span><strong>{customerName}</strong><span>)</span></p>
                    </div>
                    <div className="signature">
                        <p>ลงชื่อ <img alt="" src={staffSignature || '/img/blank.png'} className=""/> ผู้ขาย/เจ้าหน้าที่ตรวจบ้าน</p>
                        <p className="signature-name"><span>(</span><strong>{staffName}</strong><span>)</span></p>
                    </div>
                    <div className="signature">
                        <p>ลงชื่อ <img alt="" src={witnessSignature || '/img/blank.png'} className=""/> พยาน</p>
                        <p className="signature-name"><span>(</span><strong>{witnessName}</strong><span>)</span></p>
                    </div>
                </div>
            </div>
            <div>
                <div className="footer">
                    <img alt="" src={logo} className="logo-project"/>
                    <p className="footer-text">{ addressLine1 }<br />
                        { addressLine2 }
                    </p>
                </div>
                <p className="page-num">1</p>
            </div>
        </div>
    </div>
  )
}

const COUNT_PER_PAGE = 6
const PerLocation = ({ generalInfo, defects, skuCode, buildingId, location, count, projectName , address, logo }) => {
    const grouped = defects.reduce((acc, item, index) => {
        if (index % COUNT_PER_PAGE === 0) {
            // Start new group.
            acc.push([])
        }

        const page = acc[acc.length-1]

        if (index % 2 === 0) {
            page.push([])
        }

        const row = page[page.length-1]
        row.push(item)

        return acc
    }, [])

    return <div>
        { grouped.map((page, index) => {
            return <PerPage
                key={ index }
                generalInfo={ generalInfo }
                defects={ page }
                skuCode={ skuCode }
                buildingId={ buildingId }
                location={ location }
                count={ count + index }
                projectName={ projectName }
                address={ address } 
                logo={ logo }
            />
        })}
    </div>
}

const PerPage = ({ generalInfo, defects, skuCode, buildingId, location, count, projectName, address, logo }) => {
    const newLineIndex = address.indexOf('เขต')
    const addressLine1 = address.slice(0, newLineIndex)
    const addressLine2 = address.slice(newLineIndex)

    return <div className="a4">
        <div className="print-container">
            <div>
                <div className="header-section">
                    <div>
                        <p className="h1">{ projectName }</p>
                        <p className="h2">ห้อง { skuCode }</p>
                        <table className="header-info">
                            <tbody>
                                <tr>
                                    <td>ครั้งที่ตรวจ: { generalInfo.inspectionNo }</td>
                                    <td>วันที่ตรวจ: { generalInfo.inspectionDate.split(' ')[0] }</td>
                                </tr>
                                <tr>
                                    <td>ผู้ตรวจ: { generalInfo.customerName }</td>
                                    <td>ผู้นำตรวจ: { generalInfo.staffName }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <img alt="" src={logo} className="logo-project" />
                </div>
                <div className="content-section">
                    <p className="defect-header h1">{ location }</p>
                    <table className="defect-table">
                        <tbody>
                            { defects.map((row, rowIndex) => {
                                return <tr key={rowIndex}>
                                    { row.map((d, index) => {
                                        return <td className="defect-info before" key={ index }>
                                            <img alt="" src={ d.before } className="defect-picture"/>
                                            <p>{ d.title }</p>
                                        </td>
                                    })}
                                    { row.length < 2
                                        ? <td className="defect-info before">
                                            <img alt="" src="/img/blank.png" className="defect-picture"/>
                                        </td>
                                        : null
                                    }
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <div className="footer">
                    <img alt="" src={logo} className="logo-project"/>
                    <p className="footer-text">{ addressLine1 }<br />
                        { addressLine2 }
                    </p>
                </div>
                <p className="page-num">{ count }</p>
            </div>
        </div>
    </div>
}
