import apiClient from '../apis'
import { createPromise } from '../common/util'
import mocks from '../mocks'
import { Formatter } from '../utilities/Formatter';
import { NA_TEXT } from '../common/const';

const IS_MOCK = false

export function getLeads(offset, pageSize, query) {
    return apiClient.lead.getLeads(offset, pageSize, query)
        .then(res => {
            const leads = res.values.map(c => convertLeadInfoForTable(c))
            return {
                length: res.length,
                values: leads
            }
        })
}

export function searchLeads(firstName) {
    return apiClient.lead.searchLeads(firstName)
        .then(res => {
            const lead = res.values.map(c => convertLeadInfo(c))
            return {
                length: res.length,
                values: lead
            }
        })
}

export function exportLead() {
    return apiClient.lead.exportLead()
}

export function getActivity(){
        return createPromise(mocks.lead.LEAD_ACTIVITY)
}

export function getLead(leadId) {
    if (IS_MOCK) {
        return createPromise(mocks.lead.LEAD_INFO)
    }
    else{
        return apiClient.lead.getLead(leadId)
            .then(res => {
                return convertLeadInfo(res)
            })
        }
}

export function createLead(leadInfo) {
    return apiClient.lead.createLead(leadInfo)
}

export function getLeadSummary(projectId, period, targetMonth){
    return apiClient.lead.getLeadSummary(projectId, period, targetMonth)
}

export function getLeadActivities(projectId, period, targetMonth){
    return apiClient.lead.getLeadActivities(projectId, period, targetMonth)
}

export function filterSummaryByDate(projectId, startDate, endDate) {
    return apiClient.lead.filterSummaryByDate(projectId, startDate, endDate)
}

export function filterActivityByDate(projectId, startDate, endDate) {
    return apiClient.lead.filterActivityByDate(projectId, startDate, endDate)
}

function convertLeadInfo(lead) {
    const gender = convertGender(lead.gender)
    return {
        id: lead.id,
        first_name: lead.first_name,
        last_name: lead.last_name,
        email: lead.email || NA_TEXT,
        phone_number: lead.phone_number || NA_TEXT,
        qualified: lead.qualified,
        prospect: lead.prospect,
        gender: gender,
        age: lead.age || NA_TEXT,
        occupation: lead.occupation || NA_TEXT,
        income: lead.income || NA_TEXT,
        address: lead.address || NA_TEXT,
        sub_district: lead.sub_district || NA_TEXT,
        district: lead.district || NA_TEXT,
        province: lead.province || NA_TEXT,
        postal_code: lead.postal_code || NA_TEXT,
        line_id: lead.line_id || NA_TEXT,
        sources: lead.sources,
        occupation_detail: lead.occupation_detail || NA_TEXT
    }
}

function convertLeadInfoForTable (lead) {
    const gender = convertGender(lead.gender)
    const created = Formatter.formatDateTh(Formatter.createDate(lead.created))
    return {
        id: lead.id,
        first_name: lead.first_name,
        last_name: lead.last_name,
        phone_number: lead.phone_number,
        qualified: lead.qualified,
        prospect: lead.prospect,
        age: lead.age,
        occupation: lead.occupation,
        income: lead.income,
        created: created,
        creatorName: lead.creator_full_name
    }
}

function convertGender(gender){
    switch (gender){
        case 1:
            return "ชาย"
        case 2:
            return "หญิง"
        default:
            return "ไม่ระบุ"
    }
}
