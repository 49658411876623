import React, { PureComponent } from 'react'
import EditCampaignForm from '../../components/forms/EditCampaignForm';
import Wrapper from '../../layouts/Wrapper';

export default class EditCampaignView extends PureComponent {
  componentWillMount() {
    this.props.setHeader('แก้ไขแคมเปญ')
  }

  goToCampaignListStep(){
    this.props.router.push('/campaign')
  }

  render() {
    return <Wrapper>
            <div className="row">
                <div className="col-sm-12">
                    <EditCampaignForm
                        ref="form"
                        noHeaderAndFooter
                        campaignId={this.props.params.campaignId}
                    />
                    <div className="d-flex flex-row mt-3 justify-content-center">
                        <button
                            onClick={() => this.goToCampaignListStep()}
                            className="m-05 btn btn-save-cancel btn-outline"
                            style={{padding: 0}}
                        >
                            ยกเลิก
                        </button>
                        <button
                            onClick={() => {
                                this
                                .refs
                                .form.getWrappedInstance()
                                .save()
                                .then(this.goToCampaignListStep.bind(this))
                                .catch(err => console.log(err))
                            }}
                            className="m-05 btn btn-save-cancel btn-outline"
                            style={{padding: 0}}
                        >
                            บันทึก
                        </button>
                    </div>
                    <div style={{textAlign: 'center'}}>
                        <button
                            onClick={() => {
                                this
                                .refs
                                .form.getWrappedInstance()
                                .delete()
                                .then(this.goToCampaignListStep.bind(this))
                                .catch(err => console.log(err))
                            }}
                            className="m-05 btn btn-save-cancel btn-red-no-border"
                            style={{padding: 0}}
                        >
                            ลบแคมเปญ
                        </button>
                    </div>
                </div>
            </div>
        </Wrapper>
  }
}