import React, { Component } from 'react'
import { connect } from 'react-redux'

import { 
    LOAN_STATUS_NA, 
    LOAN_STATUS_NOT_APPROVED, 
    LOAN_STATUS_APPROVED, 
    LOAN_STATUS_CASH, 
    INSPECT_STATUS_NA, 
    INSPECT_STATUS_PASSED, 
    TRANSFER_STATUS_NA, 
    TRANSFER_STATUS_COMPLETED 
} from '../common/const'
import LazyTable from '../components/common/tables/LazyTable'
import Card from '../components/common/Card'
import Wrapper from '../layouts/Wrapper'
import SaleRecordInfoModal from '../components/forms/SaleRecordInfoModal'
import { translate } from '../common/util'
import datastore from '../datastore'


class SaleRecordsView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            query: '',
            searchKey: 'skuCode'
        }
        this.rowClickCallback = this.rowClickCallback.bind(this)
        this.ctaButtonCallback = this.ctaButtonCallback.bind(this)
        this.searchCallback = this.searchCallback.bind(this)
        this.getValues = this.getValues.bind(this)
        this.handleExport = this.handleExport.bind(this)
        this.columns = [
            {
                header: 'รหัสสินค้า',
                accessor: 'orderingSkuCode',
            },
            {
                header: 'ชื่อผู้ซื้อ',
                accessor: 'customerName',
            },
            {
                header: 'สถานะการยื่นสินเชื่อ',
                accessor: 'loanStatus',
                render: value => {
                    const color = (value => {
                        switch(value) {
                            case LOAN_STATUS_NA:
                                return 'gray'
                            case LOAN_STATUS_CASH:
                            case LOAN_STATUS_APPROVED:
                                return 'green'
                            case LOAN_STATUS_NOT_APPROVED:
                                return 'red'
                            default:
                                return 'orange'
                        }
                    })(value)
                    
                    const text = translate('loan_statuses', value.toString())
                    return <span 
                        style={{ color: color }}
                        className="text-overflow-2"
                    >
                        { text }
                    </span>
                }
            },
            {
                header: 'สถานะการตรวจรับ',
                accessor: 'inspectStatus',
                render: value => {
                    const color = (value => {
                        switch(value) {
                            case INSPECT_STATUS_NA:
                                return 'gray'
                            case INSPECT_STATUS_PASSED: 
                                return 'green'
                            default:
                                return 'orange'
                        }
                    })(value)

                    return (
                        <span className="text-overflow-2" style={{ color: color }}>
                            { translate('inspection_statuses', value.toString()) }
                        </span>
                    )
                }
            },
            {
                header: 'สถานะการโอน',
                accessor: 'isTransfer',
                render: value => {
                    const color = (value => {
                        switch(value) {
                            case TRANSFER_STATUS_COMPLETED:
                                return 'green'
                            case TRANSFER_STATUS_NA:
                            default:
                                return 'orange'
                        }
                    })(value)
                    // for further change to use translation set
                    const text = (value => {
                        switch(value) {
                            case TRANSFER_STATUS_COMPLETED:
                                return 'โอนแล้ว'
                            case TRANSFER_STATUS_NA:
                                return 'ยังไม่โอน'
                            default:
                                return 'n/a'
                        }
                    })(value)

                    return (
                        <span className="text-overflow-2" style={{ color: color }}>
                            { text }
                        </span> 
                    )
                }
            },
            {
                header: 'เจ้าหน้าที่รับผิดชอบ',
                accessor: 'saleName',
            },
            {
                header: 'สถานะการขาย',
                accessor: 'isCancel',
                filterAccessor: 'isCancel',
                visible: true,
                render: value => {
                    if(value){
                        return(
                            <span className="text-overflow-2" style={{ color: 'red' }}>
                                ยกเลิก
                            </span> 
                        )
                    }
                    else {
                        return(
                            <span className="text-overflow-2" style={{ color: 'green' }}>
                                ปกติ
                            </span> 
                        )
                    }
                }
            },
            {
                header: 'วันที่จอง',
                accessor: 'created',
            }
        ]
        this.searchOptions = [
            {
                label: 'รหัสสินค้า',
                value: 'skuCode'
            },
            {
                label: 'ชื่อลูกค้า',
                value: 'customerName'
            }
        ]
    }

    componentWillMount() {
        // this.props.setHeader('บันทึกการขาย', this.ctaButtonCallback, 'เพิ่มบันทึกการขาย', this.searchCallback, 'ค้นหารหัสสินค้า', this.searchOptions)
        this.props.setHeader(
            'บันทึกการขาย',
            [this.handleExport],
            ['ดาวน์โหลด CSV'],
            this.searchCallback,
            'ค้นหารหัสสินค้า',
            this.searchOptions
        )
    }

    handleExport() {
        datastore.sale.exportSaleRecord()
    }

    ctaButtonCallback() {
        this.refs.infoModal.openModal()
    }

    searchCallback(value, searchKey) {
        this.setState({
            query: value,
            searchKey
        })
    }

    getValues(offset, pageSize, sortKey, sortOrder, filters) { 
        if (!sortKey) {
            sortKey = 'orderingSkuCode'
            sortOrder = false
        }

        return datastore.sale.getSaleRecords(offset, pageSize, sortKey, sortOrder, filters, [this.state.searchKey, this.state.query])
    }

    rowClickCallback(row) {
        this.props.router.push(`/sale-record/${row.id}`)
    }

    render() {
        const { options } = this.props
        return (
            <Wrapper>
                <Card>
                    <LazyTable 
                        columns={this.columns} 
                        getValues={this.getValues} 
                        filterOptions={{...options, isCancel: CANCEL_STATUS_OPTIONS}}
                        rowClickCallback={this.rowClickCallback}
                        ref="saleRecordTable"
                    />
                </Card>
                <SaleRecordInfoModal ref="infoModal" refreshFunc={ () => this.refs.saleRecordTable.refreshTable() }/>
            </Wrapper>
        )
    }
}

function mapStateToProps(state) {
    return {
        options: state.other.options,
    }
}

export default connect(mapStateToProps, null)(SaleRecordsView)

const CANCEL_STATUS_OPTIONS = [
    {
        label: 'รายการปกติ',
        value: false,
    },
    {
        label: 'รายการที่ยกเลิก',
        value: true,
    },
]
