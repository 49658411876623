import React, { Component } from 'react'
import { connect } from 'react-redux'

import TitleBar from './TitleBar'
import NavBar from './NavBar'
import { getTranslations } from '../actions/other'
import { getProfile } from '../actions/auth'
import SelectProjectModal from '../components/SelectProjectModal'

class AppLayout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: ''
        }

        this.setHeader = this.setHeader.bind(this)
    }

    componentWillMount() {
        this.props.getTranslations()
        this.props.getProfile()
    }

    setHeader(title, ctaButtonCallback, buttonName, searchCallback, searchPlaceholder, options, leftCtas) {
        this.setState({
            title: title,
            ctaButtonCallback: ctaButtonCallback,
            buttonName: buttonName,
            searchCallback: searchCallback,
            searchPlaceholder: searchPlaceholder,
            options,
            leftCtas
        })
    }

    openProjectModal = () => {
        this.refs.projectModal.openModal()
    }

    render() {
        const { title, ctaButtonCallback, buttonName, searchCallback, searchPlaceholder, options, leftCtas } = this.state
        const { profile, translations } = this.props

        const hideNavbar = this.props.children.props.route.hideNavbar

        if (!profile || !translations) {
            return null
        }

        return (
            <div className="container-fluid m-0 p-0">
                <div className="row no-gutters white-bg" >
                    {!hideNavbar && (
                        <NavBar 
                            router={this.props.router} 
                            title={title} 
                            openProjectModal={this.openProjectModal} 
                        />
                    )}
                    <div className="col">
                        <TitleBar
                            title={title}
                            ctaButtonCallback={ctaButtonCallback}
                            buttonName={buttonName}
                            searchCallback={searchCallback}
                            searchPlaceholder={searchPlaceholder}
                            options={options}
                            backButton={hideNavbar}
                            router={this.props.router}
                            leftCtas={leftCtas}
                            hideNavbar={hideNavbar}
                        />
                            {React.cloneElement(
                                this.props.children, 
                                { 
                                    setHeader: this.setHeader, 
                                    openProjectModal: this.openProjectModal,
                                    router: this.props.router,
                                }
                            )}
                    </div>
                </div>
                <SelectProjectModal ref="projectModal" noBackground />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        translations: state.other.translations,
        profile: state.auth.profile,
    }
}

export default connect(mapStateToProps, {
    getTranslations,
    getProfile
})(AppLayout)
