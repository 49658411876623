import React, { PureComponent } from 'react';
import { inModal } from '../../../hoc/inModal';
import ModalTitle from '../../../components/common/ModalTitle';
import TextWithLabel from '../../../components/common/TextWithLabel';
import TextInput from '../../../components/common/TextInput';
import FormNewLine from '../../../components/common/FormNewLine';
import { Formatter } from '../../../utilities/Formatter';

export class ActivityDetailForm extends PureComponent {
  constructor(props){
    super(props)

    this.state = {
      data: {
        activityDetail: ''
      },
      record: this.props.activity ? this.props.activity : null
    }
  }

  render() {
    return (
      <div className="activity-modal">
        <ModalTitle title='รายละเอียดกิจกรรม' onClick={() => this.props.closeModal()} />
        <div className="row no-gutters justify-content-between mb-2" style={{maxWidth: "500px"}}>
          <TextWithLabel label="สถานะ" color="#ffb400">ยังไม่ดำเนินการ</TextWithLabel>
          <div style={{marginLeft: '14px'}}>
            <TextWithLabel label="วันที่ดำเนินกิจกรรม">{Formatter.formatDateTh(Formatter.createDate(this.state.record.targetDate))}</TextWithLabel>
          </div>
          <div></div>
        </div>
        <FormNewLine />
        <div className="row no-gutters justify-content-between mb-2" style={{maxWidth: "500px"}}>
          <TextWithLabel label="ลูกค้า">{this.state.record.customerName}</TextWithLabel>
          <TextWithLabel label="สินค้า">{this.state.record.product}</TextWithLabel>
          <TextWithLabel label="ประเภทกิจกรรม">{this.state.record.type}</TextWithLabel>
        </div>
        <FormNewLine />
        <div className="row no-gutters justify-content-between">
          <TextWithLabel label="โน้ตเพิ่มเติม">{this.state.record.activity ? this.state.record.activity : '-'}</TextWithLabel>
        </div>
        <FormNewLine />
        <div className="row no-gutters justify-content-between">
        {
          this.state.record.rejectReason !== '-' ?
          <TextWithLabel label="ขอข้อมูลเพิ่มเติมครั้งก่อน">{this.state.record.rejectReason}</TextWithLabel>
          : null
        }
        </div>
        {
          this.renderButton()
        }
      </div>
    )
  }

  renderButton() {
    return (
      <div style={{paddingTop: "16px"}} className="d-flex flex-row justify-content-center">
        {/* <button 
          style={{padding: 0}}
          onClick={ () => {
            this.props.onClickBack()
          }}
          className="m-05 btn btn-save-cancel btn-outline"
        >
          ปิด
        </button> */}
        <button 
          style={{padding: 0}}
          onClick={ () => {
            this.props.onUpdateActivity()
          }} 
          className="m-05 btn btn-save-cancel btn-outline"
        >
          แก้ไขข้อมูล
        </button>
      </div>
    )
  }
}

const ActivityDetailModal = inModal(ActivityDetailForm)
export default ActivityDetailModal;