import React, { Component } from 'react'
import Wrapper from '../../layouts/Wrapper'
import { LeadInfoForm } from '../../components/forms/LeadInfoModal'

export default class AddLeadView extends Component {
   componentWillMount() {
        this.props.setHeader('เพิ่มผู้สนใจ')
   }

   goToShowListStep() {
        this.props.router.push('/lead')
   }

   goToBookingStep(leadId) {
       this.props.router.push(`/lead/${leadId}/booking`)
   }

   render() {
        return <Wrapper>
                <div className="row">
                    <div className="col-sm-12">
                        <LeadInfoForm
                            ref="form"
                            noHeaderAndFooter
                        />
                        <div className="d-flex flex-row my-3 justify-content-center">
                            <button
                                onClick={() => this.goToShowListStep()}
                                className="m-05 btn btn-save-cancel btn-outline"
                                style={{padding: 0}}
                            >
                                ยกเลิก
                            </button>
                            <button
                                onClick={() => {
                                    this
                                    .refs
                                    .form.getWrappedInstance()
                                    .save()
                                    .then(this.goToShowListStep.bind(this))
                                }}
                                className="m-05 btn btn-save-cancel btn-outline"
                                style={{padding: 0}}
                            >
                                บันทึก
                            </button>
                            <button
                                onClick={() => {
                                    this
                                    .refs
                                    .form.getWrappedInstance()
                                    .save()
                                    .then(result => {
                                        this.goToBookingStep(result.key)
                                    })
                                }}
                                className="m-05 btn btn-main"
                                style={{padding: 0, width: "160px"}}
                            >
                                บันทึกและจองห้อง
                            </button>
                        </div>
                    </div>
                </div>
            </Wrapper>
   }
}

