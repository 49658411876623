import moment from 'moment'

export class DashboardState {
  static initialState = {
    data: {
      year: moment().year()
    },
    campaignId: 0,
    monthForWalkGraph: moment().month(),
    monthForTransferGraph: moment().month(),
    monthForCostGraph: moment().month(),
    costPerWalk: {
      offline: {
        cost: '0',
        totalWalk: '0',
        costPerWalk: '0'
      },
      online: {
        cost: '0',
        totalWalk: '0',
        costPerWalk: '0'
      }
    },
    walkAndBookingUnit: {
      walk: [],
      valuation: [],
      booking: []
    },
    transferUnit: {
      total: [],
      valuation: []
    },
    summaryCard: {
      total: {
        count: 0,
        valuation: ''
      },
      sold: {
        count: 0,
        valuation: ''
      },
      free: {
        count: 0,
        valuation: ''
      }
    },
    walkAndBookingData: {
      labels: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.',
      'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.',
      'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
      datasets: [
        {
          label: "จำนวนการWalk",
          data: [],
          fill: false,
          borderColor: '#00ace1'
        },
        {
          label: "จำนวนการขาย",
          data: [],
          fill: false,
          borderColor: '#00b777'
        }
      ]
    },
    unitRanking: {
      labels: [],
      datasets: [
        {
          label: "จำนวนคน",
          data: [],
          backgroundColor: '#00ace1'
        },
      ]
    },
    concernRanking: {
      labels: [],
      datasets: [
        {
          label: "จำนวนคน",
          data: [],
          backgroundColor: '#00b777'
        }
      ]
    },
    transferData: {
      labels: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.',
      'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.',
      'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
      datasets: [
        {
          label: "จำนวนห้องที่โอน",
          data: [],
          fill: false,
          borderColor: '#00b777'
        }
      ]
    },
    onlineAdsData: {
      labels: [],
      datasets: [
        {
          label: "จำนวนครั้ง",
          data: [],
          backgroundColor: '#ffb400'
        }
      ]
    },
    offlineAdsData: {
      labels: [],
      datasets: [
        {
          label: "จำนวนครั้ง",
          data: [],
          backgroundColor: '#00ace1'
        }
      ]
    },
    leadSourceData: {
      labels: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.',
      'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.',
      'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
      datasets: [
        {
          label: "จำนวนผู้สนใจที่มาจากสื่อออนไลน์",
          data: [],
          fill: false,
          borderColor: '#ffb400'
        },
        {
          label: "จำนวนผู้สนใจที่มาจากสื่อออฟไลน์",
          data: [],
          fill: false,
          borderColor: '#00ace1'
        }
      ]
    },
    conversionData: {
      walk: {
        total: '0'
      },
      book: {
        total: '0',
        pending: '0',
        cancel: '0'
      },
      loanApprove: {
        total: '0',
        pending: '0',
        cancel: '0'
      },
      fixedInspection: {
        total: '0',
        pending: '0',
        cancel: '0'
      },
      transfer: {
        total: '0',
        valuation: '0'
      }
    },
    campaignSelector: []
  }
}