import React  from 'react'

export default ({ name, placeholder, rows, target, stateKey, nestedKey, renderValue }) => {
    return <textarea 
        className="form-control input w-100"
        placeholder={ placeholder }
        name={ name }
        rows={ rows }
        onChange={ (e) => {
            const data = target.state[stateKey]

            if (nestedKey) {
                 data[nestedKey] = data[nestedKey] || {} 
                 data[nestedKey][e.target.name] = e.target.value 
            } else {
                data[e.target.name] = e.target.value
            }

            target.setState({
                [stateKey]: data
            })
        }}
        value={renderValue}
    />
}
