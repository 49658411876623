import React, { Component } from 'react'
import PropTypes from 'prop-types'

class BankDocumentListHeader extends Component {
    render() {
        const { title, icon, missing, selfSubmission } = this.props
        const selfSubmissionStatus = selfSubmission
            ? <span className="ml-1" style={{ color: 'red' }}>(ลูกค้ายื่นธนาคารโดยตรง)</span>
            : null

        return (
            <div className="d-flex flex-row align-items-center">
                <img alt="" src={ icon } style={{ maxWidth: "56px", maxHeight: "56px" }} />
                <div className="ml-1">
                    <h5 className="d-inline">{ title }</h5>{ selfSubmissionStatus }
                    { missing && missing > 0
                        ? <p className="mb-0 grey">ขอเอกสารเพิ่มเติม { missing } เอกสาร</p>
                        : null
                    }
                </div>
            </div>
        )
    }
}

BankDocumentListHeader.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    count: PropTypes.number,
    missing: PropTypes.number,
    selfSubmission: PropTypes.bool
}

export default BankDocumentListHeader