import React, { Component } from 'react'
import { connect } from 'react-redux'

import { 
    SURVEY_STATUS_DRAFT,
    SURVEY_STATUS_ACTIVE,
    SURVEY_STATUS_EXPIRE
} from '../../../common/const'
import LazyTable from '../../../components/common/tables/LazyTable'
import Card from '../../../components/common/Card'
import Wrapper from '../../../layouts/Wrapper'
import SaleRecordInfoModal from '../../../components/forms/SaleRecordInfoModal'
import { translate } from '../../../common/util'
import datastore from '../../../datastore'


class SurveyListView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            query: '',
            searchKey: 'title'
        }
        this.rowClickCallback = this.rowClickCallback.bind(this)
        this.ctaButtonCallback = this.ctaButtonCallback.bind(this)
        this.searchCallback = this.searchCallback.bind(this)
        this.getValues = this.getValues.bind(this)
        this.columns = [
            {
                header: 'หัวข้อ',
                accessor: 'title',
            },
            {
                header: 'URL',
                accessor: 'alias',
                render: value => <a href={`/survey/${value}`} target="_blank">{`/survey/${value}`}</a>
            },
            {
                header: 'คำถาม',
                accessor: 'questions',
                render: value => Array(value).length
            },
            {
                header: 'สถานะ',
                accessor: 'status',
                render: value => {
                    const {color, text} = (value => {
                        switch(value) {
                            case SURVEY_STATUS_ACTIVE:
                                return {color: 'green', text: 'เผยแพร่'}
                            default:
                                return {color: 'gray', text: 'ยังไม่เผยแพร่'}
                        }
                    })(value)

                    // const text = translate('survey_statuses', String(value)) || value
                    return (
                        <span style={{ color: color }} className="text-overflow-2">
                            {text}
                        </span>
                    )
                }
            }
        ]
        this.searchOptions = [
            {
                label: 'หัวข้อ',
                value: 'title'
            }
        ]
    }

    componentWillMount() {
        // this.props.setHeader('บันทึกการขาย', this.ctaButtonCallback, 'เพิ่มบันทึกการขาย', this.searchCallback, 'ค้นหาบันทึกการขาย', this.searchOptions)
        this.props.setHeader('แบบสำรวจ', this.ctaButtonCallback, 'เพิ่มแบบสำรวจ', this.searchCallback, 'ค้นหาแบบสำรวจ', this.searchOptions)
    }

    ctaButtonCallback() {
        this.props.router.push(`/survey-list/create`)
    }

    searchCallback(value, searchKey) {
        this.setState({
            query: value,
            searchKey
        })
    }

    getValues(offset, pageSize, sortKey, sortOrder, filters) { 
        if (!sortKey) {
            sortKey = 'title'
            sortOrder = true
        }

        return datastore.survey.getSurveys(offset, pageSize, sortKey, sortOrder, filters, this.state.query)
    }

    rowClickCallback(row) {
        this.props.router.push(`/survey-list/${row.id}`)
    }

    render() {
        const { options } = this.props
        return (
            <Wrapper>
                <Card>
                    <LazyTable 
                        columns={ this.columns } 
                        getValues={ this.getValues } 
                        filterOptions={ options }
                        rowClickCallback={ this.rowClickCallback }
                        ref="listTable"
                    />
                </Card>
            </Wrapper>
        )
    }
}

function mapStateToProps(state) {
    return {
        options: state.other.options,
    }
}

export default connect(mapStateToProps, null)(SurveyListView)