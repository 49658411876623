import React, { Component } from 'react'

class BlankLayout extends Component {
    render() {
        return (
            <div className="container-fluid">
                {/* <img src="/img/infiniteLogo.png" className="mt-2 ml-15" alt="" /> */}
                { React.cloneElement(this.props.children) }
            </div>
        )
    }
}

export default BlankLayout
