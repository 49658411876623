import React from 'react'
import styled, {css} from 'styled-components'
import {Colors} from '../index'

export const Container = styled.div`
    border: none;
    font-size: 1em;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: none;
`

export const Icon = styled.span`
    padding: 8px;
    display: flex;
    align-items: center;
    color: ${props => props.color || Colors.Default};
`

export const Label = styled.span`
    flex: 2;
    display: flex;
    flex-direction: column;
    padding: 0.725rem;
    padding-left: 0;
    text-align: left;
    font-size: 1rem;
    color: ${Colors.Graphite};
`

export const IconTextGroup = ({icon, children, active, ...props}) => (
    <Container active={active} color={props.color || Colors.Default}>
        <Icon active={active} color={props.color || Colors.Default}>{icon}</Icon>
        <Label active={active}>
        {React.Children.map(children, c => React.cloneElement(c, {
            color: props.color || Colors.Black
        }))}
        </Label>
    </Container>)

export default IconTextGroup