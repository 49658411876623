export const TRANSLATIONS = {
    "version": "0.2.1",
    "genders": [
        [
            "0",
            "ไม่ระบุ"
        ],
        [
            "1",
            "ชาย"
        ],
        [
            "2",
            "หญิง"
        ]
    ],
    "customer_statuses": [
        [
            "0",
            "เข้าเยี่ยมชมแล้ว"
        ],
        [
            "1",
            "จองแล้ว"
        ],
        [
            "2",
            "ตรวจรับแล้ว"
        ],
        [
            "3",
            "ลูกบ้าน"
        ],
        [
            "4",
            "Not Implemented"
        ]
    ],
    "loan_statuses": [
        [
            "0",
            "เอกสารชุดแรกไม่ครบ"
        ],
        [
            "1",
            "เอกสารเพิ่มเติมไม่ครบ"
        ],
        [
            "2",
            "รอ Pre-Approved"
        ],
        [
            "3",
            "ผ่าน Pre-Approved"
        ],
        [
            "4",
            "ไม่ผ่าน Pre-Approved"
        ],
        [
            "5",
            "รออนุมัติ"
        ],
        [
            "6",
            "อนุมัติ"
        ],
        [
            "7",
            "ไม่อนุมัติ"
        ]
    ],
    "product_statuses": [
        [
            "0",
            "Available"
        ],
        [
            "1",
            "Unavailable"
        ]
    ],
    "occupations": [
        [
            "0",
            "รับจ้าง"
        ],
        [
            "1",
            "พนักงานเอกชน"
        ],
        [
            "2",
            "พนักงานรัฐวิสาหกิจ"
        ],
        [
            "3",
            "รับราชการ"
        ]
    ],
    "inspection_statuses": [
        [
            "0",
            "ยังไม่เริ่มการตรวจรับ"
        ],
        [
            "1",
            "นัดตรวจรับ"
        ],
        [
            "2",
            "นัดตรวจรับใหม่"
        ],
        [
            "3",
            "ผ่าน"
        ],
        [
            "4",
            "ไม่ผ่าน"
        ]
    ],
    "marital_statuses": [
        [
            "0",
            "โสด"
        ],
        [
            "1",
            "สมรส"
        ],
        [
            "2",
            "สมรสไม่ได้จดทะเบียน"
        ],
        [
            "3",
            "หย่า"
        ]
    ],
    "salary_ranges": [
        [
            "0",
            "Not Implemented"
        ],
        [
            "1",
            "20,001 - 30,000 บาท"
        ],
        [
            "2",
            "30,001 - 50,000 บาท"
        ],
        [
            "3",
            "50,001 - 70,000 บาท"
        ],
        [
            "4",
            "70,001 - 100,000 บาท"
        ],
        [
            "5",
            "> 100,001 บาท"
        ]
    ],
    "room_types": [
        [
            "7920498032363457276",
            "Type A 24 sq.m"
        ],
        [
            "8098894895252165127",
            "Type B 28 sq.m"
        ],
        [
            "5106915370194822687",
            "Type Pool Terrace 35 sq.m."
        ]
    ],
    "room_views": [
        [
            "8627720340865005735",
            "Standard View"
        ],
        [
            "5976315524812162399",
            "Pool View"
        ],
        [
            "4899608731577015549",
            "Garden View"
        ],
        [
            "5551690272071822662",
            "No View"
        ]
    ],
    "channels": [
        [
            "875410236634021653",
            "Import by Billme"
        ]
    ],
    "buildings": [
        [
            "6976223331376968840",
            "BUILDING A"
        ],
        [
            "6476171722322372891",
            "BUILDING B"
        ],
        [
            "7892870156295160118",
            "BUILDING C"
        ],
        [
            "4744264717614038262",
            "BUILDING D"
        ],
        [
            "5173648908501509892",
            "BUILDING E"
        ]
    ],
    "banks": [
        {
            "id": 1,
            "name": "SCB",
            "templates": [
                {
                    "id": 5,
                    "name": "Standard - โสด + Government"
                },
                {
                    "id": 6,
                    "name": "Standard - สมรส + Government"
                },
                {
                    "id": 7,
                    "name": "Standard - สมรสไม่จดทะเบียน  + Government"
                },
                {
                    "id": 8,
                    "name": "Standard - หย่า  + Government"
                }
            ]
        },
        {
            "id": 2,
            "name": "KK",
            "templates": [
                {
                    "id": 9,
                    "name": "Standard - โสด + Government"
                },
                {
                    "id": 10,
                    "name": "Standard - สมรส + Government"
                },
                {
                    "id": 11,
                    "name": "Standard - สมรสไม่จดทะเบียน  + Government"
                },
                {
                    "id": 12,
                    "name": "Standard - หย่า  + Government"
                }
            ]
        },
        {
            "id": 3,
            "name": "KBANK",
            "templates": [
                {
                    "id": 13,
                    "name": "Standard - โสด + Government"
                },
                {
                    "id": 14,
                    "name": "Standard - สมรส + Government"
                },
                {
                    "id": 15,
                    "name": "Standard - สมรสไม่จดทะเบียน  + Government"
                },
                {
                    "id": 16,
                    "name": "Standard - หย่า  + Government"
                }
            ]
        }
    ]
}

export const STAFF_LOOKUP = {
    "results": [
        [
            "6926098836215128801",
            "Infinite Service Manager"
        ],
        [
            "8772115761772542386",
            "Padawan"
        ],
        [
            "5541026606690801150",
            "Yoda Minch"
        ]
    ]
}

export const DASHBOARD_INFO = [
    {
        title: "โครงการทั้งหมด",
        transfer: {
            total: 1400,
            actual: 840
        },
        documentStatus: {
            waitingForDocument: 140,
            waitingForBankSubmission: 70,
            waitingForResult: 192,
            receivedResult: 982
        },
        resultStatus: {
            rejected: 69,
            requestDocument: 29,
            incompleteLimit: 147,
            completeLimit: 737
        },
        inspectStatus: {
            waiting: 44,
            first: 221,
            second: 133,
            third: 44,
            passed: 442
        }
    },
    {
        title: "แฮปปี้ ดอนเมือง",
        transfer: {
            total: 420,
            actual: 210
        },
        documentStatus: {
            waitingForDocument: 84,
            waitingForBankSubmission: 21,
            waitingForResult: 63,
            receivedResult: 252
        },
        resultStatus: {
            rejected: 4,
            requestDocument: 8,
            incompleteLimit: 63,
            completeLimit: 177
        },
        inspectStatus: {
            waiting: 7,
            first: 41,
            second: 72,
            third: 12,
            passed: 108
        }
    }
]