import React, { Component } from 'react'
import { connect } from 'react-redux'

import Wrapper from '../../layouts/Wrapper'
import ProjectGraph from './ProjectGraph'
import datastore from '../../datastore'
import { removeToken } from '../../actions/auth'

class DashboardView extends Component {
    state = {
        dashboardData: null
    }

    logout = () => {
        this.props.removeToken()
        this.props.router.push('/')
    }

    componentWillMount() {
        this.props.setHeader('ภาพรวมโครงการ')
        datastore.other.getDashboardData()
            .then(res => {
                const dashboardData = res.find(d => d.title === this.props.profile.current_project.name)
                this.setState({
                    dashboardData
                })
            })
            .catch(error => {
                this.logout()
            })
    }

    render() {
        if (!this.state.dashboardData) {
            return null
        }

        const data = this.state.dashboardData

        return (
            <Wrapper>
                <ProjectGraph
                    key= {data.title}
                    title={ data.title }
                    transfer={ data.transfer }
                    documentStatus={ data.documentStatus }
                    resultStatus={ data.resultStatus }
                    inspectStatus={ data.inspectStatus }
                />
            </Wrapper>
        )
    }
}

function mapStateToProps(state) {
    return {
        profile: state.auth.profile
    }
}

export default connect(mapStateToProps, { removeToken })(DashboardView)