import JSONBig from 'json-bigint';
import moment from 'moment';

import config from './config';
import datastore from '../datastore';
import { NA_TEXT, DEFAULT_ADDRESS, DEFAULT_LOGO_URL, PRODUCT_TYPE_CONDO } from './const';
import { store } from '../index';

function stringifyParams(params) {
  const keys = Object.keys(params);
  if (keys.length === 0) {
    return '';
  }

  const q = keys.reduce((acc, key) => {
    acc += `&${key}=${params[key]}`;
    return acc;
  }, '');

  return q.substring(1);
}

function getCurrentHost() {
  const baseUrl =
    window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? ':' + window.location.port : '');

  return baseUrl;
}

function getCurrentProjectId() {
  const profile = store.getState().auth.profile;
  return profile && profile.current_project && profile.current_project.id
    ? profile.current_project.id.toString()
    : null;
}

function validateProjectId(res) {
  if (!res.headers.has(config.projectIdHeaderName)) {
    return res;
  }

  const newProjectId = res.headers.get(config.projectIdHeaderName);
  const currentProjectId = getCurrentProjectId();
  if (!newProjectId || currentProjectId === newProjectId) {
    return res;
  }

  return datastore.user.switchProject(newProjectId).then(res => {
    const currentPath = window.location.pathname + window.location.search;
    reloadPage(currentPath);
  });
}

export function openNewWindow(urlPath, params) {
  const queryString = params ? stringifyParams(params) : '';
  window.open(`${config.baseApiUrl}${urlPath}?${queryString}`, '_blank');
}

export function openNewWindowSameHost(urlPath, params) {
  const baseUrl = getCurrentHost();
  const queryString = params ? stringifyParams(params) : '';

  window.open(`${baseUrl}${urlPath}?${queryString}`, '_blank');
}

export function reloadPage(urlPath, params) {
  if (!urlPath) {
    window.location = window.location.href;
  }

  const baseUrl = getCurrentHost();
  const queryString = params ? stringifyParams(params) : '';

  window.location = `${baseUrl}${urlPath}?${queryString}`;
}

export function getRequest(urlPath, params, requiredToken = true, parseJson = true) {
  const queryString = params ? stringifyParams(params) : '';
  const headers = new Headers();
  if (requiredToken) {
    headers.append(config.tokenHeaderName, store.getState().auth.token);
  }

  return new Promise((resolve, reject) => {
    fetch(`${config.baseApiUrl}${urlPath}?${queryString}`, {
      headers: headers,
      method: 'GET',
      mode: 'cors',
    })
      .then(res => {
        return validateProjectId(res);
      })
      .then(res => {
        return parseJson && res ? res.text() : res;
      })
      .then(r => {
        if (parseJson) {
          const parsed = JSONBig.parse(r);
          resolve(parsed);
        } else {
          resolve(r);
        }
      });
  });
}

export function postRequest(urlPath, data, requiredToken = true, isFormData = false) {
  const headers = isFormData
    ? new Headers()
    : new Headers({
        'Content-Type': 'application/json',
      });

  const token = store.getState().auth.token;
  if (requiredToken) {
    headers.append(config.tokenHeaderName, token);
  }

  return new Promise((resolve, reject) => {
    fetch(`${config.baseApiUrl}${urlPath}`, {
      method: 'POST',
      headers: headers,
      body: isFormData ? data : JSON.stringify(data),
      mode: 'cors',
    })
      .then(res => {
        return res.text();
      })
      .then(r => {
        const parsed = isFormData || !r ? r : JSONBig.parse(r);
        resolve(parsed);
      })
      .catch(r => {
        console.error(r.message);
      });
  });
}

export function getReport(host, path) {
  const headers = new Headers();
  const token = store.getState().auth.token;
  headers.append(config.tokenHeaderName, token);

  return fetch(`${host}${path}`, {
    method: 'POST',
    headers: headers,
  })
    .then(response => {
      const name = response.headers
        .get('content-disposition')
        .split('filename=')
        .pop()
        .replace(/"/g, '');

      response.blob().then(result => {
        var url = window.URL.createObjectURL(result);
        var a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
      });

      return true;
    })
    .catch(() => {
      return false;
    });
}

export function putRequest(urlPath, data, requiredToken = true, isFormData = false) {
  const headers = isFormData
    ? new Headers()
    : new Headers({
        'Content-Type': 'application/json',
      });

  const token = store.getState().auth.token;
  if (requiredToken) {
    headers.append(config.tokenHeaderName, token);
  }

  return new Promise((resolve, reject) => {
    fetch(`${config.baseApiUrl}${urlPath}`, {
      method: 'PUT',
      headers: headers,
      body: isFormData ? data : JSON.stringify(data),
      mode: 'cors',
    })
      .then(res => {
        return res.text();
      })
      .then(r => {
        const parsed = isFormData || !r ? r : JSONBig.parse(r);
        resolve(parsed);
      })
      .catch(r => {
        console.error(r.message);
      });
  });
}

export function deleteRequest(urlPath, data, requiredToken = true) {
  const headers = new Headers({
    'Content-Type': 'application/json',
  });

  const token = store.getState().auth.token;
  if (requiredToken) {
    headers.append(config.tokenHeaderName, token);
  }

  return new Promise((resolve, reject) => {
    fetch(`${config.baseApiUrl}${urlPath}`, {
      method: 'DELETE',
      headers: headers,
      body: JSON.stringify(data),
      mode: 'cors',
    })
      .then(res => {
        return res.text();
      })
      .then(r => {
        const parsed = JSONBig.parse(r);
        resolve(parsed);
      })
      .catch(r => {
        console.error(r.message);
      });
  });
}

export function createPromise(values) {
  return new Promise(resolve => {
    resolve(values);
  });
}

export function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function getProject(projectId) {
  const profile = store.getState().auth.profile;
  if (!profile) {
    return '';
  }

  const project = profile.projects.find(p => p.id.toString() === projectId);
  return project;
}

export function translate(optionKey, value) {
  switch (optionKey) {
    case 'warranty_types':
      return value == 1 ? 'ส่วนควบ' : value == 2 ? 'โครงสร้าง' : 'invalid';
    default:
      break;
  }

  const translations = store.getState().other.translations;
  if (!translations) {
    // console.log('translation is not ready.')
    return '';
  }

  const options = translations[optionKey];
  if (!options) {
    // console.error(`${optionKey} is not in translations.`)
    return '';
  }

  // TODO: Or value should not be null?
  if (value == null) {
    return NA_TEXT;
  }

  const translatedValue = options[value];
  if (!translatedValue && translatedValue !== '') {
    console.error(`${value} is not a valid ${optionKey} value.`);
    return '';
  }

  return translatedValue;
}

export function getOptions(key) {
  const options = store.getState().other.options;
  if (key === 'warranty_types') {
    return [
      { value: '1', label: 'ส่วนควบ (รับประกัน 1 ปี)' },
      { value: '2', label: 'โครงสร้าง (รับประกัน 5 ปี)' },
    ];
  }

  if (!options) {
    // console.log('options is not ready.')
    return [];
  }

  const option = options[key];
  if (!option) {
    // console.error(`${key} is not in options.`)
    return [];
  }

  return option;
}

export function camelToSnake(str) {
  return str.replace(/([A-Z])/g, $1 => {
    return '_' + $1.toLowerCase();
  });
}

export function formatCurrencyNumber(number) {
  if (!number) {
    return '';
  }

  return Number(number).toLocaleString('th', { minimumFractionDigits: 2 });
}

export function formatNumberWithComma(number) {
  const cleaned = number.replace(/[^0-9]/g, '');
  if (!cleaned) {
    return '';
  }

  return parseFloat(cleaned).toLocaleString('th');
}

export function phoneNumberFormatter(phoneNumber) {
  var cleaned = ('' + phoneNumber).replace(/\D/g, '')
  var match = cleaned.match(/^(\d{2,3})(\d{3})(\d{4})$/)
  if (match) {
    return match[1] + '-' + match[2] + '-' + match[3]
  }
  return null
}

export function formatDateTime(dateStr) {
  return moment(dateStr).format('YYYY-MM-DD HH:mm:ss');
}

export function convertInspectionsInfo(inspections, type) {
  const imagePrefix = 'https://storage.googleapis.com/bcrm/';
  return inspections.map(i => {
    const locations = i.defect_locations.reduce((acc, l) => {
      acc[l.id] = l.name;
      return acc;
    }, {});

    const types = i.defect_types.reduce((acc, t) => {
      acc[t.id] = t.name;
      return acc;
    }, {});

    const inspection = {
      id: i.id.toString(),
      buildingType: i.building_type,
      inspectionNo: i.inspection_no,
      inspectionDate: i.inspect_date,
      status: i.status,
      staffName: i.inspector_name,
      saleName: '', //i.staff_name,
      nextInspectionDate: i.next_inspection_date || 'ไม่ระบุ', // TODO: Check if it should also be in QC.
      floorPlan: !i.floor_plans ? '' : i.floor_plans[0],

      defects: i.defects.map((d, index) => ({
        id: index + 1, // d.id.value,
        title: d.title,
        defectType: types[d.defect_type] || 'ไม่ระบุ',
        location: locations[d.location_id] || 'ไม่ระบุ',
        status: d.after ? 'แก้ไขแล้ว' : 'ระหว่างแก้ไข',
        // Right now we only use one floorpan per inspection as a map.
        // map: d.map ? `${imagePrefix}${d.map}` : null,
        map: !i.floor_plans ? '' : i.floor_plans[0],
        before: d.before ? `${imagePrefix}${d.before}` : null,
        after: d.after ? `${imagePrefix}${d.after}` : '/img/defect-none.png',
      })),
    };

    if (type === 'qc') {
      inspection.engineerName = i.mech_name;
      inspection.engineerSignatureUrl = i.mech_signature_url
        ? `${imagePrefix}${i.mech_signature_url}`
        : null;
      inspection.staffSignatureUrl = i.signature_url
        ? `${imagePrefix}${i.mech_signature_url}`
        : null;
    } else {
      inspection.customerName = i.customer_name;
      inspection.engineerName = i.staff_name;
      inspection.witnessName = i.witness_name;
      inspection.witnessSignatureUrl = i.witness_signature_url
        ? `${imagePrefix}${i.witness_signature_url}`
        : null;
      inspection.customerSignatureUrl = i.signature_url ? `${imagePrefix}${i.signature_url}` : null;
      inspection.mechSignatureUrl = i.mech_signature_url
        ? `${imagePrefix}${i.mech_signature_url}`
        : null;
      inspection.staffSignatureUrl = i.staff_signature_url
        ? `${imagePrefix}${i.staff_signature_url}`
        : null;
    }

    return inspection;
  });
}

export function hackToParseMetaJson(meta) {
  //const fixedMeta = meta.slice(0, meta.length-1) + '"}'
  const fixedMeta = meta;
  try {
    const parsed = JSON.parse(fixedMeta);
    return parsed;
  } catch (e) {
    console.error('Cannot parse meta_json to json: ' + fixedMeta);
    return null;
  }
}

export function parseAddressAndLogo(projectMetaJson) {
  const meta = projectMetaJson ? hackToParseMetaJson(projectMetaJson) : null;
  const address = meta && meta.branch_address ? meta.branch_address : DEFAULT_ADDRESS;
  const logoUrl = meta && meta.branch_logo_url ? meta.branch_logo_url : DEFAULT_LOGO_URL;

  return { address, logoUrl };
}


const SALE_MODE_OPTIONS = [
    { 
        label: 'เพิ่มผู้สนใจ',
        path: '/sale-mode/add-lead',
    },
    {
        label: 'สร้างกิจกรรม', 
        path: '/sale-mode/search-lead?next=activity',
    },
    {
        label: 'จองห้อง',
        path: '/sale-mode/search-lead?next=reserve',
    }
]

export function setSaleModeHeader(setHeader, push, title, toggle) {
    setHeader(
        title,
        SALE_MODE_OPTIONS.map(o => () => push(o.path)),
        SALE_MODE_OPTIONS.map(o => o.label),
        null,
        null,
        null,
        [...toggle, { label: 'หน้าแรก', callback: () => push('/') }],
    )
}
