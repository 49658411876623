const SUPERUSER = [
    '/schedule',
    '/dashboard',
    '/dashboard-v2',
    '/exec-dashboard',
    '/projects',
    '/sale-record',
    '/sale-record/:recordId',
    '/sale-record/:recordId/customer/:customerId/edit',
    '/customers-list',
    '/customers-list/:customerId/basic-info',
    '/products-list',
    '/products-list/:productId/basic-info',
    '/lead',
    '/lead/add-lead',
    '/lead/:leadId',
    '/lead/:leadId/add-activities',
    '/lead/:leadId/booking',
    '/lead/:leadId/edit-info',
    '/reserve/search',
    '/reserve/success',
    '/reserve/add-customer',
    '/reserve/select-product/:customerId',
    '/survey-list',
    '/survey-list/:surveyId',
    '/warranty-assign',
    '/warranty-list',
    '/warranty-list/:productId/restricted',
    '/warranty-list/:productId',
    '/pdefect-list/:recordId/:inspectionId',
    '/pdefect-summary/:recordId/:inspectionId',
    '/pqc-summary/:productId/:inspectionId',
    '/pqc-list/:productId/:inspectionId',
    '/pwarranty-summary/:productId',
    '/select-project',
    '/release-notes',
    '/campaign',
    '/create-campaign',
    '/campaign/:campaignId',
    '/campaign/:campaignId/edit-campaign',
    '/pinspect-start/:productId',
    '/pinspect-summary/:productId',
    '/sale-mode/products-list',
    '/sale-mode/leads',
    '/sale-mode/add-lead',
    '/sale-mode/search-lead',
    '/sale-mode/leads/:leadId/add-activity',
    '/sale-mode/leads/:leadId/book',
    '/users'
]

const JURISTIC = [
    '/warranty-list/partial',
    '/warranty-list/:productId',
    '/select-project',
    '/release-notes',
    '/campaign',
    '/create-campaign',
    '/campaign/:campaignId',
    '/campaign/:campaignId/edit-campaign',
    '/pinspect-start/:productId',
    '/pinspect-summary/:productId',
    '/sale-mode/products-list',
    '/sale-mode/leads',
    '/sale-mode/add-lead',
    '/sale-mode/search-lead',
    '/sale-mode/leads/:leadId/add-activity',
    '/sale-mode/leads/:leadId/book',
]

const STAFF = [
    '/schedule',
    '/dashboard',
    '/dashboard-v2',
    '/exec-dashboard',
    '/projects',
    '/sale-record',
    '/sale-record/:recordId',
    '/sale-record/:recordId/customer/:customerId/edit',
    '/customers-list',
    '/customers-list/:customerId/basic-info',
    '/products-list',
    '/products-list/:productId/basic-info',
    '/lead',
    '/lead/add-lead',
    '/lead/:leadId',
    '/lead/:leadId/add-activities',
    '/lead/:leadId/booking',
    '/lead/:leadId/edit-info',
    '/reserve/search',
    '/reserve/success',
    '/reserve/add-customer',
    '/reserve/select-product/:customerId',
    '/warranty-assign',
    '/warranty-list/restricted',
    '/warranty-list/:productId/restricted',
    '/pdefect-list/:recordId/:inspectionId',
    '/pdefect-summary/:recordId/:inspectionId',
    '/pqc-summary/:productId/:inspectionId',
    '/pqc-list/:productId/:inspectionId',
    '/pwarranty-summary/:productId',
    '/select-project',
    '/release-notes',
    '/campaign',
    '/create-campaign',
    '/campaign/:campaignId',
    '/campaign/:campaignId/edit-campaign',
    '/pinspect-start/:productId',
    '/pinspect-summary/:productId',
    '/sale-mode/products-list',
    '/sale-mode/leads',
    '/sale-mode/add-lead',
    '/sale-mode/search-lead',
    '/sale-mode/leads/:leadId/add-activity',
    '/sale-mode/leads/:leadId/book',
]

const EXECUTIVE = [
    '/dashboard',
    '/dashboard-v2',
    '/exec-dashboard',
    '/select-project',
    '/release-notes',
    '/campaign',
    '/create-campaign',
    '/campaign/:campaignId',
    '/campaign/:campaignId/edit-campaign',
    '/pinspect-start/:productId',
    '/pinspect-summary/:productId',
    '/sale-mode/products-list',
    '/sale-mode/leads',
    '/sale-mode/add-lead',
    '/sale-mode/search-lead',
    '/sale-mode/leads/:leadId/add-activity',
    '/sale-mode/leads/:leadId/book',
]

export default { SUPERUSER, JURISTIC, STAFF, EXECUTIVE }
