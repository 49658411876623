import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import SelectInput from '../common/SelectInput'
import TextAreaInput from '../common/TextArea'
import TextInput from '../common/TextInput'
import FormNewLine from '../common/FormNewLine'
import datastore from '../../datastore'

export class CustomerInfoForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        firstName: '',
        lastName: '',
        gender: '',
        age: '',
        citizenId: '',
        tel: '',
        email: '',
        lineId: '',
        addressesJson: {
          home: '',
          work: ''
        },
        occupation: '',
        income: '',
        purpose: '',
        channelId: '',
        note: ''
      }
    }

    this.setInitialData(this.props.recordId);
  }

  componentWillMount() {
    this.setInitialData(this.props.recordId)
  }

  componentWillReceiveProps(nextProps) {
    this.setInitialData(nextProps.recordId)
  }

  setInitialData = recordId => {
    if (recordId) {
      datastore.customer.getCustomerForEdit(recordId)
      .then(info => {
        this.setState({
          data: info
        })
      })
    }
  }

  save = () => {
    return datastore.customer.updateCustomer(this.state.data, this.props.customerId, this.props.recordId)
  }

  render() {
    const { options } = this.props
    return (
      <div className="d-flex flex-column" style={{ flexGrow: 1 }}>
        <div className="row">
            <div className="col-sm-6">
                <TextInput
                    name="firstName"
                    placeholder="ชื่อ"
                    type="text"
                    target={ this }
                    stateKey="data"
                />
            </div>
            <div className="col-sm-6">
                <TextInput
                    name="lastName"
                    placeholder="นามสกุล"
                    type="text"
                    target={ this }
                    stateKey="data"
                />
            </div>

            <FormNewLine />
            <div className="col-sm-3">
                <selectWrap>
                    <SelectInput
                        name="gender"
                        placeholder="เพศ"
                        target={ this }
                        stateKey="data"
                        options={ options.genders }
                    />
                </selectWrap>
            </div>
            <div className="col-sm-3">
                <TextInput
                    name="age"
                    placeholder="อายุ"
                    type="number"
                    target={ this }
                    stateKey="data"
                />
            </div>
            <div className="col-sm-6">
                <TextInput
                    name="citizenId"
                    placeholder="เลขประจําตัวประชาชน"
                    type="number"
                    target={ this }
                    stateKey="data"
                />
            </div>

            <FormNewLine />
            <div className="col-sm-6">
                <TextInput
                    name="tel"
                    placeholder="เบอร์โทรศัพท์ (xxxxxxxxxx,xxxxxxxxxx)"
                    type="text"
                    target={ this }
                    stateKey="data"
                    dataFormatter={ value => value.replace(/-/g, '') }
                />
            </div>
            <div className="col-sm-6">
                <TextInput
                    name="email"
                    placeholder="E-mail"
                    type="email"
                    target={ this }
                    stateKey="data"
                />
            </div>

            <FormNewLine />
            <div className="col-sm-6">
                <TextInput
                    name="lineId"
                    placeholder="LINE ID"
                    type="text"
                    target={ this }
                    stateKey="data"
                />
            </div>

            <FormNewLine />
            <div className="col-sm-12">
                <TextAreaInput
                    name="home"
                    placeholder="ที่อยู่ปัจจุบัน"
                    rows={ 4 }
                    target={ this }
                    stateKey="data"
                    nestedKey="addressesJson"
                />
            </div>

            <FormNewLine />
            <div className="col-sm-12">
                <TextAreaInput
                    name="work"
                    placeholder="ที่อยู่ที่ทำงาน"
                    rows={ 4 }
                    target={ this }
                    stateKey="data"
                    nestedKey="addressesJson"
                />
            </div>

            <FormNewLine />
            <div className="col-sm-6">
                <selectWrap>
                    <SelectInput
                        name="occupation"
                        placeholder="เลือกอาชีพ"
                        target={ this }
                        stateKey="data"
                        options={ options.occupations }
                    />
                </selectWrap>
            </div>
            <div className="col-sm-6">
                <selectWrap>
                    <SelectInput
                        name="income"
                        placeholder="รายได้ / เดือน(บาท)"
                        target={ this }
                        stateKey="data"
                        options={ options.salary_ranges }
                    />
                </selectWrap>
            </div>

            <FormNewLine />
            <div className="col-sm-6">
                <TextInput
                    name="purpose"
                    placeholder="วัตถุประสงค์"
                    type="text"
                    target={ this }
                    stateKey="data"
                />
            </div>
            <div className="col-sm-6">
                <selectWrap>
                    <SelectInput
                        name="channelId"
                        placeholder="ช่องทางการตลาด"
                        target={ this }
                        stateKey="data"
                        options={ options.channels }
                    />
                </selectWrap>
            </div>
        </div>
    </div>
    )
  }
}

CustomerInfoForm.propTypes = {
  options: PropTypes.object,
  refreshFunc: PropTypes.func,
  customerId: PropTypes.string,
  recordId: PropTypes.string,
  noHeaderAndFooter: PropTypes.bool,
}

function mapStateToProps(state) {
  return {
      options: state.other.options
  }
}

CustomerInfoForm = connect(mapStateToProps, null, null, { withRef: true })(CustomerInfoForm)