import React, { Component } from 'react'
import { Link } from 'react-router'

import Wrapper from '../layouts/Wrapper'
import datastore from '../datastore'
import DefectInfoView from './defectInfo'
import BasicInfoView from './basicInfo'
import LoanInfoView from './loanInfo'
import TransferInfoView from './transferInfo'
import BookInfoView from './bookInfo';
import ChangeSaleStatusModal from '../components/Modal/ChangeSaleStatusModal';

export default class SaleRecordInfo extends Component {
    state = {
        record: null,
        activeViewIndex: 0,
    }

    componentWillMount() {
        this.updateRecord()
    }

    changeView = index => {
        this.setState({
            activeViewIndex: index
        })
    }

    changeSaleRecordStatus = () => {
        this.refs.changeStatus.openModal();
    }

    editCustomer = () => {
        this.props.router.push(`/sale-record/${this.props.params.recordId}/customer/${this.state.record.customerId}/edit`)
    }

    updateRecord = () => {
        const recordId = this.props.params.recordId
        datastore.sale.getSaleRecord(recordId)
            .then(record => {
                this.props.setHeader(
                    `${record.customerName}, ${record.skuCode}`,
                    [this.editCustomer, this.renderChangeStatusButton(record.isTransferred, record.saleRecordStatus)],
                    ['แก้ไขข้อมูลผู้ทำสัญญา', this.renderButtonName(record.isTransferred, record.saleRecordStatus)]
                )
                this.setState({
                    record
                })
            })
    }

    renderChangeStatusButton(transferStatus, saleRecordStatus){
        if(transferStatus === true || saleRecordStatus === -1){
            return null
        } else {
            return this.changeSaleRecordStatus
        }
    }

    renderButtonName(transferStatus, saleRecordStatus) {
        if(transferStatus === true || saleRecordStatus === -1){
            return null
        } else {
            return 'เปลี่ยนสถานะการขาย'
        }
    }

    render() {
        return <div>
            <TabBar activeViewIndex={this.state.activeViewIndex} changeView={this.changeView} />
            <Wrapper>
                <ActiveView 
                    index={this.state.activeViewIndex} 
                    record={this.state.record} 
                    updateRecord={this.updateRecord}
                    router={this.props.router}
                />
            </Wrapper>
            <ChangeSaleStatusModal 
                ref="changeStatus"
                record={this.state.record}
            />
        </div>
    }
}

const TabBar = ({ activeViewIndex, changeView }) => {
    const columnClassName = "text-center text-none py-1 mr-02 w-15"
    return (
        <div className="d-flex flex-row w-100 pl-1" style={{ height: '', boxShadow: "2px 2px 4px 0 rgba(0,0,0,0.25)" }}>
            <Link className={`${columnClassName} ${isActive(0, activeViewIndex)}`} onClick={() => changeView(0)}>ข้อมูลทั่วไป</Link>
            <Link className={`${columnClassName} ${isActive(1, activeViewIndex)}`} onClick={() => changeView(1)}>เอกสารที่เกี่ยวข้อง</Link>
            <Link className={`${columnClassName} ${isActive(2, activeViewIndex)}`} onClick={() => changeView(2)}>เอกสารการขอกู้</Link>
            <Link className={`${columnClassName} ${isActive(3, activeViewIndex)}`} onClick={() => changeView(3)}>การตรวจรับ</Link>
            {/* <Link className={`${columnClassName} ${isActive(4, activeViewIndex)}`} onClick={() => changeView(4)}>การโอนกรรมสิทธิ์</Link> */}
        </div>
    )
}

const ActiveView = ({ index, record, updateRecord, router }) => {
    return index === 0 ? <BasicInfoView record={record} router={router}/>
        : index === 1 ? <BookInfoView record={record}/>
        : index === 2 ? <LoanInfoView record={record} />
        : index === 3 ? <DefectInfoView record={record} updateRecord={updateRecord} />
        : index === 4 ? <TransferInfoView record={record} updateRecord={updateRecord} />
        : null 
}

const isActive = (index, activeIndex) => {
    return index === activeIndex
        ? 'tab-active'
        : 'tab-inactive'
}
