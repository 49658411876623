export const Styles = {
    Bold: {
        fontWeight: 'bold',
    },
    Label: {
        whiteSpace: 'no-wrap',
    },
    Small: {
        fontSize: '0.875rem',
    },
    Smaller: {
        fontSize: '0.725rem',
    },
    Smallest: {
        fontSize: '0.5rem',
    }
}
export const Combind = styles => styles.reduce((prev, curr) => {
    prev =  {...prev, ...curr}
    return prev
}, {})