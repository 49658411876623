import React, { PureComponent } from 'react';
import { inModal } from '../../../hoc/inModal';
import ModalTitle from '../../../components/common/ModalTitle';
import TextWithLabel from '../../../components/common/TextWithLabel';
import FormNewLine from '../../../components/common/FormNewLine';
import TextInput from '../../../components/common/TextInput';
import datastore from '../../../datastore';

export class DeclineActivityForm extends PureComponent {
  constructor(props){
    super(props)

    this.state = {
      data: {
        declineDetail: ''
      },
      record: this.props.activity ? this.props.activity : null
    }
  }

  decline = () => {
    const activityId = this.state.record.id
    const decline = datastore.activity.declineActivity(activityId, this.state.data)
      .then(result => {
        this.props.closeModal()
        this.props.onUpdateActivity(0, 10)
      })
    return decline
  }

  render() {
    return (
      <div className="activity-modal">
        <ModalTitle title='ขอข้อมูลเพิ่มเติม' onClick={() => this.props.closeModal()} />
        <div className="row no-gutters justify-content-between mb-2" style={{maxWidth: "500px"}}>
          <TextWithLabel label="ลูกค้า">{this.state.record.customerName}</TextWithLabel>
          <TextWithLabel label="สินค้า">{this.state.record.product}</TextWithLabel>
          <TextWithLabel label="กิจกรรม">{this.state.record.type}</TextWithLabel>
        </div>
        <FormNewLine />
        <div className="row no-gutters justify-content-between">
          <TextInput
            name="declineDetail"
            placeholder="รายละเอียดข้อมูลที่ขอเพิ่มเติม"
            target={ this }
            stateKey="data"
          />
        </div>
        <div style={{paddingTop: "24px"}} className="d-flex flex-row justify-content-center">
          <button 
            style={{padding: 0}}
            onClick={ () => {
              this.props.onCancelDecline()
            }}
            className="m-05 btn btn-save-cancel btn-outline"
          >
            ยกเลิก
          </button>
          <button 
            style={{padding: 0}}
            onClick={ () => {
              this.decline()
            }} 
            className="m-05 btn btn-save-cancel btn-outline"
          >
            บันทึก
          </button>
        </div>
      </div>
    )
  }
}

const DeclineActivityModal = inModal(DeclineActivityForm)
export default DeclineActivityModal