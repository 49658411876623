import React, { Component } from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'

import Wrapper from '../../layouts/Wrapper'
import Loading from '../../components/common/Loading'
import datastore from '../../datastore'
import ConfirmModal from '../../components/ConfirmModal'

export default class TransferInfoView extends Component {
    state = {
        trasferredDate: null,
    }

    componentWillMount() {
        if (this.props.record && this.props.record.transferDate) {
            this.setState({
                transferredDate: this.props.record.transferDate
            })
        }
    }


    handleDateChange = transferredDate => {
        this.setState({
            transferredDate
        }) 
    }

    formatTransferredDate = () => {
        return moment(this.state.transferredDate).format('YYYY-MM-DD')
    }

    confirmTransfer = () => {
        const formattedDate = this.state.transferredDate.format("YYYY-MM-DD HH:mm:ss") 
        datastore.sale.updateTransferInfo(this.props.record.id, formattedDate)
            .then(res => {
                return this.props.updateRecord()
            })
    }

    render() {
        const { record } = this.props
        if (!record) {
            return <Loading />
        }

        return (
            <Wrapper>
                {record.isTransferred ? 
                    <div>
                        <h3>โอนกรรมสิทธิ์แล้ว</h3> 
                        <h4>วันที่ {this.formatTransferredDate()}</h4>
                    </div> 
                    : 
                    <div className="d-flex flex-column align-items-center mb-15">
                        <h3 className="text-center mb-2">เลือกวันที่โอนกรรมสิทธิ์</h3>
                        <DatePicker
                            inline
                            selected={this.state.transferredDate}
                            onChange={this.handleDateChange}
                            maxDate={ moment() }
                        />
                        <button 
                            className="btn btn-main my-2" 
                            style={{ width: "10rem" }}
                            onClick={() => this.refs.confirmModal.openModal()} 
                        >
                            โอนกรรมสิทธิ์
                        </button>
                    </div>
                }
                <ConfirmModal
                    ref="confirmModal"
                    title="ยืนยันการโอนกรรมสิทธิ์"
                    onConfirm={() => this.confirmTransfer()}
                >
                    <div>ยืนยันการโอนกรรมสิทธิ์วันที่ <span style={{ fontWeight: "bold", fontSize: "1.25rem" }}>{this.formatTransferredDate()}</span></div>
                </ConfirmModal>
            </Wrapper>
        )
    }
}