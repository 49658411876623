import React from 'react';
import PropTypes from 'prop-types';

import Card from '../../../components/common/Card';
import CardTitle from '../../../components/common/CardTitle';

const BuildingSelector = ({ active, buildingName, onClick }) => {
  return (
    <div
      className={`${active ? 'white blue-bg blue-border' : 'grey grey-border'} p-05 mb-02 px-1`}
      style={{ cursor: 'pointer', border: '1px solid' }}
      onClick={onClick}
    >
      {buildingName}
    </div>
  );
};

const FloorSelector = ({ active, floorName, onClick }) => {
  return (
    <div
      className={`${active ? 'white blue-bg blue-border' : 'grey grey-border'} p-05 mb-02 px-1`}
      style={{ cursor: 'pointer', border: '1px solid' }}
      onClick={onClick}
    >
      {floorName}
    </div>
  );
};

const FilterBar = ({
  selectedBuilding,
  selectedFloor,
  onBuildingClick,
  onFloorClick,
  buildings,
}) => {
  return (
    <div className="mr-3 w-100 product-filter-bar" style={{ flexGrow: 1 }}>
      <Card>
        <CardTitle title="เลือก อาคาร/ชั้น" />

        {buildings.map(b => {
          const { buildingName, buildingId, floors } = b;
          const buildingActive = selectedBuilding && selectedBuilding.buildingId === buildingId;
          return (
            <div key={buildingId} className="d-flex mb-15">
              <BuildingSelector
                buildingName={buildingName}
                active={buildingActive}
                onClick={() => onBuildingClick(b)}
              />
              {buildingActive && (
                <div className="ml-1">
                  {floors.map(f => {
                    const { floorName, floorId } = f;
                    const floorActive = selectedFloor && selectedFloor.floorId === floorId;
                    return (
                      <FloorSelector
                        key={floorId}
                        floorName={floorName}
                        active={floorActive}
                        onClick={() => onFloorClick(f)}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </Card>
    </div>
  );
};

FilterBar.propTypes = {
  selectedBuilding: PropTypes.object,
  selectedFloor: PropTypes.object,
  onBuildingClick: PropTypes.func,
  onFloorClick: PropTypes.func,
  buildings: PropTypes.array,
};

FilterBar.defaultProps = {
  buildings: [],
};

export default FilterBar;
