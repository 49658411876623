import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

import Table from '../../components/common/tables/Table'
import Calendar from '../../components/common/Calendar'
import TextWithLabel from '../../components/common/TextWithLabel'
import SelectInput from '../../components/common/SelectInput'
import { inModal } from '../../hoc/inModal'
import CheckboxInput from '../../components/common/CheckboxInput'
import TextAreaInput from '../../components/common/TextAreaInput'
import TextInput from '../../components/common/TextInput'
import datastore from '../../datastore'
import ModalTitle from '../../components/common/ModalTitle'
import ModalFooter from '../../components/common/ModalFooter'
import FormNewLine from '../../components/common/FormNewLine'
import moment from 'moment'

const MOCK_SKU_CODE = [
    '151/4',
    '201/2'
]

const MOCK_TECHNICIAN_NAME = [
    'ช่างทัก',
    'ช่างเก่า',
]

const MOCK_TIME = [
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00'
]

const MOCK_TYPE = [
    'งานปกติ',
    'งานเร่งด่วน'
]

const toOptions = mock => {
    return mock.map(m => {
        return { value: m, label: m }
    })
}

class AssignForm extends Component {
    state = {
        defects: null,
        defectsByProduct: null,
        technicians: null
    }

    assign = () => {
        this.props.closeModal()
        this.props.assign()
    }

    cancel = () => {
        this.props.closeModal()
    }

    componentWillMount = () => {
        const { profile, assignedDefects } = this.props
        const projectId = profile.current_project.id.toString()
        const isReassign = new URL(window.location.href).searchParams.get('reassign')
        const productId = new URL(window.location.href).searchParams.get('product_id')
        if (isReassign !== 'true') {
            datastore.warranty.getDefectsToAssign(productId)
            .then(res => {
                const defectsByProduct = res.items.reduce((acc, item) => {
                    if (item.id in assignedDefects) {
                        return acc
                    }

                    const skuCode = item.sku_code
                    if (!(skuCode in acc)) {
                        acc[skuCode] = []
                    }

                    acc[skuCode].push(item)
                    return acc
                }, {})
                this.setState({ defectsByProduct, defects: res.items })
            })
        } else {
            datastore.warranty.getDefectsToReassign(productId)
            .then(res => {
                const defectsByProduct = res.items.reduce((acc, item) => {
                    if (item.id in assignedDefects) {
                        return acc
                    }

                    const skuCode = item.sku_code
                    if (!(skuCode in acc)) {
                        acc[skuCode] = []
                    }

                    acc[skuCode].push(item)
                    return acc
                }, {})
                this.setState({ defectsByProduct, defects: res.items })
            })
        }
        datastore.warranty.getTechnicians(projectId)
            .then(res => {
                const technicians = res.technicians.reduce((acc, t) => {
                    acc[t.full_name] = t
                    return acc
                }, {})
                this.setState({ technicians })
            })
    }

    render() {
        const { closeModal, date, parent, profile } = this.props
        const { defects, defectsByProduct, technicians } = this.state

        if (!defects || !defectsByProduct || !technicians) {
            return null
        }

        const customStyles = {
            control: () => ({
                border: 'none',
                display: 'inline',
                cursor: 'text',
                width: '100%'
            }),
            indicatorSeparator: () => ({
                display: 'none',
            }),
            dropdownIndicator: () => ({
                display: 'none',
            }),
        }

        const valid = parent.state.data.selectedDefects 
            && parent.state.data.technician 
            && parent.state.data.time
            //&& parent.state.data.type
        
        return (
            <div style={{ padding: '25px' }}>
            <ModalTitle title={`มอบหมายงาน ${date.format('DD/MM/YY')}`} onClick={() => closeModal()} />
                <div className="d-flex flex-column" style={{ flexGrow: 1 }}>
                <div className="row">
                    <div className="col-6">
                        <h5>{defects.length > 0 ? 'ข้อบกพร่อง' : 'ไม่มีข้อบกพร่อง'}</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        {
                            this.renderAssignModal(parent, defects)
                        }
                    </div>
                    <FormNewLine/>
                    {/*<div className="col-6">
                        <selectWrap>
                            <SelectInput
                                name="defects"
                                placeholder="หมายเลขห้อง"
                                target={parent}
                                stateKey="data"
                                options={Object.entries(defectsByProduct).map(([skuCode, defects]) => { 
                                    return { value: defects, label: `${skuCode} (${defects.length})` }
                                })}
                            />
                        </selectWrap>
                    </div>
                    <FormNewLine />*/}
                    <div className="col-6">
                        <selectWrap>
                            <SelectInput
                                name="time"
                                placeholder="เวลา"
                                target={parent}
                                stateKey="data"
                                options={toOptions(MOCK_TIME)}
                            />
                        </selectWrap>
                    </div>
                    <div className="col-6">
                        <selectWrap>
                            <SelectInput
                                name="technician"
                                placeholder="ช่าง"
                                target={parent}
                                stateKey="data"
                                options={Object.entries(technicians).map(([name, t]) => { 
                                    return { value: t, label: name }
                                })}
                            />
                        </selectWrap>
                    </div>
                    {/*
                    <div className="col-6">
                        <selectWrap>
                            <SelectInput
                                name="type"
                                placeholder="สถานะงาน"
                                target={parent}
                                stateKey="data"
                                options={toOptions(MOCK_TYPE)}
                            />
                        </selectWrap>
                    </div>
                    <FormNewLine/>
                    <div className="col-12">
                        <CheckboxInput 
                            name="sendEmail" 
                            label="ส่งรายละเอียดทางอีเมล์"
                            target={parent} 
                            stateKey="data"
                        />
                    </div>
                    <FormNewLine/>
                    <div className="col-6">
                        <TextInput
                            name="email"
                            placeholder="อีเมล์"
                            type="text"
                            target={parent}
                            stateKey="data"
                        />
                    </div>
                    */}
                </div>
                <div className="row d-flex justify-content-around mt-3">
                    <div>
                    <button 
                        onClick={() => this.cancel()} 
                        className="btn btn-main"
                        style={{ marginRight: '10px' }}
                    >ยกเลิก</button>
                    <button 
                        disabled={!valid}
                        onClick={() => this.assign()} 
                        className="btn btn-main"
                    >บันทึก</button>
                    </div>
                </div>
                </div>
            </div>
        )
    }

    renderAssignModal(parent, defects) {
        const paramId = new URL(window.location.href).searchParams.get('id')
        const paramSkuCode = new URL(window.location.href).searchParams.get('sku_code')
        const isReassign = new URL(window.location.href).searchParams.get('reassign')
        if( paramId ) {
            defects = defects.filter(d => d.id.toString() == paramId)
        }
        if (paramSkuCode && !isReassign) {
            defects = defects.filter(d => d.sku_code === paramSkuCode)
        }

        return defects.map(d => {
            return <CheckboxInput 
                key={d.id.toString()}
                name={JSON.stringify(d)}
                label={`${d.sku_code ? d.sku_code : paramSkuCode} - ${d.description} ${isReassign ? `- ${moment(d.tentative_date).format('DD/MM/YYYY (HH:mm)')}` : ''}`}
                target={parent} 
                stateKey="data"
                nestedKey="selectedDefects"
            />
        })
    }
}

const AssignModal = inModal(AssignForm)
export default AssignModal
