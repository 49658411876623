import React, { PureComponent } from 'react';
import { inModal } from '../../../hoc/inModal';
import ModalTitle from '../../../components/common/ModalTitle';
import TextWithLabel from '../../../components/common/TextWithLabel';
import FormNewLine from '../../../components/common/FormNewLine';
import TextInput from '../../../components/common/TextInput';
import SelectInput from '../../../components/common/SelectInput';
import { Formatter } from '../../../utilities/Formatter';
import datastore from '../../../datastore';
import { connect } from 'react-redux';

export class EditActivityDetailForm extends PureComponent {
  constructor(props){
    super(props)

    this.state = {
      data: {
        concern: null,
        remark: '',
        isSuccess: null
      },
      record: null
    }

    this.activityStatus = [
      {
        label: 'เสร็จสิ้น',
        value: '1'
      },
      {
        label: 'ยกเลิกการดำเนินการ',
        value: '0'
      }
    ]
  }

  componentWillMount() {
    const data = this.state.data
    if (this.props.activity) {
      data.declineDetail = this.props.activity.activity
      this.setState({
        data,
        record: this.props.activity
      })
    }
  }

  updateActivity() {
    const activityId = this.state.record.id
    if (!this.state.data.concern && this.state.data.isSuccess === '1') {
      alert('กรุณาเลือกเหตุที่ยังไม่ตัดสินใจ')
    } else {
      const callUpdate = datastore.activity.updateActivity(activityId, this.state.data)
      return callUpdate.then(result => {
        this.props.closeModal()
        this.props.onUpdateActivity(0, 10)
      })
    }
  }

  render() {
    const { options } = this.props;
    return (
      <div className="activity-modal">
        <ModalTitle title='รายละเอียดกิจกรรม' onClick={() => this.props.closeModal()} />
        <div className="row no-gutters justify-content-between mb-2" style={{maxWidth: "475px"}}>
          <selectWrap style={{width: '35%'}}>
            <SelectInput 
              name="isSuccess"
              placeholder="สถานะกิจกรรม"
              target={ this }
              stateKey="data"
              options={ this.activityStatus }
            />
          </selectWrap>
          <TextWithLabel label="วันที่ดำเนินกิจกรรม">{Formatter.formatDateTh(Formatter.createDate(this.state.record.targetDate))}</TextWithLabel>
          <div></div>
        </div>
        <FormNewLine />
        <div className="row no-gutters justify-content-between mb-2" style={{maxWidth: "500px"}}>
          <TextWithLabel label="ลูกค้า">{this.state.record.customerName}</TextWithLabel>
          <TextWithLabel label="สินค้า">{this.state.record.product}</TextWithLabel>
          <TextWithLabel label="กิจกรรม">{this.state.record.type}</TextWithLabel>
        </div>
        <FormNewLine />
        <div className="row no-gutters justify-content-between" style={{maxWidth: "500px"}}>
          <selectWrap style={{width: '100%'}}>
            <SelectInput 
              name="concern"
              placeholder="เหตุที่ยังไม่ตัดสินใจ"
              target={ this }
              stateKey="data"
              options={ options.concerns }
            />
          </selectWrap>
        </div>
        <FormNewLine />
        <div className="row no-gutters justify-content-between">
          <TextInput
            name="remark"
            placeholder="โน้ตเพิ่มเติม"
            target={ this }
            stateKey="data"
          />
        </div>
        <div style={{paddingTop: "24px"}} className="d-flex flex-row justify-content-center">
          <button 
            style={{padding: 0}}
            onClick={ () => {
              this.props.onCancelUpdate()
            }}
            className="m-05 btn btn-save-cancel btn-outline"
          >
            ยกเลิกการแก้ไข
          </button>
          <button 
            style={{padding: 0}}
            onClick={ () => {
              this.updateActivity()
            }} 
            className="m-05 btn btn-save-cancel btn-outline"
          >
            บันทึก
          </button>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
      options: state.other.options,
  }
}

EditActivityDetailForm = connect(mapStateToProps, null, null, { withRef: true })(EditActivityDetailForm)

const EditActivityDetailModal = inModal(EditActivityDetailForm)
export default EditActivityDetailModal;