import React, { PureComponent } from 'react';
import apis from '../../apis';
import { Formatter } from '../../utilities/Formatter';
import moment from 'moment';
import PrintableWarrantySummary from '../printableWarrantySummary';
import { store } from '../..';
import { parseAddressAndLogo } from '../../common/util';
import './style/PrintableInspectSummary.css';

export class PrintableInspectSummary extends PureComponent {
  constructor(props){
    super(props)

    this.state = {
      customerName: '',
      customerPhoneNumber: '',
      isCondo: false,
      productAddress: '',
      totalDefect: '',
      projectName: '',
      projectLogo: '',
      warrantyDefects: [],
      warrantyEndDate: null,
      techSignature: null,
      witnessSignature: null,
      customerSignature: null,
      location: null,
      skuCode: ''
    }

  }

  componentWillMount() {
    const url = new URL(window.location.href)
    const targetDate = url.searchParams.get('target_date')
    const skuCode = url.searchParams.get('sku_code')
    const profile = store.getState().auth.profile
    const { address } = parseAddressAndLogo(profile)

    apis.warranty.getInspectionSummary(this.props.params.productId, targetDate)
      .then(result => {
        this.setState({
          customerName: result.customer_name,
          customerPhoneNumber: result.customer_phone_number,
          isCondo: result.is_condo_template,
          productAddress: result.product_address,
          projectName: result.project_name,
          projectLogo: result.project_logo,
          totalDefect: result.total_defect,
          warrantyDefects: result.warranty_defects,
          warrantyEndDate: moment(result.warranty_end_date, 'YYYY-MM-DD HH:mm:ss+Z'),
          customerSignature: result.signature ? result.signature.customer_signature_url : null,
          witnessSignature: result.signature ? result.signature.witness_signature_url : null,
          techSignature: result.signature ? result.signature.technician_signature_url : null,
          location: address.replace('aa',''),
          skuCode: skuCode
        })
      })
  }

  renderCondoTemplate() {
    const newLineIndex = this.state.location.indexOf('เขต')
    const addressLine1 = this.state.location.slice(0, newLineIndex)
    const addressLine2 = this.state.location.slice(newLineIndex)
    const createDocDate = moment()
    const day = createDocDate.date()
    const month = Formatter.formatMonthNumberToTh(createDocDate.month() + 1)
    const year = createDocDate.year() + 543
    if (this.state.warrantyDefects.length <= 1) {
      return (
        <div>
          <div className="a4">
            <div className="print-container">
                <div className="logo-session">
                  <p style={{fontSize: '14px', fontWeight: 'bold'}}>
                    เอกสารตรวจรับมอบงานแก้ไขข้อบกพร่องในระยะเวลารับประกัน โครงการ แฮปปี้ คอนโด ดอนเมือง เดอะ เทอมินอล
                  </p>
                  <div style={{textAlign: 'right'}}>
                    <p style={{fontSize: '14px'}}>ทำที่ โครงการ แฮปปี้ คอนโด ดอนเมือง เดอะ เทอมินอล</p>
                    <p style={{fontSize: '14px'}}>วันที่..{day}..เดือน..{month}..พ.ศ..{year}..</p>
                  </div>
                </div>
  
                &nbsp;&nbsp; ข้าพเจ้า &nbsp; {this.state.customerName} &nbsp; เบอร์โทรศัพท์ที่ติดต่อได้ &nbsp; {this.state.customerPhoneNumber} &nbsp; 
                ผู้ถือกรรมสิทธิ์ / ผู้พักอาศัย ในห้องชุด โครงการ แฮปปี้ คอนโด ดอนเมือง เดอะ เทอมินอล บ้านเลขที่ &nbsp; {this.state.productAddress ? this.state.productAddress : this.state.skuCode} &nbsp;
                ได้ทำการตรวจสอบงานแก้ไขข้อบกพร่อง โดยมีรายละเอียด ดังนี้ 
  
                <table className="defect-list">
                    <thead>
                      <th className="header-table"></th>
                      <th className="header-table">รายการแจ้งซ่อม</th>
                      <th className="header-table">วันที่นัดหมายซ่อมแซม</th>
                      <th className="header-table">สถานะ</th>
                    </thead>
                    <tbody>
                      {
                        this.state.warrantyDefects ? 
                        this.state.warrantyDefects.map((defect, index) => {
                          return <tr>
                          <td className="item order" width="10%">{index+1}</td>
                          <td className="item" width="30%">{defect.description}</td>
                          <td className="item" width="30%">
                              {
                                defect.tentative_date ? 
                                Formatter.formatDateWithFullMonthTh(Formatter.createDate(defect.tentative_date))
                                : 'ยังไม่มีกำหนด'
                              }
                          </td>
                          <td className="item" width="30%">
                              {defect.is_fixed ? 'แก้ไขแล้ว' : 'รอการซ่อมแซม'}
                          </td>
                        </tr>
                        })
                        : null
                      }
                    </tbody>
                </table>
  
                &nbsp;&nbsp;&nbsp;&nbsp; โดยข้าพเจ้าได้ทำการตรวจสอบรายการแก้ไขบกพร่องข้างต้นแล้ว มีความเรียบร้อยสมบูรณ์ดี สามารถเข้าครอบครองใช้ 
                ประโยชน์ในทรัพย์สิน และพักอาศัยได้ตามปกติ และข้าพเจ้ารับทราบว่า ระยะเวลารับประกันตามสัญญาจะซื้อจะขาย 
                และการ รับประกัน ส่วนควบ 1 ปี ในห้องชุดของข้าพเจ้า จะสิ้นสุดลงในวันที่ &nbsp; { this.state.warrantyEndDate ? Formatter.formatDateWithFullMonthTh(this.state.warrantyEndDate) : ''} &nbsp;

                <div>
                  &nbsp;&nbsp;&nbsp;&nbsp;จึงลงลายมือชื่อไว้ต่อหน้าพยาน
                </div>
  
                <div className="signature-session">
                  <div className="signature">
                    {
                      !this.state.customerSignature ?
                      <p>ลงชื่อ..........................................ผู้จะซื้อ</p> :
                      <p>ลงชื่อ<img className="image" src={this.state.customerSignature} alt="" />ผู้จะซื้อ</p>
                    }
                    {/* <p>ลงชื่อ..........................................ผู้จะซื้อ</p> */}
                      <p style={{marginRight: '28px'}}>
                        (............................................)
                      </p>
                  </div>
                  <div className="signature">
                    {
                      !this.state.techSignature ? 
                      <p>ลงชื่อ..........................................ผู้จะขาย</p> :
                      <p>ลงชื่อ<img className="image" src={this.state.techSignature} alt="" />ผู้จะขาย</p>
                    }
                    {/* <p>ลงชื่อ..........................................ผู้จะขาย</p> */}
                    <p style={{marginRight: '28px'}}>
                        (............................................)
                    </p>
                  </div>
                  <div className="signature">
                    {
                      !this.state.witnessSignature ?
                      <p>ลงชื่อ..........................................พยาน</p> :
                      <p>ลงชื่อ<img className="image" src={this.state.witnessSignature} alt="" />พยาน</p>
                    }
                    {/* <p>ลงชื่อ..........................................พยาน</p> */}
                    <p style={{marginRight: '28px'}}>
                        (............................................)
                    </p>
                  </div>
                </div>
            </div>
          </div>
          <div>
            <PrintableWarrantySummary 
              params={this.props.params}
              firstPageNumber={2}
            />
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div className="a4" style={{height: "35cm"}}>
            <div className={`print-section ${this.state.warrantyDefects.length > 37 ? 'height-100' : ''}`}>
                <div className="logo-session">
                  <p style={{fontSize: '14px', fontWeight: 'bold'}}>
                    เอกสารตรวจรับมอบงานแก้ไขข้อบกพร่องในระยะเวลารับประกัน โครงการ แฮปปี้ คอนโด ดอนเมือง เดอะ เทอมินอล
                  </p>
                  <div style={{textAlign: 'right'}}>
                    <p style={{fontSize: '14px'}}>ทำที่ โครงการ แฮปปี้ คอนโด ดอนเมือง เดอะ เทอมินอล</p>
                    <p style={{fontSize: '14px'}}>วันที่..{day}..เดือน..{month}..พ.ศ..{year}..</p>
                  </div>
                </div>
  
                &nbsp;&nbsp; ข้าพเจ้า &nbsp; {this.state.customerName} &nbsp; เบอร์โทรศัพท์ที่ติดต่อได้ &nbsp; {this.state.customerPhoneNumber} &nbsp; 
                ผู้ถือกรรมสิทธิ์ / ผู้พักอาศัย ในห้องชุด โครงการ แฮปปี้ คอนโด ดอนเมือง เดอะ เทอมินอล บ้านเลขที่ &nbsp; {this.state.productAddress ? this.state.productAddress : this.state.skuCode} &nbsp;
                ได้ทำการตรวจสอบงานแก้ไขข้อบกพร่อง โดยมีรายละเอียด ดังนี้ 
  
                <table className="defect-list">
                    <thead>
                      <th className="header-table"></th>
                      <th className="header-table">รายการแจ้งซ่อม</th>
                      <th className="header-table">วันที่นัดหมายซ่อมแซม</th>
                      <th className="header-table">สถานะ</th>
                    </thead>
                    <tbody>
                      {
                        this.state.warrantyDefects ? 
                        this.state.warrantyDefects.map((defect, index) => {
                          return <tr>
                          <td className="item order" width="10%">{index+1}</td>
                          <td className="item" width="30%">{defect.description}</td>
                          <td className="item" width="30%">
                            {
                              defect.tentative_date ? 
                              Formatter.formatDateWithFullMonthTh(Formatter.createDate(defect.tentative_date))
                              : 'ยังไม่มีกำหนด'
                            }
                          </td>
                          <td className="item" width="30%">
                              {defect.is_fixed ? 'แก้ไขแล้ว' : 'รอการซ่อมแซม'}
                          </td>
                        </tr>
                        })
                        : null
                      }
                    </tbody>
                </table>
              </div>
          </div>
          {
            this.state.warrantyDefects.length > 34 ? 
            <div className="a4" style={{height: "35cm"}}>&nbsp;</div> : 
            null
          }
          {
            this.state.warrantyDefects.length > 84 ? 
            <div className="a4" style={{height: "35cm"}}>&nbsp;</div> : 
            null
          }
          {

            this.state.warrantyDefects.length > 130 ? 
            <div className="a4" style={{height: "35cm"}}>&nbsp;</div> : 
            null
          }
          {
            this.state.warrantyDefects.length > 176 ? 
            <div className="a4" style={{height: "35cm"}}>&nbsp;</div> : 
            null
          }
          {
            this.state.warrantyDefects.length > 222 ? 
            <div className="a4" style={{height: "35cm"}}>&nbsp;</div> : 
            null
          }
          <div className="a4" style={{height: '35cm'}}>
            <div className="print-section height-100">
              &nbsp;&nbsp;&nbsp;&nbsp; โดยข้าพเจ้าได้ทำการตรวจสอบรายการแก้ไขบกพร่องข้างต้นแล้ว มีความเรียบร้อยสมบูรณ์ดี สามารถเข้าครอบครองใช้ 
              ประโยชน์ในทรัพย์สิน และพักอาศัยได้ตามปกติ และข้าพเจ้ารับทราบว่า ระยะเวลารับประกันตามสัญญาจะซื้อจะขาย 
              และการ รับประกัน ส่วนควบ 1 ปี ในห้องชุดของข้าพเจ้า จะสิ้นสุดลงในวันที่ &nbsp; { this.state.warrantyEndDate ? Formatter.formatDateWithFullMonthTh(this.state.warrantyEndDate) : ''} &nbsp;
              <br/>
              <br/>
              &nbsp;&nbsp;&nbsp;&nbsp;จึงลงลายมือชื่อไว้ต่อหน้าพยาน

              <div className="signature-session">
                <div className="signature">
                  {
                    !this.state.customerSignature ?
                    <p>ลงชื่อ..........................................ผู้จะซื้อ</p> :
                    <p>ลงชื่อ<img className="image" src={this.state.customerSignature} alt="" />ผู้จะซื้อ</p>
                  }
                  {/* <p>ลงชื่อ..........................................ผู้จะซื้อ</p> */}
                    <p style={{marginRight: '28px'}}>
                      (............................................)
                    </p>
                </div>
                <div className="signature">
                  {
                    !this.state.techSignature ? 
                    <p>ลงชื่อ..........................................ผู้จะขาย</p> :
                    <p>ลงชื่อ<img className="image" src={this.state.techSignature} alt="" />ผู้จะขาย</p>
                  }
                  {/* <p>ลงชื่อ..........................................ผู้จะขาย</p> */}
                  <p style={{marginRight: '28px'}}>
                      (............................................)
                  </p>
                </div>
                <div className="signature">
                  {
                    !this.state.witnessSignature ?
                    <p>ลงชื่อ..........................................พยาน</p> :
                    <p>ลงชื่อ<img className="image" src={this.state.witnessSignature} alt="" />พยาน</p>
                  }
                  {/* <p>ลงชื่อ..........................................พยาน</p> */}
                  <p style={{marginRight: '28px'}}>
                      (............................................)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <PrintableWarrantySummary 
              params={this.props.params}
              firstPageNumber={
                this.state.warrantyDefects.length < 34 ? 3 :
                this.state.warrantyDefects.length < 84 ? 4 :
                this.state.warrantyDefects.length < 130 ? 5 :
                this.state.warrantyDefects.length < 176 ? 6 :
                this.state.warrantyDefects.length <= 222 ? 7 : 6
              }
            />
          </div>
        </div>
      )
    }
    
  }

  renderHouseTemplate() {
    const location = 'สำนักงานขายโครงการ แฮปปี้ วิลล์ ดอนเมือง เลขที่ 151 ถนนเทิดราชัน แขวงสีกัน เขตดอนเมือง กรุงเทพมหานคร 10210 โทร 02-565-5732'
    const newLineIndex = location.indexOf('เขต')
    const addressLine1 = location.slice(0, newLineIndex)
    const addressLine2 = location.slice(newLineIndex)
    const createDocDate = moment()
    const day = createDocDate.date()
    const month = Formatter.formatMonthNumberToTh(createDocDate.month() + 1)
    const year = createDocDate.year() + 543
    if (this.state.warrantyDefects.length <= 1) {
      return (
        <div>
          <div className="a4">
            <div className="print-container">
                <div className="logo-session">
                  <p style={{fontSize: '14px', fontWeight: 'bold'}}>
                    เอกสารตรวจรับมอบงานแก้ไขข้อบกพร่องในระยะเวลารับประกัน โครงการ แฮปปี้ วิลล์ ดอนเมือง
                  </p>
                  <div style={{textAlign: 'right'}}>
                    <p style={{fontSize: '14px'}}>ทำที่ โครงการ แฮปปี้ วิลล์ ดอนเมือง</p>
                    <p style={{fontSize: '14px'}}>วันที่..{day}..เดือน..{month}..พ.ศ..{year}..</p>
                  </div>
                </div>
                &nbsp;&nbsp;ข้าพเจ้า &nbsp; {this.state.customerName} &nbsp; เบอร์โทรศัพท์ที่ติดต่อได้ &nbsp; {this.state.customerPhoneNumber} &nbsp; ผู้ถือกรรมสิทธิ์/ ผู้พักอาศัย 
                ในที่ดินพร้อมสิ่งปลูกสร้าง โครงการ แฮปปี้ วิลล์ ดอนเมือง บ้านเลขที่ &nbsp; {this.state.productAddress ? this.state.productAddress : this.state.skuCode} &nbsp;
                ได้ทำการตรวจสอบงานแก้ไขข้อบกพร่อง โดยมี รายละเอียด ดังนี้ 

                <table className="defect-list">
                    <thead>
                      <th className="header-table"></th>
                      <th className="header-table">รายการแจ้งซ่อม</th>
                      <th className="header-table">วันที่นัดหมายซ่อมแซม</th>
                      <th className="header-table">สถานะ</th>
                    </thead>
                    <tbody>
                      {
                        this.state.warrantyDefects ? 
                        this.state.warrantyDefects.map((defect, index) => {
                          return <tr>
                          <td className="item order" width="10%">{index+1}</td>
                          <td className="item" width="30%">{defect.description}</td>
                          <td className="item" width="30%">
                            {
                              defect.tentative_date ? 
                              Formatter.formatDateWithFullMonthTh(Formatter.createDate(defect.tentative_date))
                              : 'ยังไม่มีกำหนด'
                            }
                          </td>
                          <td className="item" width="30%">
                              {defect.is_fixed ? 'แก้ไขแล้ว' : 'รอการซ่อมแซม'}
                          </td>
                        </tr>
                        })
                        : null
                      }
                    </tbody>
                </table>
  
                โดยข้าพเจ้าได้ทำการตรวจสอบรายการแก้ไขบกพร่องข้างต้นแล้ว มีความเรียบร้อยสมบูรณ์ดี สามารถเข้าครอบครองใช้ 
                ประโยชน์ในทรัพย์สิน และพักอาศัยได้ตามปกติ และข้าพเจ้ารับทราบว่า ระยะเวลารับประกันตามสัญญาจะซื้อจะขาย 
                และการรับประกัน ส่วนควบ 1 ปี ในที่ดินพร้อมสิ่งปลูกสร้างของข้าพเจ้า จะสิ้นสุดลงในวันที่ &nbsp; { this.state.warrantyEndDate ? Formatter.formatDateWithFullMonthTh(this.state.warrantyEndDate) : ''} &nbsp; 
                <br />
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;จึงลงลายมือชื่อไว้ต่อหน้าพยาน
  
                <div className="signature-session">
                  <div className="signature">
                    {
                      !this.state.customerSignature ?
                      <p>ลงชื่อ..........................................ผู้จะซื้อ</p> :
                      <p>ลงชื่อ<img className="image" src={this.state.customerSignature} alt="" />ผู้จะซื้อ</p>
                    }
                    {/* <p>ลงชื่อ..........................................ผู้จะซื้อ</p> */}
                      <p style={{marginRight: '28px'}}>
                        (............................................)
                      </p>
                  </div>
                  <div className="signature">
                    {
                      !this.state.techSignature ?
                      <p>ลงชื่อ..........................................ผู้จะขาย</p> :
                      <p>ลงชื่อ<img className="image" src={this.state.techSignature} alt="" />ผู้จะขาย</p>
                    }
                    {/* <p>ลงชื่อ..........................................ผู้จะขาย</p> */}
                    <p style={{marginRight: '28px'}}>
                        (............................................)
                    </p>
                  </div>
                  <div className="signature">
                    {
                      !this.state.witnessSignature ?
                      <p>ลงชื่อ..........................................พยาน</p> :
                      <p>ลงชื่อ<img className="image" src={this.state.witnessSignature} alt="" />พยาน</p>
                    }
                    {/* <p>ลงชื่อ..........................................พยาน</p> */}
                    <p style={{marginRight: '28px'}}>
                        (............................................)
                    </p>
                  </div>
                </div>
            </div>
          </div>
          <div>
            <PrintableWarrantySummary 
              params={this.props.params}
              firstPageNumber={2}
            />
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div className="a4" style={{height: "35cm"}}>
            <div className={`print-section ${this.state.warrantyDefects.length > 37 ? 'height-100' : ''}`}>
                <div className="logo-session">
                  <p style={{fontSize: '14px', fontWeight: 'bold'}}>
                    เอกสารตรวจรับมอบงานแก้ไขข้อบกพร่องในระยะเวลารับประกัน โครงการ แฮปปี้ วิลล์ ดอนเมือง
                  </p>
                  <div style={{textAlign: 'right'}}>
                    <p style={{fontSize: '14px'}}>ทำที่ โครงการ แฮปปี้ วิลล์ ดอนเมือง</p>
                    <p style={{fontSize: '14px'}}>วันที่..{day}..เดือน..{month}..พ.ศ..{year}..</p>
                  </div>
                </div>
  
                &nbsp;&nbsp;ข้าพเจ้า &nbsp; {this.state.customerName} &nbsp; เบอร์โทรศัพท์ที่ติดต่อได้ &nbsp; {this.state.customerPhoneNumber} &nbsp; ผู้ถือกรรมสิทธิ์/ ผู้พักอาศัย 
                ในที่ดินพร้อมสิ่งปลูกสร้าง โครงการ แฮปปี้ วิลล์ ดอนเมือง บ้านเลขที่ &nbsp; {this.state.productAddress ? this.state.productAddress : this.state.skuCode} &nbsp;
                ได้ทำการตรวจสอบงานแก้ไขข้อบกพร่อง โดยมี รายละเอียด ดังนี้ 
  
                <table className="defect-list">
                    <thead>
                      <th className="header-table"></th>
                      <th className="header-table">รายการแจ้งซ่อม</th>
                      <th className="header-table">วันที่นัดหมายซ่อมแซม</th>
                      <th className="header-table">สถานะ</th>
                    </thead>
                    <tbody>
                      {
                        this.state.warrantyDefects ? 
                        this.state.warrantyDefects.map((defect, index) => {
                          return <tr>
                          <td className="item order" width="10%">{index+1}</td>
                          <td className="item" width="30%">{defect.description}</td>
                          <td className="item" width="30%">
                            {
                              defect.tentative_date ? 
                              Formatter.formatDateWithFullMonthTh(Formatter.createDate(defect.tentative_date))
                              : 'ยังไม่มีกำหนด'
                            }
                          </td>
                          <td className="item" width="30%">
                              {defect.is_fixed ? 'แก้ไขแล้ว' : 'รอการซ่อมแซม'}
                          </td>
                        </tr>
                        })
                        : null
                      }
                    </tbody>
                </table>
              </div>
          </div>
          {
            this.state.warrantyDefects.length > 34 ? 
            <div className="a4" style={{height: "35cm"}}>&nbsp;</div> : 
            null
          }
          {
            this.state.warrantyDefects.length > 84 ? 
            <div className="a4" style={{height: "35cm"}}>&nbsp;</div> : 
            null
          }
          {

            this.state.warrantyDefects.length > 130 ? 
            <div className="a4" style={{height: "35cm"}}>&nbsp;</div> : 
            null
          }
          {
            this.state.warrantyDefects.length > 176 ? 
            <div className="a4" style={{height: "35cm"}}>&nbsp;</div> : 
            null
          }
          {
            this.state.warrantyDefects.length > 222 ? 
            <div className="a4" style={{height: "35cm"}}>&nbsp;</div> : 
            null
          }
          <div className="a4" style={{height: '35cm'}}>
            <div className="print-section height-100">
            โดยข้าพเจ้าได้ทำการตรวจสอบรายการแก้ไขบกพร่องข้างต้นแล้ว มีความเรียบร้อยสมบูรณ์ดี สามารถเข้าครอบครองใช้ 
                ประโยชน์ในทรัพย์สิน และพักอาศัยได้ตามปกติ และข้าพเจ้ารับทราบว่า ระยะเวลารับประกันตามสัญญาจะซื้อจะขาย 
                และการรับประกัน ส่วนควบ 1 ปี ในที่ดินพร้อมสิ่งปลูกสร้างของข้าพเจ้า จะสิ้นสุดลงในวันที่ &nbsp; { this.state.warrantyEndDate ? Formatter.formatDateWithFullMonthTh(this.state.warrantyEndDate) : ''} &nbsp; 
                <br />
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;จึงลงลายมือชื่อไว้ต่อหน้าพยาน
    
              <div className="signature-session">
                <div className="signature">
                  {
                    !this.state.customerSignature ?
                    <p>ลงชื่อ..........................................ผู้จะซื้อ</p> :
                    <p>ลงชื่อ<img className="image" src={this.state.customerSignature} alt="" />ผู้จะซื้อ</p>
                  }
                  {/* <p>ลงชื่อ..........................................ผู้จะซื้อ</p> */}
                    <p style={{marginRight: '28px'}}>
                      (............................................)
                    </p>
                </div>
                <div className="signature">
                  {
                    !this.state.techSignature ?
                    <p>ลงชื่อ..........................................ผู้จะขาย</p> :
                    <p>ลงชื่อ<img className="image" src={this.state.techSignature} alt="" />ผู้จะขาย</p>
                  }
                  {/* <p>ลงชื่อ..........................................ผู้จะขาย</p> */}
                  <p style={{marginRight: '28px'}}>
                      (............................................)
                  </p>
                </div>
                <div className="signature">
                  {
                    !this.state.witnessSignature ?
                    <p>ลงชื่อ..........................................พยาน</p> :
                    <p>ลงชื่อ<img className="image" src={this.state.witnessSignature} alt="" />พยาน</p>
                  }
                  {/* <p>ลงชื่อ..........................................พยาน</p> */}
                  <p style={{marginRight: '28px'}}>
                      (............................................)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <PrintableWarrantySummary 
              params={this.props.params}
              firstPageNumber={
                this.state.warrantyDefects.length < 34 ? 3 :
                this.state.warrantyDefects.length < 84 ? 4 :
                this.state.warrantyDefects.length < 130 ? 5 :
                this.state.warrantyDefects.length < 176 ? 6 :
                this.state.warrantyDefects.length <= 222 ? 7 : 6
              }
            />
          </div>
        </div>
      )
    }
  }

  render() {
    return this.state.isCondo ? this.renderCondoTemplate() : this.renderHouseTemplate()
  }
}