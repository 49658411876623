import React, { Component } from 'react'

import Wrapper from '../layouts/Wrapper'
import datastore from '../datastore'
import BasicInfoContainer from '../components/BasicInfoCard'

export default class CustomerInfoView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            customer: null,
        }
    }

    componentWillMount() {
        const customerId = this.props.params.customerId
        datastore.customer.getCustomer(customerId)
            .then(customer => {
                this.props.setHeader(`${ customer.firstName } ${ customer.lastName }`)
                this.setState({
                    customer: customer
                })
            })
    }

    render() {
        return (
            <div>
                <Wrapper>
                    <GeneralInfoCard customer={ this.state.customer } />
                </Wrapper>
            </div>
        )
    }
}

const GeneralInfoCard = ({ customer }) => {
    const data = [
        [
            {
                label: 'ชื่อ-นามสกุล',
                // value: `${customer.firstName} ${customer.lastName}`
                value: 'ทินกร รุ่งวิสัย'
            },
            {
                label: 'เพศ',
                // value: translate('genders', customer.gender)
                value: 'ชาย'
            },
        ],
        [
            {
                label: 'อายุ',
                value: '20',
            },
            {
                label: 'อาชีพ',
                // value: customer.occupation,
                value: 'พนักงานรัฐวิสาหกิจ',
            },
        ],
        [
            {
                label: 'รายได้ / เดือน (บาท)',
                // value: translate('salary_ranges', customer.income),
                value: '50,001-70,000'
            },
            {
                label: 'โทรศัพท์',
                // value: customer.tel,
                value: '0816292904'
            },
        ],
        [
            {
                label: 'อีเมล',
                value: 'test@billme.co.th',
            },
            {
                label: 'Line ID',
                // value: customer.occupation,
                value: 'billme'
            },
        ],
        [
            {
                label: 'ที่อยู่ปัจจุบัน',
                value: '',
            },
        ],
        [
            {
                label: 'ที่อยู่ทำงาน',
                value: '',
            },
        ],
        [
            {
                label: 'วัตถุประสงค์',
                value: '',
            },
            {
                label: 'รหัสผู้ป่วย',
                value: '',
            },
            {
                label: 'วันที่สมัคร',
                value: '17/10/2010',
            },
        ],
    ]

    return <BasicInfoContainer title="ข้อมูลส่วนตัว" content={ data } />
}