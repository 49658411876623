import React from 'react';
import Wrapper from '../../layouts/Wrapper';
import Card from '../../components/common/Card';
import LazyTable from '../../components/common/tables/LazyTable';
import datastore from '../../datastore';

export default class CampaignListView extends React.PureComponent {

  constructor(props){
    super(props);

    this.rowClickCallback = this.rowClickCallback.bind(this);
    this.getValues = this.getValues.bind(this);
    this.goToCreateCampaign = this.goToCreateCampaign.bind(this);
    this.columns = [
      {
          header: 'สถานะ',
          unSortable: true,
          accessor: 'status',
          render: value => {
            return value === "active" ?
              <div style={{margin: "3px 0 0 12px"}}>
                <div className="green-status-indicator" />
              </div> :
              <div style={{margin: "3px 0 0 12px"}}>
                <div className="red-status-indicator" />
              </div>
          }
      },
      {
          header: 'ชื่อแคมเปญ',
          accessor: 'campaign_name',
          unSortable: true
      },
      {
          header: 'ระยะเวลา',
          accessor: 'duration',
          unSortable: true,
          render: (value) => {
            return <span>{value} วัน</span>
          }
      },
      {
          header: 'งบประมาณที่ใช้ไป',
          accessor: 'spend_budget',
          unSortable: true
      },
      {
          header: 'ค่าใช้จ่ายต่อการจอง',
          accessor: 'cost_per_book',
          unSortable: true
      },
      {
          header: 'ผู้เยี่ยมชม',
          accessor: 'visitor',
          unSortable: true
      },
      {
          header: 'ผู้จอง',
          accessor: 'booked',
          unSortable: true
      }
    ];
  }

  componentWillMount() {
    this.props.setHeader('แคมเปญ', this.goToCreateCampaign, 'สร้างแคมเปญ')
  }

  goToCreateCampaign() {
    this.props.router.push('/create-campaign');
  }

  rowClickCallback(row) {
    this.props.router.push(`/campaign/${row.id}`)
  }

  getValues(offset, pageSize) {
    return datastore.campaign.getCampaignList(offset, pageSize)
  }

  render() {
    const { options } = this.props
        return (
            <Wrapper>
                <Card>
                  <div className="campaign-lazytable">
                    <LazyTable 
                          columns={ this.columns } 
                          getValues={ this.getValues } 
                          filterOptions={ options }
                          rowClickCallback={ this.rowClickCallback }
                          ref="listTable"
                    />
                  </div>
                </Card>
            </Wrapper>
        )
    }
}

