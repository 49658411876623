import React, { Component } from 'react'
import { connect } from 'react-redux'

import { setToken } from '../actions/auth'
import apiClient from '../apis'
import Wrapper from '../layouts/Wrapper'
import config from '../common/config'

class LoginView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            failedLogin: true
        }

        this.login = this.login.bind(this)
    }

    componentWillMount() {
        this.setState({
            failedLogin: false
        })
    }

    login(e) {
        e.preventDefault()
        this.setState({failedLogin: false})
        const email = this.refs.email.value
        const password = this.refs.password.value

        apiClient.auth.authenticate(email, password)
            .then(info => {
                if(info) {
                    const fullName = info.full_name
                    const accountId = info.account_id
                    const token = info.token
                    const role = info.account_role[0] // TODO: Can one user have multiple role.
                    if (token) {
                        this.props.setToken(token, role)
                        localStorage.setItem('username', fullName)
                        localStorage.setItem('accountId', accountId)
                        this.props.router.push('/')
                    }
                } else {
                    this.setState({
                        failedLogin: true
                    })
                }
            })
    }

    renderErrorMessage() {
        return (
            <p className="red f13 mt-05">
                อีเมล/รหัสผ่าน ไม่ถูกต้อง
            </p>
        )
    }

    render() {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center fit-viewport">
                <Wrapper>
                    <form 
                        className="d-flex flex-column justify-content-center" 
                        style={{ width: '440px' }}
                        onSubmit={ this.login }
                    >
                        <div className="mb-2">
                            <h2>สวัสดี !</h2>
                            <h2 className="font-light py-05">เข้าสู่ระบบเพื่อเริ่มใช้งาน</h2>
                        </div>
                        <div className="mb-2" style={{ minHeight: '151px' }}>
                            <input ref="email" type="email" placeholder="อีเมล" className="form-control input mb-2" required />
                            <input ref="password" type="password" placeholder="รหัสผ่าน" className="form-control input" required />
                            { this.state.failedLogin ? this.renderErrorMessage() : null }
                        </div>
                        <div className="w-100">
                            <button className="btn btn-large btn-main mb-2 w-100" type="submit">เข้าสู่ระบบ</button>
                            {/* <Link to="#" className="blue">Forgot password</Link> */}
                        </div>
                    </form>
                </Wrapper>
                <a href='/release-notes' style={{color: '#00ace1'}} target="_blank">มีอะไรใหม่ใน {config.version} ?</a>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        token: state.auth.token
    }
}

export default connect(mapStateToProps, { setToken })(LoginView)
