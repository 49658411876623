import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import TableBase, { NO_FILTER_OPTION } from './TableBase'

export default class LazyTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            sortKey: null,
            sortOrder: false, // true, ascending, false = descending
            filters: [],
            values: [],
            filtersWithOptions: [],
            itemsPerPage: 10
        }

        this.sortColumn = this.sortColumn.bind(this)
        this.setFilter = this.setFilter.bind(this)
        this.selectPage = this.selectPage.bind(this)
        this.setItemsPerPage = this.setItemsPerPage.bind(this)
        this.getFiltersWithOptions = this.getFiltersWithOptions.bind(this)
    }

    componentWillMount() {
        const otherStates = {
            filtersWithOptions: this.getFiltersWithOptions(
                this.props.columns, 
                this.props.filterOptions
            )
        }

        this.updateValues(
            this.props.itemsPerPage || this.state.itemsPerPage,
            this.state.page, 
            this.state.sortKey,
            this.state.sortOrder,
            this.state.filters,
            otherStates
        )
    }

    componentWillReceiveProps(nextProps) {
        const otherStates = {
            filtersWithOptions: this.getFiltersWithOptions(
                nextProps.columns, 
                nextProps.filterOptions
            )
        }

        this.setState({
            itemsPerPage: nextProps.itemsPerPage || this.state.itemsPerPage
        })

        this.updateValues(
            nextProps.itemsPerPage || this.state.itemsPerPage,
            0, 
            this.state.sortKey,
            this.state.sortOrder,
            this.state.filters,
            otherStates
        )
    }

    refreshTable() {
        this.updateValues(
            this.state.itemsPerPage,
            this.state.page, 
            this.state.sortKey,
            this.state.sortOrder,
            this.state.filters
        )
    }

    updateValues(itemsPerPage, page, sortKey, sortOrder, filters, otherStates) {
        const { getValues } = this.props
        const offset = page * itemsPerPage

        this.setState({
            values: null
        })

        getValues(offset, itemsPerPage, sortKey, sortOrder, filters)
            .then(res => {
                let length, values
                if (!res || !res.length || !res.values) {
                    console.error('getValues failed')
                    length = 0
                    values = []
                } else {
                    length = res.length
                    values = res.values
                }

                this.setState({
                    itemsPerPage: itemsPerPage,
                    sortKey: sortKey, 
                    sortOrder: sortOrder,
                    page: page,
                    length: length,
                    values: values,
                    filters: filters,
                    ...otherStates
                })
            })
    }

    sortColumn(key) {
        const sortOrder = key !== this.state.sortKey ? true : !this.state.sortOrder
        this.updateValues(this.state.itemsPerPage, 0, key, sortOrder, this.state.filters)
    }

    setFilter(key) {
        const filters = this.state.filters
        const value = this.refs.tableBase.refs[`filter-${key}`].value 
        _.remove(filters, f => {
            return f.key === key
        })

        console.log(value)
        if (value != -1) {
            filters.push({ 
                key: key,
                value: value
            })
        } 

        this.updateValues(this.state.itemsPerPage, this.state.page, this.state.sortKey, this.state.sortOrder, filters)
    }

    selectPage(page) {
        this.updateValues(this.state.itemsPerPage, page, this.state.sortKey, this.state.sortOrder, this.state.filters)
    }

    setItemsPerPage(itemsPerPage) {
        this.updateValues(parseInt(itemsPerPage, 10), this.state.page, this.state.sortKey, this.state.sortOrder, this.state.filters)
    }

    getFiltersWithOptions(columns, filterOptions) {
        if (!filterOptions) {
            return []
        }

        const filtersWithOptions = columns.reduce((acc, c) => {
            if (c.filterAccessor == null) {
                return acc
            }

            let options = filterOptions[c.filterAccessor]
            if (_.isEmpty(options)) {
                return acc
            }

            acc.push({
                key: c.accessor,
                options: [NO_FILTER_OPTION, ...(_.sortBy([...options], ['label']))]
            })

            return acc
        }, [])
        
        return filtersWithOptions
    }

    render() {
        const { columns, itemsPerPage, rowClickCallback, noColumnHeader, noHeader } = this.props
        return <TableBase 
            ref="tableBase"
            columns={ columns }
            values={ this.state.values }
            page={ this.state.page }
            length={ this.state.length || 0 }
            sortColumn={ this.sortColumn }
            setFilter={ this.setFilter }
            setItemsPerPage={ this.setItemsPerPage }
            selectPage={ this.selectPage }
            rowClickCallback={ rowClickCallback }
            itemsPerPage={ itemsPerPage || this.state.itemsPerPage }
            filtersWithOptions={ this.state.filtersWithOptions }
            noColumnHeader={ noColumnHeader }
            noHeader={ noHeader }
            sortKey={ this.state.sortKey }
            sortOrder={ this.state.sortOrder }
            noItemsPerPageSelector={ itemsPerPage }
        />
    }
}

LazyTable.propTypes = {
    columns: PropTypes.array.isRequired,
    itemsPerPage: PropTypes.number,
    rowClickCallback: PropTypes.func,
    getValues: PropTypes.func, // (offset, pageSize, sortKey, sortOrder, filterObject) => { values, length }
    noColumnHeader: PropTypes.bool,
    noHeader: PropTypes.bool,
}
