import React, { Component } from 'react'

import ConfirmModal from '../../components/ConfirmModal'
import TextWithLabel from '../../components/common/TextWithLabel'

class ConfirmationModal extends Component {
    confirm = () => {
        this.props.confirm()
        this.refs.modal.closeModal()
    }

    render() {
        const { data, onClose } = this.props

        const valid = data && data.selectedDefects 
            && data.technician 
            && data.time
            && data.ready
            //&& data.type

        if (!valid) {
            return null
        }

        return (
            <ConfirmModal
                ref="modal"
                title="ยืนยันการมอบหมายงาน"
                onConfirm={this.confirm}
                onClose={onClose}
            >
                <div className="row no-gutters justify-content-start mb-2">
                    <div className="col-6">
                        <TextWithLabel label="เวลา">
                            {data.time}                        
                        </TextWithLabel>
                    </div>
                    <div className="col-6">
                        <TextWithLabel label="ชื่อช่าง">
                            {data.technician.full_name}                        
                        </TextWithLabel>
                    </div>
                </div>
                <div className="row no-gutters justify-content-start mb-2">
                    <div className="col-6">
                        <TextWithLabel label="ข้อบกพร่อง">
                            {data.selectedDefects.map((d, index) => {
                                return <p key={index} style={{ marginBottom: '0.5rem' }}>{`${d.sku_code || 'n/a'} - ${d.description || 'n/a'}`}</p>
                            })}
                        </TextWithLabel>
                    </div>
                    {/*<div className="col-6">
                        <TextWithLabel label="ประเภทงาน">
                            {data.type}                        
                        </TextWithLabel>
                    </div>
                    */}
                </div>
            </ConfirmModal>
        )
    }
}

export default ConfirmationModal
