import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ConfirmModal from '../../../components/ConfirmModal'
import FileInput from '../../../components/common/FileInput'

class SelectFileModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                name: null,
            }
        }

        this.uploadFile = this.uploadFile.bind(this)
    }

    uploadFile() {
        this.props.uploadHandler(this.state.data.name)
    }

    render() {
        return (
            <ConfirmModal
                ref="modal"
                title="เลือกไฟล์"
                onConfirm={ this.uploadFile }
            >
                <FileInput name="name" target={ this } stateKey="data" />
            </ConfirmModal>
        )
    }
}

SelectFileModal.propTypes = {
    uploadHandler: PropTypes.func.isRequired,
}

export default SelectFileModal