import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class SelectInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: null
        }
    }

    componentWillMount() {
        const { target, stateKey, nestedKey, name } = this.props
        const value = nestedKey 
            ? target.state[stateKey][nestedKey][name] 
            : target.state[stateKey][name]

        this.setState({
            value: value
        })
    }

    componentWillReceiveProps(nextProps) {
        const { target, stateKey, nestedKey, name } = nextProps
        const value = nestedKey
            ? target.state[stateKey][nestedKey][name]
            : target.state[stateKey][name]

        this.setState({
            value: value
        })
    }

    render() {
        const { 
            name, 
            placeholder, 
            options, 
            target, 
            stateKey, 
            nestedKey, 
            formatter,
            disabled
        } = this.props

        return (
            <select 
                className={"form-control input w-100 " + (this.state.value?"active":"") }
                style={this.props.style}
                name={ name }
                value={
                    this.state.value === null
                    ? placeholder
                    : this.state.value
                }

                disabled={disabled}

                onChange={ (e) => {
                    const data = target.state[stateKey]

                    const value = formatter ? formatter(e.target.value) : e.target.value
                    if (nestedKey) {
                         data[nestedKey] = data[nestedKey] || {} 
                         data[nestedKey][e.target.name] = value 
                    } else {
                        data[e.target.name] = value
                    }

                    target.setState({
                        [stateKey]: data
                    })

                    this.setState({value})

                    if (this.props.onChange) {
                        this.props.onChange(e)
                    }
                }}
            >
                <option value={ placeholder } disabled>{ placeholder }</option>
                { options 
                    ? options.map((o, index) => {
                        if (!o.value) {
                            return <option value={ null } key={ index } disabled>------------------------------</option>
                        }

                        const value = typeof(o.value) === 'object'
                            ? JSON.stringify(o.value)
                            : o.value

                        return <option value={ value } key={ index }>{ o.label }</option>
                    })
                    : null
                }
            </select>
        )
    }
}

SelectInput.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    options: PropTypes.array,
    target: PropTypes.object.isRequired,
    stateKey: PropTypes.string.isRequired,
    nestedKey: PropTypes.string,
    formatter: PropTypes.func,
    onChange: PropTypes.func,
}

export const SEPERATOR = {
    value: null,
    label: null 
}
