export const CAMPAIGN_LIST = {
  length: 2,
  values: [
      {
        id: 5937182371293719,
        status: "active",
        campaign_name: "Facebook Ads.",
        duration: 1,
        budget: 250000,
        spend_budget: 100000,
        cost_per_book: 20000,
        visitor: 45,
        booked: 5
      },
      {
        id: 5937182371293720,
        status: "inactive",
        campaign_name: "รถเคลื่อนที่",
        duration: 1,
        budget: 250000,
        spend_budget: 140000,
        cost_per_book: 140000,
        visitor: 7,
        booked: 1
    }
  ]
}