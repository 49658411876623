export const ACTIVITY_INFO = {
    length: 1,
    values: [
        {
            time: "10.30",
            name: "Thanathat Surakhup",
            activity: "เยี่ยมชมครั้งที่1",
            product: "A-310",
            period: "สองอาทิตย์ก่อน",
            salesman: "เฌอปราง อารีย์กุล"
        },
        {
            time: "11.30",
            name: "Surakhup Thanathat",
            activity: "เยี่ยมชมครั้งที่2",
            product: "A-510",
            period: "อาทิตย์ก่อน",
            salesman: "เฌอปราง อารีย์กุล"
        }
    ]
}