import React from 'react';
import PropTypes from 'prop-types';

import ProductButton from './ProductButton';

const BUILDING_BOX_WIDTH = '976px';
const BUILDING_BOX_STYLE = {
  width: BUILDING_BOX_WIDTH,
  maxWidth: BUILDING_BOX_WIDTH,
  minWidth: BUILDING_BOX_WIDTH,
};

const BuildingBox = ({ imgUrl, products, building, floor }) => {
  return (
    <div className="w-100 d-flex align-items-start justify-content-center">
      {imgUrl ? (
        <div style={{ position: 'relative', ...BUILDING_BOX_STYLE }}>
          <img src={imgUrl} className="w-100" />
          {products.map(p => {
            const { buildingName } = building || {};
            const { floorName } = floor || {};
            return (
              <ProductButton key={p.id} productData={p} building={buildingName} floor={floorName} />
            );
          })}
        </div>
      ) : (
        <h5 className="grey" style={{ ...BUILDING_BOX_STYLE }}>
          กรุณาเลือกชั้นที่ต้องการ
        </h5>
      )}
    </div>
  );
};

BuildingBox.propTypes = {
  products: PropTypes.array,
  imgUrl: PropTypes.string,
  building: PropTypes.object,
  floor: PropTypes.object,
};

BuildingBox.defaultProps = {
  products: [],
};

export default BuildingBox;
