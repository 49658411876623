import React from 'react'
import _ from 'lodash'

import Page from './page'
import './printable.css'

const DEFECTS_PER_PAGE = 3
const PrintableStartInspect = ({ skuCode, projectName, address, logoUrl, defects, firstPageNumber, header }) => {
    let count = firstPageNumber
    const groupedDefects = _.groupBy(defects, 'location')
    return <div>
        {Object.entries(groupedDefects).map(([location, defects]) => {
            const originalCount = count
            count += Math.ceil(defects.length / DEFECTS_PER_PAGE)
            const grouped = defects.reduce((acc, item, index) => {
                if (index % DEFECTS_PER_PAGE === 0) {
                    acc.push([])
                }

                const page = acc[acc.length-1]
                page.push([])

                const row = page[page.length-1]
                row.push({ image: item.before, title: `${item.title} (ก่อน)` })
                // row.push({ image: item.after, title: `${item.title} (หลัง)` })
                return acc
            }, [])
            return grouped.map((p, index) => { 
                return <Page
                    key={`${location}-${index}`}
                    header={header}
                    defects={p}
                    skuCode={skuCode}
                    location={location}
                    count={originalCount}
                    projectName={projectName}
                    address={address}
                    logo={logoUrl}
                />
            })
        })}
    </div>
}

export default PrintableStartInspect
