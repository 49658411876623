import React from 'react'
import {Colors} from '../../../../theme/colors'
import * as Md from 'styled-icons/material'
import styled from 'styled-components'
import {LeadListsTable} from './LeadListsTable'

export const Container = styled.div`
    width: calc(100vw - 340px);
    padding: 1.5rem;
    box-sizing: border-box;   
    overflow: scroll;
`

export const PageHeader = styled.h1`
    font-size: 1.5rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
`

export const PageLead = ({text, ...props}) => (
    <PageHeader>
        <Md.People 
            color={Colors.Default} 
            size={32} />
        <span style={{marginLeft: '16px'}}>{text}</span>
    </PageHeader>
)

export const __ = t => ({

}[t] || t)

export const ContentColumn = ({data, showCustomerInfo, router, ...props}) => (
    <Container>
        <PageLead text={`ผู้สนใจทั้งหมด ${data.length} คน`}></PageLead>
        <LeadListsTable data={data} showCustomerInfo={showCustomerInfo} router={router} />
    </Container>
)

export default ContentColumn