import React, { Component } from 'react'

import datastore from '../datastore'
import ProjectCard from './ProjectCard'
import { inModal } from '../hoc/inModal'

class SelectProject extends Component {
    state = {
        projects: null
    }

    componentWillMount() {
        datastore.user.getProfile()
            .then(res => {
                this.setState({
                    projects: res.projects
                })
            })
    }

    render() {
        if (!this.state.projects) {
            return null
        }

        return <div>
            <div className="d-flex flex-row justify-evenly">
                {this.state.projects.length > 0
                    ? this.state.projects.map((p, index) => {
                        return <ProjectCard name={p.name} projectId={p.id.toString()} key={index} />
                    })
                    : <h5>ไม่มีโครงการที่สามารถเข้าถึงได้</h5>
                }
            </div>
            <div style={{ marginTop: '20px' }} className="d-flex flex-row justify-content-center">
                <img src="/img/icon-close.svg"
                    width="50"
                    height="50"
                    style={{cursor: 'pointer'}}  
                    onClick={() => this.props.closeModal()} 
                />
            </div>
        </div>
    }
}

export default inModal(SelectProject, true)
