import React from 'react'

export const SALE_RECORDS_LIST = [
    {
        "id": 1
        , "skuCode": "SKU-1"
        , "productId": 2
        , "customerId": 2
        , "customerName": "Customer 1"
        , "loanStatus": "In Process"
        , "loanSubStatus": "Document not completed"
        , "inspectStatus": "1st Inspection"
        , "inspectionDate": "10 August 2017"
        , "saleId": 1
        , "saleName": "Sale 1"
        , "staffId": 2
        , "staffName": "Staff 2"
    }
    , {
        "id": 2
        , "skuCode": "SKU-2"
        , "productId": 2
        , "customerId": 2
        , "customerName": "Customer 2"
        , "loanStatus": "In Process"
        , "loanSubStatus": "Document not completed"
        , "inspectStatus": "1st Inspection"
        , "inspectionDate": "10 August 2017"
        , "saleId": 1
        , "saleName": "Sale 1"
        , "staffId": 1
        , "staffName": "Staff 1"
    }
    , {
        "id": 3
        , "skuCode": "SKU-3"
        , "productId": 2
        , "customerId": 2
        , "customerName": "Customer 3"
        , "loanStatus": "In Process"
        , "loanSubStatus": "Document not completed"
        , "inspectStatus": "1st Inspection"
        , "inspectionDate": "10 August 2017"
        , "saleId": 3
        , "saleName": "Sale 3"
        , "staffId": 3
        , "staffName": "Staff 3"
    }
]

export const SALE_RECORD_INFO = {
    "id": 1
    , "documentNo": "123456789"
    // , "inspectionDate": null
    , "nextInspectionDate": "2017-09-30 09:09:00+00"
    , "inspections": [
        {
            "id": 1
            , "inspectionNo": 1
            , "inspectionDate": "03/06/2017 10:00:00"
            , "status": "0"
            , "engineerName": "Engineer A"
            , "staffName": "Staff A"
            , "saleName": "Sale A"
            , "customerName": "Customer A"
            , "defects": [
                {
                    "id": 1
                    , "title": "สีถลอกบริเวณตู้"
                    , "defectType": "งานสี"
                    , "location": "ใต้ตู้ครัว"
                    , "status": "In Progress"
                    , "map": "/img/plan-test.png"
                    , "before": "/img/defect-before.jpeg"
                    , "after": "http://via.placeholder.com/150x150"
                }
                , {
                    "id": 2
                    , "title": "ท่อน้ำรั่ว"
                    , "defectType": "งานประปา"
                    , "location": "ห้องน้ำ"
                    , "status": "Done"
                    , "map": "/img/plan-test.png"
                    , "before": "/img/defect-before.jpeg"
                    , "after": "http://via.placeholder.com/150x150"
                }
                , {
                    "id": 3
                    , "title": "กระเบื้องร้าว"
                    , "defectType": "งานกระเบื้อง"
                    , "location": "ห้องนั่งเล่น"
                    , "status": "Done"
                    , "map": "/img/plan-test.png"
                    , "before": "/img/defect-before.jpeg"
                    , "after": "http://via.placeholder.com/150x150"
                }
            ]
            , "nextInspectionDate": "03/07/2017 (10:00)"
        }
        , {
            "id": 2
            , "inspectionNo": 2
            , "inspectionDate": "03/06/2017 10:00:00"
            , "status": "3"
            , "engineerName": "Engineer A"
            , "staffName": "Staff A"
            , "saleName": "Sale A"
            , "customerName": "Customer A"
            , "defects": [
                {
                    "id": 1
                    , "title": "สีถลอกบริเวณตู้"
                    , "defectType": "งานสี"
                    , "location": "ใต้ตู้ครัว"
                    , "status": "In Progress"
                    , "map": "/img/plan-test.png"
                    , "before": "/img/defect-before.jpeg"
                    , "after": "http://via.placeholder.com/150x150"
                }
                , {
                    "id": 2
                    , "title": "ท่อน้ำรั่ว"
                    , "defectType": "งานประปา"
                    , "location": "ห้องน้ำ"
                    , "status": "Done"
                    , "map": "/img/plan-test.png"
                    , "before": "/img/defect-before.jpeg"
                    , "after": "http://via.placeholder.com/150x150"
                }
                , {
                    "id": 3
                    , "title": "กระเบื้องร้าว"
                    , "defectType": "งานกระเบื้อง"
                    , "location": "ห้องนั่งเล่น"
                    , "status": "Done"
                    , "map": "/img/plan-test.png"
                    , "before": "/img/defect-before.jpeg"
                    , "after": "http://via.placeholder.com/150x150"
                }
            ]
            , "nextInspectionDate": "03/07/2017 (10:00)"
        }
        , {
            "id": 3
            , "inspectionNo": 3
            , "inspectionDate": "03/06/2017 10:00:00"
            , "status": "4"
            , "engineerName": "Engineer A"
            , "staffName": "Staff A"
            , "saleName": "Sale A"
            , "customerName": "Customer A"
            , "defects": [
                {
                    "id": 1
                    , "title": "สีถลอกบริเวณตู้"
                    , "defectType": "งานสี"
                    , "location": "ใต้ตู้ครัว"
                    , "status": "In Progress"
                    , "map": "/img/plan-test.png"
                    , "before": "/img/defect-before.jpeg"
                    , "after": "http://via.placeholder.com/150x150"
                }
                , {
                    "id": 2
                    , "title": "ท่อน้ำรั่ว"
                    , "defectType": "งานประปา"
                    , "location": "ห้องน้ำ"
                    , "status": "Done"
                    , "map": "/img/plan-test.png"
                    , "before": "/img/defect-before.jpeg"
                    , "after": "http://via.placeholder.com/150x150"
                }
                , {
                    "id": 3
                    , "title": "กระเบื้องร้าว"
                    , "defectType": "งานกระเบื้อง"
                    , "location": "ห้องนั่งเล่น"
                    , "status": "Done"
                    , "map": "/img/plan-test.png"
                    , "before": "/img/defect-before.jpeg"
                    , "after": "http://via.placeholder.com/150x150"
                }
            ]
            , "nextInspectionDate": "03/07/2017 (10:00)"
        }
    ]
    , "status": "In Progress"
}

export const BASIC_INFO = [
    [
        {
            label: 'ชื่อผู้ทำสัญญา',
            value: 'อานนท์ ชลวุฒิ',
        },
        {
            label: 'สินค้า',
            value: 'RM-B-405',
        },
    ],
    [
        {
            label: 'วันที่ทำสัญญา',
            value: '08/02/2017',
        },
        {
            label: 'สถานะการขอกู้',
            value: 'ระหว่างรออนุมัติ',
            render: value => {
                const indicatorColor = value === 'ระหว่างรออนุมัติ' ? 'red' : 'green'
                return <span style={{ color: indicatorColor }}>{ value }</span>
            }
        },
    ],
    [
        {
            label: 'สถานะการโอนห้อง',
            value: 'ตรวจรับครั้งที่ 1',
            render: value => {
                const indicatorColor = value === 'ตรวจรับผ่านแล้ว' ? 'green' : 'red'
                return <span style={{ color: indicatorColor }}>{ value }</span>
            }
        },
        {
            label: 'เจ้าหน้าที่ผู้ดูแล',
            value: 'สุประพาส ศรสิทธิ์',
        },
        {
            label: 'ราคาซื้อขาย',
            value: '200,000',
        },
    ]
]

export const OLD_SALE_RECORDS_LIST = [
    {
        skuCode: 'RM-B-405',
        customerName: 'ทวีกูล สีหวัง',
        loanStatus: 'เอกสารเรียบร้อย',
        inspectStatus: 'นัดตรวจรับครั้งที่ 1',
        saleName: 'สุบุษกร เทียมภักดี',
    },
    {
        skuCode: 'RM-B-408',
        customerName: 'ฉลองชัย โยชาลี',
        loanStatus: 'รอ Pre-Approved',
        inspectStatus: 'ผ่านการตรวจรับ',
        saleName: 'ละอองทิพย์ เขียวสิงห์',
    },
    {
        skuCode: 'RM-B-409',
        customerName: 'จิตชนะ ผางดี',
        loanStatus: 'เอกสารชุดแรกไม่ครบ',
        inspectStatus: 'นัดตรวจรับครั้งที่ 2',
        saleName: 'สุบุษกร เทียมภักดี',
    },
    {
        skuCode: 'RM-B-410',
        customerName: 'จิตติพร ทับทิมสวน',
        loanStatus: 'รอการอนุมัติ',
        inspectStatus: 'นัดตรวจรับครั้งที่ 1',
        saleName: 'ละอองทิพย์ เขียวสิงห์',
    },
    {
        skuCode: 'RM-B-411',
        customerName: 'ชนัดดา ลี้ดำรงค์ประเสริฐ ',
        loanStatus: 'เอกสารชุดแรกไม่ครบ',
        inspectStatus: 'ผ่านการตรวจรับ',
        saleName: 'ละอองทิพย์ เขียวสิงห์',
    },
    {
        skuCode: 'RM-B-415',
        customerName: 'ชูศิริ บุตรชาดา ',
        loanStatus: 'เอกสารชุดแรกไม่ครบ',
        inspectStatus: 'ไม่ผ่านการตรวจครั้งที่ 1',
        saleName: 'สุบุษกร เทียมภักดี',
    },
    {
        skuCode: 'RM-B-501',
        customerName: 'กาด จันทโหม ',
        loanStatus: 'เอกสารเพิ่มเติมไม่ครบ',
        inspectStatus: 'ผ่านการตรวจรับ',
        saleName: 'จีรวัฒน์ สิงโตไทย์',
    },
    {
        skuCode: 'RM-B-502',
        customerName: 'สุบุศบา คนสอน ',
        loanStatus: 'รอ Pre-Approved',
        inspectStatus: 'ไม่ผ่านการตรวจครั้งที่ 1',
        saleName: 'สุบุษกร เทียมภักดี',
    },
    {
        skuCode: 'RM-B-503',
        customerName: 'สุธรัญญา หาญพยัคฆ์สกุล ',
        loanStatus: 'เอกสารเรียบร้อย',
        inspectStatus: 'ผ่านการตรวจรับ',
        saleName: 'สุบุษกร เทียมภักดี',
    },
    {
        skuCode: 'RM-B-504',
        customerName: 'คมกริตย์ ขุนชิต ',
        loanStatus: 'รอการอนุมัติ',
        inspectStatus: 'นัดตรวจรับครั้งที่ 2',
        saleName: 'จีรวัฒน์ สิงโตไทย์',
    },
    {
        skuCode: 'RM-B-505',
        customerName: 'สุใจดี จันทรเคน ',
        loanStatus: 'เอกสารเพิ่มเติมไม่ครบ',
        inspectStatus: 'นัดตรวจรับครั้งที่ 1',
        saleName: 'สุบุษกร เทียมภักดี',
    },
    {
        skuCode: 'RM-B-506',
        customerName: 'กาญจนวัฒน์ ผลวิไล ',
        loanStatus: 'เอกสารเรียบร้อย',
        inspectStatus: 'ผ่านการตรวจรับ',
        saleName: 'จีรวัฒน์ สิงโตไทย์',
    },
]


