import React from 'react'
import styled, {css} from 'styled-components'
import {Colors} from '../index'

export const Button = styled.button`
    outline: none;
    cursor: pointer;
    border: none;
    font-size: 1em;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px 2px rgba(0,0,0,0.15);
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: none;
    background: ${props => props.active ? props.color : Colors.White};
`

export const Icon = styled.span`
    padding: 8px;
    display: flex;
    align-items: center;
    color: ${props => props.active ? Colors.White : props.color};
`

export const Label = styled.span`
    flex: 2;
    display: flex;
    flex-direction: column;
    padding: 8px;
    padding-left: 0;
    text-align: left;
    font-size: 1rem;
    color: ${props => props.active ? Colors.White : Colors.Graphite};
`

export const IconButton = ({icon, children, active, onClick, ...props}) => (<Button onClick={onClick} active={active} color={props.color || Colors.Default}>
    <Icon active={active} color={props.color || Colors.Default}>{icon}</Icon>
    <Label active={active}>
    {React.Children.map(children, c => React.cloneElement(c, {
        color: active ? Colors.White : (props.color || Colors.Default)
    }))}
    </Label>
</Button>)

export default IconButton