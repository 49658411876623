import React, { Component } from 'react'
import { LeadInfo } from './leadInfoModal'
import Wrapper from '../../../layouts/Wrapper'
import ConfirmModal from '../../../components/ConfirmModal'
import { BookingInfoForm } from '../../../components/forms/BookingInfoForm';
import Card from '../../../components/common/Card';
import Loading from '../../../components/common/Loading';
import apiClient from '../../../apis'

export default class AddBookingView extends Component {
    state = {
        lead: null
    }

    componentWillMount() {
        const leadId = this.props.params.leadId
        this.props.setHeader('จองห้อง')
        apiClient.lead.getLead_(0, leadId)
            .then(lead => {
                this.setState({ lead })
            })
    }

    formatLeadData(lead) {
        return {
            created: lead.created,
            email: lead.email,
            first_name: lead.first_name,
            last_name: lead.last_name,
            gender: lead.gender,
            income: lead.income,
            interest: lead.interest,
            line_id: lead.line_id,
            occupation: lead.occupation,
            phone_number: lead.phone_number,
            prospect: lead.prospect,
            qualified: lead.qualified,
            age: lead.age,
            sources: lead.sources
        }
    }

    render() {
        const { router } = this.props
        const { lead } = this.state
        if (!lead) {
            return <Loading />
        }
        return (
            <Wrapper>
                <div className="row">
                    <div className="col-sm-9">
                        <Card>
                            <ConfirmModal 
                                ref="modal"
                                title="ยืนยัน"
                                onConfirm={() => {
                                    this
                                    .refs
                                    .form.getWrappedInstance()
                                    .save()
                                    .then(() => router.push('/sale-mode/leads'))
                                }}
                            >
                                <div>
                                    <h3>กดปุ่มเพื่อยืนยันการจอง</h3>
                                </div>
                            </ConfirmModal>
                            <BookingInfoForm
                                ref="form"
                                noHeaderAndFooter
                                leadId={this.props.params.leadId}
                            />
                            <div className="d-flex flex-row my-3 justify-content-center">
                                <button
                                    onClick={() => router.goBack()}
                                    className="m-05 btn btn-save-cancel btn-outline"
                                    style={{ padding: 0 }}
                                >
                                    ยกเลิก
                                </button>
                                <button
                                    onClick={() => { this.refs.modal.openModal() }} 
                                    className="m-05 btn btn-save-cancel btn-outline"
                                    style={{ padding: 0 }}
                                >
                                    บันทึก
                                </button>
                            </div>
                        </Card>
                    </div>
                    <div className="col-sm-3">
                        <Card>
                            <LeadInfo customer={this.formatLeadData(lead)} />
                        </Card>
                    </div>
                </div>
            </Wrapper>
        )
    }
}
