import _ from 'lodash'
import {
  SURVEY_STATUS_DRAFT,

  QUESTION_TYPE_SINGLE,
  QUESTION_TYPE_MULTI,
  QUESTION_TYPE_TEXT,
  QUESTION_TYPE_TEL,
  QUESTION_TYPE_EMAIL,

} from '../common/const'
import {getRequest, postRequest, putRequest, camelToSnake} from '../common/util'

export const getSurveys = (offset, pageSize, sortKey, sortOrder, filters, query) => {
  const data = {
    offset: offset,
    page_size: pageSize
  }

  if (sortKey) {
    data.order = {
      name: camelToSnake(sortKey),
      reverse: sortOrder
        ? false
        : true
    }
  }

  if (filters) {
    data.filters = filters.map(f => {
      return {
        name: camelToSnake(f.key),
        operator: '=',
        value: f.value
      }
    })
  }

  if (query) {
    const f = data.filters || []
    f.push({name: camelToSnake('title'), operator: 'like', value: `%${query}%`})

    data.filters = f
  }

  return postRequest('/survey/list', data).then(res => {
    return {values: res.surveys, length: res.total}
  }).catch(err => {
    console.error(`getSurveys failed with ${err}`)
  })
}

export const getSurvey = surveyId => getRequest(`/survey/${surveyId}`)

export const getSurveyByAlias = alias => getRequest(`/public/survey/${alias}`)

export const createOrUpdateSurvey = surveyInfo => postRequest('/survey/save', convertSurveyInfo(surveyInfo))

export const submitSurvey = surveyInfo => postRequest('/public/survey/result', convertSurveyResultInfo(surveyInfo))

export const setSurveyPublishStatus = (surveyId, isPublish = true) => putRequest(`/survey/${surveyId}/publish/${isPublish}`, {})

export const convertSurveyInfo = info => ({
    id: info.id,
    title: info.title,
    alias: info.alias,
    description: info.description,
    cover_url: info.coverUrl,
    questions: _.map(info.questions, question => convertQuestionInfo(question)),
    status: info.status || SURVEY_STATUS_DRAFT
})

export const convertQuestionInfo = info => ({
    id: info.id,
    title: info.title,
    order: info.order,
    question_type: info.questionType,
    choices: _.map(info.choices, choice => convertChoiceInfo(choice))
})

export const convertChoiceInfo = info => ({
    id: info.id,
    title: info.title,
    order: info.order,
    value: info.value
})

export const convertSurveyResultInfo = info => {
  const contact = {
    ...info.contact
  }

  return {
    survey_id: info.id,
    session_id: info.sessionId,
    answers: _.map(info.answers, answer => convertAnswerInfo(answer)),
    contact: {
      name: contact.name,
      email: contact.email,
      tel: contact.tel,
      lint: contact.line
    }
  }
}

export const convertAnswerInfo = info => {
  let answer = info.answers

  if(typeof(info.answers) !== 'string' && typeof(info.answers.length) !== 'undefined') {
    answer = _.join(info.answers, ',')
  }
  
  return {
    question_id: info.questionId,
    question_type: info.questionType,
    answer: answer,
  }
}