import React from 'react'
import moment from 'moment'

import { PRODUCT_TYPE_CONDO } from '../../common/const'
import { formatDateTime } from '../../common/util'
import { Formatter } from '../../utilities/Formatter'
import './printable.css'


const getProductTypeString = productType => productType === PRODUCT_TYPE_CONDO ? 'ห้องชุด' : 'บ้าน'
const getProductPrefixString = productType => productType === PRODUCT_TYPE_CONDO ? 'ห้อง' : 'บ้านเลขที่'

const InspectionTemplate = ({ customerName, projectName, skuCode, defectsCount, nextInspectionDate, productType, addressNo }) => (
    <div>
        <p>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้าพเจ้า <strong>{customerName}</strong> ผู้จะซื้อ{productType === PRODUCT_TYPE_CONDO ? 'ห้องชุด' : 'ที่ดินพร้อมสิ่งปลูกสร้าง'} โครงการ{productType === PRODUCT_TYPE_CONDO ? 'แฮปปี้ คอนโด ดอนเมือง เดอะ เทอมินอล' : 'แฮปปี้ วิลล์ ดอนเมือง'} <strong>{projectName} {getProductPrefixString(productType)} {skuCode}</strong> {addressNo ? `(บ้านเลขที่ ${addressNo})` : null }จาก {productType === PRODUCT_TYPE_CONDO ? 'บริษัท อินฟินิท เรียลเอสเตท จำกัด' : 'บริษัท นิรันดร์พลาซ่า จำกัด'} ผู้จะขาย  
        </p>
        <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ผู้จะซื้อได้เข้าตรวจสอบ{productType === PRODUCT_TYPE_CONDO ? 'ห้องชุด' : 'ที่ดินพร้อมสิ่งปลูกสร้าง'}ดังกล่าวแล้ว มีรายการแก้ไขจำนวน <strong>{defectsCount}</strong> รายการ ไม่มีรายการแก้ไข อื่นใดอีก ตามเอกสารแนบท้ายหนังสือฉบับนี้
        </p>
        <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ดังนั้น นับจากวันที่ลงนามในเอกสารฉบับนี้ ผู้จะซื้อจะไม่แจ้งรายการแก้ไขเพิ่มเติมนอกจากรายการที่ แจ้งไว้ในเอกสาร ฉบับนี้อีก และผู้จะขายจะดำเนินการแก้ไขให้กับผู้จะซื้อให้เรียบร้อย ซึ่งผู้จะขายจะเชิญผู้จะซื้อ มาตรวจสอบการแก้ไขดังกล่าวครั้ง ต่อไปในภายหลังเมื่อทำการแก้ไขแล้วเสร็จ
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;จึงลงลายมือชื่อไว้ต่อหน้าพยาน</p>
    </div>
)

const InspectionTemplateOld = ({ customerName, projectName, skuCode, defectsCount, nextInspectionDate, productType, addressNo }) => (
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้าพเจ้า <strong>{customerName}</strong> ผู้จะซื้อ<strong>{getProductTypeString(productType)}</strong> โครงการ <strong>{projectName} {getProductPrefixString(productType)} {skuCode}</strong> {addressNo ? `(บ้านเลขที่ ${addressNo})` : null } ได้ตรวจสอบ<strong>{getProductTypeString(productType)}</strong>ดังกล่าวแล้ว มีรายการแก้ไขจำนวน <strong>{defectsCount}</strong> รายการ โดยผู้จะขายจะดำเนินการแก้ไขให้กับผู้จะซื้อให้เรียบร้อย และผู้จะขายจะเชิญผู้จะซื้อมาตรวจสอบการแก้ไขดังกล่าวครั้งต่อไป<strong>{nextInspectionDate === 'ไม่ระบุ' ? 'ในภายหลัง' : `ในวันที่ ${formatDateTime(nextInspectionDate)}`}</strong> จึงลงลายมือชื่อไว้ต่อหน้าพยาน</p>
)

const TransferTemplate = ({ customerName, projectName, skuCode, productType, addressNo }) => (
    <div>
        <p>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้าพเจ้า <strong>{customerName}</strong> ผู้จะซื้อ{productType === PRODUCT_TYPE_CONDO ? 'ห้องชุด' : 'ที่ดินพร้อมสิ่งปลูกสร้าง'} โครงการ{productType === PRODUCT_TYPE_CONDO ? 'แฮปปี้ คอนโด ดอนเมือง เดอะ เทอมินอล' : 'แฮปปี้ วิลล์ ดอนเมือง'} <strong>{projectName} {getProductPrefixString(productType)} {skuCode}</strong> {addressNo ? `(บ้านเลขที่ ${addressNo})` : null }จาก {productType === PRODUCT_TYPE_CONDO ? 'บริษัท อินฟินิท เรียลเอสเตท จำกัด' : 'บริษัท นิรันดร์พลาซ่า จำกัด'} ผู้จะขาย  
        </p>
        <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ผู้จะซื้อได้เข้าตรวจสอบ{productType === PRODUCT_TYPE_CONDO ? 'ห้องชุด' : 'ที่ดินพร้อมสิ่งปลูกสร้าง'}ดังกล่าวแล้ว มีความเรียบร้อยสมบูรณ์ดี สามารถเข้าครอบครองใช้ประโยชน์ในทรัพย์สิน และพักอาศัยได้ตามปกติ โดยไม่มีรายการแก้ไขอื่นใดที่ผู้จะขายจะต้องแก้ไขเพิ่มเติมอีก
        </p>
        <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ดังนั้น นับจากวันที่ลงนามในเอกสารฉบับนี้ ผู้จะซื้อตกลงว่าจะทำการรับโอนกรรมสิทธิ์ห้องชุดจากผู้จะขายต่อไป ภายใน ระยะเวลาที่ตกลงกันในสัญญาจะซื้อจะขาย
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;จึงลงลายมือชื่อไว้ต่อหน้าพยาน</p>
    </div>
)

const TransferTemplateOld = ({ customerName, projectName, skuCode, productType, addressNo }) => (
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้าพเจ้า <strong>{customerName}</strong> ผู้จะซื้อ<strong>{getProductTypeString(productType)}</strong> โครงการ <strong>{projectName} {getProductPrefixString(productType)} {skuCode}</strong> {addressNo ? `(บ้านเลขที่ ${addressNo})` : null } ได้ตรวจสอบ<strong>{getProductTypeString(productType)}</strong>ดังกล่าวแล้ว มีความเรียบร้อยสมบูรณ์ สามารถเข้าครอบครองและใช้ประโยชน์ในการพักอาศัย โดยไม่มีรายการอื่นใดที่ผู้จะขายจะต้องแก้ไขเพิ่มเติมอีก จึงลงลายมือชื่อไว้ต่อหน้าพยาน และยินยอมที่จะรับโอนกรรมสิทธิ์<strong>ห้องชุด</strong>จากผู้จะขายต่อไป</p>
)

const CoverPage = ({ projectName, customerName, skuCode, defectsCount, staffName, witnessName, customerSignature, staffSignature, witnessSignature, buildingType, nextInspectionDate, logo, productType, address, addressNo }) => {
    const newLineIndex = address.indexOf('เขต')
    const addressLine1 = address.slice(0, newLineIndex)
    const addressLine2 = address.slice(newLineIndex)
    const createDocDate = moment()
    const day = createDocDate.date()
    const month = Formatter.formatMonthNumberToTh(createDocDate.month() + 1)
    const year = createDocDate.year() + 543
    return (
    <div className="a4">
        <div className="print-container">
            <div>
                <div className="header">
                    {/* <img alt="" src={logo} className="logo-infinite"/> */}
                    <p className="h3">
                        {
                            defectsCount > 0 ? 
                            `เอกสารตรวจรับ${productType === PRODUCT_TYPE_CONDO ? 'ห้องชุด' : 'ที่ดินพร้อมสิ่งปลูกสร้าง'} โครงการ ${productType === PRODUCT_TYPE_CONDO ? 'แฮปปี้ คอนโด ดอนเมือง เดอะ เทอมินอล' : 'แฮปปี้ วิลล์ ดอนเมือง'}` : 
                            `เอกสารรับมอบ${productType === PRODUCT_TYPE_CONDO ? 'ห้องชุด' : 'ที่ดินพร้อมสิ่งปลูกสร้าง'} โครงการ ${productType === PRODUCT_TYPE_CONDO ? 'แฮปปี้ คอนโด ดอนเมือง เดอะ เทอมินอล' : 'แฮปปี้ วิลล์ ดอนเมือง'}`
                        }
                    </p>
                    {/* <p className="h3">โครงการ {projectName}</p> */}
                </div>
                <div style={{textAlign: 'right', margin: '32px'}}>
                    <p style={{fontSize: '14px'}}>ทำที่ โครงการ {productType === PRODUCT_TYPE_CONDO ? 'แฮปปี้ คอนโด ดอนเมือง เดอะ เทอมินอล' : 'แฮปปี้ วิลล์ ดอนเมือง'}</p>
                    <p style={{fontSize: '14px'}}>วันที่..{day}..เดือน..{month}..พ.ศ..{year}..</p>
                </div>
                <div className="summary-content">
                    { defectsCount > 0
                        ? <InspectionTemplate
                            customerName={customerName}
                            projectName={projectName}
                            skuCode={skuCode}
                            defectsCount={defectsCount}
                            nextInspectionDate={nextInspectionDate}
                            productType={productType}
                            addressNo={addressNo}
                        />
                        : <TransferTemplate
                            customerName={customerName}
                            projectName={projectName}
                            skuCode={skuCode}
                            productType={productType}
                            addressNo={addressNo}
                        />
                    }
                </div>
                <div className="summary-signature">
                    <div className="signature">
                        <p>ลงชื่อ <img alt="" src={customerSignature || '/img/blank.png'} /> ผู้จะซื้อ</p>
                        <p className="signature-name"><span>(</span><strong>{customerName}</strong><span>)</span></p>
                    </div>
                    <div className="signature">
                        <p>ลงชื่อ <img alt="" src={staffSignature || '/img/blank.png'} className=""/> ผู้จะขาย</p>
                        <p className="signature-name"><span>(</span><strong>{staffName}</strong><span>)</span></p>
                    </div>
                    <div className="signature">
                        <p>ลงชื่อ <img alt="" src={witnessSignature || '/img/blank.png'} className=""/> พยาน</p>
                        <p className="signature-name"><span>(</span><strong>{witnessName}</strong><span>)</span></p>
                    </div>
                </div>
            </div>
            <div>
                <div className="footer">
                    <img alt="" src={logo} className="logo-project"/>
                    <p className="footer-text">{ addressLine1 }<br />
                        { addressLine2 }
                    </p>
                </div>
                <p className="page-num">1</p>
            </div>
        </div>
    </div>
  )
}

export default CoverPage
