import React, { Component } from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import moment from 'moment'

import { BasicInfoContent } from '../../../components/BasicInfoCard'
import DefectInfoModal from './DefectInfoModal'
import DefectsTable from './DefectsTable'
import SelectStaffModal from '../../../components/forms/SelectStaffModal'
import { Formatter } from '../../../utilities/Formatter';

export default class InspectionInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedDefect: null,
        }
    }

    rowClickCallback = defect => {
        this.setState({ selectedDefect: defect })
        this.refs.infoModal.openModal()
    }

    render() {
        const { inspection, productId } = this.props
        console.log(inspection)

        if (!inspection) {
            return null
        }

        return (
            <div>
                <Title inspectionNo={ inspection.inspectionNo } />
                <InspectionSummary
                    inspectionDate={ inspection.inspectionDate} 
                    engineerName={ inspection.engineerName } 
                    staffName={ inspection.staffName } 
                />

                <hr className="my-0" style={{ marginLeft: "-1.5rem", marginRight: "-1.5rem" }} />

                <DefectsTable 
                    defects={ inspection.defects } 
                    status={ inspection.status }
                    rowClickCallback={ this.rowClickCallback }
                    inspectionId={ inspection.id }
                    productId={ productId }
                />
                <DefectInfoModal 
                    ref="infoModal" 
                    defect={ this.state.selectedDefect }
                    uploadHandler={ v => console.log(v) }
                />
            </div>
        )
    }
}

InspectionInfo.propTypes = {
    inspection: PropTypes.object.isRequired,
    productId: PropTypes.string,
}

const Title = ({ inspectionNo }) => {
    return (
        <div 
            className="pl-15 pt-1 pb-05" 
            style={{ 
                backgroundColor: "#E3F5FB", 
                marginLeft: "-1.5rem", 
                marginRight: "-1.5rem" 
            }}
        >
            <h6>{`ตรวจคุณภาพครั้งที่ ${inspectionNo}`}</h6>
        </div>
    )
}

class InspectionSummary extends Component {
    assignStaff(staffId) {
        console.log(staffId)
    }

    render() {
        const { inspectionDate, engineerName, staffName, } = this.props

        // Hack to change CE to BE.
        const datetime = inspectionDate
            ? moment(inspectionDate, 'YYYY-MM-DD HH:mm:ss+Z')
            : null

        const dt = `${Formatter.formatDateTh(datetime)} (${datetime.format("HH:mm")})`

        const data = [
            [
                { label: 'วันที่ตรวจ', value: dt },
            ],
            [
                { label: 'ผู้ตรวจ', value: staffName },
            ]
        ]

        return <div>
            <BasicInfoContent content={ data } />
            <SelectStaffModal 
                ref="staffModal" 
                assignStaff={ this.assignStaff }
            />
        </div>
    }
}
