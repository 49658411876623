import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import _ from 'lodash'

import config from '../../../common/config'
import ConfirmDeleteDocumentModal from './ConfirmDeleteDocumentModal'
import SelectFileModal from './SelectFileModal'
import AddLoanDocumentModal from './AddLoanDocumentModal'
import Table from '../../../components/common/tables/Table'

class DocumentList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showDocument: false,
            selectedDocumentId: 0,
            selectedFilename: '',
        }

        this.deleteButtonClickHandler = this.deleteButtonClickHandler.bind(this)
        this.cancelButtonClickHandler = this.cancelButtonClickHandler.bind(this)
        this.uploadButtonClickHandler = this.uploadButtonClickHandler.bind(this)
        this.downloadButtonClickHandler = this.downloadButtonClickHandler.bind(this)
        this.deleteDocumentButtonClickHandler = this.deleteDocumentButtonClickHandler.bind(this)

        this.columns = [
            {
                header: '',
                accessor: 'status',
                render: value => <StatusIcon status={ value } />,
                width: 0.25
            },
            {
                header: '',
                accessor: 'name',
                subaccessor: 'url',
                render: (value, subvalue) => {
                    return ( 
                        <div className="h-100 d-flex flex-column justify-content-center">
                            <h6>{ value }</h6> 
                            <p className="f13 grey mb-0">{ subvalue || 'ไม่มีไฟล์' }</p>
                        </div>
                    )
                }
            },
            {
                header: '',
                accessor: 'status',
                subaccessor: ['name', 'id'],
                filterable: true,
                width: 3,
                render: (value, subvalue) => <UploadButton 
                    status={ value }
                    filename={ subvalue[0] }
                    documentId={ subvalue[1] }
                    deleteButtonClickHandler={ this.deleteButtonClickHandler }
                    cancelButtonClickHandler={ this.cancelButtonClickHandler }
                    uploadButtonClickHandler={ this.uploadButtonClickHandler }
                    deleteDocumentButtonClickHandler={ this.deleteDocumentButtonClickHandler }
                    downloadButtonClickHandler={ this.downloadButtonClickHandler }
                />
            },
        ]
    }

    deleteButtonClickHandler(filename, documentId) {
        this.setState({ 
            selectedDocumentId: documentId,
            selectedFilename: filename
        })

        this.refs.confirmDeleteModal.refs.modal.openModal()
    }

    uploadButtonClickHandler(documentId) {
        this.setState({ 
            selectedDocumentId: documentId
        })

        this.refs.selectFileModal.refs.modal.openModal()
    }

    cancelButtonClickHandler() {
        console.log("cancel uploading ...")
    }

    downloadButtonClickHandler(documentId) {
        console.log(`downloading ${documentId} ..`)
        this.props.downloadHandler(documentId)
    }

    deleteDocumentButtonClickHandler(documentId) {
        console.log(`deleting ${documentId}`)
        this.props.deleteDocumentHandler(documentId)
    }

    render() {
        const { documents, header, footer, uploadHandler, deleteHandler, addDocumentHandler } = this.props
        return (
            <div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                    { header }
                    <div>
                        <button 
                            className="btn btn-outline" 
                            style={{ width: "11rem" }}
                            onClick={ () => this.setState({ 
                                showDocument: !this.state.showDocument 
                            })} 
                        >
                            { this.state.showDocument
                                ? ('ซ่อนรายการเอกสาร')
                                : ('ดูรายการเอกสาร') 
                            }
                        </button>
                    </div>
                </div> 

                { this.state.showDocument
                    ? <div className="mt-15">
                        { _.isEmpty(documents)
                            ? <p className="f14 text-center grey">
                                <span>ยังไม่มีรายการเอกสาร</span>
                            </p>
                            : <Table 
                                noColumnHeader 
                                noHeader
                                columns={ this.columns } 
                                values={ documents } 
                                itemsPerPage={ config.itemsPerPage } 
                                rowClickCallback={ this.rowClickCallback } 
                            />
                        }
                        <AddNewDocumentButton 
                            handler={ this.refs.addDocumentModal.refs.modal.openModal }
                        />
                    </div>
                    : null 
                }

                { footer }

                <ConfirmDeleteDocumentModal 
                    ref="confirmDeleteModal" 
                    text={ `คุณต้องการลบเอกสาร "${ this.state.selectedFilename }" หรือไม่?` } 
                    deleteHandler={ () => deleteHandler(this.state.selectedDocumentId) }
                />
                <SelectFileModal 
                    ref="selectFileModal" 
                    uploadHandler={ fileData => uploadHandler(this.state.selectedDocumentId, fileData) } 
                />
                <AddLoanDocumentModal
                    ref="addDocumentModal"
                    addDocumentHandler={ filename => addDocumentHandler(filename)}
                />
            </div>
        )
    }
}

DocumentList.propTypes = {
    documents: PropTypes.array,
    header: PropTypes.node,
    footer: PropTypes.node,
    uploadHandler: PropTypes.func,
    deleteHandler: PropTypes.func,
    addDocumentHandler: PropTypes.func,
    deleteDocumentHandler: PropTypes.func,
    downloadDocumentHandler: PropTypes.func,
}

export default DocumentList

export const FILE_STATUS = {
    uploaded: 'UPLOADED',
    uploading: 'UPLOADING',
    notUploaded: 'NOTUPLOADED'
}

const UploadButton = ({ 
    status, 
    filename,
    documentId,
    deleteButtonClickHandler,
    cancelButtonClickHandler,
    uploadButtonClickHandler,
    downloadButtonClickHandler,
    deleteDocumentButtonClickHandler,
}) => {
    let button
    switch (status) {
        case FILE_STATUS.uploaded:
            button = <div className="d-flex flex-row align-items-center">
                <Link 
                    className='link mr-15' 
                    onClick={ () => downloadButtonClickHandler(documentId) }
                >
                    <i className="fa fa-eye" style={{ fontSize:"1.5rem" }} />
                </Link>
                <Link 
                    className='link-warning' 
                    onClick={ () => deleteButtonClickHandler(filename, documentId) }
                >
                    <i className="fa fa-trash" style={{ fontSize:"1.5rem" }} />
                </Link>
            </div>
            break
        case FILE_STATUS.uploading:
            button = <div className="d-flex flex-row">
            <button 
                disabled
                className='btn btn-main mr-1' 
                style={{ width: "120px" }}
                onClick={ () => uploadButtonClickHandler(documentId) }
            >อัพโหลด</button>
            <button
                disabled
                className='btn btn-red-outline' 
                style={{ width: "80px" }}
                onClick={ () => deleteDocumentButtonClickHandler(documentId) }
            >ลบ</button>
            </div>
            break
        case FILE_STATUS.notUploaded:
            button = <div className="d-flex flex-row">
            <button 
                className='btn btn-main mr-1' 
                style={{ width: "120px" }}
                onClick={ () => uploadButtonClickHandler(documentId) }
            >อัพโหลด</button>
            <button 
                className='btn btn-red-outline' 
                style={{ width: "80px" }}
                onClick={ () => deleteDocumentButtonClickHandler(documentId) }
            >ลบ</button>
            </div>
            break
        default:
            button = null
    }

    return (
        <div className="h-100 d-flex flex-column justify-content-center align-items-end">
            { button }
        </div>
    )
}

const StatusIcon = ({ status }) => {
    let src
    switch (status) {
        case FILE_STATUS.uploaded:
            src = "/img/i_uploaded.svg"
            break
        case FILE_STATUS.uploading:
            src = "/img/i_uploading.gif" 
            break
        case FILE_STATUS.notUploaded:
            src = "/img/i_not_uploaded.svg"
            break
        default:
            src = "/img/add.png"
    }

    return (
        <div className="h-100 d-flex flex-column justify-content-center"> 
            <img alt="" src={src} height="24px" width="24px" />
        </div>
    )
}

const AddNewDocumentButton = ({ handler }) => {
    return (
        <div 
            className="clickable"
            onClick={ handler }
            style={{ maxWidth:"160px"}}
        >
            <p className="f14 mb-0 ml-1">
                <i className="fa fa-plus grey" style={{ width: "24px" }} />
                <span>เพิ่มรายการเอกสาร</span>
            </p>
        </div>
    )
}